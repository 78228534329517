import {createStore,combineReducers} from 'redux';
import toggleSchool from './Reducers/schoolsReducer';
import toggleSalon from './Reducers/salonsReducer';
import toggleInscriptions from "./Reducers/inscriptionsReducer";
import toggleListSalons from "./Reducers/salonListReducer";

const rootReducer = combineReducers({
    school: toggleSchool,
    salon: toggleSalon,
    inscriptions: toggleInscriptions,
    listSalons: toggleListSalons
});

export default createStore(rootReducer);