import React from 'react';
import {View, Image, TouchableOpacity, Text, StyleSheet, Dimensions, Platform} from 'react-native';
import {connect} from "react-redux";
import { isIphoneX, getBottomSpace} from 'react-native-iphone-x-helper';
class FooterForm extends React.Component {
    constructor(props) {
        super(props);
    }

    _toggleSchool = (type) => {
        const action = {type: type};
        this.props.dispatch(action);
    };

    renderBackButton = (styles,tablet,func) => {
        return (
            <TouchableOpacity style={[styles.buttonTouch]}
                              onPress={func}>
                <View
                    style={[tablet ? styles.buttonContainer : styles.mobileButtonContainer, tablet ? styles.backButton : styles.mobileBackButton]}>
                    <View style={styles.buttonTextView}>
                        <Text style={tablet ? styles.buttonText : styles.mobileButtonText}>RETOUR</Text>
                    </View>
                </View>
            </TouchableOpacity>
        );
    };

    renderNextButton = (styles,tablet,func) => {
        return (
            <TouchableOpacity style={[styles.buttonTouch]}
                              onPress={func}>
                <View style={[tablet ? styles.buttonContainer : styles.mobileButtonContainer, tablet ? styles.nextButton : styles.mobileNextButton]}>
                    <View style={styles.buttonTextView}>
                        <Text style={tablet ? styles.buttonText : styles.mobileButtonText}>SUIVANT</Text>
                    </View>
                </View>
            </TouchableOpacity>
        );
    };

    render() {
        let width = Dimensions.get('window').width;
        let tablet = width > 480;
        const primaryColor = this.props.color;
        const styles = StyleSheet.create({
            footerView: {
                height: '100%',
                width: '100%',
                zIndex: 1,
                paddingBottom: isIphoneX() ? getBottomSpace() - 20 : 0,
                position: 'absolute',
                bottom: 0,
                flexDirection: 'column-reverse'
            },
            buttonText: {
                height: 23,
                fontFamily: "HelveticaNeue",
                fontSize: 20,
                fontWeight: "normal",
                fontStyle: "normal",
                lineHeight: 24,
                letterSpacing: 0,
                textAlign: "left",
                color: "#ffffff"
            },
            buttonContainer: {
                justifyContent: 'center',
                alignItems: 'center',
                transform: [{skewX: '-40deg'}],
                width: "100%",
                height: 65,
            },
            buttonTextView: {
                transform: [{skewX: '40deg'}]
            },
            backButton: {
                left: Platform.OS == "ios" ? -30 : 0,
                backgroundColor: "#7b7b7b",
                alignSelf: 'flex-start'
            },
            nextButton: {
                right:Platform.OS == "ios" ? -30 : 0,
                backgroundColor: primaryColor,
                alignSelf: 'flex-end'
            },
            buttonTouch: {
                width: "25%",
            },
            mobileButtonContainer: {
                justifyContent: 'center',
                alignItems: 'center',
                transform: [{skewX: '-40deg'}],
                width: "100%",
                height: 32,
            },
            mobileBackButton: {
                left: Platform.OS === "ios" ? -15 : 0,
                backgroundColor: "#7b7b7b",
                alignSelf: 'flex-start'
            },
            mobileNextButton: {
                right: Platform.OS === "ios" ? -15 : 0,
                backgroundColor: primaryColor,
                alignSelf: 'flex-end'
            },
            mobileButtonText: {
                fontFamily: "HelveticaNeue",
                fontSize: 12,
                fontWeight: "normal",
                fontStyle: "normal",
                lineHeight: 24,
                letterSpacing: 0,
                textAlign: "left",
                color: "#ffffff"
            }
        });
        return (
            <View style={styles.footerView}>
                {this.props.actualFormState === "chooseStudy" ?
                    <View style={{flexDirection: 'row', justifyContent: "space-between"}}>
                        {this.renderBackButton(styles,tablet,() => {
                            this.props.navigation.goBack();
                            this._toggleSchool('TOGGLE_YSCHOOLS');
                        })}
                    </View>
                    : null}


                {this.props.actualFormState === "chooseInterest" ?
                    <View style={{flexDirection: 'row', justifyContent: "space-between"}}>

                        {this.renderBackButton(styles,tablet,() => this.props.actualiseFormState({
                            formState: 'chooseStudy',
                            interest: []
                        }))}

                        {this.renderNextButton(styles,tablet,() => this.props.actualiseFormState({formState: 'courseFormat'}))}

                    </View>
                    : null}

                {this.props.actualFormState === "chooseCampus" ?
                    <View style={{flexDirection: 'row', justifyContent: "space-between"}}>

                        {this.renderBackButton(styles,tablet,() => this.props.actualiseFormState({
                            formState: 'chooseInterest',
                            interest: []
                        }))}

                        {this.renderNextButton(styles,tablet,() => this.props.actualiseFormState({formState: 'enterInfos'}))}

                    </View>
                    : null}
                {this.props.actualFormState === "courseFormat" ?
                    <View style={{flexDirection: 'row', justifyContent: "space-between"}}>

                        {this.renderBackButton(styles,tablet,() => this.props.actualiseFormState({
                            formState: 'chooseInterest',
                            selectedCourseFormat: ''
                        }))}
                    </View>
                    : null}
                {this.props.actualFormState === "enterInfos" ?
                    <View style={{flexDirection: 'row', justifyContent: "space-between"}}>
                        {this.renderBackButton(styles,tablet,() => this.props.actualiseFormState({
                            formState: 'chooseInterest',
                            formValide: null,
                            campus : []
                        }))}

                        {this.renderNextButton(styles,tablet,() => this.props.saveForm())}
                    </View>
                    : null}

                {this.props.actualFormState === "confirmation" ?
                    <View style={{flexDirection: 'row', justifyContent: "space-between"}}>
                        {this.renderBackButton(styles,tablet,() => {
                            this.props.navigation.goBack();
                            this._toggleSchool('TOGGLE_YSCHOOLS');
                        })}
                    </View>
                    : null}
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    return state.school;
};

export default connect(mapStateToProps)(FooterForm)
