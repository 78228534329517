import Colors from '../../Constants/Colors';

const initialState = {
    school:'yschools',
    schoolLogo:require('../../../assets/schools_logos/yschools.png'),
    color:Colors.yschools,
    adminLogo:require('../../../assets/buttons/admin/admin_blue.png'),
    backgroundImage:require('../../../assets/backgrounds/yschools_background.png'),
    arrow:require('../../../assets/arrows/arrow_yschools.png'),
};

function toggleSchool(state = initialState, action){
    let nextState;

    switch(action.type){
        case 'TOGGLE_YSCHOOLS':
            nextState={
                ...state,
                school:'yschools',
                schoolLogo:require('../../../assets/schools_logos/yschools.png'),
                adminLogo:require('../../../assets/buttons/admin/admin_blue.png'),
                color:Colors.yschools,
                backgroundImage:require('../../../assets/backgrounds/yschools_background.png'),
                arrow:require('../../../assets/arrows/arrow_yschools.png'),

                //schoolInfos:null
            };
            return nextState || state;

        case 'TOGGLE_SCBS':
            nextState={
                ...state,
                school:'scbs',
                schoolLogo:require('../../../assets/schools_logos/scbs_head.png'),
                adminLogo:require('../../../assets/buttons/admin/admin_scbs.png'),
                color:Colors.scbs,
                backgroundImage:require('../../../assets/backgrounds/scbs_background.png'),
                schoolInfos:require('../../Datas/Schools/scbsDatas'),
                arrow:require('../../../assets/arrows/arrow_scbs.png'),

            };
            return nextState || state;

        case 'TOGGLE_PFEP':
            nextState={
                ...state,
                school:'pfep',
                schoolLogo:require('../../../assets/schools_logos/pfep_head.png'),
                adminLogo:require('../../../assets/buttons/admin/admin_pfep.png'),
                color:Colors.pfep,
                backgroundImage:require('../../../assets/backgrounds/pfep_background.png'),
                schoolInfos:require('../../Datas/Schools/pfepDatas'),
                arrow:require('../../../assets/arrows/arrow_pfep.png'),

            };
            return nextState || state;

        case 'TOGGLE_PSS':
            nextState={
                ...state,
                school:'pss',
                schoolLogo:require('../../../assets/schools_logos/pss_head.png'),
                adminLogo:require('../../../assets/buttons/admin/admin_pss.png'),
                color:Colors.pss,
                backgroundImage:require('../../../assets/backgrounds/pss_background.png'),
                schoolInfos:require('../../Datas/Schools/pssDatas'),
                arrow:require('../../../assets/arrows/arrow_pss.png'),
            };
            return nextState || state;
        case 'TOGGLE_DESIGN':
            nextState={
                ...state,
                school:'design',
                schoolLogo:require('../../../assets/schools_logos/design_head.png'),
                adminLogo:require('../../../assets/buttons/admin/admin_design.png'),
                color:Colors.design,
                backgroundImage:require('../../../assets/backgrounds/esd_background.png'),
                schoolInfos:require('../../Datas/Schools/designDatas'),
                arrow:require('../../../assets/arrows/arrow_design.png'),
            };
            return nextState || state;
        case 'TOGGLE_TOURISME':
            nextState={
                ...state,
                school:'tourisme',
                schoolLogo:require('../../../assets/schools_logos/tourisme_head.png'),
                adminLogo:require('../../../assets/buttons/admin/admin_tourisme.png'),
                color:Colors.tourisme,
                backgroundImage:require('../../../assets/backgrounds/tourisme_background.png'),
                schoolInfos:require('../../Datas/Schools/toursimeDatas'),
                arrow:require('../../../assets/arrows/arrow_tourisme.png'),
            };
            return nextState || state;
        default:
            return state;
    }
}

export default toggleSchool;