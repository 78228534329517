import React from 'react';
import { StatusBar, Dimensions, Platform, YellowBox } from 'react-native';
import { Provider } from 'react-redux';
import Navigation from "./src/Navigation/Navigation";
// import DbManager from "./src/Utils/db.manager";
import Store from './src/Store/configureStore';
import * as Font from 'expo-font';
import AppLoading from 'expo-app-loading';

export default class App extends React.Component {

    constructor(props) {
        super(props);
        // DbManager.createDb();
        StatusBar.setHidden(true);
    }

    state = {
        isLoadingComplete: false
    };

    render() {
        YellowBox.ignoreWarnings([
            'Warning: componentWillMount is deprecated',
            'Warning: componentWillReceiveProps is deprecated',
            'Module RCTImageLoader requires',
        ]);

        if (!this.state.isLoadingComplete && !this.props.skipLoadingScreen) {
            return (
                <AppLoading
                    startAsync={this._loadResourcesAsync}
                    onError={this._handleLoadingError}
                    onFinish={this._handleFinishLoading}
                />
            );
        } else {
            return (
                <Provider store={Store}>
                    <Navigation />
                </Provider>
            );
        }
    }

    _loadResourcesAsync = async () => {
        return Promise.all([
            Font.loadAsync({
                'HelveticaNeue': require('./assets/fonts/helveticaneue/HelveticaNeue_Light.ttf'),
            }),
        ]);
    };


    _handleLoadingError = error => {
        console.warn(error);
    };

    _handleFinishLoading = () => {
        this.setState({ isLoadingComplete: true });
    };

}