const initialState = {
    newSalonList: false
};

function toggleListSalon(state = initialState, action){
    let nextState;
    switch(action.type){
        case 'UPDATE_LIST_SALONS':
            nextState={
                ...state,
                newSalonList: action.needUpdate
            };
            return nextState || state;
        default:
            return state;
    }
}

export default toggleListSalon;