export default {
    formations: [
        {
            'name': '3ème',
            'availableStudy': [
                "PREPA AIDE SOIGNANT",
                "PREPA AUXILIAIRE DE PUERICULTURE",
                "PREPA MONITEUR EDUCATEUR"
            ]
        },
        {
            'name': '2nde',
            'availableStudy': [
                "PREPA AIDE SOIGNANT",
                "PREPA AUXILIAIRE DE PUERICULTURE",
                "PREPA MONITEUR EDUCATEUR"
            ]
        },
        {
            'name': 'CAP',
            'availableStudy': [
                "PREPA AIDE SOIGNANT",
                "PREPA AUXILIAIRE DE PUERICULTURE",
                "PREPA MONITEUR EDUCATEUR"
            ]
        },
        {
            'name': 'BEP',
            'availableStudy': [
                "PREPA AIDE SOIGNANT",
                "PREPA AUXILIAIRE DE PUERICULTURE",
                "PREPA MONITEUR EDUCATEUR"
            ]
        },
        {
            'name': '1ère',
            'availableStudy': [
                "PREPA AIDE SOIGNANT",
                "PREPA AUXILIAIRE DE PUERICULTURE",
                "PREPA MONITEUR EDUCATEUR",
                "PREPA ASSISTANT DE SERVICE SOCIAL",
                "PREPA EDUCATEUR DE JEUNES ENFANTS",
                "PREPA EDUCATEUR SPECIALISE",
            ]
        },
        {
            'name': 'Terminale/BAC',
            'availableStudy': [
                "PREPA AIDE SOIGNANT",
                "PREPA AUXILIAIRE DE PUERICULTURE",
                "PREPA MONITEUR EDUCATEUR",
                "PREPA ASSISTANT DE SERVICE SOCIAL",
                "PREPA EDUCATEUR DE JEUNES ENFANTS",
                "PREPA EDUCATEUR SPECIALISE",
            ]
        },
        {
            'name': 'BAC+1',
            'availableStudy': [
                "PREPA AIDE SOIGNANT",
                "PREPA AUXILIAIRE DE PUERICULTURE",
                "PREPA MONITEUR EDUCATEUR",
                "PREPA ASSISTANT DE SERVICE SOCIAL",
                "PREPA EDUCATEUR DE JEUNES ENFANTS",
                "PREPA EDUCATEUR SPECIALISE",
            ]
        },
        {
            'name': 'BAC+2',
            'availableStudy': [
                "PREPA AIDE SOIGNANT",
                "PREPA AUXILIAIRE DE PUERICULTURE",
                "PREPA MONITEUR EDUCATEUR",
                "PREPA ASSISTANT DE SERVICE SOCIAL",
                "PREPA EDUCATEUR DE JEUNES ENFANTS",
                "PREPA EDUCATEUR SPECIALISE",
            ]
        },
        {
            'name': 'BAC+3',
            'availableStudy': [
                "PREPA AIDE SOIGNANT",
                "PREPA AUXILIAIRE DE PUERICULTURE",
                "PREPA MONITEUR EDUCATEUR",
                "PREPA ASSISTANT DE SERVICE SOCIAL",
                "PREPA EDUCATEUR DE JEUNES ENFANTS",
                "PREPA EDUCATEUR SPECIALISE",
            ]
        },
        {
            'name': 'BAC+4',
            'availableStudy': [
                "PREPA AIDE SOIGNANT",
                "PREPA AUXILIAIRE DE PUERICULTURE",
                "PREPA MONITEUR EDUCATEUR",
                "PREPA ASSISTANT DE SERVICE SOCIAL",
                "PREPA EDUCATEUR DE JEUNES ENFANTS",
                "PREPA EDUCATEUR SPECIALISE",
            ]
        },
        {
            'name': 'BAC+5',
            'availableStudy': [
                "PREPA AIDE SOIGNANT",
                "PREPA AUXILIAIRE DE PUERICULTURE",
                "PREPA MONITEUR EDUCATEUR",
                "PREPA ASSISTANT DE SERVICE SOCIAL",
                "PREPA EDUCATEUR DE JEUNES ENFANTS",
                "PREPA EDUCATEUR SPECIALISE",
            ]
        },
        {
            'name': 'Autre',
            'availableStudy': [
                "PREPA AIDE SOIGNANT",
                "PREPA AUXILIAIRE DE PUERICULTURE",
                "PREPA MONITEUR EDUCATEUR",
                "PREPA ASSISTANT DE SERVICE SOCIAL",
                "PREPA EDUCATEUR DE JEUNES ENFANTS",
                "PREPA EDUCATEUR SPECIALISE",
            ]
        },
    ],
    campus: null,
    diplomes:
        [
            {
                key: 'BAC',
                label: 'BAC Général',
                value: 'BAC'

            },
            {
                key: 'BAC Techno',
                label: 'BAC Techno',
                value: 'BAC Techno'
            },
            {
                key: 'BAC PRO',
                label: 'BAC PRO',
                value: 'BAC PRO'
            },
            {
                key: 'PREPA ECE',
                label: 'PREPA ECE',
                value: 'PREPA ECE'
            },
            {
                key: 'PREPA ECP',
                label: 'PREPA ECP',
                value: 'PREPA ECP'
            },
            {
                key: 'PREPA ECS',
                label: 'PREPA ECS',
                value: 'PREPA ECS'
            },
            {
                key: 'PREPA ECT',
                label: 'PREPA ECT',
                value: 'PREPA ECT'
            },
            {
                key: 'BTS',
                label: 'BTS',
                value: 'BTS'
            },
            {
                key: 'DUT',
                label: 'DUT',
                value: 'DUT'
            },
            {
                key: 'LICENCE',
                label: 'LICENCE',
                value: 'LICENCE'
            },
            {
                key: 'LICENCE PRO',
                label: 'LICENCE PRO',
                value: 'LICENCE PRO'
            },
            {
                key: 'BACHELOR',
                label: 'BACHELOR',
                value: 'BACHELOR'
            },
            {
                key: 'MASTER',
                label: 'MASTER',
                value: 'MASTER'
            },
            {
                key: 'Autre',
                label: 'Autre',
                value: 'Autre'
            }
        ],
    immersionDay: false,
    jpo: false,
    privateVisit: false,
};