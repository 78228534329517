import React from 'react';
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity, ImageBackground, Dimensions
} from 'react-native';
import * as SQLite from 'expo-sqlite';
import Colors from '../Constants/Colors';
import {connect} from 'react-redux';
import {SchoolButton} from '../Components/SchoolButton';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from "react-native-responsive-screen";
import { isIphoneX, getBottomSpace } from 'react-native-iphone-x-helper';
import { getSalonActuel } from '../Utils/indexedDB';

// const db = SQLite.openDatabase('db.db');

class ChooseSchoolScreen extends React.Component {

    state = {
        lastSalon: null,
    };

    constructor(props) {
        super(props);
        this._toggleSalon();

    }

    componentDidUpdate() {
    }
    _toggleSchool = (type) => {
        const action = {type: type};
        this.props.dispatch(action);
    };

    _toggleSalon = async () => {
        if (this.state.lastSalon === null) {
            getSalonActuel((all) => {
                this.setState({lastSalon: all?.length > 0 ? all[0] : null});
                if (all?.length > 0) {
                    const salon = all?.length > 0 ? all?.[0] : null;
                    this.props.dispatch({type: 'TOGGLE_SALON', salon: salon});
                }
            });

            // await db.transaction(tx => {
            //     tx.executeSql(
            //         `SELECT * FROM actual_salon ORDER BY ID DESC LIMIT 1`,
            //         [],
            //         (_, {rows: {_array}}) => {
            //             this.setState({lastSalon: _array?.length > 0 ? _array[0] : null});
            //             if (_array?.length > 0) {
            //                 const salon = _array?.length > 0 ? _array?.[0] : null;
            //                 this.props.dispatch({type: 'TOGGLE_SALON', salon: salon});
            //             }
            //         },
            //         (t, error) => {
            //             console.log(error);
            //         }
            //     );
            // });
        }
    };

    renderTablet = () => {
        return (
            <View style={[styles.contentView]}>
                <Text style={styles.chooseText}>Sélectionnez l’école de votre choix</Text>
                <View style={styles.buttonsView}>
                    <View style={styles.buttonsRowView}>
                        <TouchableOpacity
                            style={styles.buttonView}
                            onPress={() => {
                                this._toggleSchool('TOGGLE_DESIGN');
                                this.navigateToForm('design');
                            }}>
                            <SchoolButton school="design"/>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={styles.buttonView}
                            onPress={() => {
                                this._toggleSchool('TOGGLE_SCBS');
                                this.navigateToForm('scbs');
                            }}>
                            <SchoolButton school="scbs"/>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={styles.buttonView}
                            onPress={() => {
                                this._toggleSchool('TOGGLE_TOURISME');
                                this.navigateToForm('tourisme');
                            }}>
                            <SchoolButton school="tourisme"/>
                        </TouchableOpacity>

                    </View>
                    <View style={styles.buttonsRowView}>
                        <TouchableOpacity
                            style={styles.buttonView}
                            onPress={() => {
                                this._toggleSchool('TOGGLE_PFEP');
                                this.navigateToForm('pfep');
                            }}>
                            <SchoolButton school="pfep"/>
                        </TouchableOpacity>
                        {/*<TouchableOpacity
                            style={styles.buttonView}
                            onPress={() => {
                                this._toggleSchool('TOGGLE_PSS');
                                this.navigateToForm('pss');
                            }}>
                            <SchoolButton school="pss"/>
                        </TouchableOpacity>*/}
                    </View>
                </View>
            </View>
        );
    };

    renderMobile = () => {
        return (
            <View style={{justifyContent: 'center', height: hp("75%")}}>
                <View style={styles.mobileContentView}>
                    <View style={[styles.mobileButtonView, {justifyContent: 'center', alignItems: 'center', marginVertical: 32}]}>
                        <Text style={styles.mobileChooseText}>Sélectionnez l’école de votre choix</Text>
                    </View>
                    <TouchableOpacity
                        style={styles.mobileButtonView}
                        onPress={() => {
                            this._toggleSchool('TOGGLE_DESIGN');
                            this.navigateToForm('design');
                        }}>
                        <SchoolButton school="design"/>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={styles.mobileButtonView}
                        onPress={() => {
                            this._toggleSchool('TOGGLE_SCBS');
                            this.navigateToForm('scbs');
                        }}>
                        <SchoolButton school="scbs"/>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={styles.mobileButtonView}
                        onPress={() => {
                            this._toggleSchool('TOGGLE_TOURISME');
                            this.navigateToForm('tourisme');
                        }}>
                        <SchoolButton school="tourisme"/>
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={styles.mobileButtonView}
                        onPress={() => {
                            this._toggleSchool('TOGGLE_PFEP');
                            this.navigateToForm('pfep');
                        }}>
                        <SchoolButton school="pfep"/>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={styles.mobileButtonView}
                        onPress={() => {
                            this._toggleSchool('TOGGLE_PSS');
                            this.navigateToForm('pss');
                        }}>
                        <SchoolButton school="pss"/>
                    </TouchableOpacity>
                </View>
            </View>
        );
    };

    render() {
        let width = Dimensions.get('window').width;
        let tablet = width > 480;

        return (
            <View>
                <ImageBackground source={require('../../assets/backgrounds/yschools_background.png')}
                                 style={{width: '100%', height: '100%'}}>
                    {tablet ? this.renderTablet() : this.renderMobile()}
                    <Text style={styles.lastUpdate}>Dernière mise a jour : 09 novembre 2022</Text>
                </ImageBackground>
            </View>
        )
    }

    navigateToForm = () => {
        this.props.navigation.navigate("Form");
    };
}

const styles = StyleSheet.create({
    contentView: {
        justifyContent: 'center',
        alignItems: 'center',
        // height: "90%",
    },
    chooseText: {
        height: 18,
        fontFamily: "HelveticaNeue",
        fontSize: 16,
        fontWeight: "300",
        fontStyle: "italic",
        lineHeight: 18,
        letterSpacing: 0,
        textAlign: "left",
        color: Colors.yschools,
    },
    buttonsView: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90%',
        width: '70%',
    },
    buttonsRowView: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '80%',
        marginTop: hp("3%"),
    },
    buttonView: {
        marginLeft: 5,
        marginRight: 5,
        //flex:0.3,
        width: 160,
        height: 160,
    },
    mobileContentView: {
        flexWrap: 'wrap',
        // height: '90%',
        alignItems: 'center',
        justifyContent: 'center',
        width: "100%",
        //marginLeft: '5%',
        //marginRight: '5%',
        flexDirection: 'row'

    },
    mobileButtonView: {
        margin: 5,
        //flex:0.3,
        width: 115,
        height: 115,
    },
    mobileChooseText: {
        fontFamily: "HelveticaNeue",
        fontSize: 16,
        fontWeight: "300",
        fontStyle: "italic",
        lineHeight: 18,
        letterSpacing: 0,
        textAlign: "center",
        textAlignVertical: "center",
        color: Colors.yschools,
        width: "100%",
        marginVertical: 32,
    },
    lastUpdate: {
        fontFamily: "HelveticaNeue",
        fontSize: 12,
        fontWeight: "300",
        textAlign: "left",
        color:"#022c6d",
        position: "absolute",
        bottom: isIphoneX() ? getBottomSpace() : hp("1%"),
        left: 5
    }
});

const mapStateToProps = (state) => {
    return {
        school: state.school,
        salon: state.salon
    };
};
export default connect(mapStateToProps)(ChooseSchoolScreen);