import React from 'react';
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    Alert,
    ActivityIndicator, Dimensions
} from 'react-native';
import {FooterAdmin} from "../../Components/FooterAdmin";
import Constants from 'expo-constants';
import * as SQLite from 'expo-sqlite';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from "react-native-responsive-screen";
import {connect} from "react-redux";
import { getUpdateSalon, insertSalons, setUpdateSalon } from '../../Utils/indexedDB';

// const db = SQLite.openDatabase('db.db');

class UpdateScreen extends React.Component {
    state = {
        isLoading: false,
        lastUpdate: ''
    };

    constructor(props) {
        super(props);
        this._getLastEventUpdate();
    }

    _displayLoading = () => {
        if (this.state.isLoading) {
            return (
                <View style={styles.loading_container}>
                    <ActivityIndicator size='large' color="#022c6d"/>
                </View>
            )
        }
    }

    _update = async () => {
        this.setState({isLoading: true});
        try {
            let response = await fetch(
                'https://yschools-form.prod.sinfin.fr/api/export/yschools', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    }
                }
            );
            console.log("TEST PASSAGE UPDATE")
            let responseJson = await response.json();
            // await this._updateEventsDB(responseJson[0].liste);
            await this._updateEventsIndexedDB(responseJson[0].liste);
            await this._setNewEventsListUpdate('ok');
            await this.setState({lastUpdate: Date.now(), isLoading: false});
            await Alert.alert(
                'Mise à jour réussie',
                '',
                [
                    {text: 'OK', onPress: () => console.log('OK Pressed')},
                ],
                {cancelable: false}
            );
        } catch (error) {
            console.log("ERROR : TEST PASSAGE UPDATE")
            console.log(error);
            this.setState({isLoading: false});
            Alert.alert(
                'Une erreur est survenue',
                'Code erreur : 1201',
                [
                    {text: 'OK', onPress: () => console.log('OK Pressed')},
                ],
                {cancelable: false}
            )
        }
    };
    _setNewEventsListUpdate = async (state) => {
        // await db.transaction(tx => {
        //     tx.executeSql(
        //         `INSERT INTO update_salon(state,date) VALUES (?,?);`,
        //         [state, Date.now()],
        //         (_,{rows: {_array}}) => console.log({items: _array}),
        //         (t, error) => {
        //             console.log(error);
        //         }
        //     );
        // });

        setUpdateSalon({
            state,
            date: Date.now()
        });
    };

    _updateEventsIndexedDB = async (eventsDatas) => {
        const onFinish = () => {
            this.props.dispatch({type: 'UPDATE_LIST_SALONS', needUpdate: true})
        };

        insertSalons(eventsDatas, onFinish);
    };

    _updateEventsDB = async (eventsDatas) => {
        let sql = `INSERT INTO events VALUES`;
        let i = 1;
        eventsDatas.map((event) => {
            sql += `(` + i + `,"` + event.id + `","` + event.date + `"),`;
            i++;
        });

        sql = sql.slice(0, -1);
        sql += ';';

        // await db.transaction(tx => {
        //     tx.executeSql('DELETE FROM events;',
        //         [],
        //         (_, {rows: {_array}}) => console.log('Truncate events Table'),
        //         (t, error) => {
        //             console.log(error);

        //             Alert.alert(
        //                 'Une erreur est survenue',
        //                 'Code erreur : 1102',
        //                 [
        //                     {text: 'OK', onPress: () => console.log('OK Pressed')},
        //                 ],
        //                 {cancelable: false}
        //             )
        //         });
        // });

        // await db.transaction(tx => {
        //     //TODO Parser les " pour le pas casser la requete SQL
        //     tx.executeSql(
        //         sql,
        //         [],
        //         (_, {rows: {_array}}) => 
        //         {
        //             console.log('Adding new events to local database')
        //             this.props.dispatch({type: 'UPDATE_LIST_SALONS', needUpdate: true})
        //         },
        //         (t, error) => {
        //             console.log(error);
        //             Alert.alert(
        //                 'Une erreur est survenue',
        //                 'Code erreur : 1104',
        //                 [
        //                     {text: 'OK', onPress: () => console.log('OK Pressed')},
        //                 ],
        //                 {cancelable: false}
        //             );
        //         }
        //     );
        // });
    };
    _getLastEventUpdate = async () => {
        // await db.transaction(tx => {
        //     tx.executeSql(
        //         `SELECT date FROM update_salon ORDER BY ID DESC LIMIT 1`,
        //         [],
        //         (_, {rows: {_array}}) => this.setState({lastUpdate: _array.length > 0 ? _array[0].date : 'Jamais'}),
        //         (t, error) => {
        //             console.log(error);
        //         }
        //     );
        // });

        getUpdateSalon((all) => {
            this.setState({lastUpdate: all.length > 0 ? all[0].date : 'Jamais'})
        });
    };

    render() {
        let saisieDate = this.state.lastUpdate;

        if (!saisieDate) {
            saisieDate = 'n/a';
        } else if (saisieDate != 'Jamais') {
            let date = new Date(saisieDate);
            var day = "0" + date.getDate(); //Current Date
            var month = "0" + (date.getMonth() + 1); //Current Month
            var year = date.getFullYear(); //Current Year
            let hours = "0" + date.getHours();
            let minutes = "0" + date.getMinutes();
            saisieDate = '' + day.substr(-2) + '-' + month.substr(-2) + '-' + year + ' à ' + hours.substr(-2) + 'h' + minutes.substr(-2);
        }

        let width = Dimensions.get('window').width;
        let tablet = width > 480;

        return (
            <View>
                <View style={tablet ? styles.contentView : styles.mobileContentView}>
                    <Text style={tablet? styles.title : styles.mobileTitle}>Mises à jour</Text>
                    <View style={tablet ? styles.majInfosBlock : styles.mobileMajInfosBlock}>
                        <View styles={styles.mobileInfoView}>
                            <Text style={[tablet ? styles.infosText : styles.mobileInfosText, {fontWeight: 'bold'}]}>ID
                                UNIQUE :</Text>
                            <Text
                                style={tablet ? styles.infosText : styles.mobileInfosText}>{Constants.installationId}</Text>
                        </View>
                        <View styles={styles.mobileInfoView}>
                            <Text style={[tablet ? styles.infosText : styles.mobileInfosText, {fontWeight: 'bold'}]}>Date
                                de
                                mise à jour :</Text>
                            <Text style={tablet ? styles.infosText : styles.mobileInfosText}>{saisieDate} </Text>
                        </View>
                        {this._displayLoading()}
                    </View>
                    <TouchableOpacity
                        style={tablet ? styles.touchableButtonContainer : styles.mobileTouchableButtonContainer}
                        onPress={() => this._update()}>
                        <View style={styles.buttonContainer}>
                            <View style={styles.buttonTextView}>
                                <Text style={tablet ? styles.buttonText : styles.mobileButtonText}>METTRE À JOUR</Text>
                            </View>
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={tablet ? styles.footerView : styles.mobileFooterView}>
                    <FooterAdmin type="maj" navigation={this.props.navigation}/>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    contentView: {
        justifyContent: 'center',
        alignItems: 'center',
        // height: '80%',
    },
    footerView: {
        height: '20%'
    },
    title: {
        fontFamily: "HelveticaNeue",
        fontSize: 22,
        fontWeight: "500",
        fontStyle: "normal",
        lineHeight: 27,
        letterSpacing: 0,
        textAlign: "center",
        color: "#022c6d",
        marginTop: 16,
        marginBottom: 16
    },

    majInfosBlock: {
        width: wp("60%"),
        height: hp("25%"),
        borderRadius: 5,
        backgroundColor: "#ffffff",
        shadowColor: "rgba(2, 44, 109, 0.37)",
        shadowOffset: {
            width: 0,
            height: 3
        },
        shadowRadius: 6,
        shadowOpacity: 1,
        justifyContent: 'space-around',
        alignItems: 'center',
        elevation: 5,
        marginBottom: 20,

    },
    infosText: {
        height: 24,
        fontFamily: "HelveticaNeue",
        fontSize: 20,
        fontWeight: "300",
        fontStyle: "normal",
        lineHeight: 24,
        letterSpacing: 0,
        textAlign: "center",
        color: "#022c6d",
    },
    buttonText: {
        fontFamily: "HelveticaNeue",
        fontSize: wp("1.8%"),
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        color: "#ffffff"
    },

    mobileButtonText: {
        fontFamily: "HelveticaNeue",
        fontSize: wp("4%"),
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: wp("4%"),
        letterSpacing: 0,
        textAlign: "center",
        color: "#ffffff"
    },

    touchableButtonContainer: {
        // width: 210,
        // height: 35,
        alignSelf: 'center',
    },
    buttonContainer: {
        backgroundColor: "#022c6d",
        justifyContent: 'center',
        alignItems: 'center',
        transform: [{skewX: '-40deg'}],
        width: '100%',
        height: '100%',
        paddingHorizontal: 32
    },
    buttonTextView: {
        transform: [{skewX: '40deg'}]
    },
    loading_container: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
    },

    mobileTitle: {
        fontFamily: "HelveticaNeue",
        fontSize: wp("5%"),
        fontWeight: "500",
        fontStyle: "normal",
        lineHeight:  wp("5%"),
        letterSpacing: 0,
        textAlign: "center",
        color: "#022c6d",
        marginTop: 16,
        marginBottom: 16
    },

    mobileMajInfosBlock: {
        width: '90%',
        height: wp("50%"),
        borderRadius: 5,
        backgroundColor: "#ffffff",
        shadowColor: "rgba(2, 44, 109, 0.37)",
        shadowOffset: {
            width: 0,
            height: 3
        },
        shadowRadius: 6,
        shadowOpacity: 1,
        justifyContent: 'center',
        alignItems: 'center',
        elevation: 5
    },
    mobileTouchableButtonContainer: {
        width: wp("50%"),
        height: 35,
        alignSelf: 'center',
        marginTop: 15
    },
    mobileInfosText: {
        fontFamily: "HelveticaNeue",
        fontSize: wp("4%"),
        fontWeight: "300",
        fontStyle: "normal",
        lineHeight:  wp("4%"),
        letterSpacing: 0,
        textAlign: "center",
        color: "#022c6d",
        marginBottom: 5
    },
    mobileInfoView: {
        marginBottom:10
    },
    mobileContentView: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '75%',
        width: '100%'
    },
    mobileFooterView: {
        height: '25%'
    },

});

const mapStateToProps = (state) => {
    return state.listSalons;
};

export default connect(mapStateToProps,)(UpdateScreen)