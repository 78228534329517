var listeVilles = [];
listeVilles["AIN"]= ["AMBERIEU EN BUGEY","BELLEGARDE SUR VALSERINE","BELLEY","BELLIGNAT","BOURG EN BRESSE","CESSY","FERNEY VOLTAIRE","LA BOISSE","MIRIBEL","NANTUA","OYONNAX","TREVOUX"];
listeVilles["AISNE"]= ["BOHAIN EN VERMANDOIS","CHATEAU THIERRY","CHAUNY","FONTAINE LES VERVINS","HIRSON","LAON","SOISSONS","ST QUENTIN","VILLERS COTTERETS"];
listeVilles["ALLIER"]= ["COMMENTRY","CUSSET","GANNAT","MONTLUCON","MOULINS","ST POURCAIN SUR SIOULE","YZEURE"];
listeVilles["ALPES DE HTE PROVENCE"]= ["BARCELONNETTE","DIGNE LES BAINS","MANOSQUE","SISTERON"];
listeVilles["ALPES MARITIMES"]= ["ANTIBES","CAGNES SUR MER","CANNES","GRASSE","MENTON","NICE","ROQUEBRUNE CAP MARTIN","VALBONNE","VALDEBLORE","VENCE"];
listeVilles["ARDECHE"]= ["ANNONAY","AUBENAS","BOURG ST ANDEOL","LE CHEYLARD","LE TEIL","PRIVAS","TOURNON SUR RHONE"];
listeVilles["ARDENNES"]= ["BAZEILLES","CHARLEVILLE MEZIERES","GIVET","RETHEL","REVIN","SEDAN","VOUZIERS"];
listeVilles["ARIEGE"]= ["FOIX","MIREPOIX","PAMIERS","ST GIRONS"];
listeVilles["AUBE"]= ["BAR SUR AUBE","ROMILLY SUR SEINE","STE SAVINE","TROYES"];
listeVilles["AUDE"]= ["CARCASSONNE","CASTELNAUDARY","LIMOUX","NARBONNE"];
listeVilles["AVEYRON"]= ["DECAZEVILLE","ESPALION","MILLAU","RODEZ","ST AFFRIQUE","VILLEFRANCHE DE ROUERGUE"];
listeVilles["BAS RHIN"]= ["BARR","BISCHHEIM","BISCHWILLER","BOUXWILLER","ERSTEIN","HAGUENAU","ILLKIRCH GRAFFENSTADEN","MOLSHEIM","OBERNAI","SARRE UNION","SAVERNE","SELESTAT","STRASBOURG","WALBOURG","WISSEMBOURG"];
listeVilles["BOUCHES DU RHONE"]= ["AIX EN PROVENCE","ARLES","AUBAGNE","GARDANNE","GEMENOS","GIGNAC LA NERTHE","ISTRES","LA CIOTAT","MARIGNANE","MARSEILLE","MARSEILLE 01","MARSEILLE 03","MARSEILLE 04","MARSEILLE 05","MARSEILLE 06","MARSEILLE 07","MARSEILLE 08","MARSEILLE 09","MARSEILLE 10","MARSEILLE 11","MARSEILLE 12","MARSEILLE 13","MARSEILLE 14","MARSEILLE 15","MARTIGUES","MIRAMAS","PORT ST LOUIS DU RHONE","SALON DE PROVENCE","TARASCON","VITROLLES"];
listeVilles["CALVADOS"]= ["BAYEUX","CAEN","CONDE SUR NOIREAU","DEAUVILLE","DOUVRES LA DELIVRANDE","FALAISE","HEROUVILLE ST CLAIR","HONFLEUR","LISIEUX","MONDEVILLE","TROUVILLE SUR MER","VIRE"];
listeVilles["CANTAL"]= ["AURILLAC","MAURIAC","ST FLOUR"];
listeVilles["CHARENTE"]= ["ANGOULEME","BARBEZIEUX ST HILAIRE","COGNAC","CONFOLENS"];
listeVilles["CHARENTE MARITIME"]= ["BOURCEFRANC LE CHAPUS","JONZAC","LA ROCHELLE","PONS","PONT L ABBE D ARNOULT","ROCHEFORT","ROYAN","SAINTES","ST GEORGES D OLERON","ST JEAN D ANGELY"];
listeVilles["CHER"]= ["BOURGES","ST AMAND MONTROND","VIERZON"];
listeVilles["CORREZE"]= ["BRIVE LA GAILLARDE","EGLETONS","TULLE","USSEL"];
listeVilles["CORSE DU SUD"]= ["AJACCIO","PORTO VECCHIO","SARTENE"];
listeVilles["COTE D'OR"]= ["AUXONNE","BEAUNE","BROCHON","CHATILLON SUR SEINE","CHEVIGNY ST SAUVEUR","DIJON","SEMUR EN AUXOIS"];
listeVilles["COTES D'ARMOR"]= ["DINAN","GUINGAMP","LAMBALLE","LANNION","LOUDEAC","PAIMPOL","QUINTIN","ROSTRENEN","ST BRIEUC","TREGUIER"];
listeVilles["CREUSE"]= ["AUBUSSON","FELLETIN","GUERET","LA SOUTERRAINE"];
listeVilles["DEUX-SEVRES"]= ["BRESSUIRE","MELLE","NIORT","PARTHENAY","ST MAIXENT L ECOLE","THOUARS"];
listeVilles["DORDOGNE"]= ["BERGERAC","EXCIDEUIL","NONTRON","PERIGUEUX","RIBERAC","SARLAT LA CANEDA","TERRASSON LAVILLEDIEU"];
listeVilles["DOUBS"]= ["BESANCON","MONTBELIARD","MORTEAU","PONTARLIER","VALENTIGNEY"];
listeVilles["DROME"]= ["BOURG LES VALENCE","CHATEAUNEUF DE GALAURE","CREST","DIE","MONTELIMAR","NYONS","PIERRELATTE","ROMANS SUR ISERE","ST VALLIER","VALENCE"];
listeVilles["ESSONNE"]= ["ARPAJON","ATHIS MONS","BONDOUFLE","BRETIGNY SUR ORGE","BRUNOY","CORBEIL ESSONNES","COURCOURONNES","DOURDAN","DRAVEIL","EPINAY SOUS SENART","ETAMPES","EVRY","GIF SUR YVETTE","LA VILLE DU BOIS","LES ULIS","LIMOURS","LONGJUMEAU","MASSY","MENNECY","MONTGERON","MORANGIS","ORSAY","PALAISEAU","SAVIGNY SUR ORGE","ST MICHEL SUR ORGE","STE GENEVIEVE DES BOIS","VILLEBON SUR YVETTE","VIRY CHATILLON"];
listeVilles["EURE"]= ["BERNAY","EVREUX","GAILLON","GISORS","LES ANDELYS","LOUVIERS","PONT AUDEMER","ST GERMAIN VILLAGE","VAL DE REUIL","VERNEUIL SUR AVRE","VERNON"];
listeVilles["EURE ET LOIR"]= ["CHARTRES","CHATEAUDUN","DREUX","LUISANT","NOGENT LE ROTROU"];
listeVilles["FINISTERE"]= ["BREST","CARHAIX PLOUGUER","CHATEAULIN","CONCARNEAU","DOUARNENEZ","LANDERNEAU","LANDIVISIAU","LESNEVEN","MORLAIX","PONT L ABBE","QUIMPER","QUIMPERLE","ST POL DE LEON"];
listeVilles["GARD"]= ["ALES","BAGNOLS SUR CEZE","LE VIGAN","MILHAUD","NIMES","ST CHRISTOL LES ALES","UZES","VILLENEUVE LES AVIGNON"];
listeVilles["GERS"]= ["AUCH","CONDOM","L ISLE JOURDAIN","LECTOURE","MIRANDE","NOGARO"];
listeVilles["GIRONDE"]= ["ANDERNOS LES BAINS","ARCACHON","BAZAS","BLANQUEFORT","BLAYE","BORDEAUX","GRADIGNAN","GUJAN MESTRAS","LA BREDE","LA REOLE","LANGON","LE TAILLAN MEDOC","LIBOURNE","LORMONT","MERIGNAC","PAUILLAC","PESSAC","ST ANDRE DE CUBZAC","STE FOY LA GRANDE","TALENCE"];
listeVilles["GUADELOUPE"]= ["BAIE MAHAULT","BASSE TERRE","CAPESTERRE BELLE EAU","GRAND BOURG","LE GOSIER","LES ABYMES","MORNE A L EAU","PETIT BOURG","POINTE A PITRE","POINTE NOIRE","PORT LOUIS","ST MARTIN","STE ANNE","STE ROSE"];
listeVilles["GUYANE"]= ["CAYENNE","KOUROU","REMIRE MONTJOLY","ST LAURENT DU MARONI"];
listeVilles["HAUT RHIN"]= ["ALTKIRCH","CARSPACH","CERNAY","COLMAR","GUEBWILLER","LANDSER","MULHOUSE","MUNSTER","RIBEAUVILLE","ST LOUIS","STE MARIE AUX MINES","THANN","WITTELSHEIM","WITTENHEIM","ZILLISHEIM"];
listeVilles["HAUTE CORSE"]= ["BASTIA","CORTE","L ILE ROUSSE","PRUNELLI DI FIUMORBO"];
listeVilles["HAUTE GARONNE"]= ["BAGNERES DE LUCHON","BLAGNAC","COLOMIERS","CUGNAUX","FONSORBES","FRONTON","GOURDAN POLIGNAN","MURET","PINS JUSTARET","REVEL","SEILH","ST GAUDENS","ST ORENS DE GAMEVILLE","TOULOUSE"];
listeVilles["HAUTE LOIRE"]= ["BRIOUDE","BRIVES CHARENSAC","LE CHAMBON SUR LIGNON","LE PUY EN VELAY","MONISTROL SUR LOIRE","YSSINGEAUX"];
listeVilles["HAUTE MARNE"]= ["CHAUMONT","JOINVILLE","LANGRES","ST DIZIER"];
listeVilles["HAUTE SAONE"]= ["GRAY","HERICOURT","LURE","LUXEUIL LES BAINS","VESOUL"];
listeVilles["HAUTE SAVOIE"]= ["ABONDANCE","ANNECY","ANNEMASSE","ARGONAY","BONNEVILLE","CHAMONIX MONT BLANC","CLUSES","CRAN GEVRIER","EVIAN LES BAINS","LA ROCHE SUR FORON","PASSY","RUMILLY","SALLANCHES","SEYNOD","ST JULIEN EN GENEVOIS","THONES","THONON LES BAINS","VILLE LA GRAND"];
listeVilles["HAUTE VIENNE"]= ["BELLAC","LIMOGES","ST JUNIEN","ST LEONARD DE NOBLAT","ST YRIEIX LA PERCHE"];
listeVilles["HAUTES ALPES"]= ["BRIANCON","EMBRUN","GAP"];
listeVilles["HAUTES PYRENEES"]= ["ARGELES GAZOST","BAGNERES DE BIGORRE","LANNEMEZAN","LOURDES","MONLEON MAGNOAC","TARBES","VIC EN BIGORRE"];
listeVilles["HAUTS DE SEINE"]= ["ANTONY","ASNIERES SUR SEINE","BAGNEUX","BOIS COLOMBES","BOULOGNE BILLANCOURT","BOURG LA REINE","CHATENAY MALABRY","CHAVILLE","CLAMART","CLICHY","COLOMBES","COURBEVOIE","GENNEVILLIERS","ISSY LES MOULINEAUX","LE PLESSIS ROBINSON","LEVALLOIS PERRET","MEUDON","MONTROUGE","NANTERRE","NEUILLY SUR SEINE","PUTEAUX","RUEIL MALMAISON","SCEAUX","SEVRES","ST CLOUD","SURESNES","VANVES","VAUCRESSON","VILLENEUVE LA GARENNE"];
listeVilles["HERAULT"]= ["AGDE","BEDARIEUX","BEZIERS","CASTELNAU LE LEZ","CLERMONT L HERAULT","LA GRANDE MOTTE","LATTES","LODEVE","LUNEL","MONTFERRIER SUR LEZ","MONTPELLIER","PEZENAS","SETE","ST CLEMENT DE RIVIERE"];
listeVilles["ILLE ET VILAINE"]= ["BAIN DE BRETAGNE","BRUZ","CESSON SEVIGNE","COMBOURG","DINARD","DOL DE BRETAGNE","FOUGERES","MONTAUBAN DE BRETAGNE","MONTFORT SUR MEU","REDON","RENNES","ST GREGOIRE","ST MALO","VITRE"];
listeVilles["INDRE"]= ["ARGENTON SUR CREUSE","CHATEAUROUX","ISSOUDUN","LA CHATRE","LE BLANC"];
listeVilles["INDRE ET LOIRE"]= ["AMBOISE","CHINON","JOUE LES TOURS","LOCHES","ST PIERRE DES CORPS","TOURS","VEIGNE"];
listeVilles["ISERE"]= ["BOURGOIN JALLIEU","ECHIROLLES","GRENOBLE","L ISLE D ABEAU","LA COTE ST ANDRE","LA MURE D ISERE","LA TOUR DU PIN","LA TRONCHE","LE PEAGE DE ROUSSILLON","LE PONT DE BEAUVOISIN","MEYLAN","MOIRANS","MORESTEL","NIVOLAS VERMELLE","PONT DE CHERUY","PONTCHARRA","ROUSSILLON","SASSENAGE","SEYSSINET PARISET","ST MARCELLIN","ST MARTIN D HERES","VIENNE","VILLARD BONNOT","VILLARD DE LANS","VILLEFONTAINE","VIZILLE","VOIRON"];
listeVilles["JURA"]= ["CHAMPAGNOLE","DOLE","LONS LE SAUNIER","MOREZ","MOUCHARD","POLIGNY","SALINS LES BAINS","ST CLAUDE"];
listeVilles["LANDES"]= ["AIRE SUR L ADOUR","DAX","MONT DE MARSAN","PARENTIS EN BORN","ST PAUL LES DAX","ST PIERRE DU MONT","ST VINCENT DE TYROSSE"];
listeVilles["LOIR ET CHER"]= ["BLOIS","ROMORANTIN LANTHENAY","VENDOME"];
listeVilles["LOIRE"]= ["ANDREZIEUX BOUTHEON","BOEN","CHARLIEU","FEURS","FIRMINY","MONTBRISON","RIVE DE GIER","ROANNE","ST CHAMOND","ST ETIENNE","ST PRIEST EN JAREZ"];
listeVilles["LOIRE ATLANTIQUE"]= ["ANCENIS","BASSE GOULAINE","BLAIN","BOUAYE","CHATEAUBRIANT","GORGES","GUERANDE","LA BAULE","LA CHAPELLE SUR ERDRE","MACHECOUL","NANTES","ORVAULT","PORNIC","REZE","SAVENAY","ST GILDAS DES BOIS","ST HERBLAIN","ST NAZAIRE","ST SEBASTIEN SUR LOIRE"];
listeVilles["LOIRET"]= ["BEAUGENCY","CHALETTE SUR LOING","GIEN","INGRE","MONTARGIS","ORLEANS","PITHIVIERS","ST JEAN DE BRAYE","ST JEAN DE LA RUELLE","VILLEMANDEUR"];
listeVilles["LOT"]= ["CAHORS","FIGEAC","GOURDON","SOUILLAC","ST CERE"];
listeVilles["LOT ET GARONNE"]= ["AGEN","AIGUILLON","FUMEL","MARMANDE","NERAC","VILLENEUVE SUR LOT"];
listeVilles["LOZERE"]= ["MARVEJOLS","MENDE","ST CHELY D APCHER"];
listeVilles["MAINE ET LOIRE"]= ["ANGERS","BEAUPREAU","CHOLET","LA POMMERAYE","LA SALLE DE VIHIERS","LE LONGERON","LES PONTS DE CE","NYOISEAU","SAUMUR","SEGRE","ST SYLVAIN D ANJOU"];
listeVilles["MANCHE"]= ["AGNEAUX","AVRANCHES","CARENTAN","CHERBOURG OCTEVILLE","COUTANCES","GRANVILLE","MORTAIN","ST HILAIRE DU HARCOUET","ST LO","VALOGNES"];
listeVilles["MARNE"]= ["CHALONS EN CHAMPAGNE","EPERNAY","REIMS","SEZANNE","VITRY LE FRANCOIS"];
listeVilles["MARTINIQUE"]= ["BELLEFONTAINE","DUCOS","FORT DE FRANCE","LA TRINITE","LE FRANCOIS","LE LAMENTIN","LE LORRAIN","LE MARIN","RIVIERE SALEE","STE LUCE","STE MARIE"];
listeVilles["MAYENNE"]= ["CHATEAU GONTIER","EVRON","LAVAL","MAYENNE"];
listeVilles["MEURTHE ET MOSELLE"]= ["ART SUR MEURTHE","BRIEY","JARNY","JARVILLE LA MALGRANGE","LAXOU","LONGWY","LUNEVILLE","NANCY","PONT A MOUSSON","TOMBLAINE","TOUL","VANDOEUVRE LES NANCY","VILLERS LES NANCY"];
listeVilles["MEUSE"]= ["BAR LE DUC","COMMERCY","STENAY","VERDUN"];
listeVilles["MORBIHAN"]= ["AURAY","GOURIN","GUER","HENNEBONT","LANESTER","LORIENT","PLOEMEUR","PLOERMEL","PONTIVY","QUESTEMBERT","STE ANNE D AURAY","VANNES"];
listeVilles["MOSELLE"]= ["ALGRANGE","BITCHE","CREUTZWALD","DIEUZE","FAMECK","FORBACH","HAYANGE","METZ","MONTIGNY LES METZ","OTTANGE","PELTRE","PHALSBOURG","ROMBAS","SARREBOURG","SARREGUEMINES","SCHOENECK","ST AVOLD","TALANGE","THIONVILLE"];
listeVilles["NIEVRE"]= ["CLAMECY","COSNE COURS SUR LOIRE","DECIZE","NEVERS"];
listeVilles["NORD"]= ["ARMENTIERES","AULNOYE AYMERIES","AVESNES SUR HELPE","BAVAY","BEAUCAMPS LIGNY","BONDUES","CAMBRAI","CAUDRY","CONDE SUR L ESCAUT","DENAIN","DOUAI","DUNKERQUE","ESTAIRES","FOURMIES","GENECH","GONDECOURT","GRANDE SYNTHE","HAUBOURDIN","HAZEBROUCK","LA MADELEINE","LAMBERSART","LANDRECIES","LE CATEAU CAMBRESIS","LE QUESNOY","LILLE","LOOS","LOUVROIL","MARCQ EN BAROEUL","MAUBEUGE","ORCHIES","ROUBAIX","SIN LE NOBLE","SOLESMES","SOMAIN","ST AMAND LES EAUX","TOURCOING","VALENCIENNES","VILLENEUVE D ASCQ","WATTRELOS"];
listeVilles["OISE"]= ["BEAUVAIS","CHANTILLY","CLERMONT","COMPIEGNE","CREIL","CREPY EN VALOIS","MERU","MONTATAIRE","MORTEFONTAINE","NOGENT SUR OISE","NOYON","PONT STE MAXENCE","SENLIS"];
listeVilles["ORNE"]= ["ALENCON","ARGENTAN","DOMFRONT","FLERS","L AIGLE","LA FERTE MACE","MORTAGNE AU PERCHE","SEES"];
listeVilles["PARIS"]= ["PARIS","PARIS 03","PARIS 04","PARIS 05","PARIS 06","PARIS 07","PARIS 08","PARIS 09","PARIS 10","PARIS 11","PARIS 12","PARIS 13","PARIS 14","PARIS 15","PARIS 16","PARIS 17","PARIS 18","PARIS 19","PARIS 20"];
listeVilles["PAS DE CALAIS"]= ["AIRE SUR LA LYS","ARRAS","AUCHEL","AVION","BERCK","BETHUNE","BEUVRY","BOULOGNE SUR MER","BRUAY LA BUISSIERE","BULLY LES MINES","CALAIS","CARVIN","GUINES","HENIN BEAUMONT","LE TOUQUET PARIS PLAGE","LENS","LIEVIN","LILLERS","LONGUENESSE","MONTREUIL","NOEUX LES MINES","ST MARTIN BOULOGNE","ST OMER","ST POL SUR TERNOISE","WINGLES"];
listeVilles["PUY DE DOME"]= ["AMBERT","CHAMALIERES","CLERMONT FERRAND","COURNON D AUVERGNE","COURPIERE","ISSOIRE","RIOM","THIERS"];
listeVilles["PYRENEES ATLANTIQUES"]= ["ANGLET","BAYONNE","BIARRITZ","CHERAUTE","HASPARREN","LESCAR","LESTELLE BETHARRAM","MOURENX","NAY","OLORON STE MARIE","ORTHEZ","PAU","ST JEAN DE LUZ","ST JEAN PIED DE PORT","USTARITZ"];
listeVilles["PYRENEES ORIENTALES"]= ["CANET EN ROUSSILLON","CERET","FONT ROMEU ODEILLO VIA","PERPIGNAN","PRADES"];
listeVilles["REUNION"]= ["BRAS PANON","LA POSSESSION","LE PORT","LE TAMPON","LES AVIRONS","LES TROIS BASSINS","ST ANDRE","ST BENOIT","ST DENIS","ST JOSEPH","ST LEU","ST LOUIS","ST PAUL","ST PIERRE","STE MARIE","STE SUZANNE"];
listeVilles["RHONE"]= ["BELLEVILLE","BRON","CHARBONNIERES LES BAINS","DARDILLY","DECINES CHARPIEU","GIVORS","LA MULATIERE","LYON 01","LYON 02","LYON 03","LYON 04","LYON 05","LYON 06","LYON 07","LYON 08","LYON 09","NEUVILLE SUR SAONE","OULLINS","RILLIEUX LA PAPE","SAIN BEL","ST GENIS LAVAL","ST PRIEST","ST SYMPHORIEN SUR COISE","TARARE","TASSIN LA DEMI LUNE","THIZY","VAULX EN VELIN","VENISSIEUX","VILLEFRANCHE SUR SAONE","VILLEURBANNE"];
listeVilles["SAONE ET LOIRE"]= ["AUTUN","CHALON SUR SAONE","CHAROLLES","CLUNY","DIGOIN","LE CREUSOT","LOUHANS","MACON","MONTCEAU LES MINES","PARAY LE MONIAL","TOURNUS"];
listeVilles["SARTHE"]= ["ALLONNES","CHATEAU DU LOIR","LA FERTE BERNARD","LA FLECHE","LE MANS","MAMERS","PARIGNE LE POLIN","PRUILLE LE CHETIF","SABLE SUR SARTHE","SILLE LE GUILLAUME"];
listeVilles["SAVOIE"]= ["AIX LES BAINS","ALBERTVILLE","BOURG ST MAURICE","CHAMBERY","LA RAVOIRE","MOUTIERS TARENTAISE","ST JEAN DE MAURIENNE","UGINE"];
listeVilles["SEINE ET MARNE"]= ["AVON","BRIE COMTE ROBERT","BUSSY ST GEORGES","CESSON","CHAMPAGNE SUR SEINE","CHAMPS SUR MARNE","CHELLES","COMBS LA VILLE","CONGIS SUR THEROUANNE","COULOMMIERS","DAMMARIE LES LYS","FONTAINEBLEAU","JUILLY","LA FERTE SOUS JOUARRE","LA ROCHETTE","LAGNY SUR MARNE","LE MEE SUR SEINE","LOGNES","LONGPERRIER","MEAUX","MELUN","MITRY MORY","MOISSY CRAMAYEL","MONTEREAU FAULT YONNE","NANGIS","NEMOURS","NOISIEL","OZOIR LA FERRIERE","PONTAULT COMBAULT","PROVINS","ROISSY EN BRIE","ROZAY EN BRIE","SAVIGNY LE TEMPLE","SERRIS","TORCY","TOURNAN EN BRIE","VAUX LE PENIL"];
listeVilles["SEINE MARITIME"]= ["BARENTIN","BOLBEC","CANTELEU","DEVILLE LES ROUEN","DIEPPE","ELBEUF","EU","FECAMP","FORGES LES EAUX","FRANQUEVILLE ST PIERRE","LE GRAND QUEVILLY","LE HAVRE","LE MESNIL ESNARD","LILLEBONNE","MONTIVILLIERS","NEUFCHATEL EN BRAY","ROUEN","SOTTEVILLE LES ROUEN","ST ETIENNE DU ROUVRAY","ST VALERY EN CAUX","STE ADRESSE","YVETOT"];
listeVilles["SEINE SAINT-DENIS"]= ["AUBERVILLIERS","AULNAY SOUS BOIS","BAGNOLET","BOBIGNY","BONDY","CLICHY SOUS BOIS","DRANCY","DUGNY","EPINAY SUR SEINE","GAGNY","LA COURNEUVE","LE BLANC MESNIL","LE RAINCY","LES LILAS","LES PAVILLONS SOUS BOIS","LIVRY GARGAN","MONTREUIL","NEUILLY SUR MARNE","NOISY LE GRAND","NOISY LE SEC","PANTIN","ROMAINVILLE","ROSNY SOUS BOIS","SEVRAN","ST DENIS","ST OUEN","STAINS","TREMBLAY EN FRANCE","VAUJOURS","VILLEMOMBLE","VILLEPINTE"];
listeVilles["SOMME"]= ["ABBEVILLE","ALBERT","AMIENS","DOULLENS","FRIVILLE ESCARBOTIN","MONTDIDIER","PERONNE"];
listeVilles["TARN"]= ["ALBI","CARMAUX","CASTRES","GAILLAC","LAVAUR","MAZAMET"];
listeVilles["TARN ET GARONNE"]= ["CASTELSARRASIN","MOISSAC","MONTAUBAN","MONTEILS"];
listeVilles["TERRITOIRE DE BELFORT"]= ["BELFORT"];
listeVilles["VAL D'OISE"]= ["ARGENTEUIL","BEAUMONT SUR OISE","CERGY","DEUIL LA BARRE","DOMONT","EAUBONNE","ENGHIEN LES BAINS","ERMONT","FOSSES","FRANCONVILLE LA GARENNE","GARGES LES GONESSE","GONESSE","GOUSSAINVILLE","HERBLAY","JOUY LE MOUTIER","L ISLE ADAM","LUZARCHES","MARGENCY","MONTMORENCY","OSNY","PONTOISE","SANNOIS","SARCELLES","ST OUEN L AUMONE","ST WITZ","TAVERNY","VAUREAL","VILLIERS LE BEL"];
listeVilles["VAL-DE-MARNE"]= ["ALFORTVILLE","BOISSY ST LEGER","CACHAN","CHAMPIGNY SUR MARNE","CHARENTON LE PONT","CHENNEVIERES SUR MARNE","CHOISY LE ROI","CRETEIL","FONTENAY SOUS BOIS","FRESNES","IVRY SUR SEINE","JOINVILLE LE PONT","LE KREMLIN BICETRE","LE PERREUX SUR MARNE","LIMEIL BREVANNES","MAISONS ALFORT","NOGENT SUR MARNE","ST MANDE","ST MAUR DES FOSSES","SUCY EN BRIE","THIAIS","VILLENEUVE LE ROI","VILLENEUVE ST GEORGES","VINCENNES","VITRY SUR SEINE"];
listeVilles["VAR"]= ["BRIGNOLES","DRAGUIGNAN","FREJUS","GASSIN","HYERES","LA GARDE","LA SEYNE SUR MER","LE MUY","LORGUES","OLLIOULES","ST MAXIMIN LA STE BAUME","ST RAPHAEL","TOULON"];
listeVilles["VAUCLUSE"]= ["APT","AVIGNON","BOLLENE","CARPENTRAS","CAVAILLON","L ISLE SUR LA SORGUE","ORANGE","PERTUIS"];
listeVilles["VENDEE"]= ["CHALLANS","CHANTONNAY","FONTENAY LE COMTE","LA ROCHE SUR YON","LES HERBIERS","LES SABLES D OLONNE","LUCON","MONTAIGU","POUZAUGES","ST LAURENT SUR SEVRE"];
listeVilles["VIENNE"]= ["CHATELLERAULT","CIVRAY","JAUNAY CLAN","LOUDUN","MONTMORILLON","POITIERS"];
listeVilles["VOSGES"]= ["BRUYERES","EPINAL","GERARDMER","MIRECOURT","NEUFCHATEAU","REMIREMONT","SAINT DIE DES VOSGES"];
listeVilles["YONNE"]= ["AUXERRE","AVALLON","JOIGNY","SENS","TONNERRE","TOUCY"];
listeVilles["YVELINES"]= ["ACHERES","AUBERGENVILLE","CARRIERES SUR SEINE","CONFLANS STE HONORINE","GUYANCOURT","LA CELLE ST CLOUD","LA QUEUE LES YVELINES","LE CHESNAY","LE VESINET","LES MUREAUX","LIMAY","MAGNANVILLE","MAISONS LAFFITTE","MANTES LA JOLIE","MARLY LE ROI","MAUREPAS","MONTIGNY LE BRETONNEUX","PLAISIR","POISSY","RAMBOUILLET","SARTROUVILLE","ST CYR L ECOLE","ST GERMAIN EN LAYE","TRAPPES","VERNEUIL SUR SEINE","VERSAILLES","VILLEPREUX","VILLIERS ST FREDERIC"];

export default listeVilles;