import React from 'react';
import {ActivityIndicator, Alert, Dimensions, FlatList, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {InscriptionListItem} from "./InscriptionListItem";
import * as SQLite from 'expo-sqlite';
import Constants from 'expo-constants';
import {connect} from "react-redux";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from "react-native-responsive-screen";
import { getInscriptions, setUploaded } from '../Utils/indexedDB';

// const db = SQLite.openDatabase('db.db');

class InscriptionList extends React.Component {
    constructor(props) {
        super(props);
        this._toggleInscription();
    }


    state = {
        inscriptions: [],
        isLoading: false
    };

    componentDidMount() {
        this._getInscriptions();
        //this._toggleInscription();
    }

    _getInscriptions = async () => {
        getInscriptions(all => {
            let sorted = all.sort((a,b) => {
                if (a.saisieDate > b.saisieDate) {
                    return -1
                } else if(a.saisieDate > b.saisieDate) {
                    return 1
                } else {
                    return 0
                }
            });

            this.setState({
                inscriptions: sorted
            });

            this._toggleInscription('TOTAL', sorted.length);
            this._toggleInscription('NEW', sorted.filter(e => e.uploaded == 0).length);
        });

        // await db.transaction(tx => {
        //     tx.executeSql(
        //         `SELECT * FROM inscriptions;`,
        //         [],
        //         (_, {rows: {_array}}) => {
        //             //console.log(_array);
        //             let sorted = _array.sort((a,b) => {
        //                 if (a.saisieDate > b.saisieDate) {
        //                     return -1
        //                 } else if(a.saisieDate > b.saisieDate) {
        //                     return 1
        //                 } else {
        //                     return 0
        //                 }
        //             })
        //             this.setState({inscriptions: sorted});
        //         },
        //         (t, error) => {
        //             console.log(error);
        //         }
        //     );
        // });

        // await db.transaction(tx => {
        //     tx.executeSql(
        //         `SELECT COUNT(*) as insc FROM inscriptions;`,
        //         [],
        //         (_, {rows: {_array}}) => {
        //             this._toggleInscription('TOTAL', _array[0].insc);
        //         },
        //         (t, error) => {
        //             console.log(error);
        //         }
        //     );
        // });

        // await db.transaction(tx => {
        //     tx.executeSql(
        //         `SELECT COUNT(*) as newInsc FROM inscriptions WHERE uploaded = 0;`,
        //         [],
        //         (_, {rows: {_array}}) => {
        //             this._toggleInscription('NEW', _array[0].newInsc);
        //         },
        //         (t, error) => {
        //             console.log(error);
        //         }
        //     );
        // });
    }

    _setUploaded = async () => {
        setUploaded((all) => {
            this.setState({inscriptions: all});
        });

        // await db.transaction(tx => {
        //     tx.executeSql(
        //         `UPDATE inscriptions SET uploaded = 1;`,
        //         [],
        //         (_, {rows: {_array}}) => {
        //             //console.log(_array);
        //             this.setState({inscriptions: _array});
        //         },
        //         (t, error) => {
        //             console.log(error);
        //         }
        //     );
        // });
    }

    _displayLoading = () => {
        if (this.state.isLoading) {
            return (
                <View style={this.styles.loading_container}>
                    <ActivityIndicator size='large' color="#022c6d"/>
                </View>
            )
        }
    }

    _uploadInscription = async () => {
        this.setState({isLoading: true});
        try {
            let response = await fetch(
                'https://yschools-form.prod.sinfin.fr/api/yschools/inscriptions/uploads', {
                    method: 'POST',
                    mode: "cors",
                    headers: {
                        Accept: '*/*',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: 'a85bb0f3f282304367d52902879f7119',
                        uniqidipad: Constants.installationId,
                        datas: this.state.inscriptions
                    }),
                }
            );
            console.log(JSON.stringify({
                token: 'a85bb0f3f282304367d52902879f7119',
                uniqidipad: Constants.installationId,
                datas: this.state.inscriptions
            }));
            let responseJson = await response.json();
            if (responseJson.success) {
                await this._setUploaded();
                await this.setState({isLoading: false, inscriptions: await this._getInscriptions()});
                Alert.alert(
                    'Transfert réussi',
                    '',
                    [
                        {text: 'OK', onPress: () => console.log('OK Pressed')},
                    ],
                    {cancelable: false}
                )
            } else {
                this.setState({isLoading: false});

                Alert.alert(
                    'Une erreur est survenue',
                    'Code erreur : ',
                    [
                        {text: 'OK', onPress: () => console.log('OK Pressed')},
                    ],
                    {cancelable: false}
                )
            }
        } catch (error) {
            this.setState({isLoading: false});

            console.log(error);
            Alert.alert(
                'Une erreur est survenue',
                'Code erreur : ',
                [
                    {text: 'OK', onPress: () => console.log('OK Pressed')},
                ],
                {cancelable: false}
            )
        }
    };

    _toggleInscription = (type, nb) => {
        switch (type) {
            case 'TOTAL':
                this.props.dispatch({type: 'UPDATE_INSCRIPTIONS', inscriptions: nb});
                break;
            case 'NEW':
                this.props.dispatch({type: 'UPDATE_INSCRIPTIONS_NEW', newInscriptions: nb});
                break;
        }
    };

    renderTablet = () => {
        return (
            <>
                <View style={this.styles.tabBlock}>
                    <View style={this.styles.tabHeader}>
                        <View style={this.styles.headerCol}>
                            <Text style={this.styles.headerText}>Nom</Text>
                        </View>
                        <View style={this.styles.headerCol}>
                            <Text style={this.styles.headerText}>Prénom</Text>
                        </View>
                        <View style={this.styles.headerCol}>
                            <Text style={this.styles.headerText}>Portable</Text>
                        </View>
                        <View style={this.styles.headerCol}>
                            <Text style={this.styles.headerText}>Inscrit le</Text>
                        </View>
                        <View style={this.styles.headerCol}>
                            <Text style={this.styles.headerText}>à</Text>
                        </View>
                        <View style={this.styles.headerCol}>
                            <Text style={this.styles.headerText}>Uploadé</Text>
                        </View>
                    </View>
                    <FlatList
                        style={{width: '100%'}}
                        data={this.state.inscriptions}
                        keyExtractor={(item) => {
                            return '' + Math.random();
                        }}
                        renderItem={({item}) =>
                            <InscriptionListItem content={item}/>
                        }
                    />
                    
                    {this._displayLoading()}
                </View>

                <TouchableOpacity style={this.styles.buttonTouchContainer} onPress={() => this._uploadInscription()}>
                    <View style={this.styles.buttonContainer}>
                        <View style={this.styles.buttonTextView}>
                            <Text style={this.styles.buttonText}>UPLOADER</Text>
                        </View>
                    </View>
                </TouchableOpacity>
            </>
        );
    };

    renderMobile = () => {
        let lengthInscriptionsList = 0;
        if (this.state.inscriptions !== undefined) {
            lengthInscriptionsList = this.state.inscriptions.length;
        }
        return (
            <View>
                <View style={this.styles.inscriptionCount}>
                    <Text style={this.styles.inscriptionCountText}>Nouveaux
                        inscrits: {this.props.inscriptions.newInscriptions}</Text>
                    <Text style={this.styles.inscriptionCountText}>Total
                        inscrits: {this.props.inscriptions.inscriptions}</Text>
                </View>
                <View style={this.styles.mobileTabBlock}>
                    <View style={this.styles.mobileTabHeader}>
                        <View style={this.styles.mobileHeaderFirstCol}>
                            <Text style={this.styles.mobileHeaderText}>Nom Prénom</Text>
                        </View>

                        <View style={this.styles.mobileHeaderOtherCol}>
                            <Text style={this.styles.mobileHeaderText}>Inscrit le</Text>
                        </View>
                        <View style={this.styles.mobileHeaderLastCol}>
                            <Text style={this.styles.mobileHeaderText}>Uploadé</Text>
                        </View>
                    </View>
                    <FlatList
                        style={{width: '100%'}}
                        data={this.state.inscriptions}
                        keyExtractor={(item) => {
                            return '' + Math.random();
                        }}
                        renderItem={({item}) =>
                            <InscriptionListItem content={item}/>
                        }
                    />
                    
                    {this._displayLoading()}
                </View>

                <TouchableOpacity   disabled={lengthInscriptionsList > 0 ? false : true}
                                    style={this.styles.mobileButtonTouchContainer}
                                    onPress={() => this._uploadInscription()}>
                    <View style={lengthInscriptionsList > 0 ? this.styles.buttonContainer : this.styles.buttonContainerDisabled}>
                        <View style={this.styles.buttonTextView}>
                            <Text style={this.styles.buttonTextMobile}>UPLOADER</Text>
                        </View>
                    </View>
                </TouchableOpacity>
            </View>
        );
    };

    render() {
        let width = Dimensions.get('window').width;
        let tablet = width > 480;

        
        
        return (
            <View>
                {tablet ? this.renderTablet() : this.renderMobile()}
            </View>
        );
    }

    styles = StyleSheet.create({
        headerCol: {
            width: '16.66%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        headerText: {
            fontFamily: "HelveticaNeue",
            fontSize: 18,
            fontWeight: "500",
            fontStyle: "normal",
            lineHeight: 23,
            letterSpacing: 0,
            textAlign: "center",
            color: "#ffffff"
        },
        tabBlock: {
            width: '100%',
            height: '80%',
            borderRadius: 5,
            backgroundColor: "#ffffff",
            shadowColor: "rgba(2, 44, 109, 0.37)",
            shadowOffset: {
                width: 0,
                height: 3
            },
            shadowRadius: 6,
            shadowOpacity: 1,
            elevation: 5
        },
        tabHeader: {
            width: '100%',
            height: 58,
            borderTopLeftRadius: 5,
            borderTopRightRadius : 5,
            backgroundColor: "#022c6d",
            flexDirection: "row"
        },
        buttonText: {
            fontFamily: "HelveticaNeue",
            fontSize:   wp("1.8%"),
            fontWeight: "normal",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "center",
            color: "#ffffff"
        },
        buttonContainer: {
            backgroundColor: "#022c6d",
            justifyContent: 'center',
            alignItems: 'center',
            transform: [{skewX: '-40deg'}],
            width: '100%',
            height: '100%'
        },

        buttonContainerDisabled: {
            backgroundColor: "#C0C0C0",
            justifyContent: 'center',
            alignItems: 'center',
            transform: [{skewX: '-40deg'}],
            width: '100%',
            height: '100%'
        },

        buttonTouchContainer: {
            // height: 35,
            marginTop: 10,
            marginBottom: 10,
            alignSelf: 'center'
        },
        buttonTextView: {
            transform: [{skewX: '40deg'}],
            paddingHorizontal: 30,
        },
        loading_container: {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            alignItems: 'center',
            justifyContent: 'center'
        },
        mobileHeaderFirstCol: {
            width: '50%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        mobileHeaderOtherCol: {
            width: '30%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        mobileHeaderLastCol: {
            width: '20%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        mobileButtonTouchContainer: {
            height:  wp("8%"),
            margin: "auto",
            alignSelf: 'center',
            marginTop: 15
        },
        mobileHeaderText: {
            fontFamily: "HelveticaNeue",
            fontSize: wp("3.5%"),
            fontWeight: "500",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "center",
            color: "#ffffff"
        },
        mobileTabHeader: {
            width: '100%',
            height: wp("6%"),
            borderTopLeftRadius: 5,
            borderTopRightRadius : 5,
            backgroundColor: "#022c6d",
            flexDirection: "row"
        },
        mobileTabBlock: {
            width: '100%',
            height: '60%',
            borderRadius: 5,
            backgroundColor: "#ffffff",
            shadowColor: "rgba(2, 44, 109, 0.37)",
            shadowOffset: {
                width: 0,
                height: 3
            },
            shadowRadius: 6,
            shadowOpacity: 1,
            elevation: 5,
            marginTop :20
        },

        buttonTextMobile: {
            fontFamily: "HelveticaNeue",
            fontSize:  wp("4%"),
            fontWeight: "normal",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "center",
            color: "#ffffff"
        },

        inscriptionCount: {
            height: hp("8%")
        },
        inscriptionCountText: {
            fontFamily: "HelveticaNeue",
            fontSize: wp("4%"),
            fontWeight: "500",
            fontStyle: "normal",
            lineHeight: wp("6%"),
            letterSpacing: 0,
            textAlign: "left",
            color: "#022c6d"
        },
    });
}


const mapStateToProps = (state) => {
    return {
        inscriptions: state.inscriptions
    };
};

export default connect(mapStateToProps)(InscriptionList)