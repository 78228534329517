import React from 'react';
import {
    View,
    StyleSheet,
    Text,
    TextInput,
    TouchableOpacity, Picker, Dimensions, ScrollView
} from 'react-native';
// import DatePicker from 'react-native-datepicker'

import ListeDept from '../../Datas/listeDepartements';
import ListeVilles from '../../Datas/listeVilles';
import ListeCps from '../../Datas/listeCps';
import ListeLycees from '../../Datas/listeLycees'
import {Header} from '../../Components/Header';
import {FooterAdmin} from "../../Components/FooterAdmin"
import {EventsList} from "../../Components/EventsList";
import {connect} from "react-redux";
import * as SQLite from 'expo-sqlite';
// import RNPickerSelect from "react-native-picker-select";

// const db = SQLite.openDatabase('db.db');

class EventAddScreen extends React.Component {

    state = {
        date: new Date(),
        dept: 'AUBE',
        city: 'TROYES',
        cp: ListeCps['TROYES'][0],
        etab: ''
    };

    _toggleSalon = async () => {
        var day = "0" + this.state.date.getDate(); //Current Date
        var month = "0" + (this.state.date.getMonth() + 1); //Current Month
        var year = this.state.date.getFullYear(); //Current Year

        let dateString = '' + day.substr(-2) + '-' + month.substr(-2) + '-' + year;
        const action = {
            type: 'TOGGLE_FORUM',
            salon: {name: 'Forum ' + this.state.etab + ' ' + this.state.cp + ' ' + this.state.city, date: dateString}
        };
        await this._saveEventToDb({
            name: 'Forum ' + this.state.etab + ' ' + this.state.cp + ' ' + this.state.city,
            date: dateString,
            type: 'FORUM'
        });
        await this.props.dispatch(action);

        this.props.navigation.goBack();
    };

    _saveEventToDb = async (salon) => {
        // await db.transaction(tx => {
        //     tx.executeSql(
        //         `INSERT INTO actual_salon(name,date,type) VALUES (?,?,?);`,
        //         [salon.name, salon.date, 'SALON'],
        //         (_, {rows: {_array}}) => console.log({items: _array}),
        //         (t, error) => {
        //             console.log(error);
        //         }
        //     );
        // });
    };

    render() {
        let width = Dimensions.get('window').width;
        let tablet = width > 480;

        const placeholder = {
            label: 'Selectionner',
            value: null,
            color: '#9EA0A4',
        };

        let listeVillePicker = [];
        let listeLyceePicker = [];

        ListeVilles[this.state.dept].map((item, index) =>
            listeVillePicker.push({
                    key: item,
                    label: item,
                    value: item
                }
            )
        );

        ListeLycees[this.state.city].map((item, index) =>
            listeLyceePicker.push({
                    key: item,
                    label: item,
                    value: item
                }
            )
        );


        return (
            <View>
                <View style={tablet ? styles.contentView : styles.mobileContentView}>
                    <View style={tablet ? styles.formView : styles.mobileFormView}>
                        <Text style={tablet ? styles.formTitle : styles.mobileFormTitle}>Créer un forum</Text>
                        <ScrollView contentContainerStyle={styles.formContent}>
                            <View style={tablet ? styles.formItem : styles.mobileFormItem}>
                                <Text style={styles.itemText}>Date</Text>
                                {/* <DatePicker
                                    style={tablet ? styles.pickerContainer : styles.mobilePickerContainer}
                                    date={this.state.date}
                                    mode="date"
                                    placeholder="Selectionner une date"
                                    format="DD-MM-YYYY"
                                    //minDate={new Date()}
                                    //maxDate={new Date()}
                                    confirmBtnText="OK"
                                    cancelBtnText="Annuler"
                                    showIcon={false}
                                    customStyles={{

                                        dateInput: styles.itemTextInput
                                        // ... You can check the source to find the other keys.
                                    }}
                                    onDateChange={(date) => {
                                        this.setState({date: date})
                                    }}
                                /> */}
                            </View>
                            <View style={tablet ? styles.formItem : styles.mobileFormItem}>
                                <Text style={styles.itemText}>Département</Text>
                                <View style={tablet ? styles.pickerContainer : styles.mobilePickerContainer}>
                                    {/* <RNPickerSelect
                                        placeholder={placeholder}
                                        doneText={"OK"}
                                        items={ListeDept}
                                        onValueChange={value => {
                                            this.setState({dept: value});
                                        }}
                                        style={pickerStyle}
                                        value={this.state.dept}
                                        useNativeAndroidPickerStyle={false}
                                    /> */}
                                </View>
                            </View>
                            <View style={tablet ? styles.formItem : styles.mobileFormItem}>
                                <Text style={styles.itemText}>Ville</Text>
                                <View style={tablet ? styles.pickerContainer : styles.mobilePickerContainer}>
                                    {/* <RNPickerSelect
                                        placeholder={placeholder}
                                        doneText={"OK"}
                                        items={listeVillePicker}
                                        onValueChange={value => {
                                            if (value) {
                                                this.setState({city: value, cp: ListeCps[value][0]});
                                            }
                                        }}
                                        style={pickerStyle}
                                        value={this.state.city}
                                        useNativeAndroidPickerStyle={false}
                                    /> */}
                                </View>
                            </View>
                            <View style={tablet ? styles.formItem : styles.mobileFormItem}>
                                <Text style={styles.itemText}>Code postal</Text>
                                <TextInput style={tablet ? styles.itemTextInput : styles.mobileItemTextInput}>{ListeCps[this.state.city]}</TextInput>
                            </View>
                            <View style={tablet ? styles.formItem : styles.mobileFormItem}>
                                <Text style={styles.itemText}>Établissement</Text>
                                <View style={tablet ? styles.pickerContainer : styles.mobilePickerContainer}>
                                    {/* <RNPickerSelect
                                        placeholder={placeholder}
                                        doneText={"OK"}
                                        items={listeLyceePicker}
                                        onValueChange={value => {
                                            if (value) {
                                                this.setState({etab: value});
                                            }
                                        }}
                                        style={pickerStyle}
                                        value={this.state.etab}
                                        useNativeAndroidPickerStyle={false}
                                    /> */}
                                </View>
                            </View>
                            <View style={styles.formButton}>
                                <TouchableOpacity style={{width: "47%"}} onPress={() => this.props.navigation.goBack()}>
                                    <View
                                        style={[tablet ? styles.buttonContainer : styles.mobileButtonContainer, styles.buttonCancel]}>
                                        <View style={styles.buttonTextView}>
                                            <Text style={styles.buttonText}>ANNULER</Text>
                                        </View>
                                    </View>
                                </TouchableOpacity>
                                <TouchableOpacity style={{width: "47%"}} onPress={() => this._toggleSalon()}>
                                    <View style={tablet ? styles.buttonContainer : styles.mobileButtonContainer}>
                                        <View style={styles.buttonTextView}>
                                            <Text style={styles.buttonText}>CRÉER</Text>
                                        </View>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </ScrollView>
                    </View>
                </View>
                <View style={tablet ? styles.footerView : styles.mobileFooterView}>
                    <FooterAdmin navigation={this.props.navigation} type="events"/>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
        contentView: {
            justifyContent: 'center',
            alignItems: 'center',
            height: '80%',
        },
        footerView: {
            height: '20%'
        },
        formView: {
            width: '52%',
        },
        formTitle: {
            width: 152,
            height: 26,
            fontFamily: "HelveticaNeue",
            fontSize: 22,
            fontWeight: "500",
            fontStyle: "normal",
            lineHeight: 27,
            letterSpacing: 0,
            textAlign: "center",
            color: "#022c6d"
        },
        formContent: {
            flexDirection: "column",
            justifyContent: 'space-around',
            //height: "90%",
            width: "100%",
            padding: 30
        },
        formItem: {
            flexDirection: "row",
            justifyContent: 'space-between',
            width: "100%",
            height: 50,

        },
        itemText: {
            height: 22,
            fontFamily: "HelveticaNeue",
            fontSize: 18,
            fontWeight: "500",
            fontStyle: "normal",
            lineHeight: 23,
            letterSpacing: 0,
            textAlign: "left",
            color: "#022c6d"
        },
        itemTextInput: {
            width: "70%",
            height: 34.8,
            borderRadius: 6,
            borderStyle: "solid",
            borderWidth: 0.5,
            borderColor: "#022c6d",
            fontFamily: "HelveticaNeue",
            fontSize: 14,
            fontWeight: "300",
            fontStyle: "normal",
            lineHeight: 17,
            letterSpacing: 0,
            textAlign: "left",
            color: "#7b7b7b",
            paddingLeft: 12
        },
        buttonText: {
            fontFamily: "HelveticaNeue",
            fontSize: 16,
            fontWeight: "normal",
            fontStyle: "normal",
            lineHeight: 18,
            letterSpacing: 0,
            textAlign: "center",
            color: "#ffffff"
        },
        buttonContainer: {
            width: "100%",
            height: 35,
            backgroundColor: "#022c6d",
            justifyContent: 'center',
            alignItems: 'center',
            transform: [{skewX: '-40deg'}],
        },
        buttonTextView: {
            transform: [{skewX: '40deg'}]
        },
        buttonCancel: {
            backgroundColor: "#7b7b7b",
        },
        formButton: {
            top: 30,
            flexDirection: "row",
            justifyContent: 'space-around',
            width: '100%'
        },
        onePicker: {
            width: "100%",
            height: 34.8,
            borderRadius: 6,
            borderStyle: "solid",
            borderWidth: 0.5,
            borderColor: "#022c6d",
            fontFamily: "HelveticaNeue",
            fontSize: 14,
            fontWeight: "300",
            fontStyle: "normal",
            lineHeight: 17,
            letterSpacing: 0,
            textAlign: "left",
            color: "#7b7b7b",
            paddingLeft: 12
        },
        onePickerItem: {
            width: '100%',
            height: 35,
            fontFamily: "HelveticaNeue",
            fontSize: 14,
            fontWeight: "300",
            fontStyle: "normal",
            lineHeight: 17,
            letterSpacing: 0,
            textAlign: "left",
            color: "#7b7b7b",
            paddingLeft: 12
        },
        pickerContainer: {
            width: "70%"
        },
        mobileContentView: {
            justifyContent: 'center',
            alignItems: 'center',
            height: '75%',
            width: '100%'
        },
        mobileFooterView: {
            height: '25%'
        },
        mobileButtonContainer: {
            width: '100%',
            height: 35,
            backgroundColor: "#022c6d",
            justifyContent: 'center',
            alignItems: 'center',
            transform: [{skewX: '-40deg'}],
            marginBottom: 20
        },
        mobileFormView: {
            width: "100%"
        },
        mobileFormItem: {
            flexDirection: "column",
            justifyContent: 'space-between',
            width: "100%",
            height: 70,
            marginBottom: 10
        },
        mobileFormTitle: {
            fontFamily: "HelveticaNeue",
            fontSize: 15,
            fontWeight: "500",
            fontStyle: "normal",
            lineHeight: 18,
            letterSpacing: 0,
            textAlign: "center",
            color: "#022c6d",
            marginTop: 10
        },
        mobilePickerContainer: {
            width: "100%"
        },
        mobileItemTextInput: {
            width: "100%",
            height: 34.8,
            borderRadius: 6,
            borderStyle: "solid",
            borderWidth: 0.5,
            borderColor: "#022c6d",
            fontFamily: "HelveticaNeue",
            fontSize: 14,
            fontWeight: "300",
            fontStyle: "normal",
            lineHeight: 17,
            letterSpacing: 0,
            textAlign: "left",
            color: "#7b7b7b",
            paddingLeft: 12
        },
    })
;

const pickerStyle = {
    inputIOS: styles.onePicker,
    inputAndroid: styles.onePicker,
};

const mapStateToProps = (state) => {
    return state.salon;
};

export default connect(mapStateToProps,)(EventAddScreen)