export default {
    formations: [
        {
            'name': '3ème',
            'availableStudy': [
                "TP COMPTABLE ASSISTANT H/F",
                "TP SECRETAIRE COMPTABLE H/F",
                "TP ASSISTANT DE VIE AUX FAMILLES H/F",
                "TP MEDIATEUR NUMERIQUE H/F",
                "TP DEVELOPPEUR WEB H/F",
                "TP WEB DESIGNER H/F",
                "TP RESPONSABLE DE RAYON H/F",
                "TP VENDEUR CONSEILLER COMMERCIAL H/F",
                "TP SUPERVISEUR RELATION CLIENT A DISTANCE H/F",
                "TP EMPLOYE COMMERCIAL MAGASIN H/F",
                "TP AGENT MAGASINIER H/F",
                "TP FABRICANT DE VETEMENTS SUR MESURE H/F",
                "Autre"
            ]
        },
        {
            'name': '2nde',
            'availableStudy': [
                "TP COMPTABLE ASSISTANT H/F",
                "TP SECRETAIRE COMPTABLE H/F",
                "TP ASSISTANT DE VIE AUX FAMILLES H/F",
                "TP MEDIATEUR NUMERIQUE H/F",
                "TP DEVELOPPEUR WEB H/F",
                "TP WEB DESIGNER H/F",
                "TP RESPONSABLE DE RAYON H/F",
                "TP VENDEUR CONSEILLER COMMERCIAL H/F",
                "TP SUPERVISEUR RELATION CLIENT A DISTANCE H/F",
                "TP EMPLOYE COMMERCIAL MAGASIN H/F",
                "TP AGENT MAGASINIER H/F",
                "TP FABRICANT DE VETEMENTS SUR MESURE H/F",
                "Autre"
            ]
        },
        {
            'name': 'CAP',
            'availableStudy': [
                "TP COMPTABLE ASSISTANT H/F",
                "TP SECRETAIRE COMPTABLE H/F",
                "TP ASSISTANT DE VIE AUX FAMILLES H/F",
                "TP MEDIATEUR NUMERIQUE H/F",
                "TP DEVELOPPEUR WEB H/F",
                "TP WEB DESIGNER H/F",
                "TP RESPONSABLE DE RAYON H/F",
                "TP VENDEUR CONSEILLER COMMERCIAL H/F",
                "TP SUPERVISEUR RELATION CLIENT A DISTANCE H/F",
                "TP EMPLOYE COMMERCIAL MAGASIN H/F",
                "TP AGENT MAGASINIER H/F",
                "TP FABRICANT DE VETEMENTS SUR MESURE H/F",
                "Autre"
            ]
        },
        {
            'name': 'BEP',
            'availableStudy': [
                "TP COMPTABLE ASSISTANT H/F",
                "TP SECRETAIRE COMPTABLE H/F",
                "TP ASSISTANT DE VIE AUX FAMILLES H/F",
                "TP MEDIATEUR NUMERIQUE H/F",
                "TP DEVELOPPEUR WEB H/F",
                "TP WEB DESIGNER H/F",
                "TP RESPONSABLE DE RAYON H/F",
                "TP VENDEUR CONSEILLER COMMERCIAL H/F",
                "TP SUPERVISEUR RELATION CLIENT A DISTANCE H/F",
                "TP EMPLOYE COMMERCIAL MAGASIN H/F",
                "TP AGENT MAGASINIER H/F",
                "TP FABRICANT DE VETEMENTS SUR MESURE H/F",
                "Autre"
            ]
        },
        {
            'name': '1ère',
            'availableStudy': [
                "TP COMPTABLE ASSISTANT H/F",
                "TP SECRETAIRE COMPTABLE H/F",
                "TP MEDIATEUR NUMERIQUE H/F",
                "TP DEVELOPPEUR WEB H/F",
                "TP WEB DESIGNER H/F",
                "TP RESPONSABLE DE RAYON H/F",
                "TP VENDEUR CONSEILLER COMMERCIAL H/F",
                "TP SUPERVISEUR RELATION CLIENT A DISTANCE H/F",
                "TP AGENT MAGASINIER H/F",
                "TP FABRICANT DE VETEMENTS SUR MESURE H/F",
                "Autre"
            ]
        },
        {
            'name': 'Terminale/BAC',
            'availableStudy': [
                "TP ASSISTANT DE DIRECTION H/F",
                "TP ASSISTANT RESSOURCES HUMAINES H/F",
                'TP GESTIONNAIRE DE PAIE H/F',
                "TP COMPTABLE ASSISTANT H/F",
                "TP COMPTABLE GESTIONNAIRE H/F",
                "TP SECRETAIRE COMPTABLE H/F",
                "TP MEDIATEUR NUMERIQUE H/F",
                "TP DEVELOPPEUR WEB H/F",
                "TP WEB DESIGNER H/F",
                "TP NEGOCIATEUR TECHNICO-COMMERCIAL H/F",
                "TP SUPERVISEUR RELATION CLIENT A DISTANCE H/F",
                "TP ASSISTANT COMMERCIAL H/F",
                "TP COMMERCIAL H/F  ",
                "TP MANAGER D'UNIVERS MARCHAND H/F",
                "TP RESPONSABLE DE RAYON H/F",
                "TP VENDEUR CONSEILLER COMMERCIAL H/F",
                "TP CONSEILLER RELATION CLIENT A DISTANCE H/F",
                "TP RESPONSABLE DE LA DISTRIBUTION ",
                "TP CONSEILLER EN INSERTION PROFESSIONNELLE ",
                "TP FORMATEUR POUR ADULTES",
                "Autre"
            ]
        },
        {
            'name': 'BAC+1',
            'availableStudy': [
                "TP ASSISTANT DE DIRECTION H/F",
                "TP ASSISTANT RESSOURCES HUMAINES H/F",
                "TP COMPTABLE GESTIONNAIRE H/F",
                'TP GESTIONNAIRE DE PAIE H/F',
                "TP MEDIATEUR NUMERIQUE H/F",
                "TP DEVELOPPEUR WEB H/F",
                "TP WEB DESIGNER H/F",
                "TP NEGOCIATEUR TECHNICO-COMMERCIAL H/F",
                "TP SUPERVISEUR RELATION CLIENT A DISTANCE H/F",
                "TP ASSISTANT COMMERCIAL H/F",
                "TP COMMERCIAL H/F  ",
                "TP MANAGER D'UNIVERS MARCHAND H/F",
                "TP RESPONSABLE DE LA DISTRIBUTION ",
                "TP CONSEILLER EN INSERTION PROFESSIONNELLE ",
                "TP FORMATEUR POUR ADULTES",
                "Autre"
            ]
        },
        {
            'name': 'BAC+5',
            'availableStudy': [
                "MASTER MPTI H/F",
                "Autre"
            ]
        },
        {
            'name': 'Autre',
            'availableStudy': [
                "MASTER MPTI H/F",
                "Autre"
            ]
        },
    ],
    campus:
        null,
    diplomes:
        [
            {
                key: 'BAC',
                label: 'BAC Général',
                value: 'BAC'

            },
            {
                key: 'BAC Techno',
                label: 'BAC Techno',
                value: 'BAC Techno'
            },
            {
                key: 'BAC PRO',
                label: 'BAC PRO',
                value: 'BAC PRO'
            },
            {
                key: 'PREPA ECE',
                label: 'PREPA ECE',
                value: 'PREPA ECE'
            },
            {
                key: 'PREPA ECP',
                label: 'PREPA ECP',
                value: 'PREPA ECP'
            },
            {
                key: 'PREPA ECS',
                label: 'PREPA ECS',
                value: 'PREPA ECS'
            },
            {
                key: 'PREPA ECT',
                label: 'PREPA ECT',
                value: 'PREPA ECT'
            },
            {
                key: 'BTS',
                label: 'BTS',
                value: 'BTS'
            },
            {
                key: 'DUT',
                label: 'DUT',
                value: 'DUT'
            },
            {
                key: 'LICENCE',
                label: 'LICENCE',
                value: 'LICENCE'
            },
            {
                key: 'LICENCE PRO',
                label: 'LICENCE PRO',
                value: 'LICENCE PRO'
            },
            {
                key: 'BACHELOR',
                label: 'BACHELOR',
                value: 'BACHELOR'
            },
            {
                key: 'MASTER',
                label: 'MASTER',
                value: 'MASTER'
            },
            {
                key: 'Autre',
                label: 'Autre',
                value: 'Autre'
            }
        ],

    immersionDay:
        false,
    jpo:
        false,
    privateVisit:
        false,
}
;

