import React from "react";
import {
  View,
  StyleSheet,
  TextInput,
  Text,
  Picker,
  Switch,
  Dimensions,
  ScrollView,
  YellowBox,
  LogBox
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { connect } from "react-redux";
// import DateTimePicker from "react-native-datepicker";
// import RNPickerSelect from "react-native-picker-select";
import { Picker as CommonPicker } from '@react-native-picker/picker';

YellowBox.ignoreWarnings(['']);

class StudentInfoForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDatePicker: true,
      diplome: "",
      dateNais: "",
      rgpdSwitch: false,
      asso_passerelle: false,
      actualSituation: "Selectionner",
      civilite: "Selectionner",
      civiliteList: [
        {
          key: "Monsieur",
          label: "Monsieur",
          value: "Monsieur",
        },
        {
          key: "Madame",
          label: "Madame",
          value: "Madame",
        },
      ],
      actualSituationList: [
        {
          key: "Salarié",
          label: "Salarié",
          value: "Salarié",
        },
        {
          key: "Demandeur d'emploi",
          label: "Demandeur d'emploi",
          value: "Demandeur d'emploi",
        },
        {
          key: "Etudiant",
          label: "Etudiant",
          value: "Etudiant",
        },
        {
          key: "Inactif",
          label: "Inactif",
          value: "Inactif",
        },
        {
          key: "Autre",
          label: "Autre",
          value: "Autre",
        },
      ],
    };
  }

  toggleRGPD = () => {
    if (this.state.rgpdSwitch === false) {
      this.setState({ rgpdSwitch: true });
      this.props.actualiseFormState({ rgpdSwitch: true });
    } else {
      this.setState({ rgpdSwitch: false });
      this.props.actualiseFormState({ rgpdSwitch: false });
    }
  };
  toggleAssoPasserelle = () => {
    if (this.state.asso_passerelle === false) {
      this.setState({ asso_passerelle: true });
      this.props.actualiseFormState({ asso_passerelle: true });
    } else {
      this.setState({ asso_passerelle: false });
      this.props.actualiseFormState({ asso_passerelle: false });
    }
  };

  showAssoPasserelle = () => {
    let res = false;
    if (this.props.school.school === "scbs") {
      console.log(this.props.interest);
      this.props.interest.map(function (interet) {
        if (interet.includes("Programme Grande Ecole")) {
          res = true;
        }
      });
    }
    return res;
  };

  renderMobile = (styles, placeholder, pickerStyle) => {
    function showSelectedCampus(campus){
      if (campus.length > 0){
        let campusListTxt = '';
        campus.map(function(item) {campusListTxt += item + ', '});
        campusListTxt = campusListTxt.substring(0, campusListTxt.length - 2);
        return  <Text style={styles.mobileItemText}>Campus sélectionné : {campusListTxt}</Text>
      }
    }

    return (
      <KeyboardAwareScrollView
        keyboardShouldPersistTaps={"always"}
        style={{ width: "100%" }}
        enableOnAndroid={true}
      >
        {showSelectedCampus(this.props.campus)}

        {this.props.school.school === "pfep" ? (
          <View style={styles.mobileFormItem}>
            <Text style={styles.mobileItemText}>Civilité</Text>
            <View style={{ width: "100%" }}>
              {/* <RNPickerSelect
                doneText={"OK"}
                placeholder={placeholder}
                items={this.state.civiliteList}
                onValueChange={(value) => {
                  this.props.actualiseFormState({ civilite: value });
                  this.setState({ civilite: value });
                }}
                style={pickerStyle}
                value={this.state.civilite}
                useNativeAndroidPickerStyle={false}
              /> */}

              <CommonPicker
                style={styles.onePicker}
                selectedValue={this.state.civilite}
                onValueChange={(itemValue, itemIndex) => {
                  this.props.actualiseFormState({ civilite: itemValue });
                  this.setState({ civilite: itemValue });
                }}
              >
                <CommonPicker.Item label="Monsieur" value="Monsieur" />
                <CommonPicker.Item label="Madame" value="Madame" />
              </CommonPicker>
            </View>
          </View>
        ) : null}
        <View style={styles.mobileFormItem}>
          <Text style={styles.mobileItemText}>Nom*</Text>
          <TextInput
            onChangeText={(value) =>
              this.props.actualiseFormState({ name: value })
            }
            placeholder={"Nom"}
            style={styles.mobileItemTextInput}
          ></TextInput>
        </View>
        <View style={styles.mobileFormItem}>
          <Text style={styles.mobileItemText}>Prénom*</Text>
          <TextInput
            onChangeText={(value) =>
              this.props.actualiseFormState({ firstname: value })
            }
            placeholder={"Prénom"}
            style={styles.mobileItemTextInput}
          ></TextInput>
        </View>
        <View style={styles.mobileFormItem}>
          <Text style={styles.mobileItemText}>Date de naissance</Text>
          {/* <TextInput onChangeText={value => this.props.actualiseFormState({dateNais: date})}
                               placeholder={"Date de naissance"}
                               style={styles.mobileItemTextInput}
                    > */}
          <View style={styles.mobileItemTextInputDatePicker}>
            {/* <DateTimePicker
              locale={"fr"}
              date={this.state.dateNais}
              confirmBtnText={"OK"}
              format="DD-MM-YYYY"
              cancelBtnText={"Annuler"}
              customStyles={{
                dateInput: {
                  borderWidth: 0,
                },
              }}
              showIcon={false}
              style={{
                width: "100%",
              }}
              customStyles={{
                dateInput: {
                  alignItems: "flex-start",
                  borderColor: "rgba(255,255,255,0)"
                },
                btnTextCancel: {
                  color: "red"
                },
                btnTextConfirm: {
                  color: "green"
                },
                dateText: {
                  alignSelf: "flex-start",
                  ...styles.datePickerPicked
                }

              }}
              placeholder="Choisissez votre date de naissance"
              // style={styles.datePicker}
              onDateChange={value => {
                this.props.actualiseFormState({ dateNais: value })
                this.setState({
                  dateNais: value
                })
              }
              }
              disabled={false}
            /> */}

            <input 
              type="date"
              onChange={e => {
                const date = e.target.value;

                this.props.actualiseFormState({ dateNais: date });
                this.setState({
                  dateNais: date
                });
              }}
              value={this.state.dateNais}
              style={{
                width: "50%",
                height: 34.8,
                borderRadius: 6,
                borderStyle: "solid",
                borderWidth: 0.5,
                borderColor: "#022c6d",
                fontFamily: "HelveticaNeue",
                fontSize: 14,
                fontWeight: "300",
                fontStyle: "normal",
                lineHeight: 17,
                letterSpacing: 0,
                textAlign: "left",
                alignItems: "flex-start",
                color: "#7b7b7b",
                paddingLeft: 0,
              }}
            />
          </View>
          {/* </TextInput> */}
        </View>
        <View style={styles.mobileFormItem}>
          <Text style={styles.mobileItemText}>Diplôme*</Text>
          <View style={{ width: "100%" }}>
            {/* <RNPickerSelect
              placeholder={placeholder}
              doneText={"OK"}
              items={this.props.school.schoolInfos.default.diplomes}
              onValueChange={(value) => {
                this.props.actualiseFormState({ diplome: value });
                this.setState({ diplome: value });
              }}
              style={pickerStyle}
              value={this.state.diplome}
              useNativeAndroidPickerStyle={false}
            /> */}

            <CommonPicker
              style={styles.onePicker}
              selectedValue={this.state.diplome}
              onValueChange={(itemValue, itemIndex) => {
                this.props.actualiseFormState({ diplome: itemValue });
                this.setState({ diplome: itemValue });
              }}
            >
              {
                this.props.school.schoolInfos.default.diplomes.map((diplome, index) => (
                  <CommonPicker.Item label={diplome.label} value={diplome.value} key={`${diplome.key}_${index}`} />
                ))
              }
            </CommonPicker>
          </View>
        </View>
        {this.props.school.school !== "pfep" ? (
          <View style={styles.mobileFormItem}>
            <Text style={styles.mobileItemText}>Lycée*</Text>
            <TextInput
              placeholder={"Lycée"}
              style={styles.mobileItemTextInput}
              onChangeText={(value) =>
                this.props.actualiseFormState({ lycee: value })
              }
            ></TextInput>
          </View>
        ) : (
          <View style={styles.mobileFormItem}>
            <Text style={styles.mobileItemText}>Situation actuelle</Text>
            <View style={{ width: "100%" }}>
              {/* <RNPickerSelect
                doneText={"OK"}
                placeholder={placeholder}
                items={this.state.actualSituationList}
                onValueChange={(value) => {
                  this.props.actualiseFormState({ actualSituation: value });
                  this.setState({ actualSituation: value });
                }}
                style={pickerStyle}
                value={this.state.actualSituation}
                useNativeAndroidPickerStyle={false}
              /> */}

              <CommonPicker
                style={styles.onePicker}
                selectedValue={this.state.actualSituation}
                onValueChange={(itemValue, itemIndex) => {
                  this.props.actualiseFormState({ actualSituation: itemValue });
                  this.setState({ actualSituation: itemValue });
                }}
              >
                <CommonPicker.Item label="Salarié" value="Salarié" />
                <CommonPicker.Item label="Demandeur d'emploi" value="Demandeur d'emploi" />
                <CommonPicker.Item label="Etudiant" value="Etudiant" />
                <CommonPicker.Item label="Inactif" value="Inactif" />
                <CommonPicker.Item label="Autre" value="Autre" />
              </CommonPicker>
            </View>
          </View>
        )}
        <View style={styles.mobileFormItem}>
          <Text style={styles.mobileItemText}>Adresse*</Text>
          <TextInput
            onChangeText={(value) =>
              this.props.actualiseFormState({ adress: value })
            }
            placeholder={"Adresse"}
            style={styles.mobileItemTextInput}
          ></TextInput>
        </View>
        <View style={styles.mobileFormItem}>
          <Text style={styles.mobileItemText}>Code Postal*</Text>
          <TextInput
            onChangeText={(value) =>
              this.props.actualiseFormState({ cp: value })
            }
            placeholder={"Code Postal"}
            keyboardType={"number-pad"}
            style={styles.mobileItemTextInput}
          ></TextInput>
        </View>
        <View style={styles.mobileFormItem}>
          <Text style={styles.mobileItemText}>Ville*</Text>
          <TextInput
            onChangeText={(value) =>
              this.props.actualiseFormState({ ville: value })
            }
            placeholder={"Ville"}
            style={styles.mobileItemTextInput}
          ></TextInput>
        </View>

        <View style={styles.mobileFormItem}>
          <Text style={styles.mobileItemText}>Portable*</Text>
          <TextInput
            onChangeText={(value) =>
              this.props.actualiseFormState({ phone: value })
            }
            placeholder={"Portable"}
            keyboardType={"phone-pad"}
            style={styles.mobileItemTextInput}
          ></TextInput>
        </View>
        <View style={styles.mobileFormItem}>
          <Text style={styles.mobileItemText}>Email*</Text>
          <TextInput
            onChangeText={(value) =>
              this.props.actualiseFormState({ email: value })
            }
            placeholder={"Email"}
            keyboardType={"email-address"}
            style={styles.mobileItemTextInput}
          ></TextInput>
        </View>

        <View style={[styles.mobileFormItem, { height: 120 }]}>
          <Text style={styles.mobileItemText}>Commentaire</Text>
          <TextInput
            onChangeText={(value) =>
              this.props.actualiseFormState({ commentaire: value })
            }
            placeholder={"Commentaire"}
            multiline={true}
            style={styles.mobileTextArea}
          ></TextInput>
        </View>
        <View
          style={[
            styles.mobileFormItem,
            { justifyContent: "flex-start", marginTop: 5 },
          ]}
        >
          <Text style={[styles.mobileItemText, { left: 0 }]}>
            En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées par Y Schools à des fins commerciales*
          </Text>
          <Switch
            onValueChange={() => this.toggleRGPD()}
            value={this.state.rgpdSwitch}
          />
        </View>
        {this.showAssoPasserelle() ? (
          <View
            style={[
              styles.mobileFormItem,
              { justifyContent: "flex-start", marginTop: 5, height: 120 },
            ]}
          >
            <Text style={[styles.mobileItemText, { left: 0 }, { height: 75 }]}>
              J’accepte que mes données personnelles soit transmises à
              l’Association Passerelle
            </Text>
            <Switch
              onValueChange={() => this.toggleAssoPasserelle()}
              value={this.state.asso_passerelle}
            />
          </View>
        ) : null}
      </KeyboardAwareScrollView>
    );
  };

  render() {
    let width = Dimensions.get("window").width;
    let tablet = width > 480;

    const placeholder = {
      label: "Selectionner",
      value: null,
      color: "#9EA0A4",
    };
    const primaryColor = this.props.school.color;
    const styles = StyleSheet.create({
      formView: {
        flexDirection: "column",
        height: "100%",
        width: "100%",
        alignItems: "center",
      },
      formColContainer: {
        flexDirection: "row",
        height: "60%",
      },
      formCol: {
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "100%",
        width: "50%",
      },
      formItem: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        paddingLeft: 20,
        paddingRight: 20,
      },
      itemText: {
        height: 22,
        fontFamily: "HelveticaNeue",
        fontSize: 18,
        fontWeight: "500",
        fontStyle: "normal",
        lineHeight: 23,
        letterSpacing: 0,
        textAlign: "left",
        color: primaryColor,
      },
      itemTextInput: {
        width: "50%",
        height: 34.8,
        borderRadius: 6,
        borderStyle: "solid",
        borderWidth: 0.5,
        borderColor: "#022c6d",
        fontFamily: "HelveticaNeue",
        fontSize: 14,
        fontWeight: "300",
        fontStyle: "normal",
        lineHeight: 17,
        letterSpacing: 0,
        textAlign: "left",
        alignItems: "flex-start",
        color: "#7b7b7b",
        paddingLeft: 12,
      },
      textArea: {
        width: "76%",
        height: 80,
        borderRadius: 6,
        borderStyle: "solid",
        borderWidth: 0.5,
        borderColor: "#022c6d",
        fontFamily: "HelveticaNeue",
        fontSize: 14,
        fontWeight: "300",
        fontStyle: "normal",
        lineHeight: 17,
        letterSpacing: 0,
        textAlign: "left",
        color: "#7b7b7b",
        paddingLeft: 12,
      },
      onePicker: {
        width: "100%",
        height: 34.8,
        borderRadius: 6,
        borderStyle: "solid",
        borderWidth: 0.5,
        borderColor: "#022c6d",
        fontFamily: "HelveticaNeue",
        fontSize: 14,
        fontWeight: "300",
        fontStyle: "normal",
        lineHeight: 17,
        letterSpacing: 0,
        textAlign: "left",
        color: "#7b7b7b",
        paddingLeft: 12,
      },
      onePickerItem: {
        width: "100%",
        height: 35,
        fontFamily: "HelveticaNeue",
        fontSize: 14,
        fontWeight: "300",
        fontStyle: "normal",
        lineHeight: 17,
        letterSpacing: 0,
        textAlign: "left",
        color: "#7b7b7b",
        paddingLeft: 12,
      },
      mobileFormItem: {
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        // height: 70,
        paddingLeft: 20,
        paddingRight: 20,
        marginBottom: 18,
      },
      mobileItemTextInput: {
        width: "100%",
        height: 34.8,
        borderRadius: 6,
        borderStyle: "solid",
        borderWidth: 0.5,
        borderColor: "#022c6d",
        fontFamily: "HelveticaNeue",
        fontSize: 14,
        fontWeight: "300",
        fontStyle: "normal",
        lineHeight: 17,
        letterSpacing: 0,
        textAlign: "left",
        alignItems: "flex-start",
        color: "#7b7b7b",
        paddingLeft: 12,
      },

      mobileItemTextInputDatePicker: {
        width: "100%",
        borderRadius: 6,
        height: 34.8,
        borderStyle: "solid",
        borderWidth: 0.5,
        borderColor: "#022c6d",
        alignItems: "flex-start",
        justifyContent: "center",
        textAlignVertical: "center",
        paddingLeft: 12,
        padding: 0,
      },

      datePicker: {
        width: "100%",
        fontFamily: "HelveticaNeue",
        fontSize: 14,
        fontWeight: "300",
        fontStyle: "normal",
        letterSpacing: 0,
        lineHeight: 17,
        textAlign: "left",
        textAlignVertical: "center",
        alignItems: "center",
        color: "#7b7b7b",
        opacity: 0.4,
        padding: 0,
      },

      datePickerPicked: {
        width: "100%",
        fontFamily: "HelveticaNeue",
        fontSize: 14,
        fontWeight: "300",
        fontStyle: "normal",
        letterSpacing: 0,
        lineHeight: 17,
        textAlign: "left",
        textAlignVertical: "center",
        alignItems: "center",
        color: "#7b7b7b",
        padding: 0,
      },

      mobileTextArea: {
        width: "100%",
        height: 80,
        borderRadius: 6,
        borderStyle: "solid",
        borderWidth: 0.5,
        borderColor: "#022c6d",
        fontFamily: "HelveticaNeue",
        fontSize: 14,
        fontWeight: "300",
        fontStyle: "normal",
        lineHeight: 17,
        letterSpacing: 0,
        textAlign: "left",
        color: "#7b7b7b",
        paddingLeft: 12,
      },
      mobileItemText: {
        //height: 22,
        width: "100%",
        fontFamily: "HelveticaNeue",
        fontSize: 16,
        fontWeight: "500",
        fontStyle: "normal",
        lineHeight: 23,
        letterSpacing: 0,
        textAlign: "left",
        color: primaryColor,
      },
    });

    const pickerStyle = {
      inputIOS: styles.onePicker,
      inputAndroid: styles.onePicker,
    };

      function showSelectedCampus(campus){
          if (campus.length > 0){
              let campusListTxt = '';
              campus.map(function(item) {campusListTxt += item + ', '});
              campusListTxt = campusListTxt.substring(0, campusListTxt.length - 2);
              return  <Text style={styles.itemText}>Campus sélectionné : {campusListTxt}</Text>
          }
      }

    if (tablet) {
      return (
        <KeyboardAwareScrollView
        //resetScrollToCoords={{x: 0, y: 0}}
        contentContainerStyle={[
          styles.formView,
          { justifyContent: "space-around" },
        ]}
        scrollEnabled={true}
        alwaysBounceVertical={false}
        style={{ flex: 1, width: "100%" }}
      >
        <View style={{ width: "90%" }}>
          {showSelectedCampus(this.props.campus)}
          <View style={styles.formColContainer}>
            <View style={styles.formCol}>
              {this.props.school.school === "pfep" ? (
                <View style={styles.formItem}>
                  <Text style={styles.itemText}>Civilité</Text>
                  <View style={{ width: "50%" }}>
                    {/* <RNPickerSelect
                      doneText={"OK"}
                      placeholder={placeholder}
                      items={this.state.civiliteList}
                      onValueChange={(value) => {
                        this.props.actualiseFormState({ civilite: value });
                        this.setState({ civilite: value });
                      }}
                      style={pickerStyle}
                      value={this.state.civilite}
                      useNativeAndroidPickerStyle={false}
                    /> */}

                    <CommonPicker
                      style={styles.onePicker}
                      selectedValue={this.state.civilite}
                      onValueChange={(itemValue, itemIndex) => {
                        this.props.actualiseFormState({ civilite: itemValue });
                        this.setState({ civilite: itemValue });
                      }}
                    >
                      <CommonPicker.Item label="Monsieur" value="Monsieur" />
                      <CommonPicker.Item label="Madame" value="Madame" />
                    </CommonPicker>
                  </View>
                </View>
              ) : null}
              <View style={styles.formItem}>
                <Text style={styles.itemText}>Nom*</Text>
                <TextInput
                  onChangeText={(value) =>
                    this.props.actualiseFormState({ name: value })
                  }
                  placeholder={"Nom"}
                  style={styles.itemTextInput}
                ></TextInput>
              </View>
              <View style={styles.formItem}>
                <Text style={styles.itemText}>Prénom*</Text>
                <TextInput
                  onChangeText={(value) =>
                    this.props.actualiseFormState({ firstname: value })
                  }
                  placeholder={"Prénom"}
                  style={styles.itemTextInput}
                ></TextInput>
              </View>
              <View style={styles.formItem}>
                <Text style={styles.itemText}>Date de naissance</Text>
                {/* <DateTimePicker
                  locale={"fr"}
                  date={this.state.dateNais}
                  confirmBtnText={"OK"}
                  format="DD-MM-YYYY"
                  cancelBtnText={"Annuler"}
                  customStyles={{
                    dateInput: {
                      borderWidth: 0,
                    },
                  }}
                  showIcon={false}
                  style={{
                    width: "50%",
                  }}
                  customStyles={{
                    dateInput: {
                      alignItems: "flex-start",
                      borderRadius: 5,
                      justifyContent: "center",
                    },
                    placeholderText: {
                      paddingLeft: 10,
                    },
                    btnTextCancel: {
                      color: "red"
                    },
                    btnTextConfirm: {
                      color: "green"
                    },
                    dateText: {
                      alignSelf: "flex-start",
                      borderColor: "rgba(255,255,255,0)",
                      paddingLeft: 10,
                      color: "#7b7b7b",
                      fontSize: 14,
                      fontFamily: "HelveticaNeue",
                      fontWeight: "500",
                      fontStyle: "normal",
                      letterSpacing: 0,
                    }

                  }}
                  placeholder="Choisissez votre date de naissance"
                  // style={styles.datePicker}
                  onDateChange={value => {
                    this.props.actualiseFormState({ dateNais: value })
                    this.setState({
                      dateNais: value
                    })
                  }
                  }
                  disabled={false}
                /> */}

                <input 
                  type="date"
                  onChange={e => {
                    const date = e.target.value;

                    this.props.actualiseFormState({ dateNais: date })
                    this.setState({
                      dateNais: date
                    });
                  }}
                  value={this.state.dateNais}
                  style={{
                    width: "50%",
                    height: 34.8,
                    borderRadius: 6,
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    borderColor: "#022c6d",
                    fontFamily: "HelveticaNeue",
                    fontSize: 14,
                    fontWeight: "300",
                    fontStyle: "normal",
                    lineHeight: 17,
                    letterSpacing: 0,
                    textAlign: "left",
                    alignItems: "flex-start",
                    color: "#7b7b7b",
                    paddingLeft: 0,
                  }}
                />
              </View>
              <View style={styles.formItem}>
                <Text style={styles.itemText}>Diplôme*</Text>
                <View style={{ width: "50%" }}>
                  {/* <RNPickerSelect
                    placeholder={placeholder}
                    doneText={"OK"}
                    items={this.props.school.schoolInfos.default.diplomes}
                    onValueChange={(value) => {
                      this.props.actualiseFormState({ diplome: value });
                      this.setState({ diplome: value });
                    }}
                    style={pickerStyle}
                    value={this.state.diplome}
                    useNativeAndroidPickerStyle={false}
                  /> */}

                  <CommonPicker
                    style={styles.onePicker}
                    selectedValue={this.state.diplome}
                    onValueChange={(itemValue, itemIndex) => {
                      this.props.actualiseFormState({ diplome: itemValue });
                      this.setState({ diplome: itemValue });
                    }}
                  >
                    {
                      this.props.school.schoolInfos.default.diplomes.map((diplome, index) => (
                        <CommonPicker.Item label={diplome.label} value={diplome.value} key={`${diplome.key}_${index}`} />
                      ))
                    }
                  </CommonPicker>
                </View>
              </View>
              {this.props.school.school !== "pfep" ? (
                <View style={styles.formItem}>
                  <Text style={styles.itemText}>Lycée*</Text>
                  <TextInput
                    placeholder={"Lycée"}
                    onChangeText={(value) =>
                        this.props.actualiseFormState({ lycee: value })
                    }
                    style={styles.itemTextInput}
                  ></TextInput>
                </View>
              ) : (
                <View style={styles.formItem}>
                  <Text style={styles.itemText}>Situation actuelle</Text>
                  <View style={{ width: "50%" }}>
                    {/* <RNPickerSelect
                      doneText={"OK"}
                      placeholder={placeholder}
                      items={this.state.actualSituationList}
                      onValueChange={(value) => {
                        this.props.actualiseFormState({
                          actualSituation: value,
                        });
                        this.setState({ actualSituation: value });
                      }}
                      style={pickerStyle}
                      value={this.state.actualSituation}
                      useNativeAndroidPickerStyle={false}
                    /> */}

                    <CommonPicker
                      style={styles.onePicker}
                      selectedValue={this.state.actualSituation}
                      onValueChange={(itemValue, itemIndex) => {
                        this.props.actualiseFormState({ actualSituation: itemValue });
                        this.setState({ actualSituation: itemValue });
                      }}
                    >
                      <CommonPicker.Item label="Salarié" value="Salarié" />
                      <CommonPicker.Item label="Demandeur d'emploi" value="Demandeur d'emploi" />
                      <CommonPicker.Item label="Etudiant" value="Etudiant" />
                      <CommonPicker.Item label="Inactif" value="Inactif" />
                      <CommonPicker.Item label="Autre" value="Autre" />
                    </CommonPicker>
                  </View>
                </View>
              )}
            </View>
            <View style={styles.formCol}>
              <View style={styles.formItem}>
                <Text style={styles.itemText}>Adresse*</Text>
                <TextInput
                  onChangeText={(value) =>
                    this.props.actualiseFormState({ adress: value })
                  }
                  placeholder={"Adresse"}
                  style={styles.itemTextInput}
                ></TextInput>
              </View>
              <View style={styles.formItem}>
                <Text style={styles.itemText}>Code Postal*</Text>
                <TextInput
                  onChangeText={(value) =>
                    this.props.actualiseFormState({ cp: value })
                  }
                  placeholder={"Code Postal"}
                  keyboardType={"number-pad"}
                  style={styles.itemTextInput}
                ></TextInput>
              </View>
              <View style={styles.formItem}>
                <Text style={styles.itemText}>Ville*</Text>
                <TextInput
                  onChangeText={(value) =>
                    this.props.actualiseFormState({ ville: value })
                  }
                  placeholder={"Ville"}
                  style={styles.itemTextInput}
                ></TextInput>
              </View>

                <View style={styles.formItem}>
                  <Text style={styles.itemText}>Portable*</Text>
                  <TextInput
                    onChangeText={(value) =>
                      this.props.actualiseFormState({ phone: value })
                    }
                    placeholder={"Portable"}
                    keyboardType={"phone-pad"}
                    style={styles.itemTextInput}
                  ></TextInput>
                </View>
                <View style={styles.formItem}>
                  <Text style={styles.itemText}>Email*</Text>
                  <TextInput
                    onChangeText={(value) =>
                      this.props.actualiseFormState({ email: value })
                    }
                    placeholder={"Email"}
                    keyboardType={"email-address"}
                    style={styles.itemTextInput}
                  ></TextInput>
                </View>
              </View>
            </View>
            <View style={styles.formItem}>
              <Text style={styles.itemText}>Commentaire</Text>
              <TextInput
                onChangeText={(value) =>
                  this.props.actualiseFormState({ commentaire: value })
                }
                placeholder={"Commentaire"}
                multiline={true}
                style={styles.textArea}
              ></TextInput>
            </View>
            <View
              style={[
                styles.formItem,
                { justifyContent: "flex-start", marginTop: 5 },
              ]}
            >
              <Text style={[styles.itemText, { left: 0, width: '90%', height: '100%' }]}>
                En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées par Y Schools à des fins commerciales*
              </Text>
              <Switch
                onValueChange={() => this.toggleRGPD()}
                value={this.state.rgpdSwitch}
              />
            </View>
            {this.showAssoPasserelle() ? (
              <View
                style={[
                  styles.formItem,
                  { justifyContent: "flex-start", marginTop: 5 },
                ]}
              >
                <Text style={[styles.itemText, { left: 0 }]}>
                  J’accepte que mes données personnelles soit transmises à
                  l’Association Passerelle
                </Text>
                <Switch
                  onValueChange={() => this.toggleAssoPasserelle()}
                  value={this.state.asso_passerelle}
                />
              </View>
            ) : null}
          </View>
        </KeyboardAwareScrollView>
      );
    } else {
      return (
        <View style={{ width: "100%" }}>
          {this.renderMobile(styles, placeholder, pickerStyle)}
        </View>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    school: state.school,
  };
};

export default connect(mapStateToProps)(StudentInfoForm);
