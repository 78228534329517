import React from 'react';
import {
    View,
    StyleSheet,
    Text, Dimensions,

} from 'react-native';
import {connect} from "react-redux";

class FormConfirmation extends React.Component {
    render() {
        let width = Dimensions.get('window').width;
        let tablet = width > 480;

        const styles = StyleSheet.create({
            text:{
                height: 53,
                fontFamily: "HelveticaNeue",
                fontSize: 22,
                fontWeight: "300",
                fontStyle: "normal",
                lineHeight: 27,
                letterSpacing: 0,
                textAlign: "center",
                color: this.props.color
            },
            mobileText:{
                fontFamily: "HelveticaNeue",
                fontSize: 15,
                fontWeight: "300",
                fontStyle: "normal",
                lineHeight: 18,
                letterSpacing: 0,
                textAlign: "center",
                color: this.props.color
            }
        });

        return (
            <View style={styles.formView}>
                <Text style={[tablet ? styles.text : styles.mobileText,{fontWeight: 'bold'}]}>Félicitations,</Text>
                <Text style={tablet ? styles.text : styles.mobileText}>Votre inscription a bien été enregistrée !</Text>
            </View>

        )
    }
}



const mapStateToProps = (state) => {
    return state.school;
};

export default connect(mapStateToProps)(FormConfirmation)