import React from 'react';
import {
    View,
    StyleSheet,
    Text,
    ImageBackground, Dimensions,
} from 'react-native';
import FooterForm from "../Components/FooterForm";
import {FormChooseButtons} from "../Components/FormChooseButtons";
import StudentInfoForm from "../Components/StudentInfoForm";
import FormConfirmation from "../Components/FormConfirmation";
import {connect} from "react-redux";
import * as SQLite from 'expo-sqlite';
import { insertInscription } from '../Utils/indexedDB';
import moment from "moment";

// const db = SQLite.openDatabase('db.db');

class FormScreen extends React.Component {

    state = {
        formState: 'chooseStudy',
        title: 'Quel est votre niveau d’étude ?',
        campusList: [],
        courseFormatList: [],
        selectedCourseFormat : '',
        school: this.props.school.school,
        name: '',
        firstname: '',
        adress: '',
        cp: '',
        ville: '',
        email: '',
        phone: '',
        dateNais: '',
        interest: [],
        level: '',
        campus: [],
        diplome: '',
        prepa: '',
        bac: '',
        bacSpe: '',
        salon: '',
        salonDate: '',
        saisieDate: Date.now(),
        adresse2: '',
        boursier: '',
        commentaire: '',
        newsletter: '',
        sms: '',
        immersionDay: '',
        jpo: '',
        visitePrivee: '',
        formValide: null,
        rgpdSwitch: false,
        actualSituation: '',
        civilite: '',
        asso_passerelle: false,
        lycee:''
    };

    constructor(props) {
        super(props);
    }

    _toggleSchool = (type) => {
        const action = {type: type};
        this.props.dispatch(action);
    };

    _addInterest = (interestText) => {
        if (this.state.interest.find((item) => interestText === item) === undefined) {
            this.setState(state => {
                const interest = [...state.interest, interestText];
                return {interest};
            });
        } else {
            this.setState(state => {
                const interest = state.interest.filter((item) => item !== interestText);
                return {
                    interest,
                };
            });
        }
    };

    _addCampus = (campusText) => {
        if (this.state.campus.find((item) => campusText === item) === undefined) {
            this.setState(state => {
                const campus = [...state.campus, campusText];
                return {campus};
            });
        } else {
            this.setState(state => {
                const campus = state.campus.filter((item) => item !== campusText);
                return {
                    campus,
                };
            });
        }
    };

    actualiseFormState = (newState) => {
        switch (newState.formState) {
            case "chooseStudy":
                newState.title = 'Quel est votre niveau d’étude ?';
                break;
            case "chooseInterest":
                newState.title = 'Par quelle(s) formation(s) êtes-vous intéressé(e)?';
                break;
            case "chooseCampus":
                if (this.state.interest.length > 0) {
                    if (this.props.school.schoolInfos.default.campus == null) {
                        newState.formState = "enterInfos";
                        newState.title = '';
                    } else {
                        newState.title = 'Quel campus souhaitez-vous intégrer ?';

                        if (this.state.interest.find(function (elem) {
                            return elem === 'Bachelor 1' || elem === 'Bachelor 3';
                        })) {
                            newState.campusList = ['troyes', 'metz', 'indéterminé'];
                        } else if (this.state.interest.find(function (elem) {
                            return elem === 'Global Bachelor in Management BAC+3 - Initial ou en alternance';
                        }) && (newState.selectedCourseFormat === 'Alternance' || this.state.level === 'Terminale' || this.state.level === 'Niveau BAC' || this.state.level === 'Autre'|| this.state.level === '2nde'|| this.state.level === '1ère')) {
                            newState.campusList = ['troyes', 'charleville', 'chaumont', 'indéterminé'];
                        } else {
                            newState.formState = "enterInfos";
                            newState.title = '';
                        }
                    }
                } else {
                    newState.formState = "chooseInterest";
                }
                break;
            case "courseFormat":
                if (this.state.interest.length > 0) {
                    newState.title = 'Quel est le parcours de votre choix ?';

                    if (this.state.interest.find(function (elem) {
                        return elem === 'Global Bachelor in Management BAC+3 - Initial ou en alternance';
                    })&& (this.state.level === 'BAC+1' || this.state.level === 'BAC+2' || this.state.level === 'Autre')) {
                        newState.courseFormatList = ['Initial', 'Alternance', 'Aucune idée'];
                    } else if (this.state.interest.find(function (elem) {
                        return elem === 'Programme Grande Ecole';
                    })&& (this.state.level === 'BAC+3' || this.state.level === 'BAC+4')) {
                        newState.courseFormatList = ['Parcours alternance', 'Parcours France','Parcours International', 'Aucune idée'];
                    }else {
                        newState.formState = "chooseCampus";
                        newState.title = '';
                        this.actualiseFormState(newState);
                    }
                } else {
                    newState.formState = "chooseInterest";
                }
                break;
                
            default:
                newState.title = '';
                break;
        }

        this.setState(newState);
    };

    renderFormContent = (formState) => {
        switch (formState) {
            case 'chooseStudy':
                return (
                    <FormChooseButtons
                        datas={this.props.school.schoolInfos.default.formations}
                        type="study"
                        school={this.state.school}
                        arrow={this.props.school.arrow}
                        actualiseFormState={this.actualiseFormState.bind(this)}/>
                );
            case 'chooseInterest':
                return (
                    <FormChooseButtons
                        datas={this.props.school.schoolInfos.default.formations.find(item => item.name === this.state.level).availableStudy}
                        school={this.state.school}
                        arrow={this.props.school.arrow}
                        showArrow={this.props.school.schoolInfos.default.formations.find(item => item.name === this.state.level).availableStudy.length > 12}
                        type="interest"
                        actualiseFormState={this.actualiseFormState.bind(this)}
                        addInterest={this._addInterest.bind(this)}
                        selectedInterest={this.state.interest}
                    />
                );
            case 'chooseCampus':
                return (
                    <FormChooseButtons
                        school={this.state.school}
                        datas={this.props.school.schoolInfos.default.formations.find(item => item.name === this.state.level).availableStudy}
                        type="campus"
                        campusList={this.state.campusList}
                        campus={this.state.campus}
                        addCampus={this._addCampus.bind(this)}
                        actualiseFormState={this.actualiseFormState.bind(this)}/>
                );
            case 'courseFormat':
                return (
                    <FormChooseButtons
                        school={this.state.school}
                        datas={this.props.school.schoolInfos.default.formations.find(item => item.name === this.state.level).availableStudy}
                        type="courseFormat"
                        courseFormatList={this.state.courseFormatList}
                        actualiseFormState={this.actualiseFormState.bind(this)}/>
                );
            case 'enterInfos':
                return (
                    <StudentInfoForm
                        school={this.state.school}
                        formValide={this.state.formValide}
                        interest={this.state.interest}
                        campus={this.state.campus}
                        actualiseFormState={this.actualiseFormState.bind(this)}/>
                );
            case 'infoEnd':
                return (
                    <StudentInfoForm
                        school={this.state.school}
                        interest={this.state.interest}
                        actualiseFormState={this.actualiseFormState.bind(this)}/>
                );
            case'confirmation':
                return (
                    <FormConfirmation/>
                );
        }
    };

    _validateInfosForm = () => {
        let res = true;

        if (this.state.name === '') {
            res = false;
        }
        if (this.state.firstname === '') {
            res = false;
        }
        if (this.state.adress === '') {
            res = false;
        }
        if (this.state.cp === '') {
            res = false;
        }
        if (this.state.ville === '') {
            res = false;
        }
        if (this.state.phone === '') {
            res = false;
        }
        if (this.state.phone === '') {
            res = false;
        }
        if (this.state.rgpdSwitch === false) {
            res = false;
        }
        return res;
    };

    saveForm = async () => {
        if (this._validateInfosForm()) {
            let inscription = {
                name: this.state.name, 
                firstname: this.state.firstname,
                dateNais: this.state.dateNais
                    ? moment(this.state.dateNais).format("DD-MM-YYYY")
                    : this.state.dateNais, 
                school: this.state.school,
                adress: this.state.adress,
                cp: this.state.cp,
                ville: this.state.ville,
                email: this.state.email,
                phone: this.state.phone,
                interest: this.state.interest.join(),
                level: this.state.level,
                campus: this.state.campus.join(),
                diplome: this.state.diplome,
                prepa: this.state.prepa,
                bac: this.state.bac,
                bacSpe: this.state.bacSpe,
                salon: this.props.salon.salonName,
                salonDate: this.props.salon.salonDate,
                salonType: this.props.salon.salonType,
                saisieDate: this.state.saisieDate,
                adresse2: this.state.adresse2,
                boursier: this.state.boursier,
                commentaire: this.state.commentaire,
                newsletter: this.state.newsletter,
                sms: this.state.sms,
                immersionDay: this.state.immersionDay,
                jpo: this.state.jpo,
                visitePrivee: this.state.visitePrivee,
                uploaded: 0,
                civilite: this.state.civilite,
                actualSituation: this.state.actualSituation,
                asso_passerelle: this.state.asso_passerelle ? 1 : 0,
                selected_course_format: this.state.selectedCourseFormat,
                lycee: this.state.lycee
            };

            console.log('inscription', inscription)

            insertInscription(inscription, async () => {
                await this.actualiseFormState({formState: 'confirmation'});
                await this.setState({formValide: true})
            });

            // await db.transaction(tx => {
            //     tx.executeSql(
            //         `INSERT INTO inscriptions(
            //             name, 
            //             firstname,
            //             dateNais, 
            //             school,
            //             adress,
            //             cp,
            //             ville,
            //             email,
            //             phone,
            //             interest,
            //             level,
            //             campus,
            //             diplome,
            //             prepa,
            //             bac,
            //             bacSpe,
            //             salon,
            //             salonDate,
            //             salonType,
            //             saisieDate,
            //             adresse2,
            //             boursier,
            //             commentaire,
            //             newsletter,
            //             sms,
            //             immersionDay,
            //             jpo,
            //             visitePrivee,
            //             uploaded,
            //             civilite,
            //             actualSituation,
            //             asso_passerelle,
            //             selected_course_format,
            //             lycee
            //         ) VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?);`,
            //         [
            //             this.state.name,
            //             this.state.firstname,
            //             this.state.dateNais,
            //             this.state.school,
            //             this.state.adress,
            //             this.state.cp,
            //             this.state.ville,
            //             this.state.email,
            //             this.state.phone,
            //             this.state.interest.join(),
            //             this.state.level,
            //             this.state.campus.join(),
            //             this.state.diplome,
            //             this.state.prepa,
            //             this.state.bac,
            //             this.state.bacSpe,
            //             this.props.salon.salonName,
            //             this.props.salon.salonDate,
            //             this.props.salon.salonType,
            //             this.state.saisieDate,
            //             this.state.adresse2,
            //             this.state.boursier,
            //             this.state.commentaire,
            //             this.state.newsletter,
            //             this.state.sms,
            //             this.state.immersionDay,
            //             this.state.jpo,
            //             this.state.visitePrivee,
            //             0,
            //             this.state.civilite,
            //             this.state.actualSituation,
            //             this.state.asso_passerelle ? 1 : 0,
            //             this.state.selectedCourseFormat,
            //             this.state.lycee
            //         ],
            //         (_, {rows: {_array}}) => console.log({items: _array}),
            //         (t, error) => {
            //             console.log(error);
            //         }
            //     );
            // });
            // await this.actualiseFormState({formState: 'confirmation'});
            // await this.setState({formValide: true})
        } else {
            this.setState({formValide: false})
        }
    };

    render() {
        console.log(this.state.lycee);
        let width = Dimensions.get('window').width;
        let tablet = width > 480;

        const styles = StyleSheet.create({
            contentView: {
                justifyContent: 'center',
                alignItems: 'center',
                //height:(752/2),
                flex: 1,
                width: '100%',
            },
            footerView: {
                height: 70,
                //alignSelf:'baseline',
                bottom: 0,
                zIndex: 1000,
            },
            title: {
                marginVertical: '3%',
                height: 26,
                fontFamily: "HelveticaNeue",
                fontSize: 22,
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: 27,
                letterSpacing: 0,
                textAlign: "center",
                color: this.props.school.color
            },
            needValidation: {
                height: 18,
                fontFamily: "HelveticaNeue",
                fontSize: 16,
                fontWeight: "300",
                fontStyle: "italic",
                lineHeight: 18,
                letterSpacing: 0,
                textAlign: "left",
                color: 'red',
            },
            mobileTitle: {
                marginVertical: '3%',
                height: 30,
                fontFamily: "HelveticaNeue",
                fontSize: 13,
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: 30,
                letterSpacing: 0,
                textAlign: "center",
                width:'100%',
                flexDirection:'column',
                color: this.props.school.color
            },
            mobileNeedValidation: {
                marginTop: '3%',
                height: 25,
                fontFamily: "HelveticaNeue",
                fontSize: 16,
                fontWeight: "300",
                fontStyle: "italic",
                lineHeight: 20,
                letterSpacing: 0,
                textAlign: "left",
                color: 'red',
            },
        });

        return (
            <View>
                <ImageBackground source={this.props.school.backgroundImage} style={{width: '100%', height: '100%'}}>
                    <View style={styles.contentView}>
                        {this.state.title ?
                            <Text style={tablet ? styles.title : styles.mobileTitle}>{this.state.title}</Text> : null}
                        {this.state.formValide === false ?
                            <Text style={tablet ? styles.needValidation : styles.mobileNeedValidation}>Veuillez remplir tous les champs requis*</Text> : null}
                        {this.renderFormContent(this.state.formState)}
                    </View>
                    <View style={styles.footerView}>
                        <FooterForm navigation={this.props.navigation}
                                    actualFormState={this.state.formState}
                                    saveForm={this.saveForm.bind(this)}
                                    actualiseFormState={this.actualiseFormState.bind(this)}/>
                    </View>
                </ImageBackground>
            </View>

        )
    }
}

const
    mapStateToProps = (state) => {
        return {
            school: state.school,
            salon: state.salon
        };
    };

export default connect(mapStateToProps)

(
    FormScreen
)