const initialState = {
    salonName:'',
    salonDate:'',
    salonType:'Salon'
};

function toggleSalon(state = initialState, action){
    let nextState;
    switch(action.type){
        case 'TOGGLE_SALON':
            nextState={
                ...state,
                salonName:action.salon.name,
                salonDate:action.salon.date,
                salonType:'Salon'
            };
            return nextState || state;
        case 'TOGGLE_FORUM':
            nextState={
                ...state,
                salonName:action.salon.name,
                salonDate:action.salon.date,
                salonType:'Forum'
            };
            return nextState || state;
        default:
            return state;
    }
}

export default toggleSalon;