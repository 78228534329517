export default {
    formations: [
        {
            'name': '3ème',
            'availableStudy': [
                'DESIGN 1'
            ]
        },
        {
            'name': 'CAP',
            'availableStudy': [
                'DESIGN 1'
            ]
        },
        {
            'name': 'BEP',
            'availableStudy': [
                'DESIGN 1'
            ]
        },
        {
            'name': '2nde',
            'availableStudy': [
                'DESIGN 1'
            ]
        },
        {
            'name': '1ère',
            'availableStudy': [
                'DESIGN 1',
                'ASSISTANT DESIGNER WEB'
            ]
        },
        {
            'name': 'Terminale',
            'availableStudy': [
                'DESIGN 1',
                'ASSISTANT DESIGNER WEB'
            ]
        },
        {
            'name': 'Niveau Bac',
            'availableStudy': [
                'DESIGN 1',
                'ASSISTANT DESIGNER WEB'
            ]
        },
        {
            'name': 'BAC+1',
            'availableStudy': [
                'DESIGN 1',
                'ASSISTANT DESIGNER WEB',
                'DESIGN 3'
            ]
        },
        {
            'name': 'BAC+2',
            'availableStudy': [
                'DESIGN 3',
                'ASSISTANT DESIGNER WEB'
            ]
        },
        {
            'name': 'BAC+3',
            'availableStudy': [
                'DESIGN 3',
                'DESIGN - 4 PGE Management',
                'DESIGN - 4 Design Stratégique',
                'DESIGN - 4 Design et Intelligence Numérique'
            ]
        },
        {
            'name': 'BAC+4',
            'availableStudy': [
                'DESIGN - 4 PGE Management',
                'DESIGN - 4 Design Stratégique',
                'DESIGN - 4 Design et Intelligence Numérique'
            ]
        },
        {
            'name': 'BAC+5',
            'availableStudy': [
                'DESIGN - 4 PGE Management',
                'DESIGN - 4 Design Stratégique',
                'DESIGN - 4 Design et Intelligence Numérique'
            ]
        },
        {
            'name': 'Autre',
            'availableStudy': [
                'DESIGN 1',
                'ASSISTANT DESIGNER WEB',
                'DESIGN 3',
                'DESIGN - 4 PGE Management',
                'DESIGN - 4 Design Stratégique',
                'DESIGN - 4 Design et Intelligence Numérique'
            ]
        },
    ],
    campus: null,
    diplomes:
        [
            {
                key: 'BAC',
                label: 'BAC Général',
                value: 'BAC'

            },
            {
                key: 'BAC Techno',
                label: 'BAC Techno',
                value: 'BAC Techno'
            },
            {
                key: 'BAC PRO',
                label: 'BAC PRO',
                value: 'BAC PRO'
            },
            {
                key: 'PREPA ECE',
                label: 'PREPA ECE',
                value: 'PREPA ECE'
            },
            {
                key: 'PREPA ECP',
                label: 'PREPA ECP',
                value: 'PREPA ECP'
            },
            {
                key: 'PREPA ECS',
                label: 'PREPA ECS',
                value: 'PREPA ECS'
            },
            {
                key: 'PREPA ECT',
                label: 'PREPA ECT',
                value: 'PREPA ECT'
            },
            {
                key: 'PREPA ART',
                label: 'PREPA ART',
                value: 'PREPA ART'
            },
            {
                key: 'BTS',
                label: 'BTS',
                value: 'BTS'
            },
            {
                key: 'DUT',
                label: 'DUT',
                value: 'DUT'
            },
            {
                key: 'DN MADE',
                label: 'DN MADE',
                value: 'DN MADE'

            },
            {
                key: 'LICENCE',
                label: 'LICENCE',
                value: 'LICENCE'
            },
            {
                key: 'LICENCE PRO',
                label: 'LICENCE PRO',
                value: 'LICENCE PRO'
            },
            {
                key: 'BACHELOR',
                label: 'BACHELOR',
                value: 'BACHELOR'
            },
            {
                key: 'MASTER',
                label: 'MASTER',
                value: 'MASTER'
            },
            {
                key: 'Autre',
                label: 'Autre',
                value: 'Autre'
            }
        ],
    immersionDay:
        false,
    jpo:
        false,
    privateVisit:
        false,
};

