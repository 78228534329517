import React from 'react';
import {View, Image, Text, StyleSheet, Dimensions} from 'react-native';
import {connect} from "react-redux";

class ChooseCampusButton extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            img:{},
            city:this.props.campus.toUpperCase()
        };

        switch (this.props.campus) {
            case 'paris':
                if(this.props.selected){
                    //this.state.img = require('../../assets/cities_logos/white/paris-white.png');
                }else if(this.props.school === "scbs"){
                    this.state.img = require('../../assets/cities_logos/scbs/paris-scbs.png');
                }else {
                    this.state.img = require('../../assets/cities_logos/paris.png');
                }
                this.state.city = 'PARIS';
                break;
            case 'troyes':
                if(this.props.selected){
                    //this.state.img = require('../../assets/cities_logos/white/troyes-white.png');
                }else if(this.props.school === "scbs"){
                    this.state.img = require('../../assets/cities_logos/scbs/troyes-scbs.png');
                }else {
                    this.state.img = require('../../assets/cities_logos/troyes.png');
                }
                this.state.city = 'TROYES';
                break;
            case 'metz':
                if(this.props.selected){
                    //this.state.img = require('../../assets/cities_logos/white/metz-white.png');
                }else if(this.props.school === "scbs"){
                    this.state.img = require('../../assets/cities_logos/scbs/mets-scbs.png');
                }else {
                    this.state.img = require('../../assets/cities_logos/metz.png');
                }
                this.state.city = 'METZ';
                break;
            case 'charleville':
                if(this.props.selected){
                    //this.state.img = require('../../assets/cities_logos/white/charleville-white.png');
                }else if(this.props.school === "scbs"){
                    this.state.img = require('../../assets/cities_logos/scbs/charleville-scbs.png');
                }else {
                    this.state.img = require('../../assets/cities_logos/charleville-mezieres.png');
                }
                this.state.city = 'CHARLEVILLE-MÉZIÈRES';
                break;
            case 'chaumont':
                if(this.props.selected){
                    //this.state.img = require('../../assets/cities_logos/white/chaumont-white.png');
                }else if(this.props.school === "scbs"){
                    this.state.img = require('../../assets/cities_logos/scbs/chaumont-scbs.png');
                }else {
                    this.state.img = require('../../assets/cities_logos/chaumont.png');
                }
                this.state.city = 'CHAUMONT';
                break;
            case 'indéterminé':
                this.state.city = 'AUCUNE IDÉE';
                this.state.img = {};
        }
    }

    render() {
        console.log(this.props.school);
        let width = Dimensions.get('window').width;
        let tablet = width > 480;

        const styles = StyleSheet.create({
            buttonView: {
                width: 161,
                height: 170,
                borderRadius: 5,
                backgroundColor: "#ffffff",
                shadowColor: "rgba(2, 44, 109, 0.37)",
                shadowOffset: {
                    width: 0,
                    height: 3
                },
                shadowRadius: 6,
                shadowOpacity: 1,
                justifyContent: 'space-around',
                alignItems: 'center',
                elevation:5
            },
            buttonViewSelected:{
                backgroundColor: this.props.color,
            },
            buttonViewUnselected:{
                backgroundColor: "#ffffff",
            },
            buttonText: {
                //height: 24,
                fontFamily: "HelveticaNeue",
                fontSize: 18,
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: 20,
                letterSpacing: 0,
                textAlign: "center",
                color: this.props.color
            },
            buttonTextSelected:{
                color: "#ffffff"
            },
            buttonImg: {
                height: '60%',
                width:'60%'
            },
            buttonImgParis: {
                height: '60%',
                width:'60%'
            },
            mobileButtonView: {
                width: 115,
                height: 115,
                borderRadius: 5,
                backgroundColor: "#ffffff",
                shadowColor: "rgba(2, 44, 109, 0.37)",
                shadowOffset: {
                    width: 0,
                    height: 3
                },
                shadowRadius: 6,
                shadowOpacity: 1,
                justifyContent: 'space-around',
                alignItems: 'center',
                elevation:5,
                margin:5,
                padding: 2
            },
            mobileButtonText: {
                //height: 22,
                fontFamily: "HelveticaNeue",
                fontSize: 15,
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: 20,
                letterSpacing: 0,
                textAlign: "center",
                color: this.props.color
            },
        });

        return (

            <View style={[tablet ? styles.buttonView : styles.mobileButtonView, this.props.selected ? styles.buttonViewSelected : null] }>
                <Image resizeMode='contain' source={this.state.img} style={[this.props.campus==='paris' ? styles.buttonImgParis:styles.buttonImg]}/>
                <Text style={[tablet ? styles.buttonText : styles.mobileButtonText, this.props.selected ? styles.buttonTextSelected : null]}>{this.state.city}</Text>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    return state.school;
};

export default connect(mapStateToProps)(ChooseCampusButton);