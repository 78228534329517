import React from 'react';
import {View, Text, StyleSheet, Dimensions, Image, Platform} from 'react-native';
import {connect} from "react-redux";
import {TouchableOpacity} from "react-native-gesture-handler"
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from "react-native-responsive-screen";
import { isIphoneX, getStatusBarHeight } from 'react-native-iphone-x-helper';

class StatusBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: '',
            time: ''
        };
    }

    componentDidMount() {
        let date = new Date(Date.now());
        var day = "0" + date.getDate(); //Current Date
        var month = "0" + (date.getMonth() + 1); //Current Month
        var year = date.getFullYear(); //Current Year
        let hours = date.getHours();
        let minutes = "0" + date.getMinutes();

        this.setState({
            //Setting the value of the date time
            date:
                '' + day.substr(-2) + '/' + month.substr(-2) + '/' + year,
            time: hours + ':' + minutes.substr(-2)
        });
    }

    _navigateToAdmin = () => {
        this._toggleSchool('TOGGLE_YSCHOOLS');
        this.props.navigation.navigate("AdminAuth");
    };

    _navigateToMain = () => {
        this.props.navigation.navigate("Main");
    };

    _toggleSchool = (type) => {
        const action = {type: type};
        this.props.dispatch(action);
    };

    render() {
        let width = Dimensions.get('window').width;
        let tablet = width > 480;

        const primaryColor = this.props.color;
        const styles = StyleSheet.create({
            statusBarView: {
                width: "100%",
                height: tablet ? hp('6%') : hp('8%'),
                flexDirection: 'row',
                justifyContent: 'space-between',
                zIndex: 1050,
            },
            salonView: {
                width: this.props.showClearButton ? "65%" : "70%",
                alignItems: 'center',
                flexDirection: 'row',
                flexWrap: "wrap",
            },
            salonText: {
                fontFamily: "HelveticaNeue",
                fontSize: 18,
                fontWeight: "300",
                fontStyle: "normal",
                lineHeight: 22,
                letterSpacing: 0,
                textAlign: "left",
                color: primaryColor,
            },
            salonDateText: {
                fontFamily: "HelveticaNeue",
                fontSize: 18,
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: 23,
                letterSpacing: 0,
                textAlign: "left",
                color: primaryColor
            },
            dateTimeView: {
                backgroundColor: primaryColor,
                width: '25%',
                height: '100%',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
                // transform: [{skewX: '-40deg'}],
                // right: Platform.OS == "ios" ? -25 : 0
            },
            timeText: {
                fontFamily: "HelveticaNeue",
                fontSize: 14,
                fontWeight: "300",
                fontStyle: "normal",
                lineHeight: 17,
                letterSpacing: 0,
                textAlign: "left",
                color: "#ffffff",
                // transform: [{skewX: '40deg'}],
            },
            dateText: {
                fontFamily: "HelveticaNeue",
                fontSize: 14,
                fontWeight: "300",
                fontStyle: "normal",
                lineHeight: 17,
                letterSpacing: 0,
                textAlign: "left",
                color: "#ffffff",
                paddingLeft: wp("2.5%"),
                // transform: [{skewX: '40deg'}],
            },
            mobileSalonView: {
                width: this.props.showClearButton ? wp("55%"): wp("70%"),
                height: "100%",
                marginTop: isIphoneX() ? getStatusBarHeight() + 5 : 5,
                marginLeft: 5,
                flexDirection: 'column',
                justifyContent:'flex-start',
                alignItems: this.props.showClearButton ? "center" : "flex-start",
            },
            mobileSalonText: {
                fontFamily: "HelveticaNeue",
                fontSize: 12,
                fontWeight: "300",
                fontStyle: "normal",
                lineHeight: 15,
                letterSpacing: 0,
                textAlign: this.props.showClearButton ? "center" : "left",
                color: primaryColor
            },
            mobileSalonDateText: {
                fontFamily: "HelveticaNeue",
                fontSize: 12,
                lineHeight: 15,
                fontWeight: "500",
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: this.props.showClearButton ? "center" : "left",
                color: primaryColor
            },
            adminLogo: {
                width: "100%",
                height: "100%",
            },
            adminLogoImg: {
                width: 40,
                height: 40,
            },
            adminLogoContainer: {
                width: wp("20%"),
                height: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
            },

            adminLogoContainerTablet: {
                width: wp("10%"),
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
            },
        });

        return (
            <View style={styles.statusBarView}>
                {
                    this.props.showClearButton &&
                    <TouchableOpacity
                        onPress={() => this._navigateToMain()}
                        style={tablet ? styles.adminLogoContainerTablet : styles.adminLogoContainer}>
                        <Image style={styles.adminLogoImg}
                               source={require('../../assets/clear.png')}/>
                    </TouchableOpacity>

                }
                <View style={tablet ? styles.salonView : styles.mobileSalonView}>
                    {
                        this.props.salon.salonName 
                            ?   <Text
                                    style={[tablet ? styles.salonText : styles.mobileSalonText, {fontWeight: 'bold'}]}
                                >
                                    {tablet ? ' ÉVÈNEMENT : ' : 'ÉVÈNEMENT:'}
                                </Text> 
                            : null
                    }

                    <Text style={tablet ? styles.salonText : styles.mobileSalonText}>{this.props.salon.salonName}</Text>

                    <Text
                        style={tablet ? styles.salonDateText : styles.mobileSalonDateText}
                    >
                        {
                            this.props.salon.salonName 
                                ? tablet 
                                    ? ' / ' 
                                    : '' 
                                : ''
                        }{this.props.salon.salonDate}
                    </Text>
                </View>
                {tablet ?
                    <View style={styles.dateTimeView}>
                        <Text style={styles.timeText}>{this.state.time}</Text>
                        <Text style={styles.dateText}>{this.state.date}</Text>
                    </View>
                    :
                    <TouchableOpacity
                        onPress={this.props.stack === 'admin' ? () => this._navigateToMain() : () => this._navigateToAdmin()}
                        style={styles.adminLogoContainer}>
                        <Image style={styles.adminLogoImg}
                               source={this.props.stack === 'admin' ? require('../../assets/buttons/admin/admin_quit.png') : this.props.school.adminLogo}/>
                    </TouchableOpacity>
                }
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        salon: state.salon,
        school: state.school
    };
};

export default connect(mapStateToProps)(StatusBar)