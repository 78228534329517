import React from 'react';
import {View, Image, Text, StyleSheet, ImageBackground, ScrollView, Dimensions} from 'react-native';
import Colors from '../Constants/Colors';

export class InscriptionListItem extends React.Component {

    constructor(props) {
        super(props);
    }

    renderMobile = (saisieDate, saisieHours) => {
        return (
            <View style={styles.listItem}>
                <View style={styles.mobileFirstCell}>
                    <Text style={styles.mobileCellText}>{this.props.content.name} {this.props.content.firstname}</Text>
                </View>
                <View style={styles.mobileOtherCell}>
                    <Text style={styles.mobileCellText}>{saisieDate}{'\n'}{saisieHours}</Text>
                </View>
                <View style={styles.mobileLastCell}>
                    <Text style={styles.mobileCellText}>{this.props.content.uploaded === 0 ? '❌' : '✅'}</Text>
                </View>
            </View>
        );
    };

    renderTablet = (saisieDate, saisieHours) => {
        return (
            <View style={styles.listItem}>
                <View style={styles.cell}>
                    <Text style={styles.cellText}>{this.props.content.name}</Text>
                </View>
                <View style={styles.cell}>
                    <Text style={styles.cellText}>{this.props.content.firstname}</Text>
                </View>
                <View style={styles.cell}>
                    <Text style={styles.cellText}>{this.props.content.phone}</Text>
                </View>
                <View style={styles.cell}>
                    <Text style={styles.cellText}>{saisieDate}</Text>
                </View>
                <View style={styles.cell}>
                    <Text style={styles.cellText}>{saisieHours}</Text>
                </View>
                <View style={styles.cell}>
                    <Text style={styles.cellText}>{this.props.content.uploaded === 0 ? '❌' : '✅'}</Text>
                </View>
            </View>
        );
    };


    render() {
        //console.log(this.props.content);
        let date = new Date(this.props.content.saisieDate);
        var day = "0" + date.getDate(); //Current Date
        var month = "0" + (date.getMonth() + 1); //Current Month
        var year = date.getFullYear(); //Current Year
        let hours = date.getHours();
        let minutes = "0" + date.getMinutes();
        let seconds = "0" + date.getSeconds();
        let saisieDate = '' + day.substr(-2) + '/' + month.substr(-2) + '/' + year;
        let saisieHours = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

        let width = Dimensions.get('window').width;
        let tablet = width > 480;

        return (
            <View>
                {tablet ? this.renderTablet(saisieDate, saisieHours) : this.renderMobile(saisieDate, saisieHours)}
            </View>
        )
    }
}

const styles = StyleSheet.create({
    listItem: {
        height: 38.5,
        width: '100%',
        flexDirection: 'row'
    },
    cell: {
        width: '16.66%',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 0.5,
        borderColor: '#022c6d',
        borderStyle: 'solid',
        borderTopWidth: 0
    },
    cellText: {
        fontFamily: "HelveticaNeue",
        fontSize: 14,
        fontWeight: "300",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        color: "#7b7b7b"
    },
    mobileFirstCell: {
        width: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 0.5,
        borderColor: '#022c6d',
        borderStyle: 'solid',
        borderTopWidth: 0
    },
    mobileOtherCell: {
        width: '30%',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 0.5,
        borderColor: '#022c6d',
        borderStyle: 'solid',
        borderTopWidth: 0
    },

    mobileLastCell: {
        width: '20%',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 0.5,
        borderColor: '#022c6d',
        borderStyle: 'solid',
        borderTopWidth: 0
    },

    mobileCellText: {
        fontFamily: "HelveticaNeue",
        fontSize: 11,
        fontWeight: "300",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        color: "#7b7b7b"
    },
});
