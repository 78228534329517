import React from 'react';
import {
    View,
    StyleSheet,
    Text, Dimensions
} from 'react-native';
import InscriptionList from '../../Components/InscriptionList';
import {FooterAdmin} from "../../Components/FooterAdmin";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from "react-native-responsive-screen";

class InscriptionsScreen extends React.Component {
    render() {
        let width = Dimensions.get('window').width;
        let tablet = width > 480;
        return (
            <View>
                <View style={tablet ? styles.contentView : styles.mobileContentView}>
                    <View style={styles.listContainer}>
                        <InscriptionList navigation={this.props.navigation}/>
                    </View>
                </View>
                <View style={tablet ? styles.footerView : styles.mobileFooterView}>
                    <FooterAdmin navigation={this.props.navigation} type="inscriptions"/>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
        contentView: {
            justifyContent: 'center',
            alignItems: 'center',
            height: '80%',
        },
        listContainer: {
            width: '85%',
            height: '90%',
        },
        footerView: {
            height: '20%'
        },
        title: {
            fontFamily: "HelveticaNeue",
            fontSize: 22,
            fontWeight: "500",
            fontStyle: "normal",
            lineHeight: 27,
            letterSpacing: 0,
            textAlign: "center",
            color: "#022c6d",
            position: "absolute",
            top: 10
        },
        infosText: {
            width: 598,
            height: 24,
            fontFamily: "HelveticaNeue",
            fontSize: 20,
            fontWeight: "300",
            fontStyle: "normal",
            lineHeight: 24,
            letterSpacing: 0,
            textAlign: "center",
            color: "#022c6d",
        },
    mobileContentView: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '75%',
        width: '100%'
    },
    mobileFooterView: {
        height: '25%'
    },
    })
;

export default InscriptionsScreen;