import React from 'react';
import {
    View,
    StyleSheet, Dimensions,

} from 'react-native';

import {FooterAdmin} from "../../Components/FooterAdmin"
import EventsList from "../../Components/EventsList";

class EventsScreen extends React.Component {
    render() {
        let width = Dimensions.get('window').width;
        let tablet = width > 480;

        return (
            <View>
                <View style={tablet ? styles.contentView : styles.mobileContentView}>
                    <EventsList navigation={this.props.navigation}/>
                </View>
                <View style={tablet ? styles.footerView : styles.mobileFooterView}>
                    <FooterAdmin navigation={this.props.navigation} type="events"/>
                </View>

            </View>
        )
    }
}

const styles = StyleSheet.create({
        contentView: {
            justifyContent: 'center',
            alignItems: 'center',
            height: '75%',
            width: '100%'
        },
        footerView: {
            height: '25%'
        },
        mobileContentView: {
            justifyContent: 'center',
            alignItems: 'center',
            height: '75%',
            width: '100%'
        },
        mobileFooterView: {
            height: '25%'
        },
    })
;

export default EventsScreen;