import React from 'react';
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity, Dimensions, Platform
} from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from "react-native-responsive-screen";
import { isIphoneX, getStatusBarHeight } from 'react-native-iphone-x-helper';

export class FooterAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: {},
            inscriptions: {},
            maj: {}
        };
        switch (this.props.type) {
            case 'events':
                this.state.events.button = {backgroundColor: "#022c6d"};
                this.state.events.text = {color: "#ffffff"};
                break;
            case 'inscriptions':
                this.state.inscriptions.button = {backgroundColor: "#022c6d"};
                this.state.inscriptions.text = {color: "#ffffff"};
                break;
            case 'maj':
                this.state.maj.button = {backgroundColor: "#022c6d"};
                this.state.maj.text = {color: "#ffffff"};
                break;
        }
    }

    render() {
        let width = Dimensions.get('window').width;
        let tablet = width > 480;

        return (
            <View style={tablet ? styles.footerView : styles.mobileFooterView}>
                <TouchableOpacity style={tablet ? styles.buttonTouchContainer : styles.mobileButtonTouchContainer}
                                  onPress={() => this.props.navigation.navigate('AdminEvents', {reload: true})}>
                    <View style={[styles.buttonContainer, this.state.events.button]}>
                        <View style={styles.buttonTextView}>
                            <Text
                                style={[tablet ? styles.buttonText : styles.mobileButtonText, this.state.events.text]}>CHOIX
                                ÉVÈNEMENT</Text>
                        </View>
                    </View>
                </TouchableOpacity>

                <TouchableOpacity style={tablet ? styles.buttonTouchContainer : styles.mobileButtonTouchContainer}
                                  onPress={() => this.props.navigation.navigate('AdminInscription')}>
                    <View style={[styles.buttonContainer, this.state.inscriptions.button]}>
                        <View style={styles.buttonTextView}>
                            <Text
                                style={[tablet ? styles.buttonText : styles.mobileButtonText, this.state.inscriptions.text]}>INSCRIPTIONS</Text>
                        </View>
                    </View>
                </TouchableOpacity>

                <TouchableOpacity style={tablet ? styles.buttonTouchContainer : styles.mobileButtonTouchContainer}
                                  onPress={() => this.props.navigation.navigate('AdminUpdate')}>
                    <View
                        style={[styles.buttonContainer, this.state.maj.button]}>
                        <View style={styles.buttonTextView}>
                            <Text style={[tablet ? styles.buttonText : styles.mobileButtonText, this.state.maj.text]}>MISE
                                À JOUR</Text>
                        </View>
                    </View>
                </TouchableOpacity>

            </View>
        )
    }
}

const styles = StyleSheet.create({
    footerView: {
        height: '100%',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 32
    },
    buttonText: {
        width: 202,
        fontFamily: "HelveticaNeue",
        fontSize: 20,
        fontWeight: "500",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        color: "#022c6d"
    },
    buttonContainer: {
        backgroundColor: "white",
        justifyContent: 'center',
        alignItems: 'center',
        // transform: [{skewX: '-40deg'}],
        width: '100%',
        height: '100%',
        shadowColor: "rgba(2, 44, 109, 0.38)",
        shadowOffset: {
            width: 0,
            height: 3
        },
        shadowRadius: 6,
        shadowOpacity: 1,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: "#022c6d",
        marginTop: 5
    },
    buttonTouchContainer: {
        width: "33%",
        height: 50,
    },
    buttonTextView: {
        // transform: [{skewX: '40deg'}],
        paddingHorizontal: Platform.OS === "ios" ? 20 : 10,
    },
    mobileFooterView: {
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        position:'absolute',
        bottom: isIphoneX() ? getStatusBarHeight() - 16: 0,
        // left: Platform.OS === "ios" ? -17 : -2
    },
    mobileButtonTouchContainer: {
        height: wp("9%"),
    },
    mobileButtonText: {
        fontFamily: "HelveticaNeue",
        fontSize: wp("3.5%"),
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "left",
        color: "#022c6d"
    },

});
