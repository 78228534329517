export default {
    formations: [
        {
            'name': '2nde',
            'availableStudy': [
                'Bachelor Business Administration BAC+4 - Initial',
                'Global Bachelor in Management BAC+3 - Initial ou en alternance',
            ]
        },
        {
            'name': '1ère',
            'availableStudy': [
                'Bachelor Business Administration BAC+4 - Initial',
                'Global Bachelor in Management BAC+3 - Initial ou en alternance',
            ]
        },
        {
            'name': 'Terminale',
            'availableStudy': [
                'Bachelor Business Administration BAC+4 - Initial',
                'Global Bachelor in Management BAC+3 - Initial ou en alternance',
            ]
        },
        {
            'name': 'Niveau BAC',
            'availableStudy': [
                'Bachelor Business Administration BAC+4 - Initial',
                'Global Bachelor in Management BAC+3 - Initial ou en alternance',
            ]
        },
        {
            'name': 'BAC+1',
            'availableStudy': [
                'Programme Grande Ecole',
                'Bachelor Business Administration BAC+4 - Initial',
                'Global Bachelor in Management BAC+3 - Initial ou en alternance',
            ]
        },
        {
            'name': 'BAC+2',
            'availableStudy': [
                'Programme Grande Ecole',
                'Bachelor Business Administration BAC+4 - Initial',
                'Global Bachelor in Management BAC+3 - Initial ou en alternance'
            ]
        },
        {
            'name': 'BAC+3',
            'availableStudy': [
                'Programme Grande Ecole',
            ]
        },
        {
            'name': 'BAC+4',
            'availableStudy': [
                'Programme Grande Ecole',
                'MSC ICE'
            ]
        },
        {
            'name': 'BAC+5',
            'availableStudy': [
                'Programme Grande Ecole',
                'MSC ICE',
                'MPTI'
            ]
        },
        {
            'name': 'Autre',
            'availableStudy': [
                'Bachelor Business Administration BAC+4 - Initial',
                'Global Bachelor in Management BAC+3 - Initial ou en alternance',
                'Programme Grande Ecole',
                'MSC ICE',
                'MPTI'
            ]
        },
    ],
    campus: [{
        'study': 'Global Bachelor in Management BAC+3 - Initial ou en alternance',
        'campus': [
            'Troyes',
            'Charleville-Mézières',
            'Chaumont',
            'indéterminé'
        ]
    }],
    diplomes:
        [
            {
                key: 'BAC',
                label: 'BAC',
                value: 'BAC'
            },
            {
                key: 'BAC Techno',
                label: 'BAC Techno',
                value: 'BAC Techno'
            },
            {
                key: 'BAC PRO',
                label: 'BAC PRO',
                value: 'BAC PRO'
            },
            {
                key: 'PREPA ECE',
                label: 'PREPA ECE',
                value: 'PREPA ECE'
            },
            {
                key: 'PREPA ECP',
                label: 'PREPA ECP',
                value: 'PREPA ECP'
            },
            {
                key: 'PREPA ECS',
                label: 'PREPA ECS',
                value: 'PREPA ECS'
            },
            {
                key: 'PREPA ECT',
                label: 'PREPA ECT',
                value: 'PREPA ECT'
            },
            {
                key: 'BTS',
                label: 'BTS',
                value: 'BTS'
            },
            {
                key: 'DUT',
                label: 'DUT',
                value: 'DUT'
            },
            {
                key: 'LICENCE',
                label: 'LICENCE',
                value: 'LICENCE'
            },
            {
                key: 'LICENCE PRO',
                label: 'LICENCE PRO',
                value: 'LICENCE PRO'
            },
            {
                key: 'BACHELOR',
                label: 'BACHELOR',
                value: 'BACHELOR'
            },
            {
                key: 'MASTER',
                label: 'MASTER',
                value: 'MASTER'
            },
            {
                key: 'Autre',
                label: 'Autre',
                value: 'Autre'
            }
        ],

    immersionDay:
        true,
    jpo:
        true,
    privateVisit:
        true,
}
;

