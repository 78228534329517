import React from 'react';
import {
    View,
    ScrollView,
    StyleSheet,
    TouchableOpacity, Image, Dimensions,
} from 'react-native';

import ChooseCampusButton from "./ChooseCampusButton";
import ChooseButton from "./ChooseButton";
import { isIphoneX, getBottomSpace} from 'react-native-iphone-x-helper';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from "react-native-responsive-screen";


export class FormChooseButtons extends React.Component {
    constructor(props) {
        super(props);
    }

    isSelected = (itemTest) => {
        return (this.props.selectedInterest.find((item) => itemTest === item) !== undefined);
    };

    isSelectedCampus = (itemTest) => {
        return (this.props.campus.find((item) => itemTest === item) !== undefined);
    };

    showArrow = () => {
        let width = Dimensions.get('window').width;
        let tablet = width > 480;

        if (((!tablet) && this.props.datas.length > 6) || this.props.showArrow) {
            return (
                <View style={styles.arrowContainer}>
                    <Image resizeMode='contain' source={this.props.arrow} style={styles.arrow}/>
                </View>
            );
        }
    };

    renderContent = (type) => {
        let width = Dimensions.get('window').width;
        let tablet = width > 480;

        switch (type) {
            case 'study':
                return (
                    <ScrollView style={{width: '80%', height: '100%',}}>
                        <View style={styles.buttonsView}>
                            {this.props.datas.map((item, key) => {
                                return (
                                    <TouchableOpacity
                                        style={tablet ? styles.buttonView : styles.mobileButtonView}
                                        onPress={() => this.props.actualiseFormState({
                                            formState: 'chooseInterest',
                                            level: item.name
                                        })}
                                        key={item.name}
                                    >
                                        <ChooseButton type='study' key={item.name} text={item.name}/>
                                    </TouchableOpacity>
                                );
                            })
                            }
                        </View>
                    </ScrollView>
                );
            case 'interest':
                return (
                    <ScrollView style={{width: '80%', height: '100%'}}>
                        <View style={styles.buttonsView}>
                            {this.props.datas.map((item, key) => {
                                return (
                                    <TouchableOpacity
                                        style={tablet ? styles.buttonView : styles.mobileButtonView}
                                        onPress={() => this.props.addInterest(item)}
                                        key={item}>
                                        <ChooseButton
                                            type='interest'
                                            selected={this.isSelected(item)}
                                            text={item}
                                        />
                                    </TouchableOpacity>
                                );
                            })
                            }
                        </View>
                    </ScrollView>
                );
            case 'campus':
                return (
                    <View style={tablet ? styles.campusButtonsView : styles.mobileCampusButtonsView}>
                        {this.props.campusList.map((item, key) => {
                            return (
                                <TouchableOpacity
                                    key={item}
                                    onPress={() => this.props.addCampus(item)}>
                                    <ChooseCampusButton school={this.props.school} selected={this.isSelectedCampus(item)} campus={item}/>
                                </TouchableOpacity>
                            );
                        })
                        }
                    </View>
                );
            case 'courseFormat':
                return (
                    <ScrollView style={{width: '80%', height: '100%'}}>
                        <View style={styles.buttonsView}>
                            {this.props.courseFormatList.map((item, key) => {
                                return (
                                    <TouchableOpacity
                                        style={tablet ? styles.buttonView : styles.mobileButtonView}
                                        onPress={() => this.props.actualiseFormState({
                                            formState: 'chooseCampus',
                                            selectedCourseFormat: item
                                        })}
                                        key={item}>
                                        <ChooseButton
                                            type='interest'
                                            text={item}
                                        />
                                    </TouchableOpacity>
                                );
                            })
                            }
                        </View>
                    </ScrollView>
                );
        }
    };


    render() {

        return (
            <View style={{width: '100%', alignItems: 'center', height: hp("50%")}}>
                {this.renderContent(this.props.type)}
                {this.showArrow()}
            </View>
        )
    }
}

const styles = StyleSheet.create({

    buttonsView: {
        justifyContent: 'space-around',
        flexDirection: 'row',
        height: '100%',
        flexWrap: 'wrap',
        flex: 1,
    },
    campusButtonsView: {
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'row',
        height: '80%',
        width: '70%',
    },
    buttonView: {
        height: 140,
        width: '25%',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    mobileButtonView: {
        width: '50%',
        height: 125,
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    arrowContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: -50,
        alignItems: 'center',
        justifyContent: 'center'
    },
    arrow: {
        height: 40,
    },
    mobileCampusButtonsView:{
        flexWrap: 'wrap',
        height: '95%',
        alignItems: 'center',
        justifyContent: 'center',
        width: "100%",
        //marginLeft: '5%',
        //marginRight: '5%',
        flexDirection: 'row'
    }
});