const initialState = {
    show: false,
    newInscriptions: '',
    inscriptions: ''
};

function toggleInscriptions(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case 'UPDATE_INSCRIPTIONS':
            nextState = {
                ...state,
                show: true,
                inscriptions: action.inscriptions
            };
            console.log('reducerOK');
            return nextState || state;
        case 'UPDATE_INSCRIPTIONS_NEW':
            nextState = {
                ...state,
                show: true,
                newInscriptions: action.newInscriptions,
            };
            console.log('reducerOK');
            return nextState || state;
        case 'HIDE_INSCRIPTION':
            nextState = {
                ...state,
                show: false,
                newInscriptions: '',
                inscriptions: ''
            };
            return nextState || state;
        default:
            return state;
    }
}

export default toggleInscriptions;