import React from 'react';
import {View, Image, Text, StyleSheet, ImageBackground} from 'react-native';
import Colors from '../Constants/Colors';

export class SchoolButton extends React.Component {

    constructor(props) {
        super(props);
    }

    render(){
        return (
            <View style={[styles.buttonView,{backgroundColor: this._getSchoolColor(this.props.school)}]}>
                <Image style={styles.buttonImg} source={this._getSchoolImg(this.props.school)}/>
            </View>
        )
    }

    _getSchoolImg = school => {
        switch(school) {
            case 'design':
                return require('../../assets/schools_logos/design_carre_violet.png');
            case 'scbs':
                return require('../../assets/schools_logos/scbs_carre_rouge.png');
            case 'tourisme':
                return require('../../assets/schools_logos/tourisme_carre_bleu.png');
            case 'pfep':
                return require('../../assets/schools_logos/pfep_carre_bleu.png');
            case 'pss':
                return require('../../assets/schools_logos/pss_carre_vert.png');
        }
    };

    _getSchoolColor = school => {
        switch(school) {
            case 'design':
                return Colors.design;
            case 'scbs':
                return Colors.scbs;
            case 'tourisme':
                return Colors.tourisme;
            case 'pfep':
                return Colors.pfep;
            case 'pss':
                return Colors.pss;
        }
    };
}

const styles = StyleSheet.create({
    buttonView:{
        width: '100%',
        height: '100%',
        borderRadius: 5,
        justifyContent:'center',
        alignItems:'center'
    },
    buttonImg:{
        width: '96%',
        height: '96%'
    }
});
