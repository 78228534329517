import React from 'react';
import {View, Image, StyleSheet, TouchableOpacity, Text, Dimensions} from 'react-native';
import StatusBar from "./StatusBar";
import {connect} from 'react-redux';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from "react-native-responsive-screen";

class Header extends React.Component {
    constructor(props) {
        super(props);
        console.log('\x1b[4mActual stack :\x1b[0m', `\x1b[36m${props.stack}\x1b[0m`);
    }

    _toggleSchool = (type) => {
        const action = {type: type};
        this.props.dispatch(action);
    };

    _navigateToAdmin = () => {
        this._toggleSchool('TOGGLE_YSCHOOLS');
        this.props.navigation.navigate("AdminAuth");
    };

    _navigateToMain = () => {
        this.props.navigation.navigate("Main");
    };

    _renderInscription = () => {
        if (this.props.inscriptions.show) {
            if (this.props.stack === 'admin') {
                return (
                    <View style={styles.inscriptionContent}>
                        <Text style={styles.inscriptionText}>Nouveaux
                            inscrits: {this.props.inscriptions.newInscriptions}</Text>
                        <Text style={styles.inscriptionText}>Total
                            inscrits: {this.props.inscriptions.inscriptions}</Text>
                    </View>
                );
            }
        }

    };

    render() {
        let width = Dimensions.get('window').width;
        let tablet = width > 480;
        return (
            <View style={tablet ? styles.headerView : styles.mobileHeaderView}>
                <StatusBar stack={this.props.stack} navigation={this.props.navigation} color={this.props.school.color}/>
                <View style={styles.headerContentView}>
                    {tablet ?
                        <View style={styles.inscriptionContainer}>
                            {this._renderInscription()}
                        </View>
                        : null
                    }
                    <View style={tablet ? styles.schoolLogoContainer : styles.mobileSchoolLogoContainer}>
                        <Image resizeMode='contain'
                               style={this.props.school.school === "yschools" ? styles.yschoolsLogo : (tablet ? styles.logo : styles.mobileLogo)}
                               source={this.props.school.schoolLogo}/>
                    </View>
                    {tablet ? <View style={styles.adminLogoContainer}>
                        <TouchableOpacity
                            onPress={this.props.stack === 'admin' ? () => this._navigateToMain() : () => this._navigateToAdmin()}
                            style={styles.adminLogo}>
                            <Image style={styles.adminLogo}
                                   source={this.props.stack === 'admin' ? require('../../assets/buttons/admin/admin_quit.png') : this.props.school.adminLogo}/>
                        </TouchableOpacity>
                    </View>: null}
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    headerView: {
        width: '100%',
        height: hp("24%"),
        backgroundColor: '#ffffff'
    },
    headerContentView: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: "row",
        height: '80%',
    },
    logo: {
        height: hp("15%"),
        width: '100%',
    },
    yschoolsLogo: {
        height: hp("7%"),
    },
    adminLogo: {
        width: 40.9,
        height: 40.9,
    },
    adminLogoContainer: {
        height: '100%',
        width: '20%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    schoolLogoContainer: {
        height: '100%',
        width: '60%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    inscriptionContainer: {
        height: '100%',
        width: '20%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    inscriptionContent: {
        left: '7%',
        position: 'absolute',
    },
    inscriptionText: {
        height: 22,
        fontFamily: "HelveticaNeue",
        fontSize: 18,
        fontWeight: "500",
        fontStyle: "normal",
        lineHeight: 23,
        letterSpacing: 0,
        textAlign: "left",
        marginBottom: 10,
        color: "#022c6d"
    },
    mobileHeaderView: {
        width: '100%',
        height: hp("25%"),
        backgroundColor: '#ffffff'
    },
    mobileLogo: {
        height: 60,
        width: '100%',
    },
    mobileSchoolLogoContainer: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        margin:"auto",
    },
});

const mapStateToProps = (state) => {
    return {
        school: state.school,
        inscriptions: state.inscriptions
    };
};

export default connect(mapStateToProps)(Header)