import React from 'react';
import {View, Image, Text, StyleSheet, ImageBackground, ScrollView, Dimensions} from 'react-native';
import Colors from '../Constants/Colors';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from "react-native-responsive-screen";

export class EventsListItem extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let width = Dimensions.get('window').width;
        let tablet = width > 480;

        let itemColor = this.props.selected ? '#022c6d' : '#ffffff';
        let contentColor = this.props.selected ? '#ffffff' : '#022c6d';
        const styles = StyleSheet.create({
            listItem: {
                paddingVertical: 10,
                borderRadius: 5,
                backgroundColor: itemColor,
                shadowColor: "rgba(2, 44, 109, 0.37)",
                shadowOffset: {
                    width: 0,
                    height: 3
                },
                shadowRadius: 6,
                shadowOpacity: 1,
                margin: 5,
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'column',
                elevation:5
            },
            itemText: {
                fontFamily: "HelveticaNeue",
                fontSize: 18,
                fontWeight: "300",
                lineHeight: 22,
                letterSpacing: 0,
                textAlign: "center",
                color: contentColor,
                width: '95%',
            },
            itemDate: {
                fontWeight: 'bold',
                fontFamily: "HelveticaNeue",
                fontSize: 18,
                lineHeight: 22,
                letterSpacing: 0,
                textAlign: "left",
                color: contentColor,
                paddingLeft: 20,
            },
            mobileListItem: {
                height: hp("10%"),
                borderRadius: 5,
                backgroundColor: itemColor,
                shadowColor: "rgba(2, 44, 109, 0.37)",
                shadowOffset: {
                    width: 0,
                    height: 3
                },
                shadowRadius: 6,
                shadowOpacity: 1,
                margin: 5,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                elevation:5,
                paddingHorizontal: 15,

            },
            mobileItemText: {
                fontFamily: "HelveticaNeue",
                fontSize: wp("4%"),
                fontWeight: "300",
                letterSpacing: 0,
                textAlign: "left",
                color: contentColor,
                width: '100%',
            },
            mobileItemDate: {
                fontWeight: 'bold',
                fontFamily: "HelveticaNeue",
                fontSize: wp("4%"),
                letterSpacing: 0,
                textAlign: "left",
                color: contentColor,
                width: '100%'
            },
        });
        return (
            <View style={tablet ? styles.listItem : styles.mobileListItem}>
                <Text style={tablet ? styles.itemDate: styles.mobileItemDate}>{this.props.content.date}</Text>
                <Text style={tablet ? styles.itemText: styles.mobileItemText}>{this.props.content.name}</Text>
            </View>
        )
    }
}

