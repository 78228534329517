import React from 'react';
import {
    View,
    StyleSheet,
    Text,
    TextInput,
    Image,
    TouchableOpacity,
    KeyboardAvoidingView,
    Alert, Dimensions
} from 'react-native';


import StatusBar from '../../Components/StatusBar';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from "react-native-responsive-screen";


class LoginScreen extends React.Component {
    state = {
        username: '',
        password: ''
    };

    render() {
        let width = Dimensions.get('window').width;
        let tablet = width > 480;
        return (
            <View style={styles.screenView}>
                <StatusBar color={this.props.color} stack={this.props.stack} navigation={this.props.navigation} showClearButton={true}/>

                <KeyboardAvoidingView style={styles.contentView} behavior="padding" enabled>
                    <Image style={styles.logo} source={require('../../../assets/schools_logos/yschools-white.png')}/>
                    <View style={[styles.loginForm, tablet ? null : styles.mobileLoginForm]}>
                        <View style={styles.loginFormContent}>
                            <View style={styles.formEntryView}>
                                <Text style={styles.formText}>Nom d'utilisateur</Text>
                                <TextInput  style={styles.formTextInput}
                                            clearButtonMode={"always"}
                                            placeholder="Nom d'utilisateur"
                                            onChangeText={username => this.setState({username})} />
                            </View>
                            <View style={styles.formEntryView}>
                                <Text style={styles.formText}>Mot de passe</Text>
                                <TextInput secureTextEntry={true}
                                        clearButtonMode={"always"}
                                        placeholder="Mot de passe"
                                        onChangeText={password => this.setState({password})}
                                        style={styles.formTextInput} />
                            </View>
                        </View>
                        

                        <TouchableOpacity style={styles.buttonTouchContainer} onPress={() => this._login()}>
                            <View style={styles.buttonContainer}>
                                <View style={styles.buttonTextView}>
                                    <Text style={styles.buttonText}>SE CONNECTER</Text>
                                </View>
                            </View>
                        </TouchableOpacity>
                    </View>
                </KeyboardAvoidingView>
            </View>
        )
    }

    _login = () => {
        if (this.state.username.toUpperCase() === 'YSCHOOLS10' && this.state.password.toUpperCase() === 'YSINFIN') {
            this.props.navigation.navigate('AdminStack');
        } else {
            Alert.alert(
                'Identifiants incorrects',
                'Veuillez vérifier vos identifiants',
                [
                    {text: 'OK', onPress: () => console.log('OK Pressed')},
                ],
                {cancelable: false}
            )
        }
    };
}

const styles = StyleSheet.create({
    screenView: {
        backgroundColor: "rgba(2, 44, 109, 0.54)",
        height: '100%'
    },
    contentView: {
        height: '95%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loginForm: {
        width: '50%',
        height: 220,
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loginFormContent: {
        width: '100%',
        height: 180,
        backgroundColor: "#ffffff",
        shadowColor: "rgba(2, 44, 109, 0.37)",
        shadowOffset: {
            width: 0,
            height: 3
        },
        shadowRadius: 6,
        shadowOpacity: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    formText: {
        width: '100%',
        fontFamily: "HelveticaNeue",
        fontSize: 16,
        fontWeight: "500",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "left",
        color: "#022c6d",
    },
    formTextInput: {
        width: '100%',
        height: 28,
        borderRadius: 6,
        borderStyle: "solid",
        borderWidth: 0.5,
        borderColor: "#022c6d",
        fontFamily: "HelveticaNeue",
        fontSize: 12,
        fontWeight: "300",
        fontStyle: "normal",
        lineHeight: 15,
        letterSpacing: 0,
        textAlign: "left",
        color: "#7b7b7b",
        paddingLeft: 12,
        marginTop: 5,
    },
    formEntryView: {
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: '70%',
        marginBottom:15
    },
    buttonText: {
        fontFamily: "HelveticaNeue",
        fontSize: 16,
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: 18,
        letterSpacing: 0,
        textAlign: "center",
        color: "#ffffff"
    },
    buttonContainer: {
        backgroundColor: "#022c6d",
        justifyContent: 'center',
        alignItems: 'center',
        transform: [{skewX: '-40deg'}],
        width: '100%',
        height: '100%'
    },
    buttonTouchContainer: {
        width: 210,
        height: 36,
        position: 'absolute',
        margin: 'auto',
        bottom: 18
    },
    buttonTextView: {
        transform: [{skewX: '40deg'}]
    },
    logo: {
        width: 179,
        height: 60.7,
        position: 'absolute',
        top: hp("10%")
    },
    mobileLoginForm: {
        width: '80%',
    },
});

export default LoginScreen;