var listeCps = [];
listeCps["AMBERIEU EN BUGEY"]= ["1004"];
listeCps["BELLEGARDE SUR VALSERINE"]= ["1033"];
listeCps["BELLEY"]= ["1034"];
listeCps["BELLIGNAT"]= ["1031"];
listeCps["BOURG EN BRESSE"]= ["1053"];
listeCps["CESSY"]= ["1071"];
listeCps["FERNEY VOLTAIRE"]= ["1160"];
listeCps["LA BOISSE"]= ["1049"];
listeCps["MIRIBEL"]= ["1249"];
listeCps["NANTUA"]= ["1269"];
listeCps["OYONNAX"]= ["1283"];
listeCps["TREVOUX"]= ["1427"];
listeCps["BOHAIN EN VERMANDOIS"]= ["2095"];
listeCps["CHATEAU THIERRY"]= ["2168"];
listeCps["CHAUNY"]= ["2173"];
listeCps["FONTAINE LES VERVINS"]= ["2321"];
listeCps["HIRSON"]= ["2381"];
listeCps["LAON"]= ["2408"];
listeCps["SOISSONS"]= ["2722"];
listeCps["ST QUENTIN"]= ["2691"];
listeCps["VILLERS COTTERETS"]= ["2810"];
listeCps["COMMENTRY"]= ["3082"];
listeCps["CUSSET"]= ["3095"];
listeCps["GANNAT"]= ["3118"];
listeCps["MONTLUCON"]= ["3185"];
listeCps["MOULINS"]= ["3190"];
listeCps["ST POURCAIN SUR SIOULE"]= ["3254"];
listeCps["YZEURE"]= ["3321"];
listeCps["BARCELONNETTE"]= ["4019"];
listeCps["DIGNE LES BAINS"]= ["4070"];
listeCps["MANOSQUE"]= ["4112"];
listeCps["SISTERON"]= ["4209"];
listeCps["ANTIBES"]= ["6004"];
listeCps["CAGNES SUR MER"]= ["6027"];
listeCps["CANNES"]= ["6029"];
listeCps["GRASSE"]= ["6069"];
listeCps["MENTON"]= ["6083"];
listeCps["NICE"]= ["6088"];
listeCps["ROQUEBRUNE CAP MARTIN"]= ["6104"];
listeCps["VALBONNE"]= ["6152"];
listeCps["VALDEBLORE"]= ["6153"];
listeCps["VENCE"]= ["6157"];
listeCps["ANNONAY"]= ["7010"];
listeCps["AUBENAS"]= ["7019"];
listeCps["BOURG ST ANDEOL"]= ["7042"];
listeCps["LE CHEYLARD"]= ["7064"];
listeCps["LE TEIL"]= ["7319"];
listeCps["PRIVAS"]= ["7186"];
listeCps["TOURNON SUR RHONE"]= ["7324"];
listeCps["BAZEILLES"]= ["8053"];
listeCps["CHARLEVILLE MEZIERES"]= ["8105"];
listeCps["GIVET"]= ["8190"];
listeCps["RETHEL"]= ["8362"];
listeCps["REVIN"]= ["8363"];
listeCps["SEDAN"]= ["8409"];
listeCps["VOUZIERS"]= ["8490"];
listeCps["FOIX"]= ["9122"];
listeCps["MIREPOIX"]= ["9194"];
listeCps["PAMIERS"]= ["9225"];
listeCps["ST GIRONS"]= ["9261"];
listeCps["BAR SUR AUBE"]= ["10033"];
listeCps["ROMILLY SUR SEINE"]= ["10323"];
listeCps["STE SAVINE"]= ["10362"];
listeCps["TROYES"]= ["10387"];
listeCps["CARCASSONNE"]= ["11069"];
listeCps["CASTELNAUDARY"]= ["11076"];
listeCps["LIMOUX"]= ["11206"];
listeCps["NARBONNE"]= ["11262"];
listeCps["DECAZEVILLE"]= ["12089"];
listeCps["ESPALION"]= ["12096"];
listeCps["MILLAU"]= ["12145"];
listeCps["RODEZ"]= ["12202"];
listeCps["ST AFFRIQUE"]= ["12208"];
listeCps["VILLEFRANCHE DE ROUERGUE"]= ["12300"];
listeCps["BARR"]= ["67021"];
listeCps["BISCHHEIM"]= ["67043"];
listeCps["BISCHWILLER"]= ["67046"];
listeCps["BOUXWILLER"]= ["67061"];
listeCps["ERSTEIN"]= ["67130"];
listeCps["HAGUENAU"]= ["67180"];
listeCps["ILLKIRCH GRAFFENSTADEN"]= ["67218"];
listeCps["MOLSHEIM"]= ["67300"];
listeCps["OBERNAI"]= ["67348"];
listeCps["SARRE UNION"]= ["67434"];
listeCps["SAVERNE"]= ["67437"];
listeCps["SELESTAT"]= ["67462"];
listeCps["STRASBOURG"]= ["67482"];
listeCps["WALBOURG"]= ["67511"];
listeCps["WISSEMBOURG"]= ["67544"];
listeCps["AIX EN PROVENCE"]= ["13001"];
listeCps["ARLES"]= ["13004"];
listeCps["AUBAGNE"]= ["13005"];
listeCps["GARDANNE"]= ["13041"];
listeCps["GEMENOS"]= ["13042"];
listeCps["GIGNAC LA NERTHE"]= ["13043"];
listeCps["ISTRES"]= ["13047"];
listeCps["LA CIOTAT"]= ["13028"];
listeCps["MARIGNANE"]= ["13054"];
listeCps["MARSEILLE"]= ["13208"];
listeCps["MARSEILLE 01"]= ["13201"];
listeCps["MARSEILLE 03"]= ["13203"];
listeCps["MARSEILLE 04"]= ["13204"];
listeCps["MARSEILLE 05"]= ["13205"];
listeCps["MARSEILLE 06"]= ["13206"];
listeCps["MARSEILLE 07"]= ["13207"];
listeCps["MARSEILLE 08"]= ["13208"];
listeCps["MARSEILLE 09"]= ["13209"];
listeCps["MARSEILLE 10"]= ["13210"];
listeCps["MARSEILLE 11"]= ["13211"];
listeCps["MARSEILLE 12"]= ["13212"];
listeCps["MARSEILLE 13"]= ["13213"];
listeCps["MARSEILLE 14"]= ["13214"];
listeCps["MARSEILLE 15"]= ["13215"];
listeCps["MARTIGUES"]= ["13056"];
listeCps["MIRAMAS"]= ["13063"];
listeCps["PORT ST LOUIS DU RHONE"]= ["13078"];
listeCps["SALON DE PROVENCE"]= ["13103"];
listeCps["TARASCON"]= ["13108"];
listeCps["VITROLLES"]= ["13117"];
listeCps["BAYEUX"]= ["14047"];
listeCps["CAEN"]= ["14118"];
listeCps["CONDE SUR NOIREAU"]= ["14174"];
listeCps["DEAUVILLE"]= ["14220"];
listeCps["DOUVRES LA DELIVRANDE"]= ["14228"];
listeCps["FALAISE"]= ["14258"];
listeCps["HEROUVILLE ST CLAIR"]= ["14327"];
listeCps["HONFLEUR"]= ["14333"];
listeCps["LISIEUX"]= ["14366"];
listeCps["MONDEVILLE"]= ["14437"];
listeCps["TROUVILLE SUR MER"]= ["14715"];
listeCps["VIRE"]= ["14762"];
listeCps["AURILLAC"]= ["15014"];
listeCps["MAURIAC"]= ["15120"];
listeCps["ST FLOUR"]= ["15187"];
listeCps["ANGOULEME"]= ["16015"];
listeCps["BARBEZIEUX ST HILAIRE"]= ["16028"];
listeCps["COGNAC"]= ["16102"];
listeCps["CONFOLENS"]= ["16106"];
listeCps["BOURCEFRANC LE CHAPUS"]= ["17058"];
listeCps["JONZAC"]= ["17197"];
listeCps["LA ROCHELLE"]= ["17300"];
listeCps["PONS"]= ["17283"];
listeCps["PONT L ABBE D ARNOULT"]= ["17284"];
listeCps["ROCHEFORT"]= ["17299"];
listeCps["ROYAN"]= ["17306"];
listeCps["SAINTES"]= ["17415"];
listeCps["ST GEORGES D OLERON"]= ["17337"];
listeCps["ST JEAN D ANGELY"]= ["17347"];
listeCps["BOURGES"]= ["18033"];
listeCps["ST AMAND MONTROND"]= ["18197"];
listeCps["VIERZON"]= ["18279"];
listeCps["BRIVE LA GAILLARDE"]= ["19031"];
listeCps["EGLETONS"]= ["19073"];
listeCps["TULLE"]= ["19272"];
listeCps["USSEL"]= ["19275"];
listeCps["AJACCIO"]= ["2A004"];
listeCps["PORTO VECCHIO"]= ["2A247"];
listeCps["SARTENE"]= ["2A272"];
listeCps["AUXONNE"]= ["21038"];
listeCps["BEAUNE"]= ["21054"];
listeCps["BROCHON"]= ["21110"];
listeCps["CHATILLON SUR SEINE"]= ["21154"];
listeCps["CHEVIGNY ST SAUVEUR"]= ["21171"];
listeCps["DIJON"]= ["21231"];
listeCps["SEMUR EN AUXOIS"]= ["21603"];
listeCps["DINAN"]= ["22050"];
listeCps["GUINGAMP"]= ["22070"];
listeCps["LAMBALLE"]= ["22093"];
listeCps["LANNION"]= ["22113"];
listeCps["LOUDEAC"]= ["22136"];
listeCps["PAIMPOL"]= ["22162"];
listeCps["QUINTIN"]= ["22262"];
listeCps["ROSTRENEN"]= ["22266"];
listeCps["ST BRIEUC"]= ["22278"];
listeCps["TREGUIER"]= ["22362"];
listeCps["AUBUSSON"]= ["23008"];
listeCps["FELLETIN"]= ["23079"];
listeCps["GUERET"]= ["23096"];
listeCps["LA SOUTERRAINE"]= ["23176"];
listeCps["BRESSUIRE"]= ["79049"];
listeCps["MELLE"]= ["79174"];
listeCps["NIORT"]= ["79191"];
listeCps["PARTHENAY"]= ["79202"];
listeCps["ST MAIXENT L ECOLE"]= ["79270"];
listeCps["THOUARS"]= ["79329"];
listeCps["BERGERAC"]= ["24037"];
listeCps["EXCIDEUIL"]= ["24164"];
listeCps["NONTRON"]= ["24311"];
listeCps["PERIGUEUX"]= ["24322"];
listeCps["RIBERAC"]= ["24352"];
listeCps["SARLAT LA CANEDA"]= ["24520"];
listeCps["TERRASSON LAVILLEDIEU"]= ["24547"];
listeCps["BESANCON"]= ["25056"];
listeCps["MONTBELIARD"]= ["25388"];
listeCps["MORTEAU"]= ["25411"];
listeCps["PONTARLIER"]= ["25462"];
listeCps["VALENTIGNEY"]= ["25580"];
listeCps["BOURG LES VALENCE"]= ["26058"];
listeCps["CHATEAUNEUF DE GALAURE"]= ["26083"];
listeCps["CREST"]= ["26108"];
listeCps["DIE"]= ["26113"];
listeCps["MONTELIMAR"]= ["26198"];
listeCps["NYONS"]= ["26220"];
listeCps["PIERRELATTE"]= ["26235"];
listeCps["ROMANS SUR ISERE"]= ["26281"];
listeCps["ST VALLIER"]= ["26333"];
listeCps["VALENCE"]= ["26362"];
listeCps["ARPAJON"]= ["91021"];
listeCps["ATHIS MONS"]= ["91027"];
listeCps["BONDOUFLE"]= ["91086"];
listeCps["BRETIGNY SUR ORGE"]= ["91103"];
listeCps["BRUNOY"]= ["91114"];
listeCps["CORBEIL ESSONNES"]= ["91174"];
listeCps["COURCOURONNES"]= ["91182"];
listeCps["DOURDAN"]= ["91200"];
listeCps["DRAVEIL"]= ["91201"];
listeCps["EPINAY SOUS SENART"]= ["91215"];
listeCps["ETAMPES"]= ["91223"];
listeCps["EVRY"]= ["91228"];
listeCps["GIF SUR YVETTE"]= ["91272"];
listeCps["LA VILLE DU BOIS"]= ["91665"];
listeCps["LES ULIS"]= ["91692"];
listeCps["LIMOURS"]= ["91338"];
listeCps["LONGJUMEAU"]= ["91345"];
listeCps["MASSY"]= ["91377"];
listeCps["MENNECY"]= ["91386"];
listeCps["MONTGERON"]= ["91421"];
listeCps["MORANGIS"]= ["91432"];
listeCps["ORSAY"]= ["91471"];
listeCps["PALAISEAU"]= ["91477"];
listeCps["SAVIGNY SUR ORGE"]= ["91589"];
listeCps["ST MICHEL SUR ORGE"]= ["91570"];
listeCps["STE GENEVIEVE DES BOIS"]= ["91549"];
listeCps["VILLEBON SUR YVETTE"]= ["91661"];
listeCps["VIRY CHATILLON"]= ["91687"];
listeCps["BERNAY"]= ["27056"];
listeCps["EVREUX"]= ["27229"];
listeCps["GAILLON"]= ["27275"];
listeCps["GISORS"]= ["27284"];
listeCps["LES ANDELYS"]= ["27016"];
listeCps["LOUVIERS"]= ["27375"];
listeCps["PONT AUDEMER"]= ["27467"];
listeCps["ST GERMAIN VILLAGE"]= ["27549"];
listeCps["VAL DE REUIL"]= ["27701"];
listeCps["VERNEUIL SUR AVRE"]= ["27679"];
listeCps["VERNON"]= ["27681"];
listeCps["CHARTRES"]= ["28085"];
listeCps["CHATEAUDUN"]= ["28088"];
listeCps["DREUX"]= ["28134"];
listeCps["LUISANT"]= ["28220"];
listeCps["NOGENT LE ROTROU"]= ["28280"];
listeCps["BREST"]= ["29019"];
listeCps["CARHAIX PLOUGUER"]= ["29024"];
listeCps["CHATEAULIN"]= ["29026"];
listeCps["CONCARNEAU"]= ["29039"];
listeCps["DOUARNENEZ"]= ["29046"];
listeCps["LANDERNEAU"]= ["29103"];
listeCps["LANDIVISIAU"]= ["29105"];
listeCps["LESNEVEN"]= ["29124"];
listeCps["MORLAIX"]= ["29151"];
listeCps["PONT L ABBE"]= ["29220"];
listeCps["QUIMPER"]= ["29232"];
listeCps["QUIMPERLE"]= ["29233"];
listeCps["ST POL DE LEON"]= ["29259"];
listeCps["ALES"]= ["30007"];
listeCps["BAGNOLS SUR CEZE"]= ["30028"];
listeCps["LE VIGAN"]= ["30350"];
listeCps["MILHAUD"]= ["30169"];
listeCps["NIMES"]= ["30189"];
listeCps["ST CHRISTOL LES ALES"]= ["30243"];
listeCps["UZES"]= ["30334"];
listeCps["VILLENEUVE LES AVIGNON"]= ["30351"];
listeCps["AUCH"]= ["32013"];
listeCps["CONDOM"]= ["32107"];
listeCps["L ISLE JOURDAIN"]= ["32160"];
listeCps["LECTOURE"]= ["32208"];
listeCps["MIRANDE"]= ["32256"];
listeCps["NOGARO"]= ["32296"];
listeCps["ANDERNOS LES BAINS"]= ["33005"];
listeCps["ARCACHON"]= ["33009"];
listeCps["BAZAS"]= ["33036"];
listeCps["BLANQUEFORT"]= ["33056"];
listeCps["BLAYE"]= ["33058"];
listeCps["BORDEAUX"]= ["33063"];
listeCps["GRADIGNAN"]= ["33192"];
listeCps["GUJAN MESTRAS"]= ["33199"];
listeCps["LA BREDE"]= ["33213"];
listeCps["LA REOLE"]= ["33352"];
listeCps["LANGON"]= ["33227"];
listeCps["LE TAILLAN MEDOC"]= ["33519"];
listeCps["LIBOURNE"]= ["33243"];
listeCps["LORMONT"]= ["33249"];
listeCps["MERIGNAC"]= ["33281"];
listeCps["PAUILLAC"]= ["33314"];
listeCps["PESSAC"]= ["33318"];
listeCps["ST ANDRE DE CUBZAC"]= ["33366"];
listeCps["STE FOY LA GRANDE"]= ["33402"];
listeCps["TALENCE"]= ["33522"];
listeCps["BAIE MAHAULT"]= ["97103"];
listeCps["BASSE TERRE"]= ["97105"];
listeCps["CAPESTERRE BELLE EAU"]= ["97107"];
listeCps["GRAND BOURG"]= ["97112"];
listeCps["LE GOSIER"]= ["97113"];
listeCps["LES ABYMES"]= ["97101"];
listeCps["MORNE A L EAU"]= ["97116"];
listeCps["PETIT BOURG"]= ["97118"];
listeCps["POINTE A PITRE"]= ["97120"];
listeCps["POINTE NOIRE"]= ["97121"];
listeCps["PORT LOUIS"]= ["97122"];
listeCps["ST MARTIN"]= ["97801"];
listeCps["STE ANNE"]= ["97128"];
listeCps["STE ROSE"]= ["97129"];
listeCps["CAYENNE"]= ["97302"];
listeCps["KOUROU"]= ["97304"];
listeCps["REMIRE MONTJOLY"]= ["97309"];
listeCps["ST LAURENT DU MARONI"]= ["97311"];
listeCps["ALTKIRCH"]= ["68004"];
listeCps["CARSPACH"]= ["68062"];
listeCps["CERNAY"]= ["68063"];
listeCps["COLMAR"]= ["68066"];
listeCps["GUEBWILLER"]= ["68112"];
listeCps["LANDSER"]= ["68174"];
listeCps["MULHOUSE"]= ["68224"];
listeCps["MUNSTER"]= ["68226"];
listeCps["RIBEAUVILLE"]= ["68269"];
listeCps["ST LOUIS"]= ["68297","97414"];
listeCps["STE MARIE AUX MINES"]= ["68298"];
listeCps["THANN"]= ["68334"];
listeCps["WITTELSHEIM"]= ["68375"];
listeCps["WITTENHEIM"]= ["68376"];
listeCps["ZILLISHEIM"]= ["68384"];
listeCps["BASTIA"]= ["2B033"];
listeCps["CORTE"]= ["2B096"];
listeCps["L ILE ROUSSE"]= ["2B134"];
listeCps["PRUNELLI DI FIUMORBO"]= ["2B251"];
listeCps["BAGNERES DE LUCHON"]= ["31042"];
listeCps["BLAGNAC"]= ["31069"];
listeCps["COLOMIERS"]= ["31149"];
listeCps["CUGNAUX"]= ["31157"];
listeCps["FONSORBES"]= ["31187"];
listeCps["FRONTON"]= ["31202"];
listeCps["GOURDAN POLIGNAN"]= ["31224"];
listeCps["MURET"]= ["31395"];
listeCps["PINS JUSTARET"]= ["31421"];
listeCps["REVEL"]= ["31451"];
listeCps["SEILH"]= ["31541"];
listeCps["ST GAUDENS"]= ["31483"];
listeCps["ST ORENS DE GAMEVILLE"]= ["31506"];
listeCps["TOULOUSE"]= ["31555"];
listeCps["BRIOUDE"]= ["43040"];
listeCps["BRIVES CHARENSAC"]= ["43041"];
listeCps["LE CHAMBON SUR LIGNON"]= ["43051"];
listeCps["LE PUY EN VELAY"]= ["43157"];
listeCps["MONISTROL SUR LOIRE"]= ["43137"];
listeCps["YSSINGEAUX"]= ["43268"];
listeCps["CHAUMONT"]= ["52121"];
listeCps["JOINVILLE"]= ["52250"];
listeCps["LANGRES"]= ["52269"];
listeCps["ST DIZIER"]= ["52448"];
listeCps["GRAY"]= ["70279"];
listeCps["HERICOURT"]= ["70285"];
listeCps["LURE"]= ["70310"];
listeCps["LUXEUIL LES BAINS"]= ["70311"];
listeCps["VESOUL"]= ["70550"];
listeCps["ABONDANCE"]= ["74001"];
listeCps["ANNECY"]= ["74010"];
listeCps["ANNEMASSE"]= ["74012"];
listeCps["ARGONAY"]= ["74019"];
listeCps["BONNEVILLE"]= ["74042"];
listeCps["CHAMONIX MONT BLANC"]= ["74056"];
listeCps["CLUSES"]= ["74081"];
listeCps["CRAN GEVRIER"]= ["74093"];
listeCps["EVIAN LES BAINS"]= ["74119"];
listeCps["LA ROCHE SUR FORON"]= ["74224"];
listeCps["PASSY"]= ["74208"];
listeCps["RUMILLY"]= ["74225"];
listeCps["SALLANCHES"]= ["74256"];
listeCps["SEYNOD"]= ["74268"];
listeCps["ST JULIEN EN GENEVOIS"]= ["74243"];
listeCps["THONES"]= ["74280"];
listeCps["THONON LES BAINS"]= ["74281"];
listeCps["VILLE LA GRAND"]= ["74305"];
listeCps["BELLAC"]= ["87011"];
listeCps["LIMOGES"]= ["87085"];
listeCps["ST JUNIEN"]= ["87154"];
listeCps["ST LEONARD DE NOBLAT"]= ["87161"];
listeCps["ST YRIEIX LA PERCHE"]= ["87187"];
listeCps["BRIANCON"]= ["5023"];
listeCps["EMBRUN"]= ["5046"];
listeCps["GAP"]= ["5061"];
listeCps["ARGELES GAZOST"]= ["65025"];
listeCps["BAGNERES DE BIGORRE"]= ["65059"];
listeCps["LANNEMEZAN"]= ["65258"];
listeCps["LOURDES"]= ["65286"];
listeCps["MONLEON MAGNOAC"]= ["65315"];
listeCps["TARBES"]= ["65440"];
listeCps["VIC EN BIGORRE"]= ["65460"];
listeCps["ANTONY"]= ["92002"];
listeCps["ASNIERES SUR SEINE"]= ["92004"];
listeCps["BAGNEUX"]= ["92007"];
listeCps["BOIS COLOMBES"]= ["92009"];
listeCps["BOULOGNE BILLANCOURT"]= ["92012"];
listeCps["BOURG LA REINE"]= ["92014"];
listeCps["CHATENAY MALABRY"]= ["92019"];
listeCps["CHAVILLE"]= ["92022"];
listeCps["CLAMART"]= ["92023"];
listeCps["CLICHY"]= ["92024"];
listeCps["COLOMBES"]= ["92025"];
listeCps["COURBEVOIE"]= ["92026"];
listeCps["GENNEVILLIERS"]= ["92036"];
listeCps["ISSY LES MOULINEAUX"]= ["92040"];
listeCps["LE PLESSIS ROBINSON"]= ["92060"];
listeCps["LEVALLOIS PERRET"]= ["92044"];
listeCps["MEUDON"]= ["92048"];
listeCps["MONTROUGE"]= ["92049"];
listeCps["NANTERRE"]= ["92050"];
listeCps["NEUILLY SUR SEINE"]= ["92051"];
listeCps["PUTEAUX"]= ["92062"];
listeCps["RUEIL MALMAISON"]= ["92063"];
listeCps["SCEAUX"]= ["92071"];
listeCps["SEVRES"]= ["92072"];
listeCps["ST CLOUD"]= ["92064"];
listeCps["SURESNES"]= ["92073"];
listeCps["VANVES"]= ["92075"];
listeCps["VAUCRESSON"]= ["92076"];
listeCps["VILLENEUVE LA GARENNE"]= ["92078"];
listeCps["AGDE"]= ["34003"];
listeCps["BEDARIEUX"]= ["34028"];
listeCps["BEZIERS"]= ["34032"];
listeCps["CASTELNAU LE LEZ"]= ["34057"];
listeCps["CLERMONT L HERAULT"]= ["34079"];
listeCps["LA GRANDE MOTTE"]= ["34344"];
listeCps["LATTES"]= ["34129"];
listeCps["LODEVE"]= ["34142"];
listeCps["LUNEL"]= ["34145"];
listeCps["MONTFERRIER SUR LEZ"]= ["34169"];
listeCps["MONTPELLIER"]= ["34172"];
listeCps["PEZENAS"]= ["34199"];
listeCps["SETE"]= ["34301"];
listeCps["ST CLEMENT DE RIVIERE"]= ["34247"];
listeCps["BAIN DE BRETAGNE"]= ["35012"];
listeCps["BRUZ"]= ["35047"];
listeCps["CESSON SEVIGNE"]= ["35051"];
listeCps["COMBOURG"]= ["35085"];
listeCps["DINARD"]= ["35093"];
listeCps["DOL DE BRETAGNE"]= ["35095"];
listeCps["FOUGERES"]= ["35115"];
listeCps["MONTAUBAN DE BRETAGNE"]= ["35184"];
listeCps["MONTFORT SUR MEU"]= ["35188"];
listeCps["REDON"]= ["35236"];
listeCps["RENNES"]= ["35238"];
listeCps["ST GREGOIRE"]= ["35278"];
listeCps["ST MALO"]= ["35288"];
listeCps["VITRE"]= ["35360"];
listeCps["ARGENTON SUR CREUSE"]= ["36006"];
listeCps["CHATEAUROUX"]= ["36044"];
listeCps["ISSOUDUN"]= ["36088"];
listeCps["LA CHATRE"]= ["36046"];
listeCps["LE BLANC"]= ["36018"];
listeCps["AMBOISE"]= ["37003"];
listeCps["CHINON"]= ["37072"];
listeCps["JOUE LES TOURS"]= ["37122"];
listeCps["LOCHES"]= ["37132"];
listeCps["ST PIERRE DES CORPS"]= ["37233"];
listeCps["TOURS"]= ["37261"];
listeCps["VEIGNE"]= ["37266"];
listeCps["BOURGOIN JALLIEU"]= ["38053"];
listeCps["ECHIROLLES"]= ["38151"];
listeCps["GRENOBLE"]= ["38185"];
listeCps["L ISLE D ABEAU"]= ["38193"];
listeCps["LA COTE ST ANDRE"]= ["38130"];
listeCps["LA MURE D ISERE"]= ["38269"];
listeCps["LA TOUR DU PIN"]= ["38509"];
listeCps["LA TRONCHE"]= ["38516"];
listeCps["LE PEAGE DE ROUSSILLON"]= ["38298"];
listeCps["LE PONT DE BEAUVOISIN"]= ["38315"];
listeCps["MEYLAN"]= ["38229"];
listeCps["MOIRANS"]= ["38239"];
listeCps["MORESTEL"]= ["38261"];
listeCps["NIVOLAS VERMELLE"]= ["38276"];
listeCps["PONT DE CHERUY"]= ["38316"];
listeCps["PONTCHARRA"]= ["38314"];
listeCps["ROUSSILLON"]= ["38344"];
listeCps["SASSENAGE"]= ["38474"];
listeCps["SEYSSINET PARISET"]= ["38485"];
listeCps["ST MARCELLIN"]= ["38416"];
listeCps["ST MARTIN D HERES"]= ["38421"];
listeCps["VIENNE"]= ["38544"];
listeCps["VILLARD BONNOT"]= ["38547"];
listeCps["VILLARD DE LANS"]= ["38548"];
listeCps["VILLEFONTAINE"]= ["38553"];
listeCps["VIZILLE"]= ["38562"];
listeCps["VOIRON"]= ["38563"];
listeCps["CHAMPAGNOLE"]= ["39097"];
listeCps["DOLE"]= ["39198"];
listeCps["LONS LE SAUNIER"]= ["39300"];
listeCps["MOREZ"]= ["39368"];
listeCps["MOUCHARD"]= ["39370"];
listeCps["POLIGNY"]= ["39434"];
listeCps["SALINS LES BAINS"]= ["39500"];
listeCps["ST CLAUDE"]= ["39478"];
listeCps["AIRE SUR L ADOUR"]= ["40001"];
listeCps["DAX"]= ["40088"];
listeCps["MONT DE MARSAN"]= ["40192"];
listeCps["PARENTIS EN BORN"]= ["40217"];
listeCps["ST PAUL LES DAX"]= ["40279"];
listeCps["ST PIERRE DU MONT"]= ["40281"];
listeCps["ST VINCENT DE TYROSSE"]= ["40284"];
listeCps["BLOIS"]= ["41018"];
listeCps["ROMORANTIN LANTHENAY"]= ["41194"];
listeCps["VENDOME"]= ["41269"];
listeCps["ANDREZIEUX BOUTHEON"]= ["42005"];
listeCps["BOEN"]= ["42019"];
listeCps["CHARLIEU"]= ["42052"];
listeCps["FEURS"]= ["42094"];
listeCps["FIRMINY"]= ["42095"];
listeCps["MONTBRISON"]= ["42147"];
listeCps["RIVE DE GIER"]= ["42186"];
listeCps["ROANNE"]= ["42187"];
listeCps["ST CHAMOND"]= ["42207"];
listeCps["ST ETIENNE"]= ["42218"];
listeCps["ST PRIEST EN JAREZ"]= ["42275"];
listeCps["ANCENIS"]= ["44003"];
listeCps["BASSE GOULAINE"]= ["44009"];
listeCps["BLAIN"]= ["44015"];
listeCps["BOUAYE"]= ["44018"];
listeCps["CHATEAUBRIANT"]= ["44036"];
listeCps["GORGES"]= ["44064"];
listeCps["GUERANDE"]= ["44069"];
listeCps["LA BAULE"]= ["44055"];
listeCps["LA CHAPELLE SUR ERDRE"]= ["44035"];
listeCps["MACHECOUL"]= ["44087"];
listeCps["NANTES"]= ["44109"];
listeCps["ORVAULT"]= ["44114"];
listeCps["PORNIC"]= ["44131"];
listeCps["REZE"]= ["44143"];
listeCps["SAVENAY"]= ["44195"];
listeCps["ST GILDAS DES BOIS"]= ["44161"];
listeCps["ST HERBLAIN"]= ["44162"];
listeCps["ST NAZAIRE"]= ["44184"];
listeCps["ST SEBASTIEN SUR LOIRE"]= ["44190"];
listeCps["BEAUGENCY"]= ["45028"];
listeCps["CHALETTE SUR LOING"]= ["45068"];
listeCps["GIEN"]= ["45155"];
listeCps["INGRE"]= ["45169"];
listeCps["MONTARGIS"]= ["45208"];
listeCps["ORLEANS"]= ["45234"];
listeCps["PITHIVIERS"]= ["45252"];
listeCps["ST JEAN DE BRAYE"]= ["45284"];
listeCps["ST JEAN DE LA RUELLE"]= ["45285"];
listeCps["VILLEMANDEUR"]= ["45338"];
listeCps["CAHORS"]= ["46042"];
listeCps["FIGEAC"]= ["46102"];
listeCps["GOURDON"]= ["46127"];
listeCps["SOUILLAC"]= ["46309"];
listeCps["ST CERE"]= ["46251"];
listeCps["AGEN"]= ["47001"];
listeCps["AIGUILLON"]= ["47004"];
listeCps["FUMEL"]= ["47106"];
listeCps["MARMANDE"]= ["47157"];
listeCps["NERAC"]= ["47195"];
listeCps["VILLENEUVE SUR LOT"]= ["47323"];
listeCps["MARVEJOLS"]= ["48092"];
listeCps["MENDE"]= ["48095"];
listeCps["ST CHELY D APCHER"]= ["48140"];
listeCps["ANGERS"]= ["49007"];
listeCps["BEAUPREAU"]= ["49023"];
listeCps["CHOLET"]= ["49099"];
listeCps["LA POMMERAYE"]= ["49244"];
listeCps["LA SALLE DE VIHIERS"]= ["49325"];
listeCps["LE LONGERON"]= ["49179"];
listeCps["LES PONTS DE CE"]= ["49246"];
listeCps["NYOISEAU"]= ["49233"];
listeCps["SAUMUR"]= ["49328"];
listeCps["SEGRE"]= ["49331"];
listeCps["ST SYLVAIN D ANJOU"]= ["49323"];
listeCps["AGNEAUX"]= ["50002"];
listeCps["AVRANCHES"]= ["50025"];
listeCps["CARENTAN"]= ["50099"];
listeCps["CHERBOURG OCTEVILLE"]= ["50129"];
listeCps["COUTANCES"]= ["50147"];
listeCps["GRANVILLE"]= ["50218"];
listeCps["MORTAIN"]= ["50359"];
listeCps["ST HILAIRE DU HARCOUET"]= ["50484"];
listeCps["ST LO"]= ["50502"];
listeCps["VALOGNES"]= ["50615"];
listeCps["CHALONS EN CHAMPAGNE"]= ["51108"];
listeCps["EPERNAY"]= ["51230"];
listeCps["REIMS"]= ["51454"];
listeCps["SEZANNE"]= ["51535"];
listeCps["VITRY LE FRANCOIS"]= ["51649"];
listeCps["BELLEFONTAINE"]= ["97234"];
listeCps["DUCOS"]= ["97207"];
listeCps["FORT DE FRANCE"]= ["97209"];
listeCps["LA TRINITE"]= ["97230"];
listeCps["LE FRANCOIS"]= ["97210"];
listeCps["LE LAMENTIN"]= ["97213"];
listeCps["LE LORRAIN"]= ["97214"];
listeCps["LE MARIN"]= ["97217"];
listeCps["RIVIERE SALEE"]= ["97221"];
listeCps["STE LUCE"]= ["97227"];
listeCps["STE MARIE"]= ["97228","97418"];
listeCps["CHATEAU GONTIER"]= ["53062"];
listeCps["EVRON"]= ["53097"];
listeCps["LAVAL"]= ["53130"];
listeCps["MAYENNE"]= ["53147"];
listeCps["ART SUR MEURTHE"]= ["54025"];
listeCps["BRIEY"]= ["54099"];
listeCps["JARNY"]= ["54273"];
listeCps["JARVILLE LA MALGRANGE"]= ["54274"];
listeCps["LAXOU"]= ["54304"];
listeCps["LONGWY"]= ["54323"];
listeCps["LUNEVILLE"]= ["54329"];
listeCps["NANCY"]= ["54395"];
listeCps["PONT A MOUSSON"]= ["54431"];
listeCps["TOMBLAINE"]= ["54526"];
listeCps["TOUL"]= ["54528"];
listeCps["VANDOEUVRE LES NANCY"]= ["54547"];
listeCps["VILLERS LES NANCY"]= ["54578"];
listeCps["BAR LE DUC"]= ["55029"];
listeCps["COMMERCY"]= ["55122"];
listeCps["STENAY"]= ["55502"];
listeCps["VERDUN"]= ["55545"];
listeCps["AURAY"]= ["56007"];
listeCps["GOURIN"]= ["56066"];
listeCps["GUER"]= ["56075"];
listeCps["HENNEBONT"]= ["56083"];
listeCps["LANESTER"]= ["56098"];
listeCps["LORIENT"]= ["56121"];
listeCps["PLOEMEUR"]= ["56162"];
listeCps["PLOERMEL"]= ["56165"];
listeCps["PONTIVY"]= ["56178"];
listeCps["QUESTEMBERT"]= ["56184"];
listeCps["STE ANNE D AURAY"]= ["56263"];
listeCps["VANNES"]= ["56260"];
listeCps["ALGRANGE"]= ["57012"];
listeCps["BITCHE"]= ["57089"];
listeCps["CREUTZWALD"]= ["57160"];
listeCps["DIEUZE"]= ["57177"];
listeCps["FAMECK"]= ["57206"];
listeCps["FORBACH"]= ["57227"];
listeCps["HAYANGE"]= ["57306"];
listeCps["METZ"]= ["57463"];
listeCps["MONTIGNY LES METZ"]= ["57480"];
listeCps["OTTANGE"]= ["57529"];
listeCps["PELTRE"]= ["57534"];
listeCps["PHALSBOURG"]= ["57540"];
listeCps["ROMBAS"]= ["57591"];
listeCps["SARREBOURG"]= ["57630"];
listeCps["SARREGUEMINES"]= ["57631"];
listeCps["SCHOENECK"]= ["57638"];
listeCps["ST AVOLD"]= ["57606"];
listeCps["TALANGE"]= ["57663"];
listeCps["THIONVILLE"]= ["57672"];
listeCps["CLAMECY"]= ["58079"];
listeCps["COSNE COURS SUR LOIRE"]= ["58086"];
listeCps["DECIZE"]= ["58095"];
listeCps["NEVERS"]= ["58194"];
listeCps["ARMENTIERES"]= ["59017"];
listeCps["AULNOYE AYMERIES"]= ["59033"];
listeCps["AVESNES SUR HELPE"]= ["59036"];
listeCps["BAVAY"]= ["59053"];
listeCps["BEAUCAMPS LIGNY"]= ["59056"];
listeCps["BONDUES"]= ["59090"];
listeCps["CAMBRAI"]= ["59122"];
listeCps["CAUDRY"]= ["59139"];
listeCps["CONDE SUR L ESCAUT"]= ["59153"];
listeCps["DENAIN"]= ["59172"];
listeCps["DOUAI"]= ["59178"];
listeCps["DUNKERQUE"]= ["59183"];
listeCps["ESTAIRES"]= ["59212"];
listeCps["FOURMIES"]= ["59249"];
listeCps["GENECH"]= ["59258"];
listeCps["GONDECOURT"]= ["59266"];
listeCps["GRANDE SYNTHE"]= ["59271"];
listeCps["HAUBOURDIN"]= ["59286"];
listeCps["HAZEBROUCK"]= ["59295"];
listeCps["LA MADELEINE"]= ["59368"];
listeCps["LAMBERSART"]= ["59328"];
listeCps["LANDRECIES"]= ["59331"];
listeCps["LE CATEAU CAMBRESIS"]= ["59136"];
listeCps["LE QUESNOY"]= ["59481"];
listeCps["LILLE"]= ["59350"];
listeCps["LOOS"]= ["59360"];
listeCps["LOUVROIL"]= ["59365"];
listeCps["MARCQ EN BAROEUL"]= ["59378"];
listeCps["MAUBEUGE"]= ["59392"];
listeCps["ORCHIES"]= ["59449"];
listeCps["ROUBAIX"]= ["59512"];
listeCps["SIN LE NOBLE"]= ["59569"];
listeCps["SOLESMES"]= ["59571"];
listeCps["SOMAIN"]= ["59574"];
listeCps["ST AMAND LES EAUX"]= ["59526"];
listeCps["TOURCOING"]= ["59599"];
listeCps["VALENCIENNES"]= ["59606"];
listeCps["VILLENEUVE D ASCQ"]= ["59009"];
listeCps["WATTRELOS"]= ["59650"];
listeCps["BEAUVAIS"]= ["60057"];
listeCps["CHANTILLY"]= ["60141"];
listeCps["CLERMONT"]= ["60157"];
listeCps["COMPIEGNE"]= ["60159"];
listeCps["CREIL"]= ["60175"];
listeCps["CREPY EN VALOIS"]= ["60176"];
listeCps["MERU"]= ["60395"];
listeCps["MONTATAIRE"]= ["60414"];
listeCps["MORTEFONTAINE"]= ["60432"];
listeCps["NOGENT SUR OISE"]= ["60463"];
listeCps["NOYON"]= ["60471"];
listeCps["PONT STE MAXENCE"]= ["60509"];
listeCps["SENLIS"]= ["60612"];
listeCps["ALENCON"]= ["61001"];
listeCps["ARGENTAN"]= ["61006"];
listeCps["DOMFRONT"]= ["61145"];
listeCps["FLERS"]= ["61169"];
listeCps["L AIGLE"]= ["61214"];
listeCps["LA FERTE MACE"]= ["61168"];
listeCps["MORTAGNE AU PERCHE"]= ["61293"];
listeCps["SEES"]= ["61464"];
listeCps["PARIS"]= ["75117"];
listeCps["PARIS 03"]= ["75103"];
listeCps["PARIS 04"]= ["75104"];
listeCps["PARIS 05"]= ["75105"];
listeCps["PARIS 06"]= ["75106"];
listeCps["PARIS 07"]= ["75107"];
listeCps["PARIS 08"]= ["75108"];
listeCps["PARIS 09"]= ["75109"];
listeCps["PARIS 10"]= ["75110"];
listeCps["PARIS 11"]= ["75111"];
listeCps["PARIS 12"]= ["75112"];
listeCps["PARIS 13"]= ["75113"];
listeCps["PARIS 14"]= ["75114"];
listeCps["PARIS 15"]= ["75115"];
listeCps["PARIS 16"]= ["75116"];
listeCps["PARIS 17"]= ["75117"];
listeCps["PARIS 18"]= ["75118"];
listeCps["PARIS 19"]= ["75119"];
listeCps["PARIS 20"]= ["75120"];
listeCps["AIRE SUR LA LYS"]= ["62014"];
listeCps["ARRAS"]= ["62041"];
listeCps["AUCHEL"]= ["62048"];
listeCps["AVION"]= ["62065"];
listeCps["BERCK"]= ["62108"];
listeCps["BETHUNE"]= ["62119"];
listeCps["BEUVRY"]= ["62126"];
listeCps["BOULOGNE SUR MER"]= ["62160"];
listeCps["BRUAY LA BUISSIERE"]= ["62178"];
listeCps["BULLY LES MINES"]= ["62186"];
listeCps["CALAIS"]= ["62193"];
listeCps["CARVIN"]= ["62215"];
listeCps["GUINES"]= ["62397"];
listeCps["HENIN BEAUMONT"]= ["62427"];
listeCps["LE TOUQUET PARIS PLAGE"]= ["62826"];
listeCps["LENS"]= ["62498"];
listeCps["LIEVIN"]= ["62510"];
listeCps["LILLERS"]= ["62516"];
listeCps["LONGUENESSE"]= ["62525"];
listeCps["MONTREUIL"]= ["62588","93048"];
listeCps["NOEUX LES MINES"]= ["62617"];
listeCps["ST MARTIN BOULOGNE"]= ["62758"];
listeCps["ST OMER"]= ["62765"];
listeCps["ST POL SUR TERNOISE"]= ["62767"];
listeCps["WINGLES"]= ["62895"];
listeCps["AMBERT"]= ["63003"];
listeCps["CHAMALIERES"]= ["63075"];
listeCps["CLERMONT FERRAND"]= ["63113"];
listeCps["COURNON D AUVERGNE"]= ["63124"];
listeCps["COURPIERE"]= ["63125"];
listeCps["ISSOIRE"]= ["63178"];
listeCps["RIOM"]= ["63300"];
listeCps["THIERS"]= ["63430"];
listeCps["ANGLET"]= ["64024"];
listeCps["BAYONNE"]= ["64102"];
listeCps["BIARRITZ"]= ["64122"];
listeCps["CHERAUTE"]= ["64188"];
listeCps["HASPARREN"]= ["64256"];
listeCps["LESCAR"]= ["64335"];
listeCps["LESTELLE BETHARRAM"]= ["64339"];
listeCps["MOURENX"]= ["64410"];
listeCps["NAY"]= ["64417"];
listeCps["OLORON STE MARIE"]= ["64422"];
listeCps["ORTHEZ"]= ["64430"];
listeCps["PAU"]= ["64445"];
listeCps["ST JEAN DE LUZ"]= ["64483"];
listeCps["ST JEAN PIED DE PORT"]= ["64485"];
listeCps["USTARITZ"]= ["64547"];
listeCps["CANET EN ROUSSILLON"]= ["66037"];
listeCps["CERET"]= ["66049"];
listeCps["FONT ROMEU ODEILLO VIA"]= ["66124"];
listeCps["PERPIGNAN"]= ["66136"];
listeCps["PRADES"]= ["66149"];
listeCps["BRAS PANON"]= ["97402"];
listeCps["LA POSSESSION"]= ["97408"];
listeCps["LE PORT"]= ["97407"];
listeCps["LE TAMPON"]= ["97422"];
listeCps["LES AVIRONS"]= ["97401"];
listeCps["LES TROIS BASSINS"]= ["97423"];
listeCps["ST ANDRE"]= ["97409"];
listeCps["ST BENOIT"]= ["97410"];
listeCps["ST DENIS"]= ["93066","97411"];
listeCps["ST JOSEPH"]= ["97412"];
listeCps["ST LEU"]= ["97413"];
listeCps["ST LOUIS"]= ["68297","97414"];
listeCps["ST PAUL"]= ["97415"];
listeCps["ST PIERRE"]= ["97416"];
listeCps["STE MARIE"]= ["97228","97418"];
listeCps["STE SUZANNE"]= ["97420"];
listeCps["BELLEVILLE"]= ["69019"];
listeCps["BRON"]= ["69029"];
listeCps["CHARBONNIERES LES BAINS"]= ["69044"];
listeCps["DARDILLY"]= ["69072"];
listeCps["DECINES CHARPIEU"]= ["69275"];
listeCps["GIVORS"]= ["69091"];
listeCps["LA MULATIERE"]= ["69142"];
listeCps["LYON 01"]= ["69381"];
listeCps["LYON 02"]= ["69382"];
listeCps["LYON 03"]= ["69383"];
listeCps["LYON 04"]= ["69384"];
listeCps["LYON 05"]= ["69385"];
listeCps["LYON 06"]= ["69386"];
listeCps["LYON 07"]= ["69387"];
listeCps["LYON 08"]= ["69388"];
listeCps["LYON 09"]= ["69389"];
listeCps["NEUVILLE SUR SAONE"]= ["69143"];
listeCps["OULLINS"]= ["69149"];
listeCps["RILLIEUX LA PAPE"]= ["69286"];
listeCps["SAIN BEL"]= ["69171"];
listeCps["ST GENIS LAVAL"]= ["69204"];
listeCps["ST PRIEST"]= ["69290"];
listeCps["ST SYMPHORIEN SUR COISE"]= ["69238"];
listeCps["TARARE"]= ["69243"];
listeCps["TASSIN LA DEMI LUNE"]= ["69244"];
listeCps["THIZY"]= ["69248"];
listeCps["VAULX EN VELIN"]= ["69256"];
listeCps["VENISSIEUX"]= ["69259"];
listeCps["VILLEFRANCHE SUR SAONE"]= ["69264"];
listeCps["VILLEURBANNE"]= ["69266"];
listeCps["AUTUN"]= ["71014"];
listeCps["CHALON SUR SAONE"]= ["71076"];
listeCps["CHAROLLES"]= ["71106"];
listeCps["CLUNY"]= ["71137"];
listeCps["DIGOIN"]= ["71176"];
listeCps["LE CREUSOT"]= ["71153"];
listeCps["LOUHANS"]= ["71263"];
listeCps["MACON"]= ["71270"];
listeCps["MONTCEAU LES MINES"]= ["71306"];
listeCps["PARAY LE MONIAL"]= ["71342"];
listeCps["TOURNUS"]= ["71543"];
listeCps["ALLONNES"]= ["72003"];
listeCps["CHATEAU DU LOIR"]= ["72071"];
listeCps["LA FERTE BERNARD"]= ["72132"];
listeCps["LA FLECHE"]= ["72154"];
listeCps["LE MANS"]= ["72181"];
listeCps["MAMERS"]= ["72180"];
listeCps["PARIGNE LE POLIN"]= ["72230"];
listeCps["PRUILLE LE CHETIF"]= ["72247"];
listeCps["SABLE SUR SARTHE"]= ["72264"];
listeCps["SILLE LE GUILLAUME"]= ["72334"];
listeCps["AIX LES BAINS"]= ["73008"];
listeCps["ALBERTVILLE"]= ["73011"];
listeCps["BOURG ST MAURICE"]= ["73054"];
listeCps["CHAMBERY"]= ["73065"];
listeCps["LA RAVOIRE"]= ["73213"];
listeCps["MOUTIERS TARENTAISE"]= ["73181"];
listeCps["ST JEAN DE MAURIENNE"]= ["73248"];
listeCps["UGINE"]= ["73303"];
listeCps["AVON"]= ["77014"];
listeCps["BRIE COMTE ROBERT"]= ["77053"];
listeCps["BUSSY ST GEORGES"]= ["77058"];
listeCps["CESSON"]= ["77067"];
listeCps["CHAMPAGNE SUR SEINE"]= ["77079"];
listeCps["CHAMPS SUR MARNE"]= ["77083"];
listeCps["CHELLES"]= ["77108"];
listeCps["COMBS LA VILLE"]= ["77122"];
listeCps["CONGIS SUR THEROUANNE"]= ["77126"];
listeCps["COULOMMIERS"]= ["77131"];
listeCps["DAMMARIE LES LYS"]= ["77152"];
listeCps["FONTAINEBLEAU"]= ["77186"];
listeCps["JUILLY"]= ["77241"];
listeCps["LA FERTE SOUS JOUARRE"]= ["77183"];
listeCps["LA ROCHETTE"]= ["77389"];
listeCps["LAGNY SUR MARNE"]= ["77243"];
listeCps["LE MEE SUR SEINE"]= ["77285"];
listeCps["LOGNES"]= ["77258"];
listeCps["LONGPERRIER"]= ["77259"];
listeCps["MEAUX"]= ["77284"];
listeCps["MELUN"]= ["77288"];
listeCps["MITRY MORY"]= ["77294"];
listeCps["MOISSY CRAMAYEL"]= ["77296"];
listeCps["MONTEREAU FAULT YONNE"]= ["77305"];
listeCps["NANGIS"]= ["77327"];
listeCps["NEMOURS"]= ["77333"];
listeCps["NOISIEL"]= ["77337"];
listeCps["OZOIR LA FERRIERE"]= ["77350"];
listeCps["PONTAULT COMBAULT"]= ["77373"];
listeCps["PROVINS"]= ["77379"];
listeCps["ROISSY EN BRIE"]= ["77390"];
listeCps["ROZAY EN BRIE"]= ["77393"];
listeCps["SAVIGNY LE TEMPLE"]= ["77445"];
listeCps["SERRIS"]= ["77449"];
listeCps["TORCY"]= ["77468"];
listeCps["TOURNAN EN BRIE"]= ["77470"];
listeCps["VAUX LE PENIL"]= ["77487"];
listeCps["BARENTIN"]= ["76057"];
listeCps["BOLBEC"]= ["76114"];
listeCps["CANTELEU"]= ["76157"];
listeCps["DEVILLE LES ROUEN"]= ["76216"];
listeCps["DIEPPE"]= ["76217"];
listeCps["ELBEUF"]= ["76231"];
listeCps["EU"]= ["76255"];
listeCps["FECAMP"]= ["76259"];
listeCps["FORGES LES EAUX"]= ["76276"];
listeCps["FRANQUEVILLE ST PIERRE"]= ["76475"];
listeCps["LE GRAND QUEVILLY"]= ["76322"];
listeCps["LE HAVRE"]= ["76351"];
listeCps["LE MESNIL ESNARD"]= ["76429"];
listeCps["LILLEBONNE"]= ["76384"];
listeCps["MONTIVILLIERS"]= ["76447"];
listeCps["NEUFCHATEL EN BRAY"]= ["76462"];
listeCps["ROUEN"]= ["76540"];
listeCps["SOTTEVILLE LES ROUEN"]= ["76681"];
listeCps["ST ETIENNE DU ROUVRAY"]= ["76575"];
listeCps["ST VALERY EN CAUX"]= ["76655"];
listeCps["STE ADRESSE"]= ["76552"];
listeCps["YVETOT"]= ["76758"];
listeCps["AUBERVILLIERS"]= ["93001"];
listeCps["AULNAY SOUS BOIS"]= ["93005"];
listeCps["BAGNOLET"]= ["93006"];
listeCps["BOBIGNY"]= ["93008"];
listeCps["BONDY"]= ["93010"];
listeCps["CLICHY SOUS BOIS"]= ["93014"];
listeCps["DRANCY"]= ["93029"];
listeCps["DUGNY"]= ["93030"];
listeCps["EPINAY SUR SEINE"]= ["93031"];
listeCps["GAGNY"]= ["93032"];
listeCps["LA COURNEUVE"]= ["93027"];
listeCps["LE BLANC MESNIL"]= ["93007"];
listeCps["LE RAINCY"]= ["93062"];
listeCps["LES LILAS"]= ["93045"];
listeCps["LES PAVILLONS SOUS BOIS"]= ["93057"];
listeCps["LIVRY GARGAN"]= ["93046"];
listeCps["MONTREUIL"]= ["62588","93048"];
listeCps["NEUILLY SUR MARNE"]= ["93050"];
listeCps["NOISY LE GRAND"]= ["93051"];
listeCps["NOISY LE SEC"]= ["93053"];
listeCps["PANTIN"]= ["93055"];
listeCps["ROMAINVILLE"]= ["93063"];
listeCps["ROSNY SOUS BOIS"]= ["93064"];
listeCps["SEVRAN"]= ["93071"];
listeCps["ST DENIS"]= ["93066","97411"];
listeCps["ST OUEN"]= ["93070"];
listeCps["STAINS"]= ["93072"];
listeCps["TREMBLAY EN FRANCE"]= ["93073"];
listeCps["VAUJOURS"]= ["93074"];
listeCps["VILLEMOMBLE"]= ["93077"];
listeCps["VILLEPINTE"]= ["93078"];
listeCps["ABBEVILLE"]= ["80001"];
listeCps["ALBERT"]= ["80016"];
listeCps["AMIENS"]= ["80021"];
listeCps["DOULLENS"]= ["80253"];
listeCps["FRIVILLE ESCARBOTIN"]= ["80368"];
listeCps["MONTDIDIER"]= ["80561"];
listeCps["PERONNE"]= ["80620"];
listeCps["ALBI"]= ["81004"];
listeCps["CARMAUX"]= ["81060"];
listeCps["CASTRES"]= ["81065"];
listeCps["GAILLAC"]= ["81099"];
listeCps["LAVAUR"]= ["81140"];
listeCps["MAZAMET"]= ["81163"];
listeCps["CASTELSARRASIN"]= ["82033"];
listeCps["MOISSAC"]= ["82112"];
listeCps["MONTAUBAN"]= ["82121"];
listeCps["MONTEILS"]= ["82126"];
listeCps["BELFORT"]= ["90010"];
listeCps["ARGENTEUIL"]= ["95018"];
listeCps["BEAUMONT SUR OISE"]= ["95052"];
listeCps["CERGY"]= ["95127"];
listeCps["DEUIL LA BARRE"]= ["95197"];
listeCps["DOMONT"]= ["95199"];
listeCps["EAUBONNE"]= ["95203"];
listeCps["ENGHIEN LES BAINS"]= ["95210"];
listeCps["ERMONT"]= ["95219"];
listeCps["FOSSES"]= ["95250"];
listeCps["FRANCONVILLE LA GARENNE"]= ["95252"];
listeCps["GARGES LES GONESSE"]= ["95268"];
listeCps["GONESSE"]= ["95277"];
listeCps["GOUSSAINVILLE"]= ["95280"];
listeCps["HERBLAY"]= ["95306"];
listeCps["JOUY LE MOUTIER"]= ["95323"];
listeCps["L ISLE ADAM"]= ["95313"];
listeCps["LUZARCHES"]= ["95352"];
listeCps["MARGENCY"]= ["95369"];
listeCps["MONTMORENCY"]= ["95428"];
listeCps["OSNY"]= ["95476"];
listeCps["PONTOISE"]= ["95500"];
listeCps["SANNOIS"]= ["95582"];
listeCps["SARCELLES"]= ["95585"];
listeCps["ST OUEN L AUMONE"]= ["95572"];
listeCps["ST WITZ"]= ["95580"];
listeCps["TAVERNY"]= ["95607"];
listeCps["VAUREAL"]= ["95637"];
listeCps["VILLIERS LE BEL"]= ["95680"];
listeCps["ALFORTVILLE"]= ["94002"];
listeCps["BOISSY ST LEGER"]= ["94004"];
listeCps["CACHAN"]= ["94016"];
listeCps["CHAMPIGNY SUR MARNE"]= ["94017"];
listeCps["CHARENTON LE PONT"]= ["94018"];
listeCps["CHENNEVIERES SUR MARNE"]= ["94019"];
listeCps["CHOISY LE ROI"]= ["94022"];
listeCps["CRETEIL"]= ["94028"];
listeCps["FONTENAY SOUS BOIS"]= ["94033"];
listeCps["FRESNES"]= ["94034"];
listeCps["IVRY SUR SEINE"]= ["94041"];
listeCps["JOINVILLE LE PONT"]= ["94042"];
listeCps["LE KREMLIN BICETRE"]= ["94043"];
listeCps["LE PERREUX SUR MARNE"]= ["94058"];
listeCps["LIMEIL BREVANNES"]= ["94044"];
listeCps["MAISONS ALFORT"]= ["94046"];
listeCps["NOGENT SUR MARNE"]= ["94052"];
listeCps["ST MANDE"]= ["94067"];
listeCps["ST MAUR DES FOSSES"]= ["94068"];
listeCps["SUCY EN BRIE"]= ["94071"];
listeCps["THIAIS"]= ["94073"];
listeCps["VILLENEUVE LE ROI"]= ["94077"];
listeCps["VILLENEUVE ST GEORGES"]= ["94078"];
listeCps["VINCENNES"]= ["94080"];
listeCps["VITRY SUR SEINE"]= ["94081"];
listeCps["BRIGNOLES"]= ["83023"];
listeCps["DRAGUIGNAN"]= ["83050"];
listeCps["FREJUS"]= ["83061"];
listeCps["GASSIN"]= ["83065"];
listeCps["HYERES"]= ["83069"];
listeCps["LA GARDE"]= ["83062"];
listeCps["LA SEYNE SUR MER"]= ["83126"];
listeCps["LE MUY"]= ["83086"];
listeCps["LORGUES"]= ["83072"];
listeCps["OLLIOULES"]= ["83090"];
listeCps["ST MAXIMIN LA STE BAUME"]= ["83116"];
listeCps["ST RAPHAEL"]= ["83118"];
listeCps["TOULON"]= ["83137"];
listeCps["APT"]= ["84003"];
listeCps["AVIGNON"]= ["84007"];
listeCps["BOLLENE"]= ["84019"];
listeCps["CARPENTRAS"]= ["84031"];
listeCps["CAVAILLON"]= ["84035"];
listeCps["L ISLE SUR LA SORGUE"]= ["84054"];
listeCps["ORANGE"]= ["84087"];
listeCps["PERTUIS"]= ["84089"];
listeCps["CHALLANS"]= ["85047"];
listeCps["CHANTONNAY"]= ["85051"];
listeCps["FONTENAY LE COMTE"]= ["85092"];
listeCps["LA ROCHE SUR YON"]= ["85191"];
listeCps["LES HERBIERS"]= ["85109"];
listeCps["LES SABLES D OLONNE"]= ["85194"];
listeCps["LUCON"]= ["85128"];
listeCps["MONTAIGU"]= ["85146"];
listeCps["POUZAUGES"]= ["85182"];
listeCps["ST LAURENT SUR SEVRE"]= ["85238"];
listeCps["CHATELLERAULT"]= ["86066"];
listeCps["CIVRAY"]= ["86078"];
listeCps["JAUNAY CLAN"]= ["86115"];
listeCps["LOUDUN"]= ["86137"];
listeCps["MONTMORILLON"]= ["86165"];
listeCps["POITIERS"]= ["86194"];
listeCps["BRUYERES"]= ["88078"];
listeCps["EPINAL"]= ["88160"];
listeCps["GERARDMER"]= ["88196"];
listeCps["MIRECOURT"]= ["88304"];
listeCps["NEUFCHATEAU"]= ["88321"];
listeCps["REMIREMONT"]= ["88383"];
listeCps["SAINT DIE DES VOSGES"]= ["88413"];
listeCps["AUXERRE"]= ["89024"];
listeCps["AVALLON"]= ["89025"];
listeCps["JOIGNY"]= ["89206"];
listeCps["SENS"]= ["89387"];
listeCps["TONNERRE"]= ["89418"];
listeCps["TOUCY"]= ["89419"];
listeCps["ACHERES"]= ["78005"];
listeCps["AUBERGENVILLE"]= ["78029"];
listeCps["CARRIERES SUR SEINE"]= ["78124"];
listeCps["CONFLANS STE HONORINE"]= ["78172"];
listeCps["GUYANCOURT"]= ["78297"];
listeCps["LA CELLE ST CLOUD"]= ["78126"];
listeCps["LA QUEUE LES YVELINES"]= ["78513"];
listeCps["LE CHESNAY"]= ["78158"];
listeCps["LE VESINET"]= ["78650"];
listeCps["LES MUREAUX"]= ["78440"];
listeCps["LIMAY"]= ["78335"];
listeCps["MAGNANVILLE"]= ["78354"];
listeCps["MAISONS LAFFITTE"]= ["78358"];
listeCps["MANTES LA JOLIE"]= ["78361"];
listeCps["MARLY LE ROI"]= ["78372"];
listeCps["MAUREPAS"]= ["78383"];
listeCps["MONTIGNY LE BRETONNEUX"]= ["78423"];
listeCps["PLAISIR"]= ["78490"];
listeCps["POISSY"]= ["78498"];
listeCps["RAMBOUILLET"]= ["78517"];
listeCps["SARTROUVILLE"]= ["78586"];
listeCps["ST CYR L ECOLE"]= ["78545"];
listeCps["ST GERMAIN EN LAYE"]= ["78551"];
listeCps["TRAPPES"]= ["78621"];
listeCps["VERNEUIL SUR SEINE"]= ["78642"];
listeCps["VERSAILLES"]= ["78646"];
listeCps["VILLEPREUX"]= ["78674"];
listeCps["VILLIERS ST FREDERIC"]= ["78683"];

export default listeCps;