var listeLycees = [];
listeLycees["AMBERIEU EN BUGEY"] = ["LYCEE DE LA PLAINE DE L\'AIN"];
listeLycees["BELLEGARDE SUR VALSERINE"] = ["LYCEE SAINT-EXUPERY (GENERAL ET TECHNO.)"];
listeLycees["BELLEY"] = ["ENSEMBLE SCOLAIRE LAMARTINE", "LYCEE DU BUGEY"];
listeLycees["BELLIGNAT"] = ["LYCEE ARBEZ CARME"];
listeLycees["BOURG EN BRESSE"] = ["LYCEE EDGAR QUINET", "LYCEE JOSEPH-MARIE CARRIAT", "LYCEE LALANDE", "LYCEE SAINT PIERRE"];
listeLycees["CESSY"] = ["LYCEE JEANNE D\'ARC (GENERAL ET TECHNO.)"];
listeLycees["FERNEY VOLTAIRE"] = ["LYCEE DE FERNEY"];
listeLycees["LA BOISSE"] = ["LYCEE DE LA COTIERE"];
listeLycees["MIRIBEL"] = ["LYCEE SAINT JOSEPH"];
listeLycees["NANTUA"] = ["LYCEE XAVIER BICHAT (GENERAL ET TECHNO.)"];
listeLycees["OYONNAX"] = ["LYCEE PAUL PAINLEVE (GENERAL ET TECHNO.)"];
listeLycees["TREVOUX"] = ["LYCEE DU VAL DE SAONE"];
listeLycees["BOHAIN EN VERMANDOIS"] = ["LYCEE SAINTE SOPHIE"];
listeLycees["CHATEAU THIERRY"] = ["LYCEE JEAN DE LA FONTAINE", "LYCEE JULES VERNE", "LYCEE SAINT JOSEPH"];
listeLycees["CHAUNY"] = ["LYCEE GAY LUSSAC", "LYCEE JEAN MACE"];
listeLycees["FONTAINE LES VERVINS"] = ["LYCEE SAINT JOSEPH"];
listeLycees["HIRSON"] = ["LYCEE F. ET I. JOLIOT CURIE"];
listeLycees["LAON"] = ["LYCEE DE LA PROVIDENCE", "LYCEE PAUL CLAUDEL", "LYCEE PIERRE MECHAIN (GENERAL ET TECHNO.)"];
listeLycees["SOISSONS"] = ["LYCEE GERARD DE NERVAL", "LYCEE LE CORBUSIER", "LYCEE LEONARD DE VINCI", "LYCEE SAINT REMY", "LYCEE ST VINCENT DE PAUL"];
listeLycees["ST QUENTIN"] = ["LYCEE CONDORCET", "LYCEE HENRI MARTIN", "LYCEE JEAN BOUIN", "LYCEE PIERRE DE LA RAMEE", "LYCEE SAINT JEAN DE LA CROIX"];
listeLycees["VILLERS COTTERETS"] = ["LYCEE EUROPEEN"];
listeLycees["COMMENTRY"] = ["LYCEE GENEVIEVE VINCENT (GENERAL ET TECHNO.)"];
listeLycees["CUSSET"] = ["LYCEE ALBERT LONDRES", "LYCEE SAINT PIERRE", "LYCEE VALERY LARBAUD (GENERAL ET TECHNO.)"];
listeLycees["GANNAT"] = ["LYCEE SAINTE PROCULE"];
listeLycees["MONTLUCON"] = ["LYCEE MADAME DE STAEL", "LYCEE PAUL CONSTANS", "LYCEE SAINT JOSEPH"];
listeLycees["MOULINS"] = ["LYCEE SAINT BENOIT", "LYCEE THEODORE DE BANVILLE"];
listeLycees["ST POURCAIN SUR SIOULE"] = ["LYCEE BLAISE DE VIGENERE"];
listeLycees["YZEURE"] = ["LYCEE JEAN MONNET"];
listeLycees["BARCELONNETTE"] = ["LYCEE ANDRE HONNORAT (GENERAL ET TECHNO.)"];
listeLycees["DIGNE LES BAINS"] = ["LYCEE ALEXANDRA DAVID NEEL", "LYCEE DU SACRE COEUR", "LYCEE PIERRE-GILLES DE GENNES"];
listeLycees["MANOSQUE"] = ["LYCEE FELIX ESCLANGON", "LYCEE LES ISCLES (GENERAL ET TECHNO.)"];
listeLycees["SISTERON"] = ["LYCEE PAUL ARENE"];
listeLycees["ANTIBES"] = ["LYCEE DU GENIE CIVIL", "LYCEE DU MONT SAINT JEAN", "LYCEE JACQUES AUDIBERTI"];
listeLycees["CAGNES SUR MER"] = ["LYCEE AUGUSTE RENOIR"];
listeLycees["CANNES"] = ["LYCEE BRISTOL", "LYCEE CARNOT", "LYCEE JULES FERRY", "LYCEE LES FAUVETTES", "LYCEE SAINTE MARIE", "LYCEE STANISLAS"];
listeLycees["GRASSE"] = ["LYCEE ALEXIS DE TOCQUEVILLE", "LYCEE AMIRAL DE GRASSE", "LYCEE FENELON"];
listeLycees["MENTON"] = ["LYCEE PIERRE ET MARIE CURIE"];
listeLycees["NICE"] = ["LYCEE ALBERT CALMETTE", "LYCEE DE LA PROVIDENCE", "LYCEE DON BOSCO", "LYCEE DU PARC IMPERIAL", "LYCEE GUILLAUME APOLLINAIRE", "LYCEE H. D\'ESTIENNE D\'ORVES", "LYCEE HOTEL.TOUR.-PAUL AUGIER", "LYCEE LES EUCALYPTUS", "LYCEE MASSENA", "LYCEE SAINT JOSEPH (GENERAL ET TECHNO.)", "LYCEE SASSERNO", "LYCEE STANISLAS", "LYCEE THIERRY MAULNIER"];
listeLycees["ROQUEBRUNE CAP MARTIN"] = ["INSTITUTION ST JOSEPH CARNOLES (GENERAL ET TECHNO.)"];
listeLycees["VALBONNE"] = ["LYCEE INTERNATIONAL", "LYCEE SIMONE VEIL"];
listeLycees["VALDEBLORE"] = ["LYCEE DE LA MONTAGNE (GENERAL ET TECHNO.)"];
listeLycees["VENCE"] = ["LYCEE HENRI MATISSE"];
listeLycees["ANNONAY"] = ["LYCEE BOISSY D\'ANGLAS", "LYCEE MARC SEGUIN-ST CHARLES", "LYCEE SAINT DENIS"];
listeLycees["AUBENAS"] = ["LYCEE ASTIER (GENERAL ET TECHNO.)", "LYCEE JULES FROMENT", "LYCEE MARCEL GIMOND (GENERAL ET TECHNO.)"];
listeLycees["BOURG ST ANDEOL"] = ["LYCEE MARIE RIVIER"];
listeLycees["LE CHEYLARD"] = ["LYCEE LE CHEYLARD (GENERAL ET TECHNO.)"];
listeLycees["LE TEIL"] = ["LYCEE XAVIER MALLET (GENERAL ET TECHNO.)"];
listeLycees["PRIVAS"] = ["LYCEE DU SACRE COEUR", "LYCEE VINCENT D\'INDY (GENERAL ET TECHNO.)"];
listeLycees["TOURNON SUR RHONE"] = ["LYCEE DU SACRE COEUR-GENERAL", "LYCEE GABRIEL FAURE"];
listeLycees["BAZEILLES"] = ["LYCEE BAZEILLES"];
listeLycees["CHARLEVILLE MEZIERES"] = ["LYCEE CHANZY", "LYCEE FRANCOIS BAZIN", "LYCEE MONGE", "LYCEE SAINT PAUL (GENERAL ET TECHNO.)", "LYCEE SEVIGNE"];
listeLycees["GIVET"] = ["LYCEE VAUBAN"];
listeLycees["RETHEL"] = ["LYCEE PAUL VERLAINE"];
listeLycees["REVIN"] = ["LYCEE JEAN MOULIN"];
listeLycees["SEDAN"] = ["LYCEE MABILLON", "LYCEE PIERRE BAYLE"];
listeLycees["VOUZIERS"] = ["LYCEE THOMAS MASARYK"];
listeLycees["FOIX"] = ["LYCEE GABRIEL FAURE"];
listeLycees["MIREPOIX"] = ["LYCEE"];
listeLycees["PAMIERS"] = ["LYCEE DU CASTELLA", "LYCEE NOTRE-DAME"];
listeLycees["ST GIRONS"] = ["LYCEE DU COUSERANS"];
listeLycees["BAR SUR AUBE"] = ["LYCEE GASTON BACHELARD"];
listeLycees["ROMILLY SUR SEINE"] = ["LYCEE F. ET I. JOLIOT CURIE"];
listeLycees["STE SAVINE"] = ["LYCEE EDOUARD HERRIOT"];
listeLycees["TROYES"] = ["LYCEE CAMILLE CLAUDEL", "LYCEE CHRESTIEN DE TROYES", "LYCEE LES LOMBARDS", "LYCEE MARIE DE CHAMPAGNE", "LYCEE SAINT BERNARD", "LYCEE SAINT FRANCOIS DE SALES", "LYCEE SAINT JOSEPH"];
listeLycees["CARCASSONNE"] = ["LYCEE JULES FIL", "LYCEE PAUL SABATIER", "LYCEE SAINT STANISLAS (GENERAL ET TECHNO.)"];
listeLycees["CASTELNAUDARY"] = ["LYCEE JEAN DURAND (GENERAL ET TECHNO.)", "LYCEE LYCEE BTP FRANCOIS ANDREOSSY"];
listeLycees["LIMOUX"] = ["LYCEE NATIONALISE MIXTE (GENERAL ET TECHNO.)"];
listeLycees["NARBONNE"] = ["LYCEE BEAUSEJOUR (GENERAL ET TECHNO.)", "LYCEE DENIS DIDEROT", "LYCEE DOCTEUR LACROIX"];
listeLycees["DECAZEVILLE"] = ["LYCEE LA DECOUVERTE"];
listeLycees["ESPALION"] = ["LYCEE IMMACULEE CONCEPTION"];
listeLycees["MILLAU"] = ["LYCEE JEAN VIGO", "LYCEE JEANNE D\'ARC"];
listeLycees["RODEZ"] = ["LYCEE ALEXIS MONTEIL", "LYCEE FERDINAND FOCH", "LYCEE FRANCOIS D\'ESTAING", "LYCEE LOUIS QUERBES", "LYCEE SAINT JOSEPH"];
listeLycees["ST AFFRIQUE"] = ["LYCEE JEAN JAURES", "LYCEE SAINT GABRIEL"];
listeLycees["VILLEFRANCHE DE ROUERGUE"] = ["LYCEE RAYMOND SAVIGNAC", "LYCEE ST JOSEPH"];
listeLycees["BARR"] = ["LYCEE SCHURE"];
listeLycees["BISCHHEIM"] = ["LYCEE MARC BLOCH"];
listeLycees["BISCHWILLER"] = ["LYCEE ANDRE MAUROIS"];
listeLycees["BOUXWILLER"] = ["LYCEE PLACE DU CHATEAU"];
listeLycees["ERSTEIN"] = ["LYCEE MARGUERITE YOURCENAR (GENERAL ET TECHNO.)"];
listeLycees["HAGUENAU"] = ["INSTITUTION SAINTE PHILOMENE", "LYCEE ALPHONSE HEINRICH", "LYCEE ROBERT SCHUMAN"];
listeLycees["ILLKIRCH GRAFFENSTADEN"] = ["LYCEE ALEXANDRE DUMAS", "LYCEE LE CORBUSIER"];
listeLycees["MOLSHEIM"] = ["LYCEE HENRI MECK", "LYCEE LOUIS MARCHAL (GENERAL ET TECHNO.)"];
listeLycees["OBERNAI"] = ["LYCEE FREPPEL"];
listeLycees["SARRE UNION"] = ["LYCEE GEORGES IMBERT (GENERAL ET TECHNO.)"];
listeLycees["SAVERNE"] = ["LYCEE GENERAL LECLERC", "LYCEE HAUT-BARR"];
listeLycees["SELESTAT"] = ["LYCEE DOCTEUR KOEBERLE", "LYCEE JEAN-BAPTISTE SCHWILGUE (GENERAL ET TECHNO.)"];
listeLycees["STRASBOURG"] = ["COLLEGE EPISCOPAL ST ETIENNE", "ECOLE AQUIBA", "ECOLE COMMERCIALE", "INSTITUTION LA DOCTRINE CHRETIENNE", "INSTITUTION NOTRE-DAME", "INSTITUTION SAINTE CLOTILDE", "LYCEE FUSTEL DE COULANGES", "LYCEE INTERNATIONAL", "LYCEE JEAN MONNET", "LYCEE JEAN ROSTAND (GENERAL ET TECHNO.)", "LYCEE JEAN STURM", "LYCEE KLEBER", "LYCEE LOUIS COUFFIGNAL", "LYCEE LOUIS PASTEUR", "LYCEE MARCEL RUDLOFF (GENERAL ET TECHNO.)", "LYCEE MARIE CURIE", "LYCEE ORT", "LYCEE RENE CASSIN"];
listeLycees["WALBOURG"] = ["SEMINAIRE DE JEUNES"];
listeLycees["WISSEMBOURG"] = ["LYCEE STANISLAS"];
listeLycees["AIX EN PROVENCE"] = ["LYCEE CELONY", "LYCEE DE LA NATIVITE", "LYCEE DU SACRE COEUR", "LYCEE EMILE ZOLA", "LYCEE GEORGES DUBY", "LYCEE PAUL CEZANNE", "LYCEE SAINT ELOI", "LYCEE STE CATHERINE DE SIENNE", "LYCEE STE MARIE", "LYCEE VAUVENARGUES"];
listeLycees["ARLES"] = ["LYCEE MONTMAJOUR", "LYCEE PASQUET"];
listeLycees["AUBAGNE"] = ["LYCEE FREDERIC JOLIOT-CURIE", "LYCEE SAINTE MARIE"];
listeLycees["GARDANNE"] = ["LYCEE MARIE MADELEINE FOURCADE"];
listeLycees["GEMENOS"] = ["LYCEE SAINT JEAN DE GARGUIER"];
listeLycees["GIGNAC LA NERTHE"] = ["LYCEE SAINT LOUIS-SAINTE MARIE"];
listeLycees["ISTRES"] = ["LYCEE ARTHUR RIMBAUD"];
listeLycees["LA CIOTAT"] = ["LYCEE AUGUSTE ET LOUIS LUMIERE (GENERAL ET TECHNO.)", "LYCEE DE LA MEDITERRANEE (GENERAL ET TECHNO.)"];
listeLycees["MARIGNANE"] = ["LYCEE MAURICE GENEVOIX"];
listeLycees["MARSEILLE"] = ["LYCEE HOTELIER REGIONAL", "LYCEE DE PROVENCE", "LYCEE HONORE DAUMIER", "LYCEE MARSEILLEVEYRE", "LYCEE PERIER"];
listeLycees["MARSEILLE 01"] = ["LYCEE SAINT CHARLES", "LYCEE THIERS"];
listeLycees["MARSEILLE 03"] = ["LYCEE VICTOR HUGO"];
listeLycees["MARSEILLE 04"] = ["LYCEE CHEVREUL BLANCARDE", "LYCEE MICHELET", "LYCEE ST JOSEPH - LA MADELEINE"];
listeLycees["MARSEILLE 05"] = ["LYCEE MARIE CURIE", "LYCEE ST CHARLES CAMAS"];
listeLycees["MARSEILLE 06"] = ["COURS BASTIDE", "LYCEE AMI", "LYCEE CHARLES PEGUY", "LYCEE DON BOSCO", "LYCEE MAXIMILIEN DE SULLY", "LYCEE MONTGRAND", "LYCEE NOTRE-DAME DE FRANCE", "LYCEE NOTRE-DAME DE SION", "LYCEE SAINT THOMAS D\'AQUIN", "LYCEE ST JOSEPH LES MARISTES", "LYCEE ST VINCENT DE PAUL"];
listeLycees["MARSEILLE 07"] = ["LYCEE DU REMPART"];
listeLycees["MARSEILLE 08"] = ["LYCEE HOTELIER REGIONAL", "LYCEE DE PROVENCE", "LYCEE HONORE DAUMIER", "LYCEE MARSEILLEVEYRE", "LYCEE PERIER"];
listeLycees["MARSEILLE 09"] = ["LYCEE PASTRE - GRANDE BASTIDE", "LYCEE STE TRINITE"];
listeLycees["MARSEILLE 10"] = ["LYCEE JEAN PERRIN (GENERAL ET TECHNO.)", "LYCEE MARCEL PAGNOL", "LYCEE ORT LEON BRAMSON"];
listeLycees["MARSEILLE 11"] = ["LYCEE CHIMIE ET BIOLOGIE LA FORBINE", "LYCEE MELIZAN"];
listeLycees["MARSEILLE 12"] = ["LYCEE L\'OLIVIER - ROBERT COFFY", "LYCEE LA CADENELLE", "LYCEE MARIE GASQUET"];
listeLycees["MARSEILLE 13"] = ["LYCEE ANTONIN ARTAUD", "LYCEE DENIS DIDEROT (GENERAL ET TECHNO.)", "LYCEE LACORDAIRE", "LYCEE SEVIGNE", "LYCEE YAVNE"];
listeLycees["MARSEILLE 14"] = ["LYCEE DE LA TOUR SAINTE"];
listeLycees["MARSEILLE 15"] = ["LYCEE NOTRE DAME DE LA VISTE", "LYCEE SAINT EXUPERY"];
listeLycees["MARTIGUES"] = ["LYCEE JEAN LURCAT", "LYCEE PAUL LANGEVIN (GENERAL ET TECHNO.)"];
listeLycees["MIRAMAS"] = ["LYCEE JEAN COCTEAU"];
listeLycees["PORT ST LOUIS DU RHONE"] = ["LYCEE HENRI LEROY"];
listeLycees["SALON DE PROVENCE"] = ["LYCEE ADAM DE CRAPONNE", "LYCEE L\'EMPERI", "LYCEE SAINT JEAN", "LYCEE VIALA LACOSTE"];
listeLycees["TARASCON"] = ["LYCEE ALPHONSE DAUDET"];
listeLycees["VITROLLES"] = ["LYCEE CAUCADIS", "LYCEE JEAN MONNET (GENERAL ET TECHNO.)", "LYCEE PIERRE MENDES-FRANCE (GENERAL ET TECHNO.)"];
listeLycees["BAYEUX"] = ["INSTITUTION JEANNE D\'ARC", "LYCEE ALAIN CHARTIER", "LYCEE ARCISSE DE CAUMONT"];
listeLycees["CAEN"] = ["INSTITUT LEMONNIER", "INSTITUTION SAINTE MARIE", "LYCEE AUGUSTIN FRESNEL", "LYCEE CHARLES DE GAULLE", "LYCEE JEAN ROSTAND", "LYCEE JEANNE D\'ARC", "LYCEE JULES DUMONT D\'URVILLE (GENERAL ET TECHNO.)", "LYCEE MALHERBE", "LYCEE NOTRE-DAME DE FIDELITE", "LYCEE P. S. DE LAPLACE (GENERAL ET TECHNO.)", "LYCEE SAINTE URSULE", "LYCEE VICTOR HUGO"];
listeLycees["CONDE SUR NOIREAU"] = ["LYCEE CHARLES TELLIER (GENERAL ET TECHNO.)"];
listeLycees["DEAUVILLE"] = ["LYCEE ANDRE MAUROIS"];
listeLycees["DOUVRES LA DELIVRANDE"] = ["COURS NOTRE-DAME"];
listeLycees["FALAISE"] = ["LYCEE LOUIS LIARD (GENERAL ET TECHNO.)"];
listeLycees["HEROUVILLE ST CLAIR"] = ["CITE SCOLAIRE EXPERIMENTALE", "LYCEE FRANCOIS RABELAIS (GENERAL ET TECHNO.)", "LYCEE SALVADOR ALLENDE"];
listeLycees["HONFLEUR"] = ["LYCEE ALBERT SOREL (GENERAL ET TECHNO.)"];
listeLycees["LISIEUX"] = ["INSTITUTION FREMONT", "LYCEE LES ROSIERS", "LYCEE MARCEL GAMBIER", "LYCEE PAUL CORNU"];
listeLycees["MONDEVILLE"] = ["LYCEE JULES VERNE"];
listeLycees["TROUVILLE SUR MER"] = ["LYCEE MARIE JOSEPH"];
listeLycees["VIRE"] = ["INSTITUT SAINT JEAN EUDES", "LYCEE MARIE CURIE"];
listeLycees["AURILLAC"] = ["LYCEE DE LA COMMUNICATION ST GERAUD (GENERAL ET TECHNO.)", "LYCEE EMILE DUCLAUX", "LYCEE JEAN MONNET", "LYCEE SAINT EUGENE - SAINT JOSEPH (GENERAL ET TECHNO.)"];
listeLycees["MAURIAC"] = ["LYCEE"];
listeLycees["ST FLOUR"] = ["LYCEE DE HAUTE AUVERGNE (GENERAL ET TECHNO.)", "LYCEE LA PRESENTATION N-DAME"];
listeLycees["ANGOULEME"] = ["LYCEE CHARLES A COULOMB", "LYCEE DE L\'IMAGE ET DU SON", "LYCEE GUEZ DE BALZAC", "LYCEE MARGUERITE DE VALOIS", "LYCEE SAINT PAUL", "LYCEE SAINTE MARTHE CHAVAGNES"];
listeLycees["BARBEZIEUX ST HILAIRE"] = ["LYCEE ELIE VINET"];
listeLycees["COGNAC"] = ["LYCEE DE BEAULIEU", "LYCEE JEAN MONNET (GENERAL ET TECHNO.)"];
listeLycees["CONFOLENS"] = ["LYCEE EMILE ROUX (GENERAL ET TECHNO.)"];
listeLycees["BOURCEFRANC LE CHAPUS"] = ["LYCEE DE LA MER ET DU LITTORAL"];
listeLycees["JONZAC"] = ["LYCEE JEAN HYPPOLITE (GENERAL ET TECHNO.)"];
listeLycees["LA ROCHELLE"] = ["LYCEE FENELON NOTRE-DAME (GENERAL ET TECHNO.)", "LYCEE HOTELIER", "LYCEE JEAN DAUTET", "LYCEE LEONCE VIELJEUX", "LYCEE RENE JOSUE VALIN", "LYCEE SAINT EXUPERY"];
listeLycees["PONS"] = ["LYCEE EMILE COMBES (GENERAL ET TECHNO.)"];
listeLycees["PONT L ABBE D ARNOULT"] = ["LYCEE SAINT LOUIS"];
listeLycees["ROCHEFORT"] = ["LYCEE MARCEL DASSAULT", "LYCEE MAURICE MERLEAU-PONTY"];
listeLycees["ROYAN"] = ["LYCEE CORDOUAN"];
listeLycees["SAINTES"] = ["LYCEE BELLEVUE", "LYCEE BERNARD PALISSY", "LYCEE N-D DE RECOUVRANCE"];
listeLycees["ST GEORGES D OLERON"] = ["LYCEE EXPERIMENTAL"];
listeLycees["ST JEAN D ANGELY"] = ["LYCEE LOUIS AUDOUIN DUBREUIL (GENERAL ET TECHNO.)"];
listeLycees["BOURGES"] = ["LYCEE ALAIN FOURNIER", "LYCEE JACQUES COEUR", "LYCEE MARGUERITE DE NAVARRE", "LYCEE PIERRE-EMILE MARTIN", "LYCEE STE MARIE-ST DOMINIQUE"];
listeLycees["ST AMAND MONTROND"] = ["LYCEE JEAN MOULIN"];
listeLycees["VIERZON"] = ["LYCEE EDOUARD VAILLANT", "LYCEE HENRI BRISSON"];
listeLycees["BRIVE LA GAILLARDE"] = ["LYCEE BOSSUET", "LYCEE D\'ARSONVAL", "LYCEE DANTON", "LYCEE GEORGES CABANIS", "LYCEE MARGUERITE BAHUET (GENERAL ET TECHNO.)"];
listeLycees["EGLETONS"] = ["LYCEE PIERRE CARAMINOT"];
listeLycees["TULLE"] = ["LYCEE EDMOND PERRIER"];
listeLycees["USSEL"] = ["LYCEE BERNART DE VENTADOUR"];
listeLycees["AJACCIO"] = ["INSTITUTION SAINT PAUL", "LYCEE FESCH", "LYCEE LAETITIA BONAPARTE"];
listeLycees["PORTO VECCHIO"] = ["LYCEE JEAN PAUL DE ROCCA SERRA (GENERAL ET TECHNO.)"];
listeLycees["SARTENE"] = ["LYCEE GEORGES CLEMENCEAU (GENERAL ET TECHNO.)"];
listeLycees["AUXONNE"] = ["LYCEE PRIEUR DE LA COTE D\'OR (GENERAL ET TECHNO.)"];
listeLycees["BEAUNE"] = ["CLOS MAIRE (GENERAL ET TECHNO.)", "LYCEE DU SAINT COEUR", "LYCEE E.J. MAREY (GENERAL ET TECHNO.)"];
listeLycees["BROCHON"] = ["LYCEE STEPHEN LIEGEARD"];
listeLycees["CHATILLON SUR SEINE"] = ["LYCEE DESIRE NISARD (GENERAL ET TECHNO.)"];
listeLycees["CHEVIGNY ST SAUVEUR"] = ["LYCEE JEAN-MARC BOIVIN"];
listeLycees["DIJON"] = ["LYCEE CARNOT", "LYCEE CHARLES DE GAULLE", "LYCEE GUSTAVE EIFFEL", "LYCEE HIPPOLYTE FONTAINE", "LYCEE LE CASTEL (GENERAL ET TECHNO.)", "LYCEE LES ARCADES (GENERAL ET TECHNO.)", "LYCEE LES MARCS D\'OR", "LYCEE MONTCHAPET", "LYCEE NOTRE-DAME", "LYCEE SAINT BENIGNE (GENERAL ET TECHNO.)", "LYCEE SAINT JOSEPH", "LYCEE SIMONE WEIL (GENERAL ET TECHNO.)"];
listeLycees["SEMUR EN AUXOIS"] = ["LYCEE POLYVALENT REGIONAL (GENERAL ET TECHNO.)"];
listeLycees["DINAN"] = ["LYCEE CORDELIERS-N-D DE LA VICTOIRE", "LYCEE DE LA FONTAINE DES EAUX"];
listeLycees["GUINGAMP"] = ["LYCEE AUGUSTE PAVIE"];
listeLycees["LAMBALLE"] = ["LYCEE HENRI AVRIL", "LYCEE SAINT JOSEPH"];
listeLycees["LANNION"] = ["LYCEE FELIX LE DANTEC", "LYCEE SAINT JOSEPH - BOSSUET"];
listeLycees["LOUDEAC"] = ["LYCEE FULGENCE BIENVENUE (GENERAL ET TECHNO.)", "LYCEE SAINT JOSEPH"];
listeLycees["PAIMPOL"] = ["LYCEE KERRAOUL"];
listeLycees["QUINTIN"] = ["LYCEE JEAN XXIII"];
listeLycees["ROSTRENEN"] = ["LYCEE NOTRE-DAME CAMPOSTAL"];
listeLycees["ST BRIEUC"] = ["LYCEE CHAPTAL", "LYCEE DU SACRE COEUR", "LYCEE ERNEST RENAN", "LYCEE EUGENE FREYSSINET", "LYCEE FRANCOIS RABELAIS", "LYCEE SAINT CHARLES", "LYCEE SAINT PIERRE"];
listeLycees["TREGUIER"] = ["LYCEE JOSEPH SAVINA"];
listeLycees["AUBUSSON"] = ["LYCEE EUGENE JAMOT"];
listeLycees["FELLETIN"] = ["LYCEE DES METIERS DU BATIMENT"];
listeLycees["GUERET"] = ["LYCEE JEAN FAVARD (GENERAL ET TECHNO.)", "LYCEE PIERRE BOURDAN"];
listeLycees["LA SOUTERRAINE"] = ["LYCEE RAYMOND LOEWY"];
listeLycees["BRESSUIRE"] = ["LYCEE MAURICE GENEVOIX", "LYCEE SAINT JOSEPH (GENERAL ET TECHNO.)"];
listeLycees["MELLE"] = ["LYCEE JOSEPH DESFONTAINES"];
listeLycees["NIORT"] = ["LYCEE JEAN MACE", "LYCEE PAUL GUERIN", "LYCEE SAINT ANDRE (GENERAL ET TECHNO.)", "LYCEE VENISE VERTE"];
listeLycees["PARTHENAY"] = ["LYCEE ERNEST PEROCHON"];
listeLycees["ST MAIXENT L ECOLE"] = ["LYCEE DU HAUT VAL DE SEVRE"];
listeLycees["THOUARS"] = ["CITE SCOLAIRE JEAN MOULIN", "LYCEE SAINT CHARLES"];
listeLycees["BERGERAC"] = ["LYCEE MAINE DE BIRAN", "LYCEE STE MARTHE - ST FRONT"];
listeLycees["EXCIDEUIL"] = ["LYCEE GIRAUT DE BORNEIL"];
listeLycees["NONTRON"] = ["LYCEE ALCIDE DUSOLIER (GENERAL ET TECHNO.)"];
listeLycees["PERIGUEUX"] = ["LYCEE ALBERT CLAVEILLE", "LYCEE BERTRAN DE BORN", "LYCEE JAY DE BEAUFORT", "LYCEE LAURE GATET", "LYCEE SAINT JOSEPH"];
listeLycees["RIBERAC"] = ["LYCEE ARNAUD DANIEL"];
listeLycees["SARLAT LA CANEDA"] = ["LYCEE PRE DE CORDY"];
listeLycees["TERRASSON LAVILLEDIEU"] = ["LYCEE ANTOINE DE ST EXUPERY (GENERAL ET TECHNO.)"];
listeLycees["BESANCON"] = ["LYCEE CLAUDE NICOLAS LEDOUX (GENERAL ET TECHNO.)", "LYCEE JULES HAAG", "LYCEE LOUIS PASTEUR", "LYCEE LOUIS PERGAUD", "LYCEE PIERRE-ADRIEN PARIS", "LYCEE SAINT JEAN", "LYCEE SAINT PAUL", "LYCEE VICTOR HUGO"];
listeLycees["MONTBELIARD"] = ["LYCEE GEORGES CUVIER", "LYCEE LE GRAND CHENOIS", "LYCEE VIETTE"];
listeLycees["MORTEAU"] = ["LYCEE EDGAR FAURE"];
listeLycees["PONTARLIER"] = ["LYCEE LES AUGUSTINS", "LYCEE XAVIER MARMIER (GENERAL ET TECHNO.)"];
listeLycees["VALENTIGNEY"] = ["LYCEE ARMAND PEUGEOT"];
listeLycees["BOURG LES VALENCE"] = ["LYCEE LES TROIS SOURCES"];
listeLycees["CHATEAUNEUF DE GALAURE"] = ["LYCEE DE JEUNES FILLES", "LYCEE SAINT BONNET"];
listeLycees["CREST"] = ["LYCEE FRANCOIS JEAN ARMORIN (GENERAL ET TECHNO.)", "LYCEE SAINT LOUIS"];
listeLycees["DIE"] = ["LYCEE DU DIOIS"];
listeLycees["MONTELIMAR"] = ["LYCEE ALAIN BORNE", "LYCEE CHABRILLAN", "LYCEE LES CATALINS"];
listeLycees["NYONS"] = ["LYCEE ROUMANILLE"];
listeLycees["PIERRELATTE"] = ["LYCEE DU DOCTEUR GUSTAVE JAUME (GENERAL ET TECHNO.)"];
listeLycees["ROMANS SUR ISERE"] = ["LYCEE ALBERT TRIBOULET", "LYCEE DU DAUPHINE", "LYCEE SAINT MAURICE"];
listeLycees["ST VALLIER"] = ["LYCEE HENRI LAURENS"];
listeLycees["VALENCE"] = ["LYCEE BARTHELEMY DE LAFFEMAS", "LYCEE CAMILLE VERNET", "LYCEE EMILE LOUBET", "LYCEE JULES ALGOUD", "LYCEE MONTPLAISIR", "LYCEE NOTRE-DAME", "LYCEE SAINT VICTOR"];
listeLycees["ARPAJON"] = ["LYCEE EDMOND MICHELET", "LYCEE RENE CASSIN"];
listeLycees["ATHIS MONS"] = ["LYCEE CLEMENT ADER (GENERAL ET TECHNO.)", "LYCEE MARCEL PAGNOL", "LYCEE SAINT CHARLES"];
listeLycees["BONDOUFLE"] = ["LYCEE FRANCOIS TRUFFAUT (GENERAL ET TECHNO.)"];
listeLycees["BRETIGNY SUR ORGE"] = ["LYCEE JEAN-PIERRE TIMBAUD (GENERAL ET TECHNO.)"];
listeLycees["BRUNOY"] = ["LYCEE FRANCOIS-JOSEPH TALMA", "LYCEE SAINT PIERRE-GENERAL"];
listeLycees["CORBEIL ESSONNES"] = ["LYCEE ROBERT DOISNEAU", "LYCEE SAINT LEON (GENERAL ET TECHNO.)"];
listeLycees["COURCOURONNES"] = ["LYCEE GEORGES BRASSENS"];
listeLycees["DOURDAN"] = ["LYCEE ALFRED KASTLER (GENERAL ET TECHNO.)", "LYCEE FRANCISQUE SARCEY (GENERAL ET TECHNO.)"];
listeLycees["DRAVEIL"] = ["LYCEE SENART"];
listeLycees["EPINAY SOUS SENART"] = ["LYCEE MAURICE ELIOT"];
listeLycees["ETAMPES"] = ["LYCEE GEOFFROY - SAINT HILAIRE (GENERAL ET TECHNO.)", "LYCEE JEANNE D ARC"];
listeLycees["EVRY"] = ["LYCEE DU PARC DES LOGES", "LYCEE NOTRE DAME DE SION (GENERAL ET TECHNO.)"];
listeLycees["GIF SUR YVETTE"] = ["LYCEE VALLEE DE CHEVREUSE"];
listeLycees["LA VILLE DU BOIS"] = ["LYCEE SACRE COEUR"];
listeLycees["LES ULIS"] = ["LYCEE L ESSOURIAU (GENERAL ET TECHNO.)"];
listeLycees["LIMOURS"] = ["LYCEE JULES VERNE"];
listeLycees["LONGJUMEAU"] = ["LYCEE JACQUES PREVERT"];
listeLycees["MASSY"] = ["LYCEE FUSTEL DE COULANGES", "LYCEE PARC DE VILGENIS (GENERAL ET TECHNO.)"];
listeLycees["MENNECY"] = ["LYCEE MARIE LAURENCIN (GENERAL ET TECHNO.)"];
listeLycees["MONTGERON"] = ["LYCEE DE MONTGERON"];
listeLycees["MORANGIS"] = ["LYCEE MARGUERITE YOURCENAR (GENERAL ET TECHNO.)"];
listeLycees["ORSAY"] = ["LYCEE BLAISE PASCAL", "LYCEE DU COURS SECOND. D\'ORSAY"];
listeLycees["PALAISEAU"] = ["LYCEE CAMILLE CLAUDEL", "LYCEE HENRI POINCARE", "LYCEE SAINTE JEANNE D\'ARC"];
listeLycees["SAVIGNY SUR ORGE"] = ["LYCEE GASPARD MONGE", "LYCEE JEAN-BAPTISTE COROT"];
listeLycees["ST MICHEL SUR ORGE"] = ["LYCEE LEONARD DE VINCI (GENERAL ET TECHNO.)"];
listeLycees["STE GENEVIEVE DES BOIS"] = ["LYCEE ALBERT EINSTEIN"];
listeLycees["VILLEBON SUR YVETTE"] = ["LYCEE DE L\'ILE DE FRANCE"];
listeLycees["VIRY CHATILLON"] = ["LYCEE ST LOUIS-ST CLEMENT"];
listeLycees["BERNAY"] = ["LYCEE AUGUSTIN FRESNEL", "LYCEE SAINT ANSELME"];
listeLycees["EVREUX"] = ["LYCEE ARISTIDE BRIAND", "LYCEE MODESTE LEROY", "LYCEE POLYVALENT GENERAL", "LYCEE SAINT FRANCOIS DE SALES"];
listeLycees["GAILLON"] = ["LYCEE ANDRE MALRAUX"];
listeLycees["GISORS"] = ["LYCEE LOUISE MICHEL"];
listeLycees["LES ANDELYS"] = ["LYCEE JEAN MOULIN (GENERAL ET TECHNO.)"];
listeLycees["LOUVIERS"] = ["LYCEE JEAN-BAPT. DECRETOT", "LYCEE LES FONTENELLES"];
listeLycees["PONT AUDEMER"] = ["LYCEE JACQUES PREVERT"];
listeLycees["ST GERMAIN VILLAGE"] = ["LYCEE SAINT OUEN"];
listeLycees["VAL DE REUIL"] = ["LYCEE MARC BLOCH"];
listeLycees["VERNEUIL SUR AVRE"] = ["LYCEE DES ROCHES", "LYCEE PORTE DE NORMANDIE"];
listeLycees["VERNON"] = ["LYCEE GEORGES DUMEZIL", "LYCEE SAINT ADJUTOR"];
listeLycees["CHARTRES"] = ["LYCEE FULBERT", "LYCEE JEHAN DE BEAUCE", "LYCEE MARCEAU", "LYCEE NOTRE-DAME"];
listeLycees["CHATEAUDUN"] = ["LYCEE EMILE ZOLA"];
listeLycees["DREUX"] = ["LYCEE EDOUARD BRANLY", "LYCEE ROTROU", "LYCEE SAINT PIERRE-SAINT PAUL"];
listeLycees["LUISANT"] = ["LYCEE SILVIA MONFORT"];
listeLycees["NOGENT LE ROTROU"] = ["LYCEE REMI BELLEAU"];
listeLycees["BREST"] = ["LYCEE AMIRAL RONARC\'H", "LYCEE BREST - RIVE DROITE - JAVOUHEY", "LYCEE DE L\'HARTELOIRE", "LYCEE DE L\'IROISE", "LYCEE DE LA CROIX ROUGE", "LYCEE DUPUY DE LOME (GENERAL ET TECHNO.)", "LYCEE ESTRAN CHARLES DE FOUCAULD", "LYCEE ESTRAN FENELON", "LYCEE JULES LESVEN", "LYCEE KERICHEN", "LYCEE SAINTE ANNE", "LYCEE VAUBAN (GENERAL ET TECHNO.)"];
listeLycees["CARHAIX PLOUGUER"] = ["LYCEE DIWAN", "LYCEE PAUL SERUSIER (GENERAL ET TECHNO.)"];
listeLycees["CHATEAULIN"] = ["LYCEE JEAN MOULIN", "LYCEE SAINT LOUIS"];
listeLycees["CONCARNEAU"] = ["LYCEE PIERRE GUEGUIN", "LYCEE SAINT JOSEPH"];
listeLycees["DOUARNENEZ"] = ["LYCEE JEAN-MARIE LE BRIS", "LYCEE SAINTE ELISABETH"];
listeLycees["LANDERNEAU"] = ["LYCEE DE L\'ELORN", "LYCEE SAINT SEBASTIEN"];
listeLycees["LANDIVISIAU"] = ["LYCEE DU LEON", "LYCEE SAINT ESPRIT"];
listeLycees["LESNEVEN"] = ["LYCEE SAINT FRANCOIS"];
listeLycees["MORLAIX"] = ["LYCEE NOTRE-DAME DU MUR", "LYCEE TRISTAN CORBIERE"];
listeLycees["PONT L ABBE"] = ["LYCEE RENE LAENNEC"];
listeLycees["QUIMPER"] = ["LYCEE AUGUSTE BRIZEUX", "LYCEE DE CORNOUAILLE", "LYCEE JEAN CHAPTAL", "LYCEE LE LIKES", "LYCEE LE PARACLET", "LYCEE SAINTE THERESE", "LYCEE YVES THEPOT"];
listeLycees["QUIMPERLE"] = ["LYCEE DE KERNEUZEC", "LYCEE N-D DE KERBERTRAND"];
listeLycees["ST POL DE LEON"] = ["LYCEE N-D DU KREISKER"];
listeLycees["ALES"] = ["LYCEE BELLEVUE", "LYCEE DE LA SALLE", "LYCEE JEAN-BAPTISTE DUMAS (GENERAL ET TECHNO.)"];
listeLycees["BAGNOLS SUR CEZE"] = ["LYCEE ALBERT EINSTEIN (GENERAL ET TECHNO.)"];
listeLycees["LE VIGAN"] = ["LYCEE ANDRE CHAMSON (GENERAL ET TECHNO.)"];
listeLycees["MILHAUD"] = ["LYCEE GENEVIEVE DE GAULLE-ANTHONIOZ (GENERAL ET TECHNO.)"];
listeLycees["NIMES"] = ["LYCEE ALBERT CAMUS", "LYCEE ALPHONSE DAUDET", "LYCEE DHUODA", "LYCEE EMMANUEL D\'ALZON (GENERAL ET TECHNO.)", "LYCEE ERNEST HEMINGWAY (GENERAL ET TECHNO.)", "LYCEE PHILIPPE LAMOUR", "LYCEE SAINT STANISLAS - SACRE COEUR", "LYCEE SAINT VINCENT DE PAUL (GENERAL ET TECHNO.)"];
listeLycees["ST CHRISTOL LES ALES"] = ["LYCEE JACQUES PREVERT (GENERAL ET TECHNO.)"];
listeLycees["UZES"] = ["LYCEE CHARLES GIDE"];
listeLycees["VILLENEUVE LES AVIGNON"] = ["LYCEE JEAN VILAR"];
listeLycees["AUCH"] = ["LYCEE LE GARROS", "LYCEE PARDAILHAN", "ORATOIRE SAINTE MARIE"];
listeLycees["CONDOM"] = ["LYCEE BOSSUET"];
listeLycees["L ISLE JOURDAIN"] = ["LYCEE JOSEPH SAVERNE"];
listeLycees["LECTOURE"] = ["LYCEE MARECHAL LANNES (GENERAL ET TECHNO.)", "LYCEE SAINT JOSEPH"];
listeLycees["MIRANDE"] = ["LYCEE ALAIN FOURNIER (GENERAL ET TECHNO.)"];
listeLycees["NOGARO"] = ["LYCEE D\'ARTAGNAN (GENERAL ET TECHNO.)"];
listeLycees["ANDERNOS LES BAINS"] = ["LYCEE NORD BASSIN"];
listeLycees["ARCACHON"] = ["LYCEE GRAND AIR", "LYCEE SAINT ELME"];
listeLycees["BAZAS"] = ["LYCEE ANATOLE DE MONZIE"];
listeLycees["BLANQUEFORT"] = ["LYCEE", "LYCEE JEAN MONNET"];
listeLycees["BLAYE"] = ["LYCEE JAUFRE RUDEL"];
listeLycees["BORDEAUX"] = ["LYCEE ALBERT LE GRAND", "LYCEE BEL ORME", "LYCEE CAMILLE JULLIAN", "LYCEE DE L\'ASSOMPTION", "LYCEE DU MIRAIL", "LYCEE FRANCOIS MAGENDIE", "LYCEE FRANCOIS MAURIAC", "LYCEE GUSTAVE EIFFEL (GENERAL ET TECHNO.)", "LYCEE JEAN CONDORCET", "LYCEE MICHEL MONTAIGNE", "LYCEE MONTESQUIEU", "LYCEE NICOLAS BREMONTIER", "LYCEE NOTRE-DAME", "LYCEE SAINT GENES", "LYCEE SAINT JOSEPH DE TIVOLI", "LYCEE SAINT LOUIS", "LYCEE SAINT VINCENT DE PAUL", "LYCEE STE FAMILLE SAINTONGE", "LYCEE STE MARIE BASTIDE", "LYCEE STE MARIE GRAND LEBRUN"];
listeLycees["GRADIGNAN"] = ["LYCEE DES GRAVES"];
listeLycees["GUJAN MESTRAS"] = ["LYCEE DE LA MER"];
listeLycees["LA BREDE"] = ["LYCEE LA SAUQUE"];
listeLycees["LA REOLE"] = ["LYCEE JEAN RENOU (GENERAL ET TECHNO.)"];
listeLycees["LANGON"] = ["LYCEE JEAN MOULIN"];
listeLycees["LE TAILLAN MEDOC"] = ["LYCEE DU SUD MEDOC"];
listeLycees["LIBOURNE"] = ["LYCEE INDUST.ET HOTEL.J.MONNET", "LYCEE MAX LINDER", "LYCEE MONTESQUIEU"];
listeLycees["LORMONT"] = ["LYCEE ELIE FAURE", "LYCEE LES IRIS (GENERAL ET TECHNO.)"];
listeLycees["MERIGNAC"] = ["LYCEE FERNAND DAGUIN"];
listeLycees["PAUILLAC"] = ["LYCEE ODILON REDON"];
listeLycees["PESSAC"] = ["LYCEE PAPE CLEMENT"];
listeLycees["ST ANDRE DE CUBZAC"] = ["LYCEE PHILIPPE COUSTEAU"];
listeLycees["STE FOY LA GRANDE"] = ["LYCEE RECLUS"];
listeLycees["TALENCE"] = ["LYCEE ALFRED KASTLER (GENERAL ET TECHNO.)", "LYCEE HOTEL.TOURISME GASCOGNE", "LYCEE VICTOR LOUIS (GENERAL ET TECHNO.)"];
listeLycees["BAIE MAHAULT"] = ["LYCEE CHARLES COEFFIN (GENERAL ET TECHNO.)"];
listeLycees["BASSE TERRE"] = ["LYCEE GERVILLE REACHE", "LYCEE RAOUL GEORGES NICOLO (GENERAL ET TECHNO.)", "PENSIONNAT DE VERSAILLES-GENERAL ET TECHNO."];
listeLycees["CAPESTERRE BELLE EAU"] = ["LYCEE PAUL LACAVE"];
listeLycees["GRAND BOURG"] = ["LYCEE YACINTHE BASTARAUD (GENERAL ET TECHNO.)"];
listeLycees["LE GOSIER"] = ["LYCEE HOTELIER DU GOSIER (GENERAL ET TECHNO.)"];
listeLycees["LES ABYMES"] = ["LYCEE BAIMBRIDGE", "LYCEE BAIMBRIDGE 2 (EX CARAIBES) (GENERAL ET TECHNO.)", "LYCEE DE LA PROVIDENCE", "LYCEE JARDIN D\'ESSAI"];
listeLycees["MORNE A L EAU"] = ["LYCEE FAUSTIN FLERET"];
listeLycees["PETIT BOURG"] = ["LYCEE DES DROITS DE L\'HOMME"];
listeLycees["POINTE A PITRE"] = ["LYCEE DE LA PERSEVERANCE", "LYCEE MAITRISE DE MASSABIELLE"];
listeLycees["POINTE NOIRE"] = ["LYCEE POINTE NOIRE (GENERAL ET TECHNO.)"];
listeLycees["PORT LOUIS"] = ["LYCEE NORD GRANDE TERRE (GENERAL ET TECHNO.)"];
listeLycees["ST MARTIN"] = ["LYCEE ILES DU NORD (GENERAL ET TECHNO.)"];
listeLycees["STE ANNE"] = ["LYCEE SAINTE ANNE"];
listeLycees["STE ROSE"] = ["LYCEE NORD BASSE TERRE"];
listeLycees["CAYENNE"] = ["EXTERNAT SAINT JOSEPH", "LYCEE A.M.JAVOUHEY CAYENNE (GENERAL ET TECHNO.)", "LYCEE FELIX EBOUE", "LYCEE MELKIOR GARRE (GENERAL ET TECHNO.)"];
listeLycees["KOUROU"] = ["LYCEE GASTON MONNERVILLE"];
listeLycees["REMIRE MONTJOLY"] = ["LYCEE LEON-GONTRAN DAMAS"];
listeLycees["ST LAURENT DU MARONI"] = ["LYCEE BERTENE JUMINER (GENERAL ET TECHNO.)", "LYCEE LUMINA SOPHIE (GENERAL ET TECHNO.)"];
listeLycees["ALTKIRCH"] = ["LYCEE JEAN-JACQUES HENNER"];
listeLycees["CARSPACH"] = ["INSTITUT SONNENBERG"];
listeLycees["CERNAY"] = ["LYCEE GUSTAVE EIFFEL"];
listeLycees["COLMAR"] = ["LYCEE BARTHOLDI", "LYCEE BLAISE PASCAL (GENERAL ET TECHNO.)", "LYCEE CAMILLE SEE", "LYCEE SAINT ANDRE"];
listeLycees["GUEBWILLER"] = ["LYCEE ALFRED KASTLER", "LYCEE HOTELIER JOSEPH STORCK", "LYCEE THEODORE DECK (GENERAL ET TECHNO.)"];
listeLycees["LANDSER"] = ["LYCEE DON BOSCO"];
listeLycees["MULHOUSE"] = ["LYCEE ALBERT SCHWEITZER", "LYCEE JEANNE D\'ARC", "LYCEE LAMBERT", "LYCEE LAVOISIER (GENERAL ET TECHNO.)", "LYCEE LOUIS ARMAND", "LYCEE MICHEL DE MONTAIGNE", "LYCEE SAINT JOSEPH DE CLUNY"];
listeLycees["MUNSTER"] = ["LYCEE FREDERIC KIRSCHLEGER"];
listeLycees["RIBEAUVILLE"] = ["LYCEE RIBEAUPIERRE"];
listeLycees["ST LOUIS"] = ["LYCEE JEAN MERMOZ (GENERAL ET TECHNO.)"];
listeLycees["ST LOUIS"] = ["LYCEE ANTOINE ROUSSIN", "LYCEE JEAN JOLY"];
listeLycees["STE MARIE AUX MINES"] = ["LYCEE LOUISE WEISS (GENERAL ET TECHNO.)"];
listeLycees["THANN"] = ["LYCEE SCHEURER KESTNER"];
listeLycees["WITTELSHEIM"] = ["LYCEE AMELIE ZURCHER (GENERAL ET TECHNO.)"];
listeLycees["WITTENHEIM"] = ["INSTITUTION DON BOSCO"];
listeLycees["ZILLISHEIM"] = ["COLLEGE EPISCOPAL"];
listeLycees["BASTIA"] = ["LYCEE GIOCANTE DE CASABIANCA", "LYCEE PAUL VINCENSINI", "PENSIONNAT JEANNE D\'ARC"];
listeLycees["CORTE"] = ["LYCEE PASCAL PAOLI"];
listeLycees["L ILE ROUSSE"] = ["LYCEE DE BALAGNE (GENERAL ET TECHNO.)"];
listeLycees["PRUNELLI DI FIUMORBO"] = ["LYCEE DU FIUM\'ORBU (GENERAL ET TECHNO.)"];
listeLycees["BAGNERES DE LUCHON"] = ["LYCEE EDMOND ROSTAND"];
listeLycees["BLAGNAC"] = ["LYCEE LE FERRADOU", "LYCEE SAINT-EXUPERY"];
listeLycees["COLOMIERS"] = ["LYCEE INTERNAT. VICTOR HUGO", "LYCEE ORT MAURICE GRYNFOGEL"];
listeLycees["CUGNAUX"] = ["LYCEE HENRI MATISSE"];
listeLycees["FONSORBES"] = ["LYCEE CL?MENCE ROYER"];
listeLycees["FRONTON"] = ["LYCEE PIERRE BOURDIEU"];
listeLycees["GOURDAN POLIGNAN"] = ["LYCEE PAUL MATHOU"];
listeLycees["MURET"] = ["LYCEE CHARLES DE GAULLE", "LYCEE PIERRE D\'ARAGON"];
listeLycees["PINS JUSTARET"] = ["LYCEE JEAN-PIERRE VERNANT"];
listeLycees["REVEL"] = ["LYCEE VINCENT AURIOL"];
listeLycees["SEILH"] = ["LYCEE DE L\'ANNONCIATION"];
listeLycees["ST GAUDENS"] = ["LYCEE DE BAGATELLE", "LYCEE SAINTE THERESE (GENERAL ET TECHNO.)"];
listeLycees["ST ORENS DE GAMEVILLE"] = ["LYCEE PIERRE-PAUL RIQUET"];
listeLycees["TOULOUSE"] = ["LYCEE BELLEVUE (GENERAL ET TECHNO.)", "LYCEE DEODAT DE SEVERAC", "LYCEE DES ARENES", "LYCEE EMILIE DE RODAT (GENERAL ET TECHNO.)", "LYCEE HOTELLERIE ET TOURISME", "LYCEE JOLIMONT", "LYCEE LE CAOUSOU", "LYCEE MARCELIN BERTHELOT", "LYCEE MONTALEMBERT NOTRE-DAME (GENERAL ET TECHNO.)", "LYCEE OZAR HATORAH", "LYCEE OZENNE", "LYCEE PIERRE DE FERMAT", "LYCEE RAYMOND NAVES (GENERAL ET TECHNO.)", "LYCEE RIVE GAUCHE", "LYCEE SAINT JOSEPH", "LYCEE SAINT SERNIN", "LYCEE SAINTE MARIE DE NEVERS", "LYCEE SAINTE MARIE DES CHAMPS", "LYCEE TOULOUSE-LAUTREC"];
listeLycees["BRIOUDE"] = ["LYCEE LA FAYETTE", "LYCEE SAINT JULIEN (GENERAL ET TECHNO.)"];
listeLycees["BRIVES CHARENSAC"] = ["LYCEE LA CHARTREUSE PARADIS"];
listeLycees["LE CHAMBON SUR LIGNON"] = ["LYCEE CEVENOL"];
listeLycees["LE PUY EN VELAY"] = ["LYCEE CHARLES ET ADRIEN DUPUY", "LYCEE SIMONE WEIL", "LYCEE ST-JACQUES DE COMPOSTELLE"];
listeLycees["MONISTROL SUR LOIRE"] = ["LYCEE LEONARD DE VINCI", "LYCEE NOTRE-DAME DU CHATEAU"];
listeLycees["YSSINGEAUX"] = ["LYCEE DU SACRE COEUR"];
listeLycees["CHAUMONT"] = ["LYCEE CHARLES DE GAULLE", "LYCEE EDME BOUCHARDON", "LYCEE OUDINOT"];
listeLycees["JOINVILLE"] = ["LYCEE PHILIPPE LEBON (GENERAL ET TECHNO.)"];
listeLycees["LANGRES"] = ["LYCEE DIDEROT (GENERAL ET TECHNO.)"];
listeLycees["ST DIZIER"] = ["LYCEE BLAISE PASCAL", "LYCEE DE L\'ASSOMPTION", "LYCEE IMMACULEE CONCEPTION", "LYCEE SAINT EXUPERY"];
listeLycees["GRAY"] = ["LYCEE AUGUSTIN COURNOT (GENERAL ET TECHNO.)"];
listeLycees["HERICOURT"] = ["LYCEE LOUIS ARAGON (GENERAL ET TECHNO.)"];
listeLycees["LURE"] = ["LYCEE GEORGES COLOMB"];
listeLycees["LUXEUIL LES BAINS"] = ["LYCEE LUMIERE (GENERAL ET TECHNO.)"];
listeLycees["VESOUL"] = ["LYCEE EDOUARD BELIN (GENERAL ET TECHNO.)", "LYCEE LES HABERGES"];
listeLycees["ABONDANCE"] = ["LYCEE SAINTE CROIX DES NEIGES"];
listeLycees["ANNECY"] = ["LYCEE CLAUDE LOUIS BERTHOLLET", "LYCEE GABRIEL FAURE", "LYCEE SAINT MICHEL"];
listeLycees["ANNEMASSE"] = ["LYCEE DES GLIERES (GENERAL ET TECHNO.)", "LYCEE JEAN MONNET"];
listeLycees["ARGONAY"] = ["LYCEE LOUIS LACHENAL"];
listeLycees["BONNEVILLE"] = ["LYCEE GUILLAUME FICHET (GENERAL ET TECHNO.)"];
listeLycees["CHAMONIX MONT BLANC"] = ["LYCEE ROGER FRISON ROCHE (GENERAL ET TECHNO.)"];
listeLycees["CLUSES"] = ["LYCEE CHARLES PONCET"];
listeLycees["CRAN GEVRIER"] = ["LYCEE CHARLES BAUDELAIRE"];
listeLycees["EVIAN LES BAINS"] = ["LYCEE ANNA DE NOAILLES (GENERAL ET TECHNO.)"];
listeLycees["LA ROCHE SUR FORON"] = ["LYCEE DE LA SAINTE FAMILLE"];
listeLycees["PASSY"] = ["LYCEE DU MONT BLANC"];
listeLycees["RUMILLY"] = ["LYCEE DE L\'ALBANAIS", "LYCEE DEMOTZ DE LA SALLE (GENERAL ET TECHNO.)"];
listeLycees["SALLANCHES"] = ["LYCEE SAINT JOSEPH-GENERAL"];
listeLycees["SEYNOD"] = ["LYCEE LES BRESSIS"];
listeLycees["ST JULIEN EN GENEVOIS"] = ["LYCEE MME DE STAEL", "LYCEE PRESENTATION DE MARIE"];
listeLycees["THONES"] = ["LYCEE SAINT JOSEPH"];
listeLycees["THONON LES BAINS"] = ["LYCEE JEANNE D\'ARC", "LYCEE LA VERSOIE", "LYCEE SAINT JOSEPH", "LYCEE SAVOIE LEMAN"];
listeLycees["VILLE LA GRAND"] = ["LYCEE SAINT FRANCOIS"];
listeLycees["BELLAC"] = ["LYCEE JEAN GIRAUDOUX"];
listeLycees["LIMOGES"] = ["LYCEE AUGUSTE RENOIR", "LYCEE BEAUPEYRAT", "LYCEE GAY LUSSAC", "LYCEE HOTELIER JEAN MONNET", "LYCEE LEONARD LIMOSIN", "LYCEE RAOUL DAUTRY", "LYCEE SAINT JEAN", "LYCEE SUZANNE VALADON (GENERAL ET TECHNO.)", "LYCEE TURGOT"];
listeLycees["ST JUNIEN"] = ["LYCEE PAUL ELUARD (GENERAL ET TECHNO.)"];
listeLycees["ST LEONARD DE NOBLAT"] = ["LYCEE BERNARD PALISSY"];
listeLycees["ST YRIEIX LA PERCHE"] = ["LYCEE JEAN-BAPTISTE DARNET"];
listeLycees["BRIANCON"] = ["LYCEE CLIMATIQUE D\'ALTITUDE (GENERAL ET TECHNO.)"];
listeLycees["EMBRUN"] = ["LYCEE HONORE ROMANE"];
listeLycees["GAP"] = ["LYCEE ARISTIDE BRIAND", "LYCEE DOMINIQUE VILLARS", "LYCEE SAINT JOSEPH"];
listeLycees["ARGELES GAZOST"] = ["LYCEE RENE BILLERES"];
listeLycees["BAGNERES DE BIGORRE"] = ["LYCEE VICTOR DURUY (GENERAL ET TECHNO.)"];
listeLycees["LANNEMEZAN"] = ["LYCEE MICHELET"];
listeLycees["LOURDES"] = ["LYCEE DE L\'ARROUZA", "LYCEE LA SERRE DE SARSAN", "LYCEE PEYRAMALE-SAINT JOSEPH"];
listeLycees["MONLEON MAGNOAC"] = ["LYCEE NOTRE-DAME DE GARAISON (GENERAL ET TECHNO.)"];
listeLycees["TARBES"] = ["LYCEE JEAN DUPUY", "LYCEE JEANNE D\'ARC", "LYCEE MARIE CURIE", "LYCEE PRADEAU-LA SEDE", "LYCEE THEOPHILE GAUTIER"];
listeLycees["VIC EN BIGORRE"] = ["LYCEE PIERRE MENDES-FRANCE"];
listeLycees["ANTONY"] = ["LYCEE DESCARTES", "LYCEE SAINTE MARIE LA CROIX"];
listeLycees["ASNIERES SUR SEINE"] = ["LYCEE AUGUSTE RENOIR", "LYCEE SAINTE GENEVIEVE"];
listeLycees["BAGNEUX"] = ["LYCEE SAINT GABRIEL"];
listeLycees["BOIS COLOMBES"] = ["LYCEE ALBERT CAMUS"];
listeLycees["BOULOGNE BILLANCOURT"] = ["LYCEE JACQUES PREVERT", "LYCEE NOTRE-DAME", "LYCEE RAMBAM"];
listeLycees["BOURG LA REINE"] = ["LYCEE NOTRE-DAME"];
listeLycees["CHATENAY MALABRY"] = ["LYCEE EMMANUEL MOUNIER", "LYCEE JEAN JAURES", "LYCEE SOPHIE BARAT"];
listeLycees["CHAVILLE"] = ["LYCEE ST THOMAS DE VILLENEUVE"];
listeLycees["CLAMART"] = ["LYCEE JACQUES MONOD"];
listeLycees["CLICHY"] = ["LYCEE NEWTON-ENREA (GENERAL ET TECHNO.)", "LYCEE RENE AUFFRAY"];
listeLycees["COLOMBES"] = ["LYCEE GUY DE MAUPASSANT", "LYCEE JEANNE D ARC"];
listeLycees["COURBEVOIE"] = ["LYCEE LUCIE AUBRAC", "LYCEE MONTALEMBERT", "LYCEE PAUL LAPIE"];
listeLycees["GENNEVILLIERS"] = ["LYCEE GALILEE"];
listeLycees["ISSY LES MOULINEAUX"] = ["LYCEE EUGENE IONESCO (GENERAL ET TECHNO.)", "LYCEE LA SALLE ST NICOLAS"];
listeLycees["LE PLESSIS ROBINSON"] = ["LYCEE MONTESQUIEU (GENERAL ET TECHNO.)"];
listeLycees["LEVALLOIS PERRET"] = ["LYCEE LEONARD DE VINCI"];
listeLycees["MEUDON"] = ["LYCEE DE LA SOURCE", "LYCEE NOTRE-DAME", "LYCEE RABELAIS"];
listeLycees["MONTROUGE"] = ["LYCEE JEANNE D ARC", "LYCEE MAURICE GENEVOIX"];
listeLycees["NANTERRE"] = ["LYCEE JOLIOT-CURIE"];
listeLycees["NEUILLY SUR SEINE"] = ["LYCEE LA FOLIE SAINT JAMES", "LYCEE LOUIS PASTEUR", "LYCEE N-DAME DE SAINTE CROIX", "LYCEE SAINT DOMINIQUE", "LYCEE SAINTE MARIE"];
listeLycees["PUTEAUX"] = ["LYCEE L AGORA"];
listeLycees["RUEIL MALMAISON"] = ["LYCEE LA SALLE PASSY BUZENVAL", "LYCEE MADELEINE DANIELOU", "LYCEE RICHELIEU"];
listeLycees["SCEAUX"] = ["LYCEE LAKANAL", "LYCEE MARIE CURIE"];
listeLycees["SEVRES"] = ["LYCEE JEAN-PIERRE VERNANT"];
listeLycees["ST CLOUD"] = ["LYCEE ALEXANDRE DUMAS"];
listeLycees["SURESNES"] = ["LYCEE PAUL LANGEVIN"];
listeLycees["VANVES"] = ["LYCEE MICHELET"];
listeLycees["VAUCRESSON"] = ["LYCEE SUGER"];
listeLycees["VILLENEUVE LA GARENNE"] = ["LYCEE MICHEL ANGE"];
listeLycees["AGDE"] = ["LYCEE AUGUSTE LOUBATIERES (GENERAL ET TECHNO.)"];
listeLycees["BEDARIEUX"] = ["LYCEE FERDINAND FABRE"];
listeLycees["BEZIERS"] = ["LYCEE HENRI IV", "LYCEE JEAN MOULIN", "LYCEE LA TRINITE"];
listeLycees["CASTELNAU LE LEZ"] = ["LYCEE GEORGES POMPIDOU (GENERAL ET TECHNO.)"];
listeLycees["CLERMONT L HERAULT"] = ["LYCEE RENE GOSSE"];
listeLycees["LA GRANDE MOTTE"] = ["LYCEE LA MERCI LITTORAL (GENERAL ET TECHNO.)"];
listeLycees["LATTES"] = ["LYCEE J-FRANCOIS CHAMPOLLION (GENERAL ET TECHNO.)"];
listeLycees["LODEVE"] = ["LYCEE JOSEPH VALLOT (GENERAL ET TECHNO.)"];
listeLycees["LUNEL"] = ["LYCEE LOUIS FEUILLADE (GENERAL ET TECHNO.)", "LYCEE VICTOR HUGO (GENERAL ET TECHNO.)"];
listeLycees["MONTFERRIER SUR LEZ"] = ["LYCEE ST JOSEPH PIERRE ROUGE"];
listeLycees["MONTPELLIER"] = ["LYCEE GEORGES CLEMENCEAU", "LYCEE JEAN MERMOZ (GENERAL ET TECHNO.)", "LYCEE JEAN MONNET", "LYCEE JOFFRE", "LYCEE JULES FERRY", "LYCEE JULES GUESDE (GENERAL ET TECHNO.)", "LYCEE NEVERS (GENERAL ET TECHNO.)", "LYCEE NOTRE-DAME DE LA MERCI", "LYCEE RABELAIS"];
listeLycees["PEZENAS"] = ["LYCEE JEAN MOULIN"];
listeLycees["SETE"] = ["LYCEE IRENE ET FREDERIC JOLIOT CURIE", "LYCEE PAUL VALERY", "LYCEE SAINT JOSEPH (GENERAL ET TECHNO.)"];
listeLycees["ST CLEMENT DE RIVIERE"] = ["LYCEE JEAN JAURES (GENERAL ET TECHNO.)"];
listeLycees["BAIN DE BRETAGNE"] = ["LYCEE JEAN BRITO"];
listeLycees["BRUZ"] = ["LYCEE ANITA CONTI", "LYCEE SAINT JOSEPH"];
listeLycees["CESSON SEVIGNE"] = ["LYCEE SAINT ETIENNE (GENERAL ET TECHNO.)", "LYCEE SEVIGNE"];
listeLycees["COMBOURG"] = ["LYCEE FRANCOIS RENE DE CHATEAUBRIAND"];
listeLycees["DINARD"] = ["LYCEE HOTELLERIE ET RESTAURATION"];
listeLycees["DOL DE BRETAGNE"] = ["LYCEE SAINT MAGLOIRE"];
listeLycees["FOUGERES"] = ["LYCEE J-B LE TAILLANDIER", "LYCEE JEAN GUEHENNO"];
listeLycees["MONTAUBAN DE BRETAGNE"] = ["LYCEE DE LA PROVIDENCE (GENERAL ET TECHNO.)"];
listeLycees["MONTFORT SUR MEU"] = ["LYCEE RENE CASSIN"];
listeLycees["REDON"] = ["LYCEE BEAUMONT", "LYCEE MARCEL CALLO", "LYCEE SAINT SAUVEUR"];
listeLycees["RENNES"] = ["LYCEE BREQUIGNY", "LYCEE DE L\'ASSOMPTION", "LYCEE DE L\'ILE DE FRANCE", "LYCEE EMILE ZOLA", "LYCEE F. RENE DE CHATEAUBRIAND", "LYCEE J-B DE LA SALLE", "LYCEE JEAN MACE", "LYCEE JEANNE D\'ARC", "LYCEE JOLIOT-CURIE", "LYCEE PIERRE MENDES-FRANCE", "LYCEE RENE DESCARTES", "LYCEE SAINT MARTIN", "LYCEE SAINT VINCENT-PROVIDENCE (GENERAL ET TECHNO.)", "LYCEE SAINTE THERESE"];
listeLycees["ST GREGOIRE"] = ["LYCEE JEAN PAUL II (GENERAL ET TECHNO.)"];
listeLycees["ST MALO"] = ["INSTITUTION ST MALO-PROVIDENCE", "LYCEE JACQUES CARTIER (GENERAL ET TECHNO.)", "LYCEE LES RIMAINS", "LYCEE MAUPERTUIS"];
listeLycees["VITRE"] = ["LYCEE BERTRAND D\'ARGENTRE", "LYCEE JEANNE D\'ARC"];
listeLycees["ARGENTON SUR CREUSE"] = ["LYCEE ROLLINAT"];
listeLycees["CHATEAUROUX"] = ["LYCEE BLAISE PASCAL (GENERAL ET TECHNO.)", "LYCEE JEAN GIRAUDOUX", "LYCEE PIERRE ET MARIE CURIE", "LYCEE SAINTE SOLANGE (GENERAL ET TECHNO.)"];
listeLycees["ISSOUDUN"] = ["LYCEE HONORE DE BALZAC", "LYCEE SAINT CYR (GENERAL ET TECHNO.)"];
listeLycees["LA CHATRE"] = ["LYCEE GEORGE SAND (GENERAL ET TECHNO.)"];
listeLycees["LE BLANC"] = ["LYCEE PASTEUR (GENERAL ET TECHNO.)"];
listeLycees["AMBOISE"] = ["LYCEE LEONARD DE VINCI"];
listeLycees["CHINON"] = ["LYCEE FRANCOIS RABELAIS (GENERAL ET TECHNO.)", "LYCEE SAINT JOSEPH"];
listeLycees["JOUE LES TOURS"] = ["LYCEE JEAN MONNET", "LYCEE SAINT GATIEN (GENERAL ET TECHNO.)"];
listeLycees["LOCHES"] = ["LYCEE ALFRED DE VIGNY", "LYCEE SAINT DENIS"];
listeLycees["ST PIERRE DES CORPS"] = ["LYCEE MARTIN NADAUD"];
listeLycees["TOURS"] = ["INSTITUTION ND DE LA RICHE-ST MEDARD", "LYCEE BALZAC", "LYCEE CHOISEUL", "LYCEE DESCARTES", "LYCEE GRAND SAINT GREGOIRE", "LYCEE GRANDMONT", "LYCEE JACQUES DE VAUCANSON", "LYCEE MARMOUTIER (GENERAL ET TECHNO.)", "LYCEE PAUL-LOUIS COURIER", "LYCEE SAINTE MARGUERITE (GENERAL ET TECHNO.)", "LYCEE SAINTE URSULE"];
listeLycees["VEIGNE"] = ["LYCEE FONTIVILLE (GENERAL ET TECHNO.)"];
listeLycees["BOURGOIN JALLIEU"] = ["LYCEE L\'OISELET"];
listeLycees["ECHIROLLES"] = ["LYCEE MARIE CURIE"];
listeLycees["GRENOBLE"] = ["EXTERNAT NOTRE DAME", "LYCEE ANDRE ARGOUGES", "LYCEE CHAMPOLLION", "LYCEE EAUX CLAIRES", "LYCEE EMMANUEL MOUNIER", "LYCEE INTERNATIONAL EUROPOLE", "LYCEE ISER - BORDIER", "LYCEE LESDIGUIERES", "LYCEE LOUISE MICHEL", "LYCEE PIERRE TERMIER", "LYCEE STENDHAL", "LYCEE VAUCANSON (GENERAL ET TECHNO.)"];
listeLycees["L ISLE D ABEAU"] = ["LYCEE PHILIBERT DELORME (GENERAL ET TECHNO.)"];
listeLycees["LA COTE ST ANDRE"] = ["LYCEE HECTOR BERLIOZ (GENERAL ET TECHNO.)", "LYCEE SAINTE CECILE"];
listeLycees["LA MURE D ISERE"] = ["LYCEE DE LA MATHEYSINE (GENERAL ET TECHNO.)"];
listeLycees["LA TOUR DU PIN"] = ["LYCEE ELIE CARTAN"];
listeLycees["LA TRONCHE"] = ["LYCEE ITEC BOISFLEURY EUROPE", "LYCEE UNIT? SOINS ETUDES GR?SIVAUDAN (GENERAL ET TECHNO.)"];
listeLycees["LE PEAGE DE ROUSSILLON"] = ["LYCEE JEANNE D\'ARC"];
listeLycees["LE PONT DE BEAUVOISIN"] = ["LYCEE CHARLES GABRIEL PRAVAZ (GENERAL ET TECHNO.)"];
listeLycees["MEYLAN"] = ["LYCEE DU GRESIVAUDAN (GENERAL ET TECHNO.)"];
listeLycees["MOIRANS"] = ["LYCEE PIERRE BEGHIN"];
listeLycees["MORESTEL"] = ["LYCEE CAMILLE COROT"];
listeLycees["NIVOLAS VERMELLE"] = ["LYCEE SAINT MARC (GENERAL ET TECHNO.)"];
listeLycees["PONT DE CHERUY"] = ["LYCEE LA PLEIADE"];
listeLycees["PONTCHARRA"] = ["LYCEE PIERRE DU TERRAIL"];
listeLycees["ROUSSILLON"] = ["LYCEE DE L\'EDIT"];
listeLycees["SASSENAGE"] = ["LYCEE ROGER DESCHAUX"];
listeLycees["SEYSSINET PARISET"] = ["LYCEE ARISTIDES BERGES"];
listeLycees["ST MARCELLIN"] = ["LYCEE LA SAULAIE (GENERAL ET TECHNO.)"];
listeLycees["ST MARTIN D HERES"] = ["LYCEE PABLO NERUDA"];
listeLycees["VIENNE"] = ["LYCEE DE VIENNE (GENERAL ET TECHNO.)", "LYCEE GALILEE", "LYCEE ROBIN", "LYCEE SAINT CHARLES"];
listeLycees["VILLARD BONNOT"] = ["LYCEE MARIE REYNOARD"];
listeLycees["VILLARD DE LANS"] = ["LYCEE ET SPORTIF JEAN PREVOST"];
listeLycees["VILLEFONTAINE"] = ["LYCEE LEONARD DE VINCI"];
listeLycees["VIZILLE"] = ["LYCEE DE VIZILLE"];
listeLycees["VOIRON"] = ["LYCEE EDOUARD HERRIOT (GENERAL ET TECHNO.)", "LYCEE FERDINAND BUISSON (GENERAL ET TECHNO.)", "LYCEE NOTRE-DAME DES VICTOIRES"];
listeLycees["CHAMPAGNOLE"] = ["LYCEE JEANNE D\'ARC", "LYCEE PAUL EMILE VICTOR (GENERAL ET TECHNO.)"];
listeLycees["DOLE"] = ["LYCEE CHARLES NODIER", "LYCEE JACQUES DUHAMEL (GENERAL ET TECHNO.)", "LYCEE PASTEUR MONT ROLAND (GENERAL ET TECHNO.)"];
listeLycees["LONS LE SAUNIER"] = ["LYCEE JEAN MICHEL", "LYCEE SAINTE MARIE"];
listeLycees["MOREZ"] = ["LYCEE VICTOR BERARD"];
listeLycees["MOUCHARD"] = ["LYCEE DU BOIS"];
listeLycees["POLIGNY"] = ["LYCEE HYACINTHE FRIANT"];
listeLycees["SALINS LES BAINS"] = ["LYCEE VICTOR CONSIDERANT"];
listeLycees["ST CLAUDE"] = ["LYCEE PRE SAINT SAUVEUR"];
listeLycees["AIRE SUR L ADOUR"] = ["LYCEE GASTON CRAMPE (GENERAL ET TECHNO.)"];
listeLycees["DAX"] = ["LYCEE DE BORDA (GENERAL ET TECHNO.)", "LYCEE ST JACQUES DE COMPOSTELLE"];
listeLycees["MONT DE MARSAN"] = ["LYCEE CHARLES DESPIAU", "LYCEE VICTOR DURUY"];
listeLycees["PARENTIS EN BORN"] = ["LYCEE SAINT EXUPERY"];
listeLycees["ST PAUL LES DAX"] = ["LYCEE HAROUN TAZIEFF"];
listeLycees["ST PIERRE DU MONT"] = ["LYCEE JEAN CASSAIGNE"];
listeLycees["ST VINCENT DE TYROSSE"] = ["LYCEE DU SUD DES LANDES"];
listeLycees["BLOIS"] = ["LYCEE AUGUSTIN THIERRY (GENERAL ET TECHNO.)", "LYCEE CAMILLE CLAUDEL", "LYCEE DE LA PROVIDENCE (GENERAL ET TECHNO.)", "LYCEE DESSAIGNES", "LYCEE HOT.TOUR. VAL DE LOIRE", "LYCEE NOTRE-DAME DES AYDES", "LYCEE SAINTE MARIE"];
listeLycees["ROMORANTIN LANTHENAY"] = ["LYCEE CLAUDE DE FRANCE"];
listeLycees["VENDOME"] = ["LYCEE RONSARD (GENERAL ET TECHNO.)", "LYCEE SAINT JOSEPH (GENERAL ET TECHNO.)"];
listeLycees["ANDREZIEUX BOUTHEON"] = ["LYCEE FRANCOIS MAURIAC-FOREZ"];
listeLycees["BOEN"] = ["LYCEE L\'ASTREE"];
listeLycees["CHARLIEU"] = ["LYCEE JEREMIE DE LA RUE (GENERAL ET TECHNO.)", "LYCEE NOTRE-DAME"];
listeLycees["FEURS"] = ["LYCEE DU FOREZ"];
listeLycees["FIRMINY"] = ["LYCEE ALBERT CAMUS", "LYCEE JACOB HOLTZER"];
listeLycees["MONTBRISON"] = ["LYCEE BEAUREGARD", "LYCEE SAINT PAUL FOREZ"];
listeLycees["RIVE DE GIER"] = ["LYCEE GEORGES BRASSENS (GENERAL ET TECHNO.)"];
listeLycees["ROANNE"] = ["LYCEE ALBERT THOMAS", "LYCEE CARNOT", "LYCEE JEAN PUY", "LYCEE SAINT PAUL", "LYCEE SAINTE ANNE"];
listeLycees["ST CHAMOND"] = ["LYCEE CLAUDE LEBOIS", "LYCEE SAINTE MARIE LA GRAND\'GRANGE"];
listeLycees["ST ETIENNE"] = ["EXTERNAT SAINT MICHEL", "LYCEE BENOIT FOURNEYRON", "LYCEE CLAUDE FAURIEL", "LYCEE ETIENNE MIMARD", "LYCEE HONORE D\'URFE", "LYCEE JEAN MONNET", "LYCEE NOTRE-DAME DE VALBENOITE", "LYCEE SAINT LOUIS", "LYCEE SAINT PAUL", "LYCEE SAINTE BARBE", "LYCEE SEVIGNE", "LYCEE TEZENAS DU MONTCEL"];
listeLycees["ST PRIEST EN JAREZ"] = ["LYCEE SIMONE WEIL"];
listeLycees["ANCENIS"] = ["LYCEE JOUBERT-EMILIEN MAILLARD (GENERAL ET TECHNO.)", "LYCEE SAINT JOSEPH"];
listeLycees["BASSE GOULAINE"] = ["LYCEE LA HERDRIE"];
listeLycees["BLAIN"] = ["LYCEE CAMILLE CLAUDEL"];
listeLycees["BOUAYE"] = ["LYCEE ALCIDE D\'ORBIGNY"];
listeLycees["CHATEAUBRIANT"] = ["LYCEE GUY MOQUET - ETIENNE LENOIR (GENERAL ET TECHNO.)", "LYCEE SAINT JOSEPH"];
listeLycees["GORGES"] = ["LYCEE CHARLES PEGUY"];
listeLycees["GUERANDE"] = ["LYCEE GALILEE", "LYCEE LA MENNAIS"];
listeLycees["LA BAULE"] = ["LYCEE GRAND AIR"];
listeLycees["LA CHAPELLE SUR ERDRE"] = ["ECOLE POUR SPORTIFS DE HAUT NIVEAU"];
listeLycees["MACHECOUL"] = ["LYCEE SAINT JOSEPH"];
listeLycees["NANTES"] = ["LYCEE ALBERT CAMUS", "LYCEE BLANCHE DE CASTILLE", "LYCEE CARCOUET", "LYCEE CLEMENCEAU", "LYCEE DE BRETAGNE", "LYCEE DU SACRE COEUR (GENERAL ET TECHNO.)", "LYCEE GABRIEL GUISTHAU", "LYCEE GASPARD MONGE - LA CHAUVINIERE (GENERAL ET TECHNO.)", "LYCEE JULES VERNE", "LYCEE LA COLINIERE", "LYCEE LA PERVERIE-SACRE COEUR", "LYCEE LES BOURDONNIERES (GENERAL ET TECHNO.)", "LYCEE LIVET", "LYCEE ND DE TOUTES AIDES", "LYCEE SAINT FELIX-GENERAL", "LYCEE SAINT J-B DE LA SALLE", "LYCEE SAINT JOSEPH DU LOQUIDY", "LYCEE SAINT STANISLAS", "LYCEE VIAL"];
listeLycees["ORVAULT"] = ["LYCEE NICOLAS APPERT"];
listeLycees["PORNIC"] = ["LYCEE DU PAYS DE RETZ"];
listeLycees["REZE"] = ["LYCEE JEAN PERRIN", "LYCEE NOTRE-DAME (GENERAL ET TECHNO.)"];
listeLycees["SAVENAY"] = ["LYCEE JACQUES PREVERT"];
listeLycees["ST GILDAS DES BOIS"] = ["LYCEE GABRIEL DESHAYES"];
listeLycees["ST HERBLAIN"] = ["LYCEE SAINT DOMINIQUE"];
listeLycees["ST NAZAIRE"] = ["LYCEE ARISTIDE BRIAND", "LYCEE HOTELIER SAINTE ANNE", "LYCEE NOTRE-DAME D\'ESPERANCE-GENERAL", "LYCEE SAINT LOUIS"];
listeLycees["ST SEBASTIEN SUR LOIRE"] = ["LYCEE LA BAUGERIE", "LYCEE ST JOSEPH LA JOLIVERIE (GENERAL ET TECHNO.)", "LYCEE ST PIERRE LA JOLIVERIE"];
listeLycees["BEAUGENCY"] = ["LYCEE FRANCOIS VILLON"];
listeLycees["CHALETTE SUR LOING"] = ["LYCEE CHATEAU BLANC"];
listeLycees["GIEN"] = ["LYCEE BERNARD PALISSY", "LYCEE SAINT FRANCOIS DE SALES (GENERAL ET TECHNO.)"];
listeLycees["INGRE"] = ["LYCEE MAURICE GENEVOIX (GENERAL ET TECHNO.)"];
listeLycees["MONTARGIS"] = ["LYCEE EN FORET", "LYCEE SAINT LOUIS (GENERAL ET TECHNO.)"];
listeLycees["ORLEANS"] = ["LYCEE BENJAMIN FRANKLIN (GENERAL ET TECHNO.)", "LYCEE CHARLES PEGUY", "LYCEE JEAN ZAY (GENERAL ET TECHNO.)", "LYCEE POTHIER", "LYCEE S.PAUL-BOURDON BLANC-GENERAL", "LYCEE S.PAUL-BOURDON BLANC-POLYVALENT (TECHNO.)", "LYCEE SAINT CHARLES", "LYCEE STE CROIX-ST EUVERTE-GENERAL", "LYCEE VOLTAIRE"];
listeLycees["PITHIVIERS"] = ["LYCEE DUHAMEL DU MONCEAU"];
listeLycees["ST JEAN DE BRAYE"] = ["LYCEE GAUDIER-BRZESKA", "LYCEE JACQUES MONOD"];
listeLycees["ST JEAN DE LA RUELLE"] = ["LYCEE MAL LECLERC DE HAUTECLOCQUE"];
listeLycees["VILLEMANDEUR"] = ["LYCEE DURZY"];
listeLycees["CAHORS"] = ["LYCEE CLEMENT MAROT", "LYCEE GASTON MONNERVILLE", "LYCEE SAINT-ETIENNE"];
listeLycees["FIGEAC"] = ["LYCEE DES METIERS CHAMPOLLION", "LYCEE JEANNE D\'ARC (GENERAL ET TECHNO.)"];
listeLycees["GOURDON"] = ["LYCEE LEO FERRE (GENERAL ET TECHNO.)"];
listeLycees["SOUILLAC"] = ["LYCEE LOUIS VICAT"];
listeLycees["ST CERE"] = ["LYCEE JEAN LURCAT"];
listeLycees["AGEN"] = ["LYCEE BERNARD PALISSY", "LYCEE JEAN-BAPTISTE DE BAUDRE", "LYCEE SAINT CAPRAIS"];
listeLycees["AIGUILLON"] = ["LYCEE STENDHAL"];
listeLycees["FUMEL"] = ["LYCEE MARGUERITE FILHOL"];
listeLycees["MARMANDE"] = ["LYCEE DE LA PLASTURGIE V DE GARONNE", "LYCEE NOTRE-DAME LA COMPASSION"];
listeLycees["NERAC"] = ["LYCEE GEORGE SAND"];
listeLycees["VILLENEUVE SUR LOT"] = ["LYCEE SAINTE CATHERINE", "LYCEE TERTIAIRE ET SOCIAL G.LEYGUES"];
listeLycees["MARVEJOLS"] = ["LYCEE SAINT JOSEPH (GENERAL ET TECHNO.)"];
listeLycees["MENDE"] = ["LYCEE CHAPTAL", "LYCEE EMILE PEYTAVIN", "LYCEE NOTRE-DAME"];
listeLycees["ST CHELY D APCHER"] = ["LYCEE THEOPHILE ROUSSEL (GENERAL ET TECHNO.)"];
listeLycees["ANGERS"] = ["LYCEE AUGUSTE ET JEAN RENOIR", "LYCEE CHEVROLLIER", "LYCEE DAVID D\'ANGERS", "LYCEE DU SACRE COEUR", "LYCEE EMMANUEL MOUNIER", "LYCEE HENRI BERGSON", "LYCEE JEAN MOULIN", "LYCEE JEANNE D\'ARC", "LYCEE JOACHIM DU BELLAY", "LYCEE SAINT MARTIN", "LYCEE SAINTE AGNES"];
listeLycees["BEAUPREAU"] = ["LYCEE ND DE BONNES NOUVELLES"];
listeLycees["CHOLET"] = ["LYCEE EUROPE ROBERT SCHUMAN", "LYCEE FERNAND RENAUDEAU", "LYCEE JEANNE DELANOUE"];
listeLycees["LA POMMERAYE"] = ["LYCEE ST JOSEPH"];
listeLycees["LA SALLE DE VIHIERS"] = ["LYCEE NOTRE DAME"];
listeLycees["LE LONGERON"] = ["LYCEE CHAMP BLANC"];
listeLycees["LES PONTS DE CE"] = ["LYCEE JEAN BODIN"];
listeLycees["NYOISEAU"] = ["LYCEE ND D\'ORVEAU"];
listeLycees["SAUMUR"] = ["LYCEE DUPLESSIS MORNAY", "LYCEE LES ARDILLIERS", "LYCEE SADI CARNOT - JEAN BERTIN", "LYCEE ST LOUIS"];
listeLycees["SEGRE"] = ["LYCEE BLAISE PASCAL (GENERAL ET TECHNO.)", "LYCEE BOURG CHEVREAU (GENERAL ET TECHNO.)"];
listeLycees["ST SYLVAIN D ANJOU"] = ["LYCEE ST JULIEN LA BARONNERIE"];
listeLycees["AGNEAUX"] = ["INSTITUT SAINT LO-GENERAL ET TECHNO."];
listeLycees["AVRANCHES"] = ["LYCEE EMILE LITTRE (GENERAL ET TECHNO.)", "LYCEE NOTRE DAME DE LA PROVIDENCE"];
listeLycees["CARENTAN"] = ["LYCEE SIVARD DE BEAULIEU"];
listeLycees["CHERBOURG OCTEVILLE"] = ["LYCEE ALEXIS DE TOCQUEVILLE", "LYCEE JEAN-FRANCOIS MILLET", "LYCEE THOMAS HELYE", "LYCEE VICTOR GRIGNARD"];
listeLycees["COUTANCES"] = ["LYCEE CHARLES FRANCOIS LEBRUN", "LYCEE JEAN PAUL II"];
listeLycees["GRANVILLE"] = ["LYCEE JULLIOT DE LA MORANDIERE", "LYCEE MAURICE MARLAND", "LYCEE SEVIGNE"];
listeLycees["MORTAIN"] = ["LYCEE ROBERT DE MORTAIN (GENERAL ET TECHNO.)"];
listeLycees["ST HILAIRE DU HARCOUET"] = ["LYCEE CLAUDE LEHEC"];
listeLycees["ST LO"] = ["LYCEE LE BON SAUVEUR", "LYCEE LE VERRIER", "LYCEE PIERRE ET MARIE CURIE"];
listeLycees["VALOGNES"] = ["LYCEE HENRI CORNAT"];
listeLycees["CHALONS EN CHAMPAGNE"] = ["LYCEE ETIENNE OEHMICHEN", "LYCEE FREDERIC OZANAM (GENERAL ET TECHNO.)", "LYCEE JEAN TALON (GENERAL ET TECHNO.)", "LYCEE PIERRE BAYEN"];
listeLycees["EPERNAY"] = ["LYCEE GODART ROGER", "LYCEE LEON BOURGEOIS", "LYCEE NOTRE-DAME SAINT VICTOR"];
listeLycees["REIMS"] = ["LYCEE COLBERT", "LYCEE DU SACRE COEUR", "LYCEE FRANCOIS ARAGO", "LYCEE FRANKLIN ROOSEVELT", "LYCEE GEORGES CLEMENCEAU", "LYCEE HUGUES LIBERGIER", "LYCEE JEAN JAURES", "LYCEE JEAN XXIII", "LYCEE MARC CHAGALL", "LYCEE SAINT J-B DE LA SALLE", "LYCEE SAINT JOSEPH", "LYCEE SAINT MICHEL (GENERAL ET TECHNO.)", "LYCEE VAL DE MURIGNY"];
listeLycees["SEZANNE"] = ["LYCEE LA FONTAINE DU VE"];
listeLycees["VITRY LE FRANCOIS"] = ["LYCEE FRANCOIS 1ER"];
listeLycees["BELLEFONTAINE"] = ["LYCEE DU NORD CARAIBE (GENERAL ET TECHNO.)"];
listeLycees["DUCOS"] = ["LYCEE CENTRE SUD"];
listeLycees["FORT DE FRANCE"] = ["ASSOC.MARTINIQ.EDUC.POPULAIRE (GENERAL ET TECHNO.)", "INSTITUT MARTINIQUAIS D\'ETUDES", "LYCEE BELLEVUE", "LYCEE JOSEPH GAILLARD", "LYCEE SAINT JOSEPH DE CLUNY", "LYCEE VICTOR SCHOELCHER", "SEMINAIRE COLLEGE STE MARIE"];
listeLycees["LA TRINITE"] = ["LYCEE FRANTZ FANON"];
listeLycees["LE FRANCOIS"] = ["LYCEE LA JETEE (GENERAL ET TECHNO.)"];
listeLycees["LE LAMENTIN"] = ["LYCEE ACAJOU 1", "LYCEE ACAJOU 2 (GENERAL ET TECHNO.)"];
listeLycees["LE LORRAIN"] = ["LYCEE JOSEPH PERNOCK (GENERAL ET TECHNO.)"];
listeLycees["LE MARIN"] = ["LYCEE MONTGERALD"];
listeLycees["RIVIERE SALEE"] = ["LYCEE JOSEPH ZOBEL (GENERAL ET TECHNO.)"];
listeLycees["STE LUCE"] = ["LYCEE ADVENTISTE RAMA (GENERAL ET TECHNO.)"];
listeLycees["STE MARIE"] = ["LYCEE NORD ATLANTIQUE (GENERAL ET TECHNO.)"];
listeLycees["STE MARIE"] = ["LYCEE LE VERGER-SAINTE MARIE"];
listeLycees["CHATEAU GONTIER"] = ["LYCEE ST MICHEL", "LYCEE VICTOR HUGO"];
listeLycees["EVRON"] = ["LYCEE RAOUL VADEPIED (GENERAL ET TECHNO.)"];
listeLycees["LAVAL"] = ["LYCEE AMBROISE PARE", "LYCEE D\'AVESNIERES", "LYCEE DOUANIER ROUSSEAU", "LYCEE HAUTE FOLLIS", "LYCEE IMMACULEE CONCEPTION", "LYCEE REAUMUR"];
listeLycees["MAYENNE"] = ["LYCEE GENERAL DON BOSCO", "LYCEE LAVOISIER"];
listeLycees["ART SUR MEURTHE"] = ["LYCEE SAINT MICHEL"];
listeLycees["BRIEY"] = ["LYCEE DE L\'ASSOMPTION", "LYCEE LOUIS BERTRAND"];
listeLycees["JARNY"] = ["LYCEE JEAN ZAY (GENERAL ET TECHNO.)"];
listeLycees["JARVILLE LA MALGRANGE"] = ["LYCEE LA MALGRANGE"];
listeLycees["LAXOU"] = ["LYCEE BAT. ET DE L\'ENERGIE E.HERE"];
listeLycees["LONGWY"] = ["LYCEE ALFRED MEZIERES", "LYCEE DES RECOLLETS"];
listeLycees["LUNEVILLE"] = ["LYCEE BOUTET DE MONVEL (GENERAL ET TECHNO.)", "LYCEE ERNEST BICHAT"];
listeLycees["NANCY"] = ["LYCEE CHARLES DE FOUCAULD", "LYCEE FREDERIC CHOPIN", "LYCEE GEORGES DE LA TOUR", "LYCEE HENRI LORITZ", "LYCEE HENRI POINCARE", "LYCEE JEANNE D\'ARC", "LYCEE N-D SAINT SIGISBERT", "LYCEE SAINT DOMINIQUE", "LYCEE SAINT LEON IX"];
listeLycees["PONT A MOUSSON"] = ["LYCEE JACQUES MARQUETTE", "LYCEE JEAN HANZELET (GENERAL ET TECHNO.)"];
listeLycees["TOMBLAINE"] = ["LYCEE ARTHUR VAROQUAUX"];
listeLycees["TOUL"] = ["LYCEE JEAN-BAPTISTE VATELOT", "LYCEE LOUIS MAJORELLE"];
listeLycees["VANDOEUVRE LES NANCY"] = ["LYCEE JACQUES CALLOT"];
listeLycees["VILLERS LES NANCY"] = ["LYCEE STANISLAS (GENERAL ET TECHNO.)"];
listeLycees["BAR LE DUC"] = ["LYCEE RAYMOND POINCARE", "LYCEE SAINT LOUIS"];
listeLycees["COMMERCY"] = ["LYCEE HENRI VOGT (GENERAL ET TECHNO.)", "LYCEE SAINTE JEANNE D\'ARC"];
listeLycees["STENAY"] = ["LYCEE ALFRED KASTLER (GENERAL ET TECHNO.)"];
listeLycees["VERDUN"] = ["LYCEE JEAN-AUGUSTE MARGUERITTE (GENERAL ET TECHNO.)", "LYCEE SAINTE ANNE"];
listeLycees["AURAY"] = ["LYCEE BENJAMIN FRANKLIN"];
listeLycees["GOURIN"] = ["LYCEE SAINTE JEANNE D\'ARC"];
listeLycees["GUER"] = ["LYCEE BROCELIANDE"];
listeLycees["HENNEBONT"] = ["LYCEE NOTRE-DAME DU VOEU", "LYCEE VICTOR HUGO"];
listeLycees["LANESTER"] = ["LYCEE JEAN MACE (GENERAL ET TECHNO.)"];
listeLycees["LORIENT"] = ["LYCEE COLBERT (GENERAL ET TECHNO.)", "LYCEE DUPUY DE LOME", "LYCEE SAINT JOSEPH", "LYCEE SAINT LOUIS"];
listeLycees["PLOEMEUR"] = ["LYCEE NOTRE-DAME DE LA PAIX"];
listeLycees["PLOERMEL"] = ["LYCEE LA MENNAIS - SAINT ARMEL"];
listeLycees["PONTIVY"] = ["LYCEE JEANNE D\'ARC-SAINT IVY", "LYCEE JOSEPH LOTH"];
listeLycees["QUESTEMBERT"] = ["LYCEE MARCELIN BERTHELOT"];
listeLycees["STE ANNE D AURAY"] = ["LYCEE SAINTE ANNE-SAINT LOUIS (GENERAL ET TECHNO.)"];
listeLycees["VANNES"] = ["LYCEE ALAIN RENE LESAGE", "LYCEE CHARLES DE GAULLE", "LYCEE NOTRE-DAME LE MENIMUR", "LYCEE SAINT JOSEPH", "LYCEE SAINT PAUL"];
listeLycees["ALGRANGE"] = ["LYCEE SAINT VINCENT DE PAUL"];
listeLycees["BITCHE"] = ["LYCEE SAINT AUGUSTIN", "LYCEE TEYSSIER"];
listeLycees["CREUTZWALD"] = ["LYCEE FELIX MAYER (GENERAL ET TECHNO.)"];
listeLycees["DIEUZE"] = ["LYCEE CHARLES HERMITE (GENERAL ET TECHNO.)"];
listeLycees["FAMECK"] = ["LYCEE ANTOINE DE SAINT EXUPERY"];
listeLycees["FORBACH"] = ["LYCEE BLAISE PASCAL", "LYCEE DE LA PROVIDENCE", "LYCEE JEAN MOULIN"];
listeLycees["HAYANGE"] = ["LYCEE LES GRANDS BOIS"];
listeLycees["METZ"] = ["LYCEE ANNE DE MEJANES", "LYCEE DE LA COMMUNICATION", "LYCEE DE LA MISERICORDE", "LYCEE DE LA SALLE", "LYCEE FABERT", "LYCEE GEORGES DE LA TOUR", "LYCEE HOTELIER RAYMOND MONDON", "LYCEE LOUIS DE CORMONTAIGNE (GENERAL ET TECHNO.)", "LYCEE LOUIS VINCENT", "LYCEE ROBERT SCHUMAN"];
listeLycees["MONTIGNY LES METZ"] = ["LYCEE JEAN XXIII"];
listeLycees["OTTANGE"] = ["LYCEE SAINT ANDRE"];
listeLycees["PELTRE"] = ["LYCEE NOTRE-DAME"];
listeLycees["PHALSBOURG"] = ["LYCEE ERCKMANN CHATRIAN", "LYCEE SAINT ANTOINE"];
listeLycees["ROMBAS"] = ["LYCEE JULIE DAUBIE (GENERAL ET TECHNO.)"];
listeLycees["SARREBOURG"] = ["LYCEE MANGIN (GENERAL ET TECHNO.)"];
listeLycees["SARREGUEMINES"] = ["LYCEE HENRI NOMINE", "LYCEE JEAN DE PANGE", "LYCEE SAINTE CHRETIENNE"];
listeLycees["SCHOENECK"] = ["LYCEE CONDORCET"];
listeLycees["ST AVOLD"] = ["LYCEE ET DES TECHNO INNOV. C. JULLY", "LYCEE JEAN-VICTOR PONCELET", "LYCEE SAINTE CHRETIENNE"];
listeLycees["TALANGE"] = ["LYCEE GUSTAVE EIFFEL"];
listeLycees["THIONVILLE"] = ["LYCEE CHARLEMAGNE", "LYCEE DE LA BRIQUERIE (GENERAL ET TECHNO.)", "LYCEE HELENE BOUCHER", "LYCEE JEAN-BAPTISTE COLBERT", "LYCEE N-D DE LA PROVIDENCE", "LYCEE SAINT PIERRE CHANEL"];
listeLycees["CLAMECY"] = ["LYCEE ROMAIN ROLLAND (GENERAL ET TECHNO.)"];
listeLycees["COSNE COURS SUR LOIRE"] = ["LYCEE PIERRE-GILLES DE GENNES (GENERAL ET TECHNO.)", "LYCEE SIMONE DOUNON (GENERAL ET TECHNO.)"];
listeLycees["DECIZE"] = ["LYCEE MAURICE GENEVOIX (GENERAL ET TECHNO.)"];
listeLycees["NEVERS"] = ["LYCEE ALAIN COLAS", "LYCEE DE L\'ESPERANCE (GENERAL ET TECHNO.)", "LYCEE JULES RENARD", "LYCEE RAOUL FOLLEREAU", "LYCEE SAINT CYR"];
listeLycees["ARMENTIERES"] = ["LYCEE GUSTAVE EIFFEL (GENERAL ET TECHNO.)", "LYCEE PAUL HAZARD", "LYCEE SAINT JUDE"];
listeLycees["AULNOYE AYMERIES"] = ["LYCEE SAINTE JEANNE D\'ARC"];
listeLycees["AVESNES SUR HELPE"] = ["LYCEE JESSE DE FOREST (GENERAL ET TECHNO.)", "LYCEE SAINTE THERESE"];
listeLycees["BAVAY"] = ["LYCEE DES NERVIENS"];
listeLycees["BEAUCAMPS LIGNY"] = ["LYCEE SAINTE MARIE"];
listeLycees["BONDUES"] = ["LYCEE DE LA CROIX BLANCHE"];
listeLycees["CAMBRAI"] = ["LYCEE FENELON", "LYCEE PAUL DUEZ", "LYCEE SAINT LUC"];
listeLycees["CAUDRY"] = ["LYCEE JOSEPH-MARIE JACQUARD (GENERAL ET TECHNO.)"];
listeLycees["CONDE SUR L ESCAUT"] = ["LYCEE DU PAYS DE CONDE (GENERAL ET TECHNO.)"];
listeLycees["DENAIN"] = ["LYCEE ALFRED KASTLER", "LYCEE JULES MOUSSERON"];
listeLycees["DOUAI"] = ["LYCEE ALBERT CHATELET", "LYCEE DEFOREST DE LEWARDE", "LYCEE EDMOND LABBE", "LYCEE JEAN-BAPTISTE COROT", "LYCEE SAINT JEAN"];
listeLycees["DUNKERQUE"] = ["LYCEE AUGUSTE ANGELLIER", "LYCEE DE L\'EUROPE", "LYCEE E.P.I.D.", "LYCEE JEAN BART", "LYCEE NOTRE-DAME DES DUNES", "LYCEE VAUBAN"];
listeLycees["ESTAIRES"] = ["LYCEE VAL DE LYS"];
listeLycees["FOURMIES"] = ["LYCEE CAMILLE CLAUDEL"];
listeLycees["GENECH"] = ["LYCEE CHARLOTTE PERRIAND", "LYCEE PRIVE DE GENECH"];
listeLycees["GONDECOURT"] = ["LYCEE MARGUERITE DE FLANDRE"];
listeLycees["GRANDE SYNTHE"] = ["LYCEE DU NOORDOVER"];
listeLycees["HAUBOURDIN"] = ["LYCEE DE BEAUPRE"];
listeLycees["HAZEBROUCK"] = ["LYCEE DES FLANDRES", "LYCEE FONDATION DEPOORTER", "LYCEE SAINT JACQUES", "LYCEE SAINT JOSEPH"];
listeLycees["LA MADELEINE"] = ["LYCEE VALENTINE LABBE"];
listeLycees["LAMBERSART"] = ["LYCEE JEAN PERRIN", "LYCEE SAINTE ODILE"];
listeLycees["LANDRECIES"] = ["LYCEE DUPLEIX"];
listeLycees["LE CATEAU CAMBRESIS"] = ["LYCEE CAMILLE DESMOULINS (GENERAL ET TECHNO.)"];
listeLycees["LE QUESNOY"] = ["LYCEE EUGENE THOMAS (GENERAL ET TECHNO.)"];
listeLycees["LILLE"] = ["LYCEE CESAR BAGGIO", "LYCEE EUROPEEN MONTEBELLO", "LYCEE FAIDHERBE", "LYCEE FENELON", "LYCEE FREDERIC OZANAM", "LYCEE JEAN PROUVE", "LYCEE LOUIS PASTEUR", "LYCEE NOTRE-DAME D\'ANNAY", "LYCEE NOTRE-DAME DE LA PAIX", "LYCEE PRIVE AVERROES", "LYCEE SAINT PAUL", "LYCEE SAINT PIERRE", "LYCEE SAINTE CLAIRE", "LYCEE ST J-B DE LA SALLE", "LYCEE THERESE D\'AVILA"];
listeLycees["LOOS"] = ["INSTITUT SAINT VINCENT DE PAUL"];
listeLycees["LOUVROIL"] = ["LYCEE TECHNOLOGIQUE"];
listeLycees["MARCQ EN BAROEUL"] = ["LYCEE ACTIVE BILINGUE JEANNINE MANUE", "LYCEE MARCQ", "LYCEE YVES KERNANEC"];
listeLycees["MAUBEUGE"] = ["LYCEE ANDRE LURCAT (GENERAL ET TECHNO.)", "LYCEE NOTRE-DAME DE GRACE", "LYCEE NOTRE-DAME DU TILLEUL", "LYCEE PIERRE FOREST (GENERAL ET TECHNO.)"];
listeLycees["ORCHIES"] = ["LYCEE N-D DE LA PROVIDENCE"];
listeLycees["ROUBAIX"] = ["ECOLE SUP. ARTS APPLI. TEXTILE", "LYCEE BAUDELAIRE", "LYCEE JEAN MOULIN", "LYCEE JEAN ROSTAND", "LYCEE MAXENCE VAN DER MEERSCH", "LYCEE SAINT MARTIN", "LYCEE SAINT REMI"];
listeLycees["SIN LE NOBLE"] = ["LYCEE ARTHUR RIMBAUD"];
listeLycees["SOLESMES"] = ["LYCEE SAINT MICHEL"];
listeLycees["SOMAIN"] = ["LYCEE HELENE BOUCHER", "LYCEE LOUIS PASTEUR"];
listeLycees["ST AMAND LES EAUX"] = ["LYCEE ERNEST COUTEAUX", "LYCEE NOTRE-DAME DES ANGES"];
listeLycees["TOURCOING"] = ["LYCEE COLBERT", "LYCEE DU SACRE COEUR", "LYCEE GAMBETTA", "LYCEE INDUSTRIEL ET COMMERCIAL PRIVE", "LYCEE JEANNNE D\'ARC", "LYCEE MARIE NOEL", "LYCEE SEVIGNE"];
listeLycees["VALENCIENNES"] = ["LYCEE DAMPIERRE", "LYCEE DE L\'ESCAUT", "LYCEE DE LA SAGESSE", "LYCEE DU HAINAUT", "LYCEE HENRI WALLON", "LYCEE NOTRE-DAME", "LYCEE WATTEAU"];
listeLycees["VILLENEUVE D ASCQ"] = ["LYCEE RAYMOND QUENEAU", "LYCEE SAINT ADRIEN"];
listeLycees["WATTRELOS"] = ["LYCEE EMILE ZOLA (GENERAL ET TECHNO.)"];
listeLycees["BEAUVAIS"] = ["LYCEE FELIX FAURE", "LYCEE FRANCOIS TRUFFAUT", "LYCEE JEANNE HACHETTE", "LYCEE PAUL LANGEVIN", "LYCEE SAINT ESPRIT", "LYCEE SAINT VINCENT DE PAUL"];
listeLycees["CHANTILLY"] = ["LYCEE JEAN ROSTAND"];
listeLycees["CLERMONT"] = ["LYCEE CASSINI"];
listeLycees["COMPIEGNE"] = ["LYCEE CHARLES DE GAULLE", "LYCEE GUYNEMER", "LYCEE MIREILLE GRENET", "LYCEE NOTRE-DAME DE LA TILLOYE", "LYCEE PIERRE D\'AILLY", "LYCEE SEVIGNE"];
listeLycees["CREIL"] = ["LYCEE JULES UHRY"];
listeLycees["CREPY EN VALOIS"] = ["LYCEE JEAN MONNET"];
listeLycees["MERU"] = ["LYCEE CONDORCET"];
listeLycees["MONTATAIRE"] = ["LYCEE ANDRE MALRAUX"];
listeLycees["MORTEFONTAINE"] = ["LYCEE SAINT DOMINIQUE"];
listeLycees["NOGENT SUR OISE"] = ["LYCEE MARIE CURIE"];
listeLycees["NOYON"] = ["LYCEE JEAN CALVIN"];
listeLycees["PONT STE MAXENCE"] = ["LYCEE SAINT JOSEPH DU MONCEL"];
listeLycees["SENLIS"] = ["LYCEE HUGUES CAPET", "LYCEE SAINT VINCENT"];
listeLycees["ALENCON"] = ["LYCEE ALAIN", "LYCEE MARGUERITE DE NAVARRE", "LYCEE ST FRANCOIS DE SALES (GENERAL ET TECHNO.)"];
listeLycees["ARGENTAN"] = ["LYCEE JEANNE D\'ARC", "LYCEE MEZERAY (GENERAL ET TECHNO.)"];
listeLycees["DOMFRONT"] = ["LYCEE AUGUSTE CHEVALIER"];
listeLycees["FLERS"] = ["LYCEE JEAN GUEHENNO", "LYCEE SAINT THOMAS D\'AQUIN (GENERAL ET TECHNO.)"];
listeLycees["L AIGLE"] = ["LYCEE NAPOLEON"];
listeLycees["LA FERTE MACE"] = ["LYCEE DES ANDAINES"];
listeLycees["MORTAGNE AU PERCHE"] = ["LYCEE BIGNON", "LYCEE JEAN MONNET (GENERAL ET TECHNO.)"];
listeLycees["SEES"] = ["LYCEE MARIE IMMACULEE"];
listeLycees["PARIS"] = ["LYCEE HONORE DE BALZAC", "ECOLE HOTELIERE JEAN DROUANT", "LYCEE CARNOT", "LYCEE ST MICHEL DES BATIGNOLLE", "LYCEE STE URSULE-L.BETTIGNIES"];
listeLycees["PARIS 03"] = ["LYCEE SIMONE WEIL", "LYCEE TURGOT", "LYCEE VICTOR HUGO"];
listeLycees["PARIS 04"] = ["LYCEE CHARLEMAGNE", "LYCEE DES FRANCS BOURGEOIS", "LYCEE MASSILLON", "LYCEE SOPHIE GERMAIN"];
listeLycees["PARIS 05"] = ["LYCEE HENRI IV", "LYCEE JACQUES MONOD (GENERAL ET TECHNO.)", "LYCEE LAVOISIER", "LYCEE LOUIS LE GRAND", "LYCEE LOUISE DE MARILLAC", "LYCEE SEVIGNE"];
listeLycees["PARIS 06"] = ["ECOLE ALSACIENNE", "LYCEE CARCADO SAISSEVAL", "LYCEE FENELON", "LYCEE MAXIMILIEN VOX-ART-DESSIN (GENERAL ET TECHNO.)", "LYCEE MONTAIGNE", "LYCEE NOTRE-DAME DE SION", "LYCEE SAINT NICOLAS", "LYCEE SAINT SULPICE", "LYCEE SAINTE GENEVIEVE", "LYCEE STANISLAS"];
listeLycees["PARIS 07"] = ["LYCEE ALBERT DE MUN (GENERAL ET TECHNO.)", "LYCEE D\'HULST", "LYCEE DE L\'ALMA", "LYCEE LA ROCHEFOUCAULD", "LYCEE PAUL CLAUDEL", "LYCEE SAINT THOMAS D\'AQUIN", "LYCEE SAINTE JEANNE ELISABETH", "LYCEE VICTOR DURUY"];
listeLycees["PARIS 08"] = ["ECOLE ACTIVE BILINGUE ETOILE", "LYCEE CHAPTAL", "LYCEE FENELON-SAINTE MARIE", "LYCEE RACINE"];
listeLycees["PARIS 09"] = ["LYCEE CONDORCET", "LYCEE EDGAR QUINET (GENERAL ET TECHNO.)", "LYCEE JACQUES DECOUR", "LYCEE JULES FERRY", "LYCEE LAMARTINE", "LYCEE SAINT LOUIS"];
listeLycees["PARIS 10"] = ["LYCEE BOSSUET-NOTRE-DAME", "LYCEE COLBERT", "LYCEE EDGAR POE", "LYCEE ROCROY-SAINT LEON"];
listeLycees["PARIS 11"] = ["LYCEE CHARLES PEGUY", "LYCEE DES PETITS CHAMPS", "LYCEE DORIAN", "LYCEE OZAR HATORAH", "LYCEE VOLTAIRE"];
listeLycees["PARIS 12"] = ["LYCEE ARAGO", "LYCEE BOULLE ESAA", "LYCEE ELISA LEMONNIER (GENERAL ET TECHNO.)", "LYCEE GEORGES LEVEN", "LYCEE PAUL VALERY", "LYCEE SAINT MICHEL DE PICPUS", "LYCEE SAINT PIERRE-FOURIER"];
listeLycees["PARIS 13"] = ["ECOLE NAT.CHIMIE PHYS.BIOLOGIE", "LYCEE CLAUDE MONET", "LYCEE GABRIEL FAURE", "LYCEE JEAN LURCAT (GENERAL ET TECHNO.)", "LYCEE LE REBOURS", "LYCEE NOTRE-DAME DE FRANCE", "LYCEE RODIN", "LYCEE TEC SUP DU LABORATOIRE", "LYCEE YABNE"];
listeLycees["PARIS 14"] = ["LYCEE CATHERINE LABOURE (GENERAL ET TECHNO.)", "LYCEE EMILE DUBOIS", "LYCEE FRANCOIS VILLON", "LYCEE GUILLAUME TIREL", "LYCEE PAUL BERT", "LYCEE RASPAIL"];
listeLycees["PARIS 15"] = ["ECOLE ACTIVE BILINGUE J.MANUEL", "LYCEE AUTOGERE", "LYCEE BLOMET", "LYCEE BUFFON", "LYCEE CAMILLE SEE", "LYCEE FRESNEL", "LYCEE LOUIS ARMAND (GENERAL ET TECHNO.)", "LYCEE ROGER VERLOMME", "LYCEE SAINT LAMBERT - BATIMENT", "LYCEE SAINTE ELISABETH"];
listeLycees["PARIS 16"] = ["LYCEE CLAUDE BERNARD", "LYCEE DE L\'ASSOMPTION", "LYCEE DE LA TOUR", "LYCEE GERSON", "LYCEE JANSON DE SAILLY", "LYCEE JEAN DE LA FONTAINE", "LYCEE JEAN-BAPTISTE SAY", "LYCEE MOLIERE", "LYCEE N-D DES OISEAUX (JOUR)", "LYCEE PASCAL", "LYCEE PASSY - SAINT HONORE", "LYCEE SAINT JEAN DE PASSY", "LYCEE SAINT LOUIS DE GONZAGUE"];
listeLycees["PARIS 17"] = ["LYCEE HONORE DE BALZAC", "ECOLE HOTELIERE JEAN DROUANT", "LYCEE CARNOT", "LYCEE ST MICHEL DES BATIGNOLLE", "LYCEE STE URSULE-L.BETTIGNIES"];
listeLycees["PARIS 18"] = ["LYCEE AUGUSTE RENOIR", "LYCEE CHARLES DE FOUCAULD", "LYCEE RABELAIS", "LYCEE SINAI"];
listeLycees["PARIS 19"] = ["LYCEE BETH HANNA", "LYCEE DIDEROT (GENERAL ET TECHNO.)", "LYCEE GEORGES BRASSENS", "LYCEE HENRI BERGSON", "LYCEE JACQUARD", "LYCEE JULES RICHARD-MICROTECH. (GENERAL ET TECHNO.)", "LYCEE LUCIEN DE HIRSCH"];
listeLycees["PARIS 20"] = ["LYCEE HEIKHAL MENAHEM SINAI", "LYCEE HELENE BOUCHER", "LYCEE MAURICE RAVEL"];
listeLycees["AIRE SUR LA LYS"] = ["LYCEE VAUBAN (GENERAL ET TECHNO.)"];
listeLycees["ARRAS"] = ["LYCEE BAUDIMONT SAINT-CHARLES", "LYCEE GUY MOLLET (GENERAL ET TECHNO.)", "LYCEE ROBESPIERRE"];
listeLycees["AUCHEL"] = ["LYCEE LAVOISIER"];
listeLycees["AVION"] = ["LYCEE PABLO PICASSO (GENERAL ET TECHNO.)"];
listeLycees["BERCK"] = ["LYCEE JAN LAVEZZARI"];
listeLycees["BETHUNE"] = ["LYCEE ANDRE MALRAUX", "LYCEE LOUIS BLARINGHEM", "LYCEE ST VAAST-ST DOMINIQUE"];
listeLycees["BEUVRY"] = ["LYCEE MARGUERITE YOURCENAR"];
listeLycees["BOULOGNE SUR MER"] = ["LYCEE EDOUARD BRANLY (GENERAL ET TECHNO.)", "LYCEE HAFFREINGUE CHANLAIRE", "LYCEE MARIETTE"];
listeLycees["BRUAY LA BUISSIERE"] = ["LYCEE CARNOT (GENERAL ET TECHNO.)"];
listeLycees["BULLY LES MINES"] = ["LYCEE LEO LAGRANGE"];
listeLycees["CALAIS"] = ["LYCEE LEONARD DE VINCI (GENERAL ET TECHNO.)", "LYCEE PIERRE DE COUBERTIN", "LYCEE SAINT PIERRE", "LYCEE SOPHIE BERTHELOT"];
listeLycees["CARVIN"] = ["LYCEE DIDEROT"];
listeLycees["GUINES"] = ["LYCEE JEAN BOSCO"];
listeLycees["HENIN BEAUMONT"] = ["LYCEE FERNAND DARCHICOURT", "LYCEE LOUIS PASTEUR"];
listeLycees["LE TOUQUET PARIS PLAGE"] = ["LYCEE HOTELIER"];
listeLycees["LENS"] = ["LYCEE AUGUSTE BEHAL", "LYCEE CONDORCET", "LYCEE SAINT PAUL"];
listeLycees["LIEVIN"] = ["LYCEE HENRI DARRAS"];
listeLycees["LILLERS"] = ["LYCEE ANATOLE FRANCE"];
listeLycees["LONGUENESSE"] = ["LYCEE BLAISE PASCAL", "PENSIONNAT SAINT JOSEPH"];
listeLycees["MONTREUIL"] = ["LYCEE EUGENE WOILLEZ (GENERAL ET TECHNO.)"];
listeLycees["MONTREUIL"] = ["LYCEE CONDORCET", "LYCEE EUGENIE COTTON", "LYCEE HENRI MATISSE", "LYCEE JEAN JAURES (GENERAL ET TECHNO.)", "LYCEE ORT"];
listeLycees["NOEUX LES MINES"] = ["LYCEE D\'ARTOIS (GENERAL ET TECHNO.)"];
listeLycees["ST MARTIN BOULOGNE"] = ["LYCEE GIRAUX SANNIER (GENERAL ET TECHNO.)", "LYCEE SAINT JOSEPH"];
listeLycees["ST OMER"] = ["LYCEE ALEXANDRE RIBOT", "LYCEE DE L\'AA", "LYCEE NOTRE-DAME DE SION", "LYCEE SAINT DENIS"];
listeLycees["ST POL SUR TERNOISE"] = ["LYCEE ALBERT CHATELET"];
listeLycees["WINGLES"] = ["LYCEE VOLTAIRE"];
listeLycees["AMBERT"] = ["LYCEE BLAISE PASCAL (GENERAL ET TECHNO.)"];
listeLycees["CHAMALIERES"] = ["LYCEE", "LYCEE SAINTE THECLE"];
listeLycees["CLERMONT FERRAND"] = ["LYCEE AMBROISE BRUGIERE", "LYCEE BLAISE PASCAL", "LYCEE FENELON", "LYCEE GODEFROY DE BOUILLON", "LYCEE JEANNE D\'ARC", "LYCEE LA FAYETTE", "LYCEE MASSILLON", "LYCEE SAINT ALYRE", "LYCEE SIDOINE APOLLINAIRE"];
listeLycees["COURNON D AUVERGNE"] = ["LYCEE RENE DESCARTES"];
listeLycees["COURPIERE"] = ["LYCEE SAINT PIERRE"];
listeLycees["ISSOIRE"] = ["LYCEE MURAT", "LYCEE SEVIGNE-SAINT LOUIS"];
listeLycees["RIOM"] = ["LYCEE C. ET P. VIRLOGEUX", "LYCEE PIERRE JOEL BONTE (GENERAL ET TECHNO.)", "LYCEE SAINTE MARIE"];
listeLycees["THIERS"] = ["LYCEE JEAN ZAY", "LYCEE MONTDORY"];
listeLycees["ANGLET"] = ["LYCEE SAINTE ANNE", "LYCEE TECHNOLOG. CANTAU"];
listeLycees["BAYONNE"] = ["LYCEE BERNAT ETXEPARE", "LYCEE LARGENTE", "LYCEE LOUIS DE FOIX", "LYCEE RENE CASSIN", "LYCEE SAINT LOUIS VILLA PIA"];
listeLycees["BIARRITZ"] = ["LYCEE EXPERIMENTAL A.MALRAUX", "LYCEE HOTELLERIE BIARRITZ ATLANTIQUE"];
listeLycees["CHERAUTE"] = ["LYCEE DU PAYS DE SOULE"];
listeLycees["HASPARREN"] = ["LYCEE ST JOSEPH"];
listeLycees["LESCAR"] = ["LYCEE JACQUES MONOD"];
listeLycees["LESTELLE BETHARRAM"] = ["LYCEE NOTRE-DAME DE BETHARRAM"];
listeLycees["MOURENX"] = ["LYCEE DE LA CHIMIE ALBERT CAMUS"];
listeLycees["NAY"] = ["LYCEE NATIONALISE MIXTE", "LYCEE SAINT JOSEPH"];
listeLycees["OLORON STE MARIE"] = ["LYCEE JULES SUPERVIELLE", "LYCEE SAINT JOSEPH"];
listeLycees["ORTHEZ"] = ["LYCEE GASTON FEBUS", "LYCEE JEANNE D\'ARC"];
listeLycees["PAU"] = ["LYCEE IMMAC.CONCEPT.BEAU FRENE", "LYCEE LOUIS BARTHOU", "LYCEE SAINT CRICQ (GENERAL ET TECHNO.)", "LYCEE SAINT DOMINIQUE", "LYCEE SAINT JOHN PERSE"];
listeLycees["ST JEAN DE LUZ"] = ["LYCEE MAURICE RAVEL", "LYCEE SAINT THOMAS D\'AQUIN"];
listeLycees["ST JEAN PIED DE PORT"] = ["LYCEE DE NAVARRE (GENERAL ET TECHNO.)"];
listeLycees["USTARITZ"] = ["LYCEE SAINT JOSEPH"];
listeLycees["CANET EN ROUSSILLON"] = ["LYCEE ROSA LUXEMBURG (GENERAL ET TECHNO.)"];
listeLycees["CERET"] = ["LYCEE DEODAT DE SEVERAC (GENERAL ET TECHNO.)"];
listeLycees["FONT ROMEU ODEILLO VIA"] = ["LYCEE PIERRE DE COUBERTIN"];
listeLycees["PERPIGNAN"] = ["LYCEE ARISTIDE MAILLOL (GENERAL ET TECHNO.)", "LYCEE FRANCOIS ARAGO", "LYCEE JEAN LURCAT (GENERAL ET TECHNO.)", "LYCEE LYCEE HOTEL.ECON.MOULIN A VENT", "LYCEE N-D DE BON SECOURS", "LYCEE PABLO PICASSO", "LYCEE SAINT LOUIS DE GONZAGUE", "LYCEE STE LOUISE DE MARILLAC (GENERAL ET TECHNO.)"];
listeLycees["PRADES"] = ["LYCEE CHARLES RENOUVIER (GENERAL ET TECHNO.)"];
listeLycees["BRAS PANON"] = ["LYCEE BRAS PANON (GENERAL ET TECHNO.)"];
listeLycees["LA POSSESSION"] = ["LYCEE DE LA POSSESSION (GENERAL ET TECHNO.)"];
listeLycees["LE PORT"] = ["LYCEE JEAN HINGLO"];
listeLycees["LE TAMPON"] = ["LYCEE BOISJOLY POTIER (GENERAL ET TECHNO.)", "LYCEE PIERRE LAGOURGUE (GENERAL ET TECHNO.)", "LYCEE ROLAND GARROS (GENERAL ET TECHNO.)"];
listeLycees["LES AVIRONS"] = ["LYCEE LES AVIRONS (GENERAL ET TECHNO.)"];
listeLycees["LES TROIS BASSINS"] = ["LYCEE DE TROIS BASSINS (GENERAL ET TECHNO.)"];
listeLycees["ST ANDRE"] = ["LYCEE MAHATMA GANDHI", "LYCEE SARDA GARRIGA"];
listeLycees["ST BENOIT"] = ["LYCEE AMIRAL PIERRE BOUVET", "LYCEE DE BRAS FUSIL (GENERAL ET TECHNO.)"];
listeLycees["ST DENIS"] = ["LYCEE APPLICATION DE L\'ENNA", "LYCEE J-B DE LA SALLE", "LYCEE PAUL ELUARD (GENERAL ET TECHNO.)", "LYCEE SUGER"];
listeLycees["ST DENIS"] = ["LYCEE BELLEPIERRE", "LYCEE CATHOLIQUE LEVAVASSEUR", "LYCEE GEORGES BRASSENS", "LYCEE LECONTE DE LISLE", "LYCEE LISLET GEOFFROY"];
listeLycees["ST JOSEPH"] = ["LYCEE DE VINCENDO (GENERAL ET TECHNO.)", "LYCEE PIERRE POIVRE"];
listeLycees["ST LEU"] = ["LYCEE STELLA (GENERAL ET TECHNO.)"];
listeLycees["ST LOUIS"] = ["LYCEE JEAN MERMOZ (GENERAL ET TECHNO.)"];
listeLycees["ST LOUIS"] = ["LYCEE ANTOINE ROUSSIN", "LYCEE JEAN JOLY"];
listeLycees["ST PAUL"] = ["LYCEE DE ST PAUL 4 (GENERAL ET TECHNO.)", "LYCEE EVARISTE DE PARNY", "LYCEE HOTELIER LA RENAISSANCE", "LYCEE LOUIS PAYEN"];
listeLycees["ST PIERRE"] = ["LYCEE AMBROISE VOLLARD", "LYCEE DE BOIS D\'OLIVE (GENERAL ET TECHNO.)", "LYCEE SAINT CHARLES (GENERAL ET TECHNO.)"];
listeLycees["STE MARIE"] = ["LYCEE NORD ATLANTIQUE (GENERAL ET TECHNO.)"];
listeLycees["STE MARIE"] = ["LYCEE LE VERGER-SAINTE MARIE"];
listeLycees["STE SUZANNE"] = ["LYCEE BEL AIR"];
listeLycees["BELLEVILLE"] = ["LYCEE AIGUERANDE (GENERAL ET TECHNO.)"];
listeLycees["BRON"] = ["LYCEE JEAN-PAUL SARTRE"];
listeLycees["CHARBONNIERES LES BAINS"] = ["LYCEE BLAISE PASCAL"];
listeLycees["DARDILLY"] = ["LYCEE FRANCOIS RABELAIS"];
listeLycees["DECINES CHARPIEU"] = ["LYCEE CHAPLIN BECQUEREL (GENERAL ET TECHNO.)"];
listeLycees["GIVORS"] = ["LYCEE LOUIS ARAGON"];
listeLycees["LA MULATIERE"] = ["LYCEE DE L\'ASSOMPTION-BELLEVUE"];
listeLycees["LYON 01"] = ["INSTITUTION DES CHARTREUX", "LYCEE J-B DE LA SALLE", "LYCEE LA MARTINIERE DIDEROT", "LYCEE SAINT BRUNO-SAINT LOUIS"];
listeLycees["LYON 02"] = ["LYCEE AMPERE", "LYCEE CHEVREUL", "LYCEE JULIETTE R?CAMIER", "LYCEE SAINT MARC"];
listeLycees["LYON 03"] = ["LYCEE CHARLES DE FOUCAULD", "LYCEE LACASSAGNE"];
listeLycees["LYON 04"] = ["LYCEE ANTOINE DE SAINT-EXUPERY"];
listeLycees["LYON 05"] = ["EXTERNAT SAINTE MARIE", "LYCEE AUX LAZARISTES", "LYCEE DON BOSCO", "LYCEE EDOUARD BRANLY", "LYCEE LA FAVORITE SAINTE THERESE", "LYCEE NOTRE-DAME DES MINIMES", "LYCEE SAINT JUST"];
listeLycees["LYON 06"] = ["EXTERNAT DE LA TRINITE", "LYCEE DEBORDE", "LYCEE DU PARC", "LYCEE EDOUARD HERRIOT"];
listeLycees["LYON 07"] = ["LYCEE BELMONT-CAPDEPON", "LYCEE CHEVREUL LESTONNAC", "LYCEE DE GERLAND"];
listeLycees["LYON 08"] = ["LYCEE AUGUSTE ET LOUIS LUMIERE", "LYCEE COLBERT", "LYCEE LA MACHE", "LYCEE LA MARTINIERE MONPLAISIR", "LYCEE LA XAVIERE", "LYCEE O R T", "LYCEE PIERRE TERMIER"];
listeLycees["LYON 09"] = ["LYCEE JEAN PERRIN", "LYCEE LA MARTINIERE DUCHERE"];
listeLycees["NEUVILLE SUR SAONE"] = ["LYCEE NOTRE-DAME DE BELLEGARDE", "LYCEE ROSA PARKS"];
listeLycees["OULLINS"] = ["LYCEE DES CHASSAGNES", "LYCEE PARC CHABRIERES", "LYCEE SAINT THOMAS D\'AQUIN"];
listeLycees["RILLIEUX LA PAPE"] = ["LYCEE ALBERT CAMUS"];
listeLycees["SAIN BEL"] = ["LYCEE GERMAINE TILLION"];
listeLycees["ST GENIS LAVAL"] = ["LYCEE RENE DESCARTES"];
listeLycees["ST PRIEST"] = ["LYCEE CONDORCET"];
listeLycees["ST SYMPHORIEN SUR COISE"] = ["LYCEE CHAMPAGNAT"];
listeLycees["TARARE"] = ["LYCEE NOTRE-DAME DE BEL AIR", "LYCEE RENE CASSIN"];
listeLycees["TASSIN LA DEMI LUNE"] = ["LYCEE SAINT JOSEPH"];
listeLycees["THIZY"] = ["LYCEE FRANCOIS MANSART (GENERAL ET TECHNO.)"];
listeLycees["VAULX EN VELIN"] = ["LYCEE ROBERT DOISNEAU"];
listeLycees["VENISSIEUX"] = ["LYCEE JACQUES BREL", "LYCEE MARCEL SEMBAT"];
listeLycees["VILLEFRANCHE SUR SAONE"] = ["LYCEE CLAUDE BERNARD", "LYCEE LOUIS ARMAND (GENERAL ET TECHNO.)", "LYCEE NOTRE-DAME", "LYCEE NOTRE-DAME DE MONGRE"];
listeLycees["VILLEURBANNE"] = ["LYCEE FREDERIC FAYS", "LYCEE IMMACULEE CONCEPTION", "LYCEE JUIF DE LYON", "LYCEE PIERRE BROSSOLETTE"];
listeLycees["AUTUN"] = ["LYCEE BONAPARTE", "LYCEE SAINT LAZARE (GENERAL ET TECHNO.)"];
listeLycees["CHALON SUR SAONE"] = ["LYCEE CATHOLIQUE CHALONNAIS (GENERAL ET TECHNO.)", "LYCEE EMILAND GAUTHEY (GENERAL ET TECHNO.)", "LYCEE HILAIRE DE CHARDONNET (GENERAL ET TECHNO.)", "LYCEE MATHIAS (GENERAL ET TECHNO.)", "LYCEE NICEPHORE NIEPCE", "LYCEE PONTUS DE TYARD"];
listeLycees["CHAROLLES"] = ["LYCEE JULIEN WITTMER (GENERAL ET TECHNO.)"];
listeLycees["CLUNY"] = ["LYCEE LA PRAT\'S"];
listeLycees["DIGOIN"] = ["LYCEE CAMILLE CLAUDEL"];
listeLycees["LE CREUSOT"] = ["LYCEE L?ON BLUM (GENERAL ET TECHNO.)"];
listeLycees["LOUHANS"] = ["LYCEE HENRI VINCENOT (GENERAL ET TECHNO.)"];
listeLycees["MACON"] = ["LYCEE FREDERIC OZANAM (GENERAL ET TECHNO.)", "LYCEE LAMARTINE", "LYCEE RENE CASSIN"];
listeLycees["MONTCEAU LES MINES"] = ["LYCEE HENRI PARRIAT"];
listeLycees["PARAY LE MONIAL"] = ["LYCEE DU SACRE COEUR (GENERAL ET TECHNO.)", "LYCEE JEANNE D\'ARC"];
listeLycees["TOURNUS"] = ["LYCEE GABRIEL VOISIN"];
listeLycees["ALLONNES"] = ["LYCEE ANDRE MALRAUX"];
listeLycees["CHATEAU DU LOIR"] = ["LYCEE RACAN"];
listeLycees["LA FERTE BERNARD"] = ["LYCEE ROBERT GARNIER (GENERAL ET TECHNO.)", "LYCEE SAINT PAUL - NOTRE-DAME"];
listeLycees["LA FLECHE"] = ["LYCEE D\'ESTOURNELLES DE CONSTANT (GENERAL ET TECHNO.)", "LYCEE NOTRE-DAME"];
listeLycees["LE MANS"] = ["LYCEE BELLEVUE", "LYCEE GABRIEL TOUCHARD - WASHINGTON (GENERAL ET TECHNO.)", "LYCEE LE MANS SUD (GENERAL ET TECHNO.)", "LYCEE MARGUERITE YOURCENAR", "LYCEE MONTESQUIEU", "LYCEE NOTRE-DAME", "LYCEE NOTRE-DAME DE STE CROIX", "LYCEE SAINT CHARLES"];
listeLycees["MAMERS"] = ["LYCEE PERSEIGNE (GENERAL ET TECHNO.)"];
listeLycees["PARIGNE LE POLIN"] = ["LYCEE SAINT MICHEL DES PERRAIS"];
listeLycees["PRUILLE LE CHETIF"] = ["LYCEE SAINT JOSEPH LORRAINE (GENERAL ET TECHNO.)"];
listeLycees["SABLE SUR SARTHE"] = ["LYCEE COLBERT DE TORCY - CH. CROS", "LYCEE SAINTE ANNE (GENERAL ET TECHNO.)"];
listeLycees["SILLE LE GUILLAUME"] = ["LYCEE PAUL SCARRON"];
listeLycees["AIX LES BAINS"] = ["LYCEE MARLIOZ (GENERAL ET TECHNO.)"];
listeLycees["ALBERTVILLE"] = ["LYCEE JEAN MOULIN", "LYCEE JEANNE D\'ARC"];
listeLycees["BOURG ST MAURICE"] = ["LYCEE SAINT EXUPERY"];
listeLycees["CHAMBERY"] = ["LYCEE LOUIS ARMAND", "LYCEE MONGE", "LYCEE SAINT AMBROISE", "LYCEE VAUGELAS"];
listeLycees["LA RAVOIRE"] = ["LYCEE DU GRANIER"];
listeLycees["MOUTIERS TARENTAISE"] = ["LYCEE AMBROISE CROIZAT"];
listeLycees["ST JEAN DE MAURIENNE"] = ["LYCEE PAUL HEROULT"];
listeLycees["UGINE"] = ["LYCEE RENE PERRIN"];
listeLycees["AVON"] = ["LYCEE URUGUAY FRANCE"];
listeLycees["BRIE COMTE ROBERT"] = ["LYCEE BLAISE PASCAL (GENERAL ET TECHNO.)"];
listeLycees["BUSSY ST GEORGES"] = ["LYCEE MARTIN LUTHER KING", "LYCEE MAURICE RONDEAU"];
listeLycees["CESSON"] = ["LYCEE SONIA DELAUNAY (GENERAL ET TECHNO.)"];
listeLycees["CHAMPAGNE SUR SEINE"] = ["LYCEE LA FAYETTE"];
listeLycees["CHAMPS SUR MARNE"] = ["LYCEE RENE DESCARTES (GENERAL ET TECHNO.)"];
listeLycees["CHELLES"] = ["LYCEE GASTON BACHELARD", "LYCEE GUY GASNIER-SAINTE BATHILDE", "LYCEE JEHAN DE CHELLES"];
listeLycees["COMBS LA VILLE"] = ["LYCEE GALILEE"];
listeLycees["CONGIS SUR THEROUANNE"] = ["LYCEE DU GUE A TRESMES"];
listeLycees["COULOMMIERS"] = ["LYCEE JULES FERRY"];
listeLycees["DAMMARIE LES LYS"] = ["LYCEE FREDERIC JOLIOT CURIE"];
listeLycees["FONTAINEBLEAU"] = ["LYCEE BLANCHE DE CASTILLE (GENERAL ET TECHNO.)", "LYCEE FRANCOIS 1ER", "LYCEE FRANCOIS COUPERIN", "LYCEE JEANNE D\'ARC SAINT ASPAIS"];
listeLycees["JUILLY"] = ["LYCEE LIBRE DE JUILLY"];
listeLycees["LA FERTE SOUS JOUARRE"] = ["LYCEE SAINTE CELINE", "LYCEE SAMUEL BECKETT (GENERAL ET TECHNO.)"];
listeLycees["LA ROCHETTE"] = ["LYCEE BENJAMIN FRANKLIN"];
listeLycees["LAGNY SUR MARNE"] = ["LYCEE ST LAURENT-LA PAIX N-D", "LYCEE VAN DONGEN"];
listeLycees["LE MEE SUR SEINE"] = ["LYCEE GEORGE SAND"];
listeLycees["LOGNES"] = ["LYCEE EMILY BRONTE"];
listeLycees["LONGPERRIER"] = ["LYCEE CHARLES DE GAULLE"];
listeLycees["MEAUX"] = ["LYCEE BOSSUET", "LYCEE HENRI MOISSAN", "LYCEE JEAN VILAR", "LYCEE PIERRE DE COUBERTIN", "LYCEE SAINTE MARIE-TECHNO."];
listeLycees["MELUN"] = ["LYCEE JACQUES AMYOT", "LYCEE LEONARD DE VINCI", "LYCEE SAINT ASPAIS (GENERAL ET TECHNO.)"];
listeLycees["MITRY MORY"] = ["LYCEE HONORE DE BALZAC (GENERAL ET TECHNO.)"];
listeLycees["MOISSY CRAMAYEL"] = ["LYCEE DE LA MARE CARREE (GENERAL ET TECHNO.)"];
listeLycees["MONTEREAU FAULT YONNE"] = ["LYCEE ANDRE MALRAUX"];
listeLycees["NANGIS"] = ["LYCEE HENRI BECQUEREL (GENERAL ET TECHNO.)"];
listeLycees["NEMOURS"] = ["LYCEE ETIENNE BEZOUT"];
listeLycees["NOISIEL"] = ["LYCEE GERARD DE NERVAL (GENERAL ET TECHNO.)", "LYCEE RENE CASSIN (GENERAL ET TECHNO.)"];
listeLycees["OZOIR LA FERRIERE"] = ["LYCEE CAMPUS SAINTE THERESE", "LYCEE LINO VENTURA"];
listeLycees["PONTAULT COMBAULT"] = ["LYCEE CAMILLE CLAUDEL"];
listeLycees["PROVINS"] = ["LYCEE LES PANNEVELLES", "LYCEE SAINTE CROIX", "LYCEE THIBAUT DE CHAMPAGNE (GENERAL ET TECHNO.)"];
listeLycees["ROISSY EN BRIE"] = ["LYCEE CHARLES LE CHAUVE"];
listeLycees["ROZAY EN BRIE"] = ["LYCEE LA TOUR DES DAMES (GENERAL ET TECHNO.)"];
listeLycees["SAVIGNY LE TEMPLE"] = ["LYCEE ANTONIN CAREME", "LYCEE PIERRE MENDES-FRANCE"];
listeLycees["SERRIS"] = ["LYCEE EMILIE DU CHATELET (GENERAL ET TECHNO.)"];
listeLycees["TORCY"] = ["LYCEE JEAN MOULIN", "LYCEE TORCY (GENERAL ET TECHNO.)"];
listeLycees["TOURNAN EN BRIE"] = ["LYCEE CLEMENT ADER (GENERAL ET TECHNO.)"];
listeLycees["VAUX LE PENIL"] = ["LYCEE SIMONE SIGNORET (GENERAL ET TECHNO.)"];
listeLycees["BARENTIN"] = ["LYCEE THOMAS CORNEILLE"];
listeLycees["BOLBEC"] = ["LYCEE COUBERTIN"];
listeLycees["CANTELEU"] = ["LYCEE GEORGES BAPTISTE"];
listeLycees["DEVILLE LES ROUEN"] = ["LYCEE VALLEE DU CAILLY"];
listeLycees["DIEPPE"] = ["LYCEE DE LA PROVIDENCE", "LYCEE DU GOLF (GENERAL ET TECHNO.)", "LYCEE JEHAN ANGO", "LYCEE PABLO NERUDA"];
listeLycees["ELBEUF"] = ["LYCEE ANDRE MAUROIS", "LYCEE FENELON", "LYCEE FERDINAND BUISSON"];
listeLycees["EU"] = ["LYCEE ANGUIER"];
listeLycees["FECAMP"] = ["LYCEE DE LA PROVIDENCE", "LYCEE GUY DE MAUPASSANT"];
listeLycees["FORGES LES EAUX"] = ["LYCEE E.DELAMARE DEBOUTTEVILLE (GENERAL ET TECHNO.)"];
listeLycees["FRANQUEVILLE ST PIERRE"] = ["LYCEE GALILEE"];
listeLycees["LE GRAND QUEVILLY"] = ["LYCEE DU VAL DE SEINE"];
listeLycees["LE HAVRE"] = ["LYCEE AUGUSTE PERRET", "LYCEE CLAUDE MONET", "LYCEE FRANCOIS 1ER", "LYCEE FRANCOISE DE GRACE", "LYCEE JULES LE CESNE", "LYCEE JULES SIEGFRIED", "LYCEE PORTE OCEANE (GENERAL ET TECHNO.)", "LYCEE ROBERT SCHUMAN", "LYCEE SAINT JOSEPH"];
listeLycees["LE MESNIL ESNARD"] = ["LYCEE DE LA PROVIDENCE", "LYCEE LA CHATAIGNERAIE (GENERAL ET TECHNO.)"];
listeLycees["LILLEBONNE"] = ["LYCEE GUILLAUME LE CONQUERANT"];
listeLycees["MONTIVILLIERS"] = ["LYCEE MONTIVIL"];
listeLycees["NEUFCHATEL EN BRAY"] = ["LYCEE GEORGES BRASSENS (GENERAL ET TECHNO.)"];
listeLycees["ROUEN"] = ["LYCEE BLAISE PASCAL (GENERAL ET TECHNO.)", "LYCEE CAMILLE SAINT-SAENS", "LYCEE DU SACRE COEUR", "LYCEE GUSTAVE FLAUBERT", "LYCEE J-B DE LA SALLE", "LYCEE JEAN-PAUL II", "LYCEE JEANNE D\'ARC", "LYCEE LES TOURELLES (GENERAL ET TECHNO.)", "LYCEE PIERRE CORNEILLE", "LYCEE PROVIDENCE MISERICORDE (GENERAL ET TECHNO.)", "LYCEE REY"];
listeLycees["SOTTEVILLE LES ROUEN"] = ["LYCEE LES BRUYERES", "LYCEE MARCEL SEMBAT"];
listeLycees["ST ETIENNE DU ROUVRAY"] = ["LYCEE LE CORBUSIER"];
listeLycees["ST VALERY EN CAUX"] = ["LYCEE DE LA COTE D\'ALBATRE"];
listeLycees["STE ADRESSE"] = ["LYCEE JEANNE D\'ARC (GENERAL ET TECHNO.)"];
listeLycees["YVETOT"] = ["LYCEE JEAN XXIII", "LYCEE RAYMOND QUENEAU (GENERAL ET TECHNO.)"];
listeLycees["AUBERVILLIERS"] = ["LYCEE HENRI WALLON", "LYCEE LE CORBUSIER"];
listeLycees["AULNAY SOUS BOIS"] = ["LYCEE DE L\'ESPERANCE", "LYCEE JEAN ZAY (GENERAL ET TECHNO.)", "LYCEE VOILLAUME", "PROTECTORAT SAINT JOSEPH (GENERAL ET TECHNO.)"];
listeLycees["BAGNOLET"] = ["LYCEE EUGENE HENAFF"];
listeLycees["BOBIGNY"] = ["LYCEE CHARLES PEGUY", "LYCEE LOUISE MICHEL"];
listeLycees["BONDY"] = ["LYCEE DE L\'ASSOMPTION (GENERAL ET TECHNO.)", "LYCEE JEAN RENOIR"];
listeLycees["CLICHY SOUS BOIS"] = ["LYCEE ALFRED NOBEL (GENERAL ET TECHNO.)"];
listeLycees["DRANCY"] = ["LYCEE EUGENE DELACROIX (GENERAL ET TECHNO.)", "LYCEE PAUL LE ROLLAND (GENERAL ET TECHNO.)"];
listeLycees["DUGNY"] = ["LYCEE FRANCOIS RABELAIS"];
listeLycees["EPINAY SUR SEINE"] = ["LYCEE JACQUES FEYDER"];
listeLycees["GAGNY"] = ["LYCEE GUSTAVE EIFFEL", "LYCEE MERKAZ HATORAH GARCONS"];
listeLycees["LA COURNEUVE"] = ["LYCEE JACQUES BREL"];
listeLycees["LE BLANC MESNIL"] = ["LYCEE JEAN MOULIN (GENERAL ET TECHNO.)", "LYCEE WOLFGANG AMADEUS MOZART"];
listeLycees["LE RAINCY"] = ["LYCEE ALBERT SCHWEITZER", "LYCEE MERKAZ HATORAH FILLES", "LYCEE RENE CASSIN (GENERAL ET TECHNO.)", "LYCEE SAINT LOUIS-SAINTE CLOTILDE"];
listeLycees["LES LILAS"] = ["LYCEE PAUL ROBERT (GENERAL ET TECHNO.)"];
listeLycees["LES PAVILLONS SOUS BOIS"] = ["LYCEE DE L\'ALLIANCE"];
listeLycees["LIVRY GARGAN"] = ["LYCEE ANDRE BOULLOCHE", "LYCEE HENRI SELLIER (GENERAL ET TECHNO.)"];
listeLycees["MONTREUIL"] = ["LYCEE EUGENE WOILLEZ (GENERAL ET TECHNO.)"];
listeLycees["MONTREUIL"] = ["LYCEE CONDORCET", "LYCEE EUGENIE COTTON", "LYCEE HENRI MATISSE", "LYCEE JEAN JAURES (GENERAL ET TECHNO.)", "LYCEE ORT"];
listeLycees["NEUILLY SUR MARNE"] = ["LYCEE NICOLAS-JOSEPH CUGNOT"];
listeLycees["NOISY LE GRAND"] = ["LYCEE EVARISTE GALOIS (GENERAL ET TECHNO.)", "LYCEE FLORA TRISTAN", "LYCEE FRANCOISE CABRINI"];
listeLycees["NOISY LE SEC"] = ["LYCEE OLYMPE DE GOUGES"];
listeLycees["PANTIN"] = ["LYCEE LUCIE AUBRAC (GENERAL ET TECHNO.)", "LYCEE MARCELIN BERTHELOT"];
listeLycees["ROMAINVILLE"] = ["LYCEE LIBERTE"];
listeLycees["ROSNY SOUS BOIS"] = ["LYCEE CHARLES DE GAULLE"];
listeLycees["SEVRAN"] = ["LYCEE BLAISE CENDRARS (GENERAL ET TECHNO.)"];
listeLycees["ST DENIS"] = ["LYCEE APPLICATION DE L\'ENNA", "LYCEE J-B DE LA SALLE", "LYCEE PAUL ELUARD (GENERAL ET TECHNO.)", "LYCEE SUGER"];
listeLycees["ST DENIS"] = ["LYCEE BELLEPIERRE", "LYCEE CATHOLIQUE LEVAVASSEUR", "LYCEE GEORGES BRASSENS", "LYCEE LECONTE DE LISLE", "LYCEE LISLET GEOFFROY"];
listeLycees["ST OUEN"] = ["LYCEE AUGUSTE BLANQUI (GENERAL ET TECHNO.)", "LYCEE MARCEL CACHIN"];
listeLycees["STAINS"] = ["LYCEE MAURICE UTRILLO"];
listeLycees["TREMBLAY EN FRANCE"] = ["LYCEE LEONARD DE VINCI"];
listeLycees["VAUJOURS"] = ["LYCEE FENELON"];
listeLycees["VILLEMOMBLE"] = ["LYCEE BLAISE PASCAL (GENERAL ET TECHNO.)", "LYCEE GEORGES CLEMENCEAU", "LYCEE ST LOUIS-BL.DE CASTILLE"];
listeLycees["VILLEPINTE"] = ["LYCEE JEAN ROSTAND (GENERAL ET TECHNO.)"];
listeLycees["ABBEVILLE"] = ["LYCEE BOUCHER DE PERTHES", "LYCEE SAINT PIERRE"];
listeLycees["ALBERT"] = ["LYCEE LAMARCK"];
listeLycees["AMIENS"] = ["LYCEE DE LA PROVIDENCE", "LYCEE DE LA SAINTE FAMILLE", "LYCEE DU SACRE COEUR", "LYCEE EDOUARD BRANLY", "LYCEE EDOUARD GAND", "LYCEE JEAN-BAPTISTE DELAMBRE", "LYCEE LOUIS THUILLIER", "LYCEE MADELEINE MICHELIS", "LYCEE ROBERT DE LUZARCHES", "LYCEE SAINT REMI", "LYCEE SAINT RIQUIER"];
listeLycees["DOULLENS"] = ["LYCEE DE L\'AUTHIE", "LYCEE MONTALEMBERT"];
listeLycees["FRIVILLE ESCARBOTIN"] = ["LYCEE DU VIMEU"];
listeLycees["MONTDIDIER"] = ["LYCEE JEAN RACINE"];
listeLycees["PERONNE"] = ["LYCEE DU SACRE COEUR", "LYCEE PIERRE MENDES-FRANCE"];
listeLycees["ALBI"] = ["LYCEE BELLEVUE", "LYCEE D\'AMBOISE", "LYCEE LAPEROUSE", "LYCEE LOUIS RASCOL", "LYCEE SAINTE CECILE"];
listeLycees["CARMAUX"] = ["LYCEE JEAN JAURES"];
listeLycees["CASTRES"] = ["LYCEE BARRAL", "LYCEE BORDE BASSE", "LYCEE NOTRE-DAME"];
listeLycees["GAILLAC"] = ["LYCEE S.JOSEPH DE L\'APPARITION", "LYCEE VICTOR HUGO"];
listeLycees["LAVAUR"] = ["LYCEE LAS CASES"];
listeLycees["MAZAMET"] = ["LYCEE JEANNE D\'ARC", "LYCEE MARECHAL SOULT"];
listeLycees["CASTELSARRASIN"] = ["LYCEE JEAN DE PRADES"];
listeLycees["MOISSAC"] = ["LYCEE FRANCOIS MITTERRAND"];
listeLycees["MONTAUBAN"] = ["LYCEE BOURDELLE", "LYCEE JULES MICHELET", "LYCEE PIERRE MARIE THEAS"];
listeLycees["MONTEILS"] = ["LYCEE CLAUDE NOUGARO"];
listeLycees["BELFORT"] = ["LYCEE CONDORCET", "LYCEE GUSTAVE COURBET", "LYCEE NOTRE-DAME DES ANGES", "LYCEE RAOUL FOLLEREAU", "LYCEE SAINTE MARIE"];
listeLycees["ARGENTEUIL"] = ["LYCEE FERNAND ET NADIA", "LYCEE GEORGES BRAQUE (GENERAL ET TECHNO.)", "LYCEE JEAN JAURES (GENERAL ET TECHNO.)", "LYCEE JULIE-VICTOIRE DAUBIE"];
listeLycees["BEAUMONT SUR OISE"] = ["LYCEE EVARISTE GALOIS (GENERAL ET TECHNO.)"];
listeLycees["CERGY"] = ["LYCEE ALFRED KASTLER", "LYCEE GALILEE", "LYCEE JULES VERNE (GENERAL ET TECHNO.)"];
listeLycees["DEUIL LA BARRE"] = ["LYCEE CAMILLE SAINT SAENS"];
listeLycees["DOMONT"] = ["LYCEE GEORGE SAND (GENERAL ET TECHNO.)"];
listeLycees["EAUBONNE"] = ["LYCEE LOUIS ARMAND (GENERAL ET TECHNO.)"];
listeLycees["ENGHIEN LES BAINS"] = ["LYCEE GUSTAVE MONOD", "LYCEE N-DAME DE LA PROVIDENCE"];
listeLycees["ERMONT"] = ["LYCEE VAN GOGH"];
listeLycees["FOSSES"] = ["LYCEE CHARLES BAUDELAIRE (GENERAL ET TECHNO.)"];
listeLycees["FRANCONVILLE LA GARENNE"] = ["LYCEE JEAN MONNET (GENERAL ET TECHNO.)"];
listeLycees["GARGES LES GONESSE"] = ["LYCEE SIMONE DE BEAUVOIR"];
listeLycees["GONESSE"] = ["LYCEE RENE CASSIN"];
listeLycees["GOUSSAINVILLE"] = ["LYCEE ROMAIN ROLLAND (GENERAL ET TECHNO.)"];
listeLycees["HERBLAY"] = ["LYCEE MONTESQUIEU"];
listeLycees["JOUY LE MOUTIER"] = ["LYCEE DE L HAUTIL (GENERAL ET TECHNO.)"];
listeLycees["L ISLE ADAM"] = ["LYCEE FRAGONARD"];
listeLycees["LUZARCHES"] = ["LYCEE GERARD DE NERVAL"];
listeLycees["MARGENCY"] = ["LYCEE NOTRE-DAME DE BURY"];
listeLycees["MONTMORENCY"] = ["LYCEE JEAN-JACQUES ROUSSEAU"];
listeLycees["OSNY"] = ["LYCEE PAUL-EMILE VICTOR (GENERAL ET TECHNO.)"];
listeLycees["PONTOISE"] = ["LYCEE CAMILLE PISSARRO", "LYCEE N-DAME DE LA COMPASSION", "LYCEE SAINT MARTIN DE FRANCE", "LYCEE VAUBAN"];
listeLycees["SANNOIS"] = ["LYCEE NOTRE-DAME"];
listeLycees["SARCELLES"] = ["LYCEE DE LA TOURELLE (GENERAL ET TECHNO.)", "LYCEE DU SAINT ROSAIRE", "LYCEE JEAN-JACQUES ROUSSEAU (GENERAL ET TECHNO.)", "LYCEE OZAR HATORAH", "LYCEE TORAT EMET"];
listeLycees["ST OUEN L AUMONE"] = ["LYCEE EDMOND ROSTAND", "LYCEE JEAN PERRIN (GENERAL ET TECHNO.)"];
listeLycees["ST WITZ"] = ["LYCEE LEONARD DE VINCI"];
listeLycees["TAVERNY"] = ["LYCEE JACQUES PREVERT", "LYCEE LOUIS JOUVET (GENERAL ET TECHNO.)"];
listeLycees["VAUREAL"] = ["LYCEE CAMILLE CLAUDEL (GENERAL ET TECHNO.)"];
listeLycees["VILLIERS LE BEL"] = ["LYCEE O R T (GENERAL ET TECHNO.)"];
listeLycees["ALFORTVILLE"] = ["LYCEE MAXIMILIEN PERRET"];
listeLycees["BOISSY ST LEGER"] = ["LYCEE BERNARD PALISSY"];
listeLycees["CACHAN"] = ["LYCEE GUSTAVE EIFFEL (GENERAL ET TECHNO.)", "LYCEE MAXIMILIEN SORRE"];
listeLycees["CHAMPIGNY SUR MARNE"] = ["LYCEE LANGEVIN-WALLON (GENERAL ET TECHNO.)", "LYCEE LOUISE MICHEL", "LYCEE MARX DORMOY"];
listeLycees["CHARENTON LE PONT"] = ["LYCEE NOTRE-DAME DES MISSIONS", "LYCEE ROBERT SCHUMAN (GENERAL ET TECHNO.)"];
listeLycees["CHENNEVIERES SUR MARNE"] = ["LYCEE SAMUEL DE CHAMPLAIN"];
listeLycees["CHOISY LE ROI"] = ["LYCEE SAINT ANDRE"];
listeLycees["CRETEIL"] = ["LYCEE ANTOINE DE SAINT EXUPERY (GENERAL ET TECHNO.)", "LYCEE EDOUARD BRANLY (GENERAL ET TECHNO.)", "LYCEE JOHANNES GUTENBERG (GENERAL ET TECHNO.)", "LYCEE LEON BLUM (GENERAL ET TECHNO.)", "LYCEE OZAR HATORAH FILLES"];
listeLycees["FONTENAY SOUS BOIS"] = ["LYCEE PABLO PICASSO"];
listeLycees["FRESNES"] = ["LYCEE FREDERIC MISTRAL (GENERAL ET TECHNO.)"];
listeLycees["IVRY SUR SEINE"] = ["LYCEE FERNAND LEGER", "LYCEE ROMAIN ROLLAND"];
listeLycees["JOINVILLE LE PONT"] = ["LYCEE DU VAL DE BEAUTE"];
listeLycees["LE KREMLIN BICETRE"] = ["LYCEE DARIUS MILHAUD (GENERAL ET TECHNO.)"];
listeLycees["LE PERREUX SUR MARNE"] = ["LYCEE PAUL DOUMER (GENERAL ET TECHNO.)"];
listeLycees["LIMEIL BREVANNES"] = ["LYCEE GUILLAUME BUDE (GENERAL ET TECHNO.)"];
listeLycees["MAISONS ALFORT"] = ["LYCEE EUGENE DELACROIX"];
listeLycees["NOGENT SUR MARNE"] = ["LYCEE ALBERT DE MUN", "LYCEE EDOUARD BRANLY", "LYCEE LOUIS ARMAND (GENERAL ET TECHNO.)", "LYCEE MONTALEMBERT"];
listeLycees["ST MANDE"] = ["LYCEE SAINT MICHEL DE PICPUS"];
listeLycees["ST MAUR DES FOSSES"] = ["LYCEE CONDORCET (GENERAL ET TECHNO.)", "LYCEE D\'ARSONVAL", "LYCEE FRANCOIS MANSART", "LYCEE MARCELIN BERTHELOT", "LYCEE TEILHARD DE CHARDIN"];
listeLycees["SUCY EN BRIE"] = ["LYCEE CHRISTOPHE COLOMB (GENERAL ET TECHNO.)", "LYCEE MONTALEAU", "LYCEE PETIT VAL"];
listeLycees["THIAIS"] = ["LYCEE GUILLAUME APOLLINAIRE"];
listeLycees["VILLENEUVE LE ROI"] = ["LYCEE GEORGES BRASSENS (GENERAL ET TECHNO.)"];
listeLycees["VILLENEUVE ST GEORGES"] = ["LYCEE FRANCOIS ARAGO (GENERAL ET TECHNO.)"];
listeLycees["VINCENNES"] = ["LYCEE BATIMENT TRAVAUX PUBLICS", "LYCEE GREGOR MENDEL", "LYCEE HECTOR BERLIOZ", "LYCEE N-D DE LA PROVIDENCE"];
listeLycees["VITRY SUR SEINE"] = ["LYCEE ADOLPHE CHERIOUX (GENERAL ET TECHNO.)", "LYCEE EPIN", "LYCEE JEAN MACE"];
listeLycees["BRIGNOLES"] = ["LYCEE RAYNOUARD (GENERAL ET TECHNO.)", "LYCEE SAINTE JEANNE D\'ARC (GENERAL ET TECHNO.)"];
listeLycees["DRAGUIGNAN"] = ["LYCEE JEAN MOULIN"];
listeLycees["FREJUS"] = ["LYCEE ALBERT CAMUS (GENERAL ET TECHNO.)"];
listeLycees["GASSIN"] = ["LYCEE DE GASSIN (GENERAL ET TECHNO.)"];
listeLycees["HYERES"] = ["LYCEE COSTEBELLE (GENERAL ET TECHNO.)", "LYCEE JEAN AICARD", "LYCEE MAINTENON (GENERAL ET TECHNO.)"];
listeLycees["LA GARDE"] = ["LYCEE DU COUDON"];
listeLycees["LA SEYNE SUR MER"] = ["LYCEE BEAUSSIER", "LYCEE PAUL LANGEVIN", "LYCEE SAINTE MARIE"];
listeLycees["LE MUY"] = ["LYCEE DU VAL D\'ARGENS (GENERAL ET TECHNO.)"];
listeLycees["LORGUES"] = ["LYCEE DE LORGUES"];
listeLycees["OLLIOULES"] = ["LYCEE SAINT JOSEPH"];
listeLycees["ST MAXIMIN LA STE BAUME"] = ["LYCEE MAURICE JANETTI (GENERAL ET TECHNO.)"];
listeLycees["ST RAPHAEL"] = ["LYCEE ANTOINE DE SAINT EXUPERY (GENERAL ET TECHNO.)", "LYCEE STANISLAS"];
listeLycees["TOULON"] = ["LYCEE BONAPARTE", "LYCEE DUMONT D\'URVILLE", "LYCEE FENELON", "LYCEE HOTELLERIE ET TOURISME TPM", "LYCEE MARIE FRANCE", "LYCEE NOTRE-DAME", "LYCEE ROUVIERE (GENERAL ET TECHNO.)"];
listeLycees["APT"] = ["LYCEE CHARLES DE GAULLE (GENERAL ET TECHNO.)"];
listeLycees["AVIGNON"] = ["LYCEE FREDERIC MISTRAL", "LYCEE LOUIS PASTEUR", "LYCEE PHILIPPE DE GIRARD", "LYCEE RENE CHAR", "LYCEE SAINT J-B DE LA SALLE", "LYCEE SAINT JOSEPH", "LYCEE THEODORE AUBANEL"];
listeLycees["BOLLENE"] = ["LYCEE LUCIE AUBRAC"];
listeLycees["CARPENTRAS"] = ["LYCEE IMMACULEE CONCEPTION", "LYCEE JEAN-HENRI FABRE (GENERAL ET TECHNO.)", "LYCEE SAINT JOSEPH", "LYCEE VICTOR HUGO"];
listeLycees["CAVAILLON"] = ["LYCEE ISMAEL DAUPHIN"];
listeLycees["L ISLE SUR LA SORGUE"] = ["LYCEE ALPHONSE BENOIT"];
listeLycees["ORANGE"] = ["LYCEE DE L\'ARC", "LYCEE SAINT LOUIS"];
listeLycees["PERTUIS"] = ["LYCEE VAL DE DURANCE (GENERAL ET TECHNO.)"];
listeLycees["CHALLANS"] = ["LYCEE FRANCOIS TRUFFAUT", "LYCEE NOTRE DAME (GENERAL ET TECHNO.)"];
listeLycees["CHANTONNAY"] = ["LYCEE GEORGES CLEMENCEAU", "LYCEE SAINTE MARIE (GENERAL ET TECHNO.)"];
listeLycees["FONTENAY LE COMTE"] = ["LYCEE FRANCOIS RABELAIS (GENERAL ET TECHNO.)", "LYCEE NOTRE-DAME"];
listeLycees["LA ROCHE SUR YON"] = ["LYCEE ALFRED KASTLER - GUITTON (GENERAL ET TECHNO.)", "LYCEE EDOUARD BRANLY", "LYCEE J.DE LATTRE DE TASSIGNY", "LYCEE NOTRE-DAME DU ROC", "LYCEE PIERRE MENDES-FRANCE", "LYCEE SAINT LOUIS (GENERAL ET TECHNO.)", "LYCEE ST JOSEPH"];
listeLycees["LES HERBIERS"] = ["LYCEE JEAN MONNET (GENERAL ET TECHNO.)", "LYCEE JEAN XXIII"];
listeLycees["LES SABLES D OLONNE"] = ["LYCEE SAINTE MARIE DU PORT (GENERAL ET TECHNO.)", "LYCEE SAVARY DE MAULEON"];
listeLycees["LUCON"] = ["LYCEE ATLANTIQUE (GENERAL ET TECHNO.)", "LYCEE SAINTE URSULE (GENERAL ET TECHNO.)"];
listeLycees["MONTAIGU"] = ["LYCEE JEANNE D\'ARC", "LYCEE LEONARD DE VINCI (GENERAL ET TECHNO.)"];
listeLycees["POUZAUGES"] = ["LYCEE N-D DE LA TOURTELIERE"];
listeLycees["ST LAURENT SUR SEVRE"] = ["LYCEE SAINT GABRIEL - SAINT MICHEL"];
listeLycees["CHATELLERAULT"] = ["CITE TECHNIQUE EDOUARD BRANLY", "LYCEE MARCELIN BERTHELOT"];
listeLycees["CIVRAY"] = ["LYCEE ANDRE THEURIET"];
listeLycees["JAUNAY CLAN"] = ["LYCEE PILOTE-LE FUTUROSCOPE"];
listeLycees["LOUDUN"] = ["LYCEE GUY CHAUVET"];
listeLycees["MONTMORILLON"] = ["LYCEE JEAN MOULIN"];
listeLycees["POITIERS"] = ["LYCEE ALIENOR D\'AQUITAINE", "LYCEE CAMILLE GUERIN", "LYCEE DU BOIS D\'AMOUR", "LYCEE ISAAC DE L\'ETOILE (FEUILLANTS)", "LYCEE LOUIS ARMAND", "LYCEE ST J. DE COMPOSTELLE", "LYCEE UNION CHRETIENNE", "LYCEE VICTOR HUGO"];
listeLycees["BRUYERES"] = ["LYCEE JEAN LURCAT"];
listeLycees["EPINAL"] = ["LYCEE CLAUDE GELLEE", "LYCEE LOUIS LAPICQUE", "LYCEE NOTRE DAME - SAINT JOSEPH", "LYCEE PIERRE MENDES-FRANCE"];
listeLycees["GERARDMER"] = ["LYCEE HOTELIER J-B SIMEON CHARDIN", "LYCEE LA HAIE GRISELLE"];
listeLycees["MIRECOURT"] = ["LYCEE JEAN-BAPTISTE VUILLAUME"];
listeLycees["NEUFCHATEAU"] = ["LYCEE PIERRE ET MARIE CURIE (GENERAL ET TECHNO.)"];
listeLycees["REMIREMONT"] = ["LYCEE FILIERE BOIS ANDRE MALRAUX", "LYCEE JEANNE D\'ARC"];
listeLycees["SAINT DIE DES VOSGES"] = ["LYCEE BEAU JARDIN", "LYCEE GEORGES BAUMONT", "LYCEE JULES FERRY"];
listeLycees["AUXERRE"] = ["LYCEE JACQUES AMYOT", "LYCEE JOSEPH FOURIER (GENERAL ET TECHNO.)", "LYCEE SAINT JOSEPH (GENERAL ET TECHNO.)", "LYCEE VAUBAN"];
listeLycees["AVALLON"] = ["LYCEE DES CHAUMES (GENERAL ET TECHNO.)", "LYCEE JEANNE D\'ARC (GENERAL ET TECHNO.)"];
listeLycees["JOIGNY"] = ["LYCEE LOUIS DAVIER (GENERAL ET TECHNO.)"];
listeLycees["SENS"] = ["LYCEE POLYVALENT REGIONAL", "LYCEE SAINT ETIENNE (GENERAL ET TECHNO.)"];
listeLycees["TONNERRE"] = ["LYCEE CHEVALIER D\'EON (GENERAL ET TECHNO.)"];
listeLycees["TOUCY"] = ["LYCEE PIERRE LAROUSSE"];
listeLycees["ACHERES"] = ["LYCEE LOUISE WEISS (GENERAL ET TECHNO.)"];
listeLycees["AUBERGENVILLE"] = ["LYCEE VINCENT VAN GOGH (GENERAL ET TECHNO.)"];
listeLycees["CARRIERES SUR SEINE"] = ["LYCEE LES PIERRES VIVES (GENERAL ET TECHNO.)"];
listeLycees["CONFLANS STE HONORINE"] = ["LYCEE JULES FERRY"];
listeLycees["GUYANCOURT"] = ["LYCEE D\'HOTELLERIE ET DE TOURISME", "LYCEE DE VILLAROY"];
listeLycees["LA CELLE ST CLOUD"] = ["LYCEE PIERRE CORNEILLE"];
listeLycees["LA QUEUE LES YVELINES"] = ["LYCEE JEAN MONNET"];
listeLycees["LE CHESNAY"] = ["LYCEE BLANCHE DE CASTILLE"];
listeLycees["LE VESINET"] = ["LYCEE ALAIN", "LYCEE LE BON SAUVEUR"];
listeLycees["LES MUREAUX"] = ["LYCEE FRANCOIS VILLON", "LYCEE VAUCANSON (GENERAL ET TECHNO.)"];
listeLycees["LIMAY"] = ["LYCEE CONDORCET (GENERAL ET TECHNO.)"];
listeLycees["MAGNANVILLE"] = ["LYCEE DE MAGNANVILLE (GENERAL ET TECHNO.)"];
listeLycees["MAISONS LAFFITTE"] = ["LYCEE DE L\'ERMITAGE"];
listeLycees["MANTES LA JOLIE"] = ["LYCEE JEAN ROSTAND (GENERAL ET TECHNO.)", "LYCEE NOTRE-DAME", "LYCEE SAINT EXUPERY"];
listeLycees["MARLY LE ROI"] = ["LYCEE LOUIS DE BROGLIE"];
listeLycees["MAUREPAS"] = ["LYCEE DUMONT D URVILLE (GENERAL ET TECHNO.)", "LYCEE LES SEPT MARES"];
listeLycees["MONTIGNY LE BRETONNEUX"] = ["LYCEE DESCARTES", "LYCEE EMILIE DE BRETEUIL (GENERAL ET TECHNO.)", "LYCEE SAINT EXUPERY", "LYCEE SAINT FRANCOIS D\'ASSISE"];
listeLycees["PLAISIR"] = ["LYCEE JEAN VILAR"];
listeLycees["POISSY"] = ["LYCEE CHARLES DE GAULLE", "LYCEE LE CORBUSIER"];
listeLycees["RAMBOUILLET"] = ["LYCEE LOUIS BASCAN (GENERAL ET TECHNO.)", "LYCEE SAINTE THERESE"];
listeLycees["SARTROUVILLE"] = ["LYCEE EVARISTE GALOIS"];
listeLycees["ST CYR L ECOLE"] = ["LYCEE MANSART"];
listeLycees["ST GERMAIN EN LAYE"] = ["LYCEE INTERNATIONAL", "LYCEE JEAN-BAPTISTE POQUELIN", "LYCEE JEANNE D ALBRET", "LYCEE LEONARD DE VINCI", "LYCEE NOTRE-DAME", "LYCEE SAINT EREMBERT", "LYCEE ST THOMAS DE VILLENEUVE"];
listeLycees["TRAPPES"] = ["LYCEE PLAINE DE NEAUPHLE"];
listeLycees["VERNEUIL SUR SEINE"] = ["LYCEE NOTRE-DAME"];
listeLycees["VERSAILLES"] = ["LYCEE DES CHATAIGNIERS", "LYCEE HOCHE", "LYCEE JULES FERRY (GENERAL ET TECHNO.)", "LYCEE LA BRUYERE", "LYCEE MARIE CURIE", "LYCEE N-DAME DU GRANDCHAMP", "LYCEE SAINT JEAN HULST"];
listeLycees["VILLEPREUX"] = ["LYCEE SONIA DELAUNAY (GENERAL ET TECHNO.)"];
listeLycees["VILLIERS ST FREDERIC"] = ["LYCEE VIOLLET LEDUC"];

export default listeLycees;
