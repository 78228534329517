import React from 'react';
import {createAppContainer, createSwitchNavigator} from 'react-navigation'
import {createStackNavigator} from 'react-navigation-stack'
import ChooseSchoolScreen from '../Screens/ChooseSchoolScreen'
import LoginScreen from '../Screens/Admin/LoginScreen'
import EventsScreen from "../Screens/Admin/EventsScreen";
import EventAddScreen from "../Screens/Admin/EventAddScreen";
import InscriptionsScreen from "../Screens/Admin/InscriptionsScreen";
import UpdateScreen from "../Screens/Admin/UpdateScreen";
import FormScreen from "../Screens/FormScreen";
import Header from "../Components/Header";


const registerNavigationOptions = () => ({navigation}) => ({
    header: <Header navigation={navigation} stack="main"/>,
});

const registerAdminNavigationOptions = () => ({navigation}) => ({
    header: <Header navigation={navigation} stack="admin"/>,
});

const AdminAuthStack = createStackNavigator(
    {
        AdminLogin: {
            screen: LoginScreen,
            headerMode: 'none',
            navigationOptions: {
                headerVisible: false,
                header: null
            }
        }
    }, {
        initialRouteName: 'AdminLogin',
    }
);

const AdminStack = createStackNavigator(
    {
        AdminEvents: {
            screen: EventsScreen,
            navigationOptions: registerAdminNavigationOptions()
        },
        AdminAddEvent: {
            screen: EventAddScreen,
            navigationOptions: registerAdminNavigationOptions()
        },
        AdminInscription: {
            screen: InscriptionsScreen,
            navigationOptions: registerAdminNavigationOptions()
        },
        AdminUpdate: {
            screen: UpdateScreen,
            navigationOptions: registerAdminNavigationOptions()
        },
    },
    {
        initialRouteName: 'AdminEvents',
    });

const MainStack = createStackNavigator(
    {
        ChooseSchool: {
            screen: ChooseSchoolScreen,
            navigationOptions: registerNavigationOptions()
        },
        Form: {
            screen: FormScreen,
            navigationOptions: registerNavigationOptions()
        },
    }, {
        initialRouteName: 'ChooseSchool',

    });


export default createAppContainer(createSwitchNavigator({
        AdminAuth: AdminAuthStack,
        AdminStack: AdminStack,
        Main: MainStack
    }, {
        initialRouteName: 'Main'
    })
);