import React from 'react';
import {View, Text, StyleSheet, FlatList, TouchableOpacity, Dimensions} from 'react-native';
import {EventsListItem} from "./EventsListItem";
import moment from "moment";
import * as SQLite from 'expo-sqlite';
import {connect} from "react-redux";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from "react-native-responsive-screen";
import { getSalons, insertSalonActuel } from '../Utils/indexedDB';

// const db = SQLite.openDatabase('db.db');

class EventsList extends React.Component {
    state = {
        eventList: [],
        selectedEvent: ''
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.getAllEvents();
    }

    componentDidUpdate(prevProps) {
        if (this.props.newSalonList !== prevProps.newSalonList) {
            this.getAllEvents();
            this.props.dispatch({type: 'UPDATE_LIST_SALONS', needUpdate: false})
        }
    }

    _saveEventToDb = async (salon) => {
        // await db.transaction(tx => {
        //     tx.executeSql(
        //         `INSERT INTO actual_salon(name,date,type) VALUES (?,?,?);`,
        //         [salon.name, salon.date, 'SALON'],
        //         (_,{rows: {_array}}) => console.log({items: _array}),
        //         (t, error) => {
        //             console.log(error);
        //         }
        //     );
        // });

        insertSalonActuel({
            name: salon.name,
            date: salon.date,
            type: 'SALON'
        }, () => {
            const action = {type: 'TOGGLE_SALON', salon: salon};
            this.props.dispatch(action);
        });
    };

    async getAllEvents() {
        console.log("\n\x1b[32mFETCHING THE EVENTS in EventList.js file\n\x1b[0m")

        getSalons((all) => {
            let sorted = all.sort((a, b) => {
                let momentA = moment(a.date, 'DD-MM-YYYY');
                let momentB = moment(b.date, 'DD-MM-YYYY');

                if (momentA.isBefore(momentB)) {
                    return 1
                } else if (momentA.isAfter(momentB)) {
                    return -1
                } else {
                    return 0;
                }
            });

            this.setState({eventList: sorted})
        })

        // await db.transaction(tx => {
        //     tx.executeSql(
        //         `SELECT * FROM events;`,
        //         [],
        //         (_,{rows: {_array}}) => {
        //         let sorted = _array.sort((a, b) => {
        //             let momentA = moment(a.date, 'DD-MM-YYYY');
        //             let momentB = moment(b.date, 'DD-MM-YYYY');

        //             if (momentA.isBefore(momentB)) {
        //                 return 1
        //             } else if (momentA.isAfter(momentB)) {
        //                 return -1
        //             } else {
        //                 return 0;
        //             }
        //         })
        //         this.setState({eventList: sorted})},
        //         (t, error) => {
        //             console.log(error);
        //         }
        //     );
        // });
    }

    _toggleSalon = async (salon) => {
        await this._saveEventToDb(salon);
    };

    render() {
        let width = Dimensions.get('window').width;
        let tablet = width > 480;
        return (
            <View style={tablet ? styles.listView : styles.mobileListView}>
                <View style={tablet ? styles.listTopView : styles.mobileListTopView}>
                    <Text style={tablet ? styles.listTitle : styles.mobileListTitle}>Choix de l'évènement</Text>

                    {/* <TouchableOpacity
                        onPress={() => this.props.navigation.navigate('AdminAddEvent')}>
                        <View style={styles.buttonContainer}>
                            <View style={styles.buttonTextView}>
                                <Text style={tablet ? styles.buttonText : styles.mobileButtonText}>AJOUTER UN FORUM</Text>
                            </View>
                        </View>
                    </TouchableOpacity> */}

                </View>
                <FlatList
                    style={{width: '100%', marginTop: 10,}}
                    data={this.state.eventList}
                    keyExtractor={(item) => {
                        return '' + item.id;
                    }}
                    renderItem={({item}) =>
                        <TouchableOpacity
                            onPress={() => {
                                this._toggleSalon({name: item.name, date: item.date});
                                this.setState({selectedEvent: item});
                            }
                            }>
                            <EventsListItem content={item} selected={item === this.state.selectedEvent}/>
                        </TouchableOpacity>}
                />
            </View>
        )
    }
}

const styles = StyleSheet.create({
    listTitle: {
        width: 220,
        height: 26,
        fontFamily: "HelveticaNeue",
        fontSize: 22,
        fontWeight: "500",
        fontStyle: "normal",
        lineHeight: 27,
        letterSpacing: 0,
        textAlign: "center",
        color: "#022c6d"
    },
    buttonText: {
        fontFamily: "HelveticaNeue",
        fontSize: wp("1.8%"),
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        color: "#ffffff"
    },
    buttonContainer: {
        // width: 210,
        // height: 35,
        backgroundColor: "#022c6d",
        justifyContent: 'center',
        alignItems: 'center',
        transform: [{skewX: '-40deg'}],
        paddingHorizontal: 32
    },
    buttonTextView: {
        transform: [{skewX: '40deg'}]
    },
    listTopView: {
        width: '95%',
        height: '10%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 16
    },
    listView: {
        width: "60%",
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mobileListView: {
        width: "90%",
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mobileListTopView: {
        width: '95%',
        height: '15%',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 16
    },
    mobileListTitle: {
        width: "80%",
        height: hp("2.5%"),
        fontFamily: "HelveticaNeue",
        fontSize: 15,
        fontWeight: "500",
        fontStyle: "normal",
        lineHeight: hp("2.5%"),
        letterSpacing: 0,
        textAlign: "center",
        color: "#022c6d",
        marginBottom:15
    },

    mobileButtonText: {
        fontFamily: "HelveticaNeue",
        fontSize: 14,
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: 18,
        letterSpacing: 0,
        textAlign: "center",
        color: "#ffffff"
    },
});


const mapStateToProps = (state) => {
    return state.listSalons;
};

export default connect(mapStateToProps,)(EventsList)