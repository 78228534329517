import React from 'react';
import {View, Text, StyleSheet, Dimensions} from 'react-native';
import {connect} from "react-redux";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from "react-native-responsive-screen";

class ChooseButton extends React.Component {

    constructor(props) {
        super(props);
    }



    render() {

        let width = Dimensions.get('window').width;
        let tablet = width > 480;
        
        const styles = StyleSheet.create({
            buttonView: {
                width: '80%',
                height: '65%',
                borderRadius: 5,
                backgroundColor: "#ffffff",
                shadowColor: "rgba(2, 44, 109, 0.37)",
                shadowOffset: {
                    width: 0,
                    height: 3
                },
                shadowRadius: 6,
                shadowOpacity: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                elevation:5,
                flexShrink:0.5

            },
            buttonText: {
                fontFamily: "HelveticaNeue",
                fontSize: tablet ? wp("1.5%") : wp("3.5%"),
                fontWeight: "500",
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: "center",
                color: this.props.color,
                justifyContent: 'center',
                alignItems: 'center',
                margin:'auto'
            },
            selectedButton: {
                backgroundColor: this.props.color,
            },
            selectedButtonText: {
                color: "#ffffff"
            },

        });

        return (
            <View style={[styles.buttonView, this.props.selected ? styles.selectedButton : null]}>
                <Text
                    style={[styles.buttonText,  this.props.selected ? styles.selectedButtonText : null]}>
                    {this.props.text}
                </Text>

            </View>
        )
    }
}

const mapStateToProps = (state) => {
    return state.school;
};

export default connect(mapStateToProps)(ChooseButton);