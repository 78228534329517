import {
    Alert,
} from 'react-native';

const onInit = (event) => {
    let db = event.target.result;

    let salons = db.createObjectStore('Salons', {
        autoIncrement: true
    });

    let inscriptions = db.createObjectStore('Inscriptions', {
        autoIncrement: true
    });

    let salonActuel = db.createObjectStore('SalonActuel', {
        autoIncrement: true
    });

    let updateSalon = db.createObjectStore('UpdateSalon', {
        autoIncrement: true
    });
};

export const insertSalons = (data, onFinish) => {
    const request = indexedDB.open('MainDB', 1);

    request.onerror = (event) => {
        console.error(`Database error: ${event.target.errorCode}`);
    };
    
    request.onsuccess = (event) => {
        const db = event.target.result;
        
        const txn = db.transaction('Salons', 'readwrite');

        const store = txn.objectStore('Salons');

        store.clear();
        
        for (let event of data) {
            store.put({
                id: event.id,
                name: event.id,
                date: event.date
            });
        }

        if (onFinish)
            onFinish();
    };

    request.onupgradeneeded = onInit;
}

export const getSalons = (onSuccess) => {
    const request = indexedDB.open('MainDB', 1);

    request.onerror = (event) => {
        console.error(`Database error: ${event.target.errorCode}`);
    };
    
    request.onsuccess = (event) => {
        const db = event.target.result;
        
        const txn = db.transaction('Salons', 'readwrite');

        const store = txn.objectStore('Salons');

        const all = store.getAll();
        
        all.onsuccess = () => {
            if (onSuccess)
                onSuccess(all.result);
        }
    };

    request.onupgradeneeded = onInit;
}

export const insertSalonActuel = (data, onFinish) => {
    const request = indexedDB.open('MainDB', 1);

    request.onerror = (event) => {
        console.error(`Database error: ${event.target.errorCode}`);
    };
    
    request.onsuccess = (event) => {
        const db = event.target.result;
        
        const txn = db.transaction('SalonActuel', 'readwrite');

        const store = txn.objectStore('SalonActuel');

        const clear = store.clear();

        clear.onsuccess = () => {
            const put = store.put(data);

            put.onsuccess = () => {
                if (onFinish)
                    onFinish();
            };
        };
    };

    request.onupgradeneeded = onInit;
}

export const getSalonActuel = (onSuccess) => {
    const request = indexedDB.open('MainDB', 1);

    request.onerror = (event) => {
        console.error(`Database error: ${event.target.errorCode}`);
    };
    
    request.onsuccess = (event) => {
        const db = event.target.result;
        
        const txn = db.transaction('SalonActuel', 'readwrite');

        const store = txn.objectStore('SalonActuel');

        const all = store.getAll();
        
        all.onsuccess = () => {
            if (onSuccess)
                onSuccess(all.result);
        }
    };

    request.onupgradeneeded = onInit;
}

export const setUpdateSalon = (data) => {
    const request = indexedDB.open('MainDB', 1);

    request.onerror = (event) => {
        console.error(`Database error: ${event.target.errorCode}`);
    };
    
    request.onsuccess = (event) => {
        const db = event.target.result;
        
        const txn = db.transaction('UpdateSalon', 'readwrite');

        const store = txn.objectStore('UpdateSalon');

        store.put(data);
    };

    request.onupgradeneeded = onInit;
}

export const getUpdateSalon = (onSuccess) => {
    const request = indexedDB.open('MainDB', 1);

    request.onerror = (event) => {
        console.error(`Database error: ${event.target.errorCode}`);
    };
    
    request.onsuccess = (event) => {
        const db = event.target.result;
        
        const txn = db.transaction('UpdateSalon', 'readwrite');

        const store = txn.objectStore('UpdateSalon');

        const all = store.getAll();
        
        all.onsuccess = () => {
            if (onSuccess)
                onSuccess(all.result);
        }
    };

    request.onupgradeneeded = onInit;
}

export const insertInscription = (data, onSuccess) => {
    const request = indexedDB.open('MainDB', 1);

    request.onerror = (event) => {
        console.error(`Database error: ${event.target.errorCode}`);
    };
    
    request.onsuccess = (event) => {
        const db = event.target.result;
        
        const txn = db.transaction('Inscriptions', 'readwrite');

        const store = txn.objectStore('Inscriptions');

        const insert = store.put(data);

        insert.onsuccess = () => {
            if (onSuccess)
                onSuccess();
        };
    };

    request.onupgradeneeded = onInit;
}

export const getInscriptions = (onSuccess) => {
    const request = indexedDB.open('MainDB', 1);

    request.onerror = (event) => {
        console.error(`Database error: ${event.target.errorCode}`);
    };
    
    request.onsuccess = (event) => {
        const db = event.target.result;
        
        const txn = db.transaction('Inscriptions', 'readwrite');

        const store = txn.objectStore('Inscriptions');

        const all = store.getAll();
        
        all.onsuccess = () => {
            if (onSuccess)
                onSuccess(all.result);
        }
    };

    request.onupgradeneeded = onInit;
}

export const setUploaded = (onSuccess) => {
    const request = indexedDB.open('MainDB', 1);

    request.onerror = (event) => {
        console.error(`Database error: ${event.target.errorCode}`);
    };
    
    request.onsuccess = (event) => {
        const db = event.target.result;
        
        const txn = db.transaction('Inscriptions', 'readwrite');

        const store = txn.objectStore('Inscriptions');

        const all = store.getAll();
        
        all.onsuccess = () => {
            store.clear();

            for (let insc of all.result) {
                insc.uploaded = 1;

                store.put(insc);
            }

            if (onSuccess)
                onSuccess(all.result);
        }
    };

    request.onupgradeneeded = onInit;
}